import { useState } from "react";
import { useAccount, useProvider } from "wagmi";
import { blockExplorers, SupportedChainId } from "connectors/chains";
import useChainId from "hooks/useChainId";
import useSigner from "hooks/useSigner";
import { useStore } from "store/zustand";
import {
  confirmStaking,
  withdrawAllNfts,
  withdrawDeposits,
} from "utils/stakingFunctions";
import useRPADBalance from "views/Stake/hooks/useRPADBalance";
import { doc, setDoc } from "firebase/firestore";
import { db } from "services/firebase";
import { useGetIsWalletExist } from "services/queries";
import { UNIQUE_WALLET_LIST_FIREBASE_KEY } from "config/exchange";

export default function useConfirmStaking(
  rpadIn: number = 0,
  rpadLpIn: number = 0,
  lockPeriod: number = 0,
  isExtend?: boolean,
  isIncrease?: boolean
) {
  const [isLoading, setIsLoading] = useState(false);
  const chainId = useChainId();
  const provider = useProvider();
  const signer = useSigner();
  const { address } = useAccount();
  const { refreshBalance } = useRPADBalance();
  const selectedNFTIds = useStore((state) => state.selectedNFTIds);
  const [updateStakedRPAD, updateStakedRPADLP, updateStakedNFTs, updateToast] =
    useStore((state) => [
      state.updateStakedRPAD,
      state.updateStakedRPADLP,
      state.updateStakedNFTs,
      state.updateToast,
    ]);
  const [stakedRPAD, stakedRPADLP, stakedNFTs] = useStore((state) => [
    state.stakedRPAD,
    state.stakedRPADLP,
    state.stakedNFTs,
  ]);

  const { data: isWalletExist } = useGetIsWalletExist(address);
  const refreshStakedValues = () => {
    if (rpadIn > 0) {
      updateStakedRPAD(stakedRPAD + rpadIn);
    }
    if (rpadLpIn > 0) {
      updateStakedRPADLP(stakedRPADLP + rpadLpIn);
    }
    if (selectedNFTIds?.length > 0) {
      updateStakedNFTs([...stakedNFTs, ...selectedNFTIds]);
    }
  };

  const onConfirmStaking = async (onClear?: () => void) => {
    if (!provider || !signer) return;

    try {
      setIsLoading(true);
      await confirmStaking(
        signer,
        rpadIn,
        rpadLpIn,
        selectedNFTIds,
        lockPeriod,
        chainId as SupportedChainId
      )
        .then((response: any) => {
          return response.hash;
        })
        .then(async (hash: string) => {
          return await provider.waitForTransaction(hash, 2);
        })
        .then(async (receipt) => {
          if (!isWalletExist) {
            await setDoc(
              doc(db, UNIQUE_WALLET_LIST_FIREBASE_KEY, address as string),
              {
                address,
                joinDate: new Date(),
              }
            );
          }
          const successMessage = `Successfully staked ${getStakingDescription(
            rpadIn,
            rpadLpIn,
            selectedNFTIds?.length || 0
          )}`;
          const txUrl = `${blockExplorers.get(chainId)}/tx/${
            receipt.transactionHash
          }`;
          updateToast({
            message: isExtend
              ? `Successfully extended locktime`
              : isIncrease
              ? `Successfully increased stake`
              : successMessage,
            url: txUrl,
            type: "success",
          });
          refreshBalance();
          refreshStakedValues();
          !!onClear && onClear();
        });
    } catch (error: any) {
      updateToast({
        message: `Transaction Failed ${error.message}`,
        url: "",
        type: "error",
      });
      console.log("stake error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmWithdrawDeposits = async (onClear?: () => void) => {
    if (!provider || !signer) return;

    try {
      setIsLoading(true);
      await withdrawDeposits(signer, chainId as SupportedChainId)
        .then((response: any) => {
          return response.hash;
        })
        .then(async (hash: string) => {
          return await provider.waitForTransaction(hash);
        })
        .then((receipt) => {
          const successMessage = `Successfully withdrew deposits`;
          const txUrl = `${blockExplorers.get(chainId)}/tx/${
            receipt.transactionHash
          }`;
          updateToast({
            message: successMessage,
            url: txUrl,
            type: "success",
          });
          refreshBalance();
          refreshStakedValues();
          !!onClear && onClear();
        });
    } catch (error: any) {
      updateToast({
        message: `Transaction Failed ${error.message}`,
        url: "",
        type: "error",
      });
      console.log("stake error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmWithdrawAllNfts = async (onClear?: () => void) => {
    if (!provider || !signer) return;

    try {
      setIsLoading(true);
      await withdrawAllNfts(signer, chainId as SupportedChainId)
        .then((response: any) => {
          return response.hash;
        })
        .then(async (hash: string) => {
          return await provider.waitForTransaction(hash);
        })
        .then((receipt) => {
          const successMessage = `Successfully withdrew all NFTs`;
          const txUrl = `${blockExplorers.get(chainId)}/tx/${
            receipt.transactionHash
          }`;
          updateToast({
            message: successMessage,
            url: txUrl,
            type: "success",
          });
          refreshBalance();
          refreshStakedValues();
          !!onClear && onClear();
        });
    } catch (error: any) {
      updateToast({
        message: `Transaction Failed ${error.message}`,
        url: "",
        type: "error",
      });
      console.log("stake error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onConfirmStaking,
    onConfirmWithdrawDeposits,
    onConfirmWithdrawAllNfts,
    isLoading,
  };
}

export const getStakingDescription = (
  rpadIn: number,
  rpadLpIn: number,
  numberOfNfts: number
) => {
  let desc = "";
  if (rpadIn > 0) {
    desc += `${rpadIn} RHUB, `;
  }
  if (rpadLpIn > 0) {
    desc += `${rpadLpIn} RHUB-LP `;
  }

  if (numberOfNfts > 0) {
    desc += `and ${numberOfNfts} NFTs`;
  }

  return desc;
};
