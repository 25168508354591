import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import { useRewardLockAddress } from "hooks/contracts/useContractAddresses";
import { useRewardLockInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "store/zustand";
import { useAccount, useSigner, useWaitForTransaction } from "wagmi";

export default function useConfirmUnlock(pid: number, onClear: () => void) {
  const chainId = useChainId();
  const { address } = useAccount();
  const updateToast = useStore((state) => state.updateToast);
  const rewardLockInterface = useRewardLockInterface();
  const { data: signer } = useSigner();
  const rewardLockAddress = useRewardLockAddress();
  const mutationHashRef = useRef<string>("");
  const { mutate, isLoading } = useMutation(
    async () => {
      const rewardContract = new ethers.Contract(
        rewardLockAddress,
        rewardLockInterface,
        signer
      );

      const result = await rewardContract.unlock(address, pid);
      return result.hash;
    },
    {
      onSuccess: (hash) => {
        mutationHashRef.current = hash;
      },
      onError: (error: any) => {
        updateToast({
          type: "error",
          message: `Transaction Failed ${error?.message}`,
          url: "",
        });
      },
    }
  );
  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    confirmations: 2,
    hash: mutationHashRef?.current,
    enabled: !!mutationHashRef.current,
    onSuccess: () => {
      updateToast({
        type: "success",
        message: `You have successfully claimed your staked RHUB.`,
        url: `${blockExplorers.get(chainId)}/tx/${mutationHashRef?.current}`,
      });
    },
    onError: (error: any) => {
      updateToast({
        type: "error",
        message: `Transaction Failed ${error?.message}`,
        url: "",
      });
    },
    onSettled: () => {
      onClear();
    },
  });

  return {
    isLoading: isLoading || isWaitingForTransaction,
    confirmUnlock: mutate,
  };
}

export function useConfirmEmergencyUnlock(
  enabled: boolean,
  onClear: () => void
) {
  const chainId = useChainId();
  const updateToast = useStore((state) => state.updateToast);
  const rewardLockInterface = useRewardLockInterface();
  const { data: signer } = useSigner();
  const rewardLockAddress = useRewardLockAddress();
  const mutationHashRef = useRef<string>("");
  const { mutate, isLoading } = useMutation(
    async () => {
      const rewardContract = new ethers.Contract(
        rewardLockAddress,
        rewardLockInterface,
        signer
      );

      const result = await rewardContract.emergencyUnlockAll();
      return result.hash;
    },
    {
      onSuccess: (hash) => {
        mutationHashRef.current = hash;
      },
      onError: (error: any) => {
        updateToast({
          type: "error",
          message: `Transaction Failed ${error?.message}`,
          url: "",
        });
      },
    }
  );
  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    confirmations: 2,
    hash: mutationHashRef?.current,
    enabled: !!mutationHashRef.current,
    onSuccess: () => {
      updateToast({
        type: "success",
        message: `You have successfully claimed your staked RHUB.`,
        url: `${blockExplorers.get(chainId)}/tx/${mutationHashRef?.current}`,
      });
    },
    onError: (error: any) => {
      updateToast({
        type: "error",
        message: `Transaction Failed ${error?.message}`,
        url: "",
      });
    },
    onSettled: () => {
      !!onClear && onClear();
    },
  });

  return {
    isLoading: isLoading || isWaitingForTransaction,
    confirmEmergencyUnlock: mutate,
  };
}
