import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { CgClose } from "react-icons/cg";
import GradientButton from "components/GradientBtn";
import GradientBtnAlt from "components/GradientBtnAlt";
import { useConfirmEmergencyUnlock } from "./hooks/useConfirmUnlock";

export const StyledCloseIcon = styled(CgClose)`
  opacity: 0.64;

  &:hover {
    cursor: pointer;
  }
`;
export const StyledModal = styled(Modal)`
  width: 100%;

  .modal-content {
    background: linear-gradient(
      180deg,
      rgba(28, 28, 28, 0.2) 0%,
      rgba(2, 2, 2, 0.2) 100%,
      rgba(2, 2, 2, 0.2) 100%
    );
    box-shadow: inset 0px 4px 18px rgba(255, 0, 153, 0.4);
    backdrop-filter: blur(80px);
    transform-style: preserve-3d;
    min-height: 150px;

    .modal-title.h4,
    .modal-body p {
      font-family: "Nasalization";
    }

    .modal-header,
    .modal-footer {
      border-bottom: none;
      border-top: none;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
    }
  }
`;

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid rgba(243, 8, 137, 0.4);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;

  .modal-title.h4 {
    font-size: 1.15rem !important;
    color: rgb(255, 0, 137) !important;
  }
`;

export const Description = styled.p`
  margin-bottom: 0;
  padding: 0 1rem;
  line-height: 1.5rem;
  font-size: 1rem;
`;

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 1.7rem;

  button {
    width: 50%;
  }
`;

const WarningText = styled.span`
  font-size: 1.17rem;
  color: red;
  font-weight: 700;
`;

export default function ConfirmEmergencyUnlockModal({
  handleClose,
  show,
}: {
  handleClose: () => void;
  show: boolean;
}) {
  const { confirmEmergencyUnlock, isLoading } = useConfirmEmergencyUnlock(
    show,
    () => handleClose()
  );

  return (
    <StyledModal show={show} onHide={handleClose}>
      <StyledModalHeader>
        <Modal.Title>Confirm Emergency Unlock</Modal.Title>
        <StyledCloseIcon onClick={handleClose} />
      </StyledModalHeader>
      <Modal.Body>
        <Description>
          Are you sure you want to <WarningText>Emergency Unlock</WarningText>?
          This will unlock all your pending withdrawals and you will only get
          50%.{" "}
        </Description>
      </Modal.Body>
      <ModalActions>
        <GradientBtnAlt label="Cancel" onClick={handleClose} />
        <GradientButton
          label="Confirm"
          onClick={confirmEmergencyUnlock}
          loading={isLoading}
        />
      </ModalActions>
    </StyledModal>
  );
}
