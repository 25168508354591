import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import {
  useLPTokenAddress,
  useRHUBTokenAddress,
  useRoseApeTokenAddress,
  useStakeMasterAddress,
} from "hooks/contracts/useContractAddresses";
import { useRoseApesNFTInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "store/zustand";
import { erc20ABI, useSigner, useWaitForTransaction } from "wagmi";
import useRPADAllowance from "./useRPADAllowance";

export function useApproveRoseHub() {
  const chainId = useChainId();
  const stakingContractAddress = useStakeMasterAddress();
  const allowance = useRPADAllowance();
  const updateToast = useStore((state) => state.updateToast);
  const rpadAddress = useRHUBTokenAddress();
  const { data: signer } = useSigner();
  const {
    mutate: write,
    data: writeData,
    isLoading: writeLoading,
  } = useMutation(
    async () => {
      if (!signer) return;
      const contract = new ethers.Contract(rpadAddress, erc20ABI, signer);
      const result = await contract.approve(
        stakingContractAddress,
        ethers.constants.MaxUint256
      );
      return result;
    },
    {
      onError: (error) => {
        console.log("useApproveRHUB error", error);
      },
    }
  );

  const {
    isError,
    error: transactionError,
    isLoading: transactionLoading,
    isSuccess,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    onSettled: () => {
      console.log("useApproveRHUB settled");
      allowance?.refetchAllowances();
    },
    onSuccess: () => {
      updateToast({
        type: "success",
        message: "RHUB approved",
        url: `${blockExplorers.get(chainId)}/tx/${writeData?.hash}`,
      });
    },
  });
  return {
    write,
    isLoading: writeLoading || transactionLoading,
    error: isError ? transactionError : undefined,
    isSuccess,
  };
}

export function useApproveLP() {
  const chainId = useChainId();
  const updateToast = useStore((state) => state.updateToast);
  const stakingContractAddress = useStakeMasterAddress();
  const lpAddress = useLPTokenAddress();
  const allowance = useRPADAllowance();
  const { data: signer } = useSigner();
  const {
    mutate: write,
    data: writeData,
    isLoading: writeLoading,
  } = useMutation(
    async () => {
      if (!signer) return;
      const contract = new ethers.Contract(lpAddress, erc20ABI, signer);
      const result = await contract.approve(
        stakingContractAddress,
        ethers.constants.MaxUint256
      );
      return result;
    },
    {
      onError: (error) => {
        console.log("useApproveRHUB-LP error", error);
        allowance?.refetchAllowances();
      },
    }
  );

  const {
    isError,
    isLoading: transactionLoading,
    error: transactionError,
    isSuccess,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    onSettled: () => {
      console.log("useApproveRHUBLP settled");
      allowance?.refetchAllowances();
    },
    onSuccess: () => {
      updateToast({
        type: "success",
        message: "RHUB-LP approved",
        url: `${blockExplorers.get(chainId)}/tx/${writeData?.hash}`,
      });
    },
  });
  return {
    write,
    isLoading: writeLoading || transactionLoading,
    error: isError ? transactionError : undefined,
    isSuccess,
  };
}

export function useApproveNFTs() {
  const chainId = useChainId();
  const allowance = useRPADAllowance();
  const stakingContractAddress = useStakeMasterAddress();
  const roseApesInterface = useRoseApesNFTInterface();
  const updateToast = useStore((state) => state.updateToast);
  const nftAddress = useRoseApeTokenAddress();
  const { data: signer } = useSigner();
  const {
    mutate: write,
    data: writeData,
    isLoading: writeLoading,
  } = useMutation(
    async () => {
      if (!signer) return;
      const nftContract = new ethers.Contract(
        nftAddress,
        roseApesInterface,
        signer
      );
      const result = await nftContract.setApprovalForAll(
        stakingContractAddress,
        true
      );
      return result;
    },
    {
      onError: (error) => {
        console.log("useApproveNFTs error", error);
      },
    }
  );

  const {
    isError,
    isLoading: transactionLoading,
    error: transactionError,
    isSuccess,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    onSuccess: () => {
      updateToast({
        type: "success",
        message: "RoseApe NFT contract approved",
        url: `${blockExplorers.get(chainId)}/tx/${writeData?.hash}`,
      });
    },
    onSettled: () => {
      console.log("useApproveNFTs settled");
      allowance?.refetchAllowances();
    },
  });
  return {
    write,
    isLoading: writeLoading || transactionLoading,
    error: isError ? transactionError : undefined,
    isSuccess,
  };
}
