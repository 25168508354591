import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";
import useChainId from "hooks/useChainId";
import { useStore } from "store/zustand";
import {
  erc20ABI,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { useGetWROSE } from "./useGetAmountsOut";
import useSwapAllowance from "./useSwapAllowance";

export default function useApproveSwapAllowance(tokenAddress: string) {
  const chainId = useChainId();
  const updateToast = useStore((state) => state.updateToast);
  const routerAddress = useRouterAddress();
  const wroseAddress = useGetWROSE();
  const swap = useSwapAllowance(tokenAddress);

  const { config } = usePrepareContractWrite({
    addressOrName: tokenAddress || wroseAddress,
    contractInterface: erc20ABI,
    functionName: "approve",
    args: [routerAddress, ethers.constants.MaxUint256],
    onError: (error) => {
      console.log("useApproveSwapAllowance error", error);
    },
    enabled: !!wroseAddress,
  });
  const {
    write,
    data: writeData,
    isLoading: writeLoading,
  } = useContractWrite(config);
  const {
    isError,
    isLoading: transactionLoading,
    error: transactionError,
    isSuccess,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    onSettled: () => {
      console.log("useApproveSwapAllowance settled");
      swap?.refetchSwapAllowance();
    },
    onSuccess: () => {
      updateToast({
        type: "success",
        message: "Contract approved",
        url: `${blockExplorers.get(chainId)}/tx/${writeData?.hash}`,
      });
    },
    enabled: !!wroseAddress,
  });
  return {
    write,
    isLoading: writeLoading || transactionLoading,
    error: isError ? transactionError : undefined,
    isSuccess,
  };
}
