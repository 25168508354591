import { ChainId, Token, WNATIVE } from "@rosehub-tech/sdk";

interface TokenList {
  [symbol: string]: Token;
}

export const defineTokens = <T extends TokenList>(t: T) => t;
const { EMERALD_TESTNET, EMERALD_MAINNET } = ChainId;

export const mainnetTokens = defineTokens({
  wrose: new Token(
    EMERALD_MAINNET,
    "0x21C718C22D52d0F3a789b752D4c2fD5908a8A733",
    18,
    "WROSE",
    "Wrapped ROSE",
    "https://www.oasis.org"
  ),
  rose: new Token(
    EMERALD_MAINNET,
    "0x21C718C22D52d0F3a789b752D4c2fD5908a8A733",
    18,
    "ROSE",
    "Oasis Network",
    "https://www.oasis.org"
  ),
  rhub: new Token(
    EMERALD_MAINNET,
    "0x17933E09231306c16A5d6ebc5E55511eCadd00E3",
    18,
    "RHUB",
    "RoseHub",
    "https://rosehub.io/"
  ),
  rhub_lp: new Token(
    EMERALD_MAINNET,
    "0xc928bdECD1BEFbA06E036260FbbbFda382A57130",
    18,
    "RHUB_LP",
    "RoseHub LP",
    "https://rosehub.io/"
  ),
  usdt: new Token(
    EMERALD_MAINNET,
    "0xdC19A122e268128B5eE20366299fc7b5b199C8e3",
    6,
    "USDT",
    "US Tether",
    "https://tether.to/"
  ),
});

export const testnetTokens = defineTokens({
  wrose: new Token(
    EMERALD_TESTNET,
    "0xFEa03d709361547E99CC927D3717ed96e94e32aC",
    18,
    "WROSE",
    "Wrapped ROSE",
    "https://www.oasis.org"
  ),
  rose: new Token(
    EMERALD_TESTNET,
    "0xFEa03d709361547E99CC927D3717ed96e94e32aC",
    18,
    "ROSE",
    "Oasis Network",
    "https://www.oasis.org"
  ),
  rhub: new Token(
    EMERALD_TESTNET,
    "0xE0b7eA0f9426a5d22Cb0197c881147f75F893520",
    18,
    "RHUB",
    "RoseHub",
    "https://rosehub.io/"
  ),
  rhub_lp: new Token(
    EMERALD_TESTNET,
    "0x4836336c0cec31ba71c66e4c4e91cc4c5dfc1b4c",
    18,
    "RHUB_LP",
    "RoseHub LP",
    "https://rosehub.io/"
  ),
  usdt: new Token(
    EMERALD_TESTNET,
    "0x0Cb878e857E59468c2eeEBE722770a26a0a146E1",
    18,
    "USDT",
    "US Tether",
    "https://tether.to/"
  ),
});

const ROSE_MAINNET = new Token(
  ChainId.EMERALD_MAINNET,
  "0x21C718C22D52d0F3a789b752D4c2fD5908a8A733",
  18,
  "ROSE",
  "Oasis Network",
  "https://rose.io/"
);

const ROSE_TESTNET = new Token(
  EMERALD_TESTNET,
  "0xFEa03d709361547E99CC927D3717ed96e94e32aC",
  18,
  "ROSE",
  "Oasis Network",
  "https://rose.io/"
);

const RHUB_MAINNET = new Token(
  ChainId.EMERALD_MAINNET,
  "0x17933E09231306c16A5d6ebc5E55511eCadd00E3",
  18,
  "RHUB",
  "RoseHub Token",
  "https://rosehub.io/"
);

const RHUB_TESTNET = new Token(
  ChainId.EMERALD_TESTNET,
  "0xE0b7eA0f9426a5d22Cb0197c881147f75F893520",
  18,
  "RHUB",
  "RoseHub Token",
  "https://rosehub.io/"
);

const USDT_MAINNET = new Token(
  ChainId.EMERALD_MAINNET,
  "0xdC19A122e268128B5eE20366299fc7b5b199C8e3",
  6,
  "USDT",
  "USD Tether",
  "https://tether.to/"
);

const USDT_TESTNET = new Token(
  ChainId.EMERALD_TESTNET,
  "0x0Cb878e857E59468c2eeEBE722770a26a0a146E1",
  18,
  "USDT",
  "USD Tether",
  "https://tether.to/"
);

export const ROSE = {
  [ChainId.EMERALD_MAINNET]: ROSE_MAINNET,
  [ChainId.EMERALD_TESTNET]: ROSE_TESTNET,
};

export const RHUB = {
  [ChainId.EMERALD_MAINNET]: RHUB_MAINNET,
  [ChainId.EMERALD_TESTNET]: RHUB_TESTNET,
};

export const USDT = {
  [ChainId.EMERALD_MAINNET]: USDT_MAINNET,
  [ChainId.EMERALD_TESTNET]: USDT_TESTNET,
};

export const emeraldMainnetTokens = {
  // rose: ROSE[ChainId.EMERALD_MAINNET],
  wrose: WNATIVE[ChainId.EMERALD_MAINNET],
  rhub: RHUB[ChainId.EMERALD_MAINNET],
  usdt: USDT[ChainId.EMERALD_MAINNET],
};

export const emeraldTestnetTokens = {
  // rose: ROSE[ChainId.EMERALD_TESTNET],
  wrose: WNATIVE[ChainId.EMERALD_TESTNET],
  rhub: RHUB[ChainId.EMERALD_TESTNET],
  usdt: USDT[ChainId.EMERALD_TESTNET],
};
