import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const cryptoApiHeaders = {
  "x-rapidapi-host": "coinranking1.p.rapidapi.com",
  "x-rapidapi-key": "fe25e44c28msh675075f6a942f6dp1dbb99jsnf1bbb156a085",
};

const baseUrl = "https://coinranking1.p.rapidapi.com";

const createRequest = (url: string) => ({ url, headers: cryptoApiHeaders });

export const cryptoApi: any = createApi({
  reducerPath: "cryptoAPI",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getCryptos: builder.query({
      query: () => createRequest("/coins"),
    }),
  }),
});

const jotFormsApiHeaders = {
  APIKEY: "330bfc6b4a3a2263f3ea5f1d71fcf51e",
};
// const formId = "221106198289258";
const apiKey = "330bfc6b4a3a2263f3ea5f1d71fcf51e";
const jotformsBaseUrls = {
  baseUrl: "https://api.jotform.com/",
  baseUrlForm: "https://api.jotform.com/form/",
  baseUrlFormSubmissions: "https://api.jotform.com/form/{formId}/submissions/",
};

const createJotFormsRequest = (url: string, method: string, body?: any) => {
  return {
    url: `${jotformsBaseUrls.baseUrl}/${url}`,
    method: method,
    headers: jotFormsApiHeaders,
    body: body,
  };
};

export const jotFormsApi = createApi({
  reducerPath: "jotFormsAPI",
  baseQuery: fetchBaseQuery({ baseUrl: jotformsBaseUrls.baseUrl }),
  endpoints: (builder) => ({
    getForms: builder.query({
      query: (formId: string) => createJotFormsRequest(`form/${formId}`, "GET"),
    }),
    getFormSubmissions: builder.query({
      query: (formId: string) =>
        createJotFormsRequest(
          `form/${formId}/submissions?apiKey=${apiKey}`,
          "GET"
        ),
    }),
  }),
});

export const { useGetFormsQuery, useGetFormSubmissionsQuery } = jotFormsApi;

export const getRpadSubmissionsLive = async (form: string) => {
  var axios = require("axios");
  var config = {
    method: "get",
    url: `https://api.jotform.com/form/${form}/submissions?apiKey=${apiKey}`,
    headers: {},
  };
  return axios(config);
};

export const getRpadSubmissionsUpcoming = async (form: string) => {
  var axios = require("axios");
  var config = {
    method: "get",
    url: `https://api.jotform.com/form/${form}/submissions?apiKey=${apiKey}`,
    headers: {},
  };
  return axios(config);
};

export const getRpadSubmissionsFinished = async (form: string) => {
  var axios = require("axios");
  var config = {
    method: "get",
    url: `https://api.jotform.com/form/${form}/submissions?apiKey=${apiKey}`,
    headers: {},
  };
  return axios(config);
};

export const getRpadFormSubmissionsAxios = async (form: string) => {
  var axios = require("axios");
  var config = {
    method: "get",
    url: `https://api.jotform.com/form/${form}/submissions?apiKey=${apiKey}`,
    headers: {},
  };
  return axios(config);
};

export const getRpadSubmissionAxios = async (
  submissionId: string | undefined
) => {
  var axios = require("axios");
  var config = {
    method: "get",
    url: `https://api.jotform.com/submission/${submissionId}?apiKey=${apiKey}`,
    headers: {},
  };
  return axios(config);
};
