import { Currency, JSBI, Percent, Price } from "@rosehub-tech/sdk";
import { useTokenBalance } from "./useTokenBalances";
import useTotalSupply from "./useTokenSupply";

/**
 * Helper to multiply a Price object by an arbitrary amount
 */
export const multiplyPriceByAmount = (
  price: Price<Currency, Currency>,
  amount: number,
  significantDigits = 18
) => {
  try {
    return parseFloat(price.toSignificant(significantDigits)) * amount;
  } catch (error) {
    return 0;
  }
};

export default function useLPValues(account, pair) {
  const userPoolBalance = useTokenBalance(
    account ?? undefined,
    pair.liquidityToken
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
        ]
      : [undefined, undefined];

  // const token0USDValue = token0Deposited
  //   ? multiplyPriceByAmount(
  //       token0Price,
  //       parseFloat(token0Deposited.toSignificant(6))
  //     )
  //   : null;
  // const token1USDValue = token1Deposited
  //   ? multiplyPriceByAmount(
  //       token1Price,
  //       parseFloat(token1Deposited.toSignificant(6))
  //     )
  //   : null;
  // const totalUSDValue =
  //   token0USDValue && token1USDValue ? token0USDValue + token1USDValue : null;

  return {
    token0Deposited,
    token1Deposited,
    totalUSDValue: null,
    poolTokenPercentage,
    userPoolBalance,
  };
}
