import { genID } from "utils/functions";
import Audited1 from "assets/images/peckshield2.png";
import Audited2 from "assets/images/mythx_logo.svg";
export default [
  {
    link: "https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-RosePad-v1.0.pdf",
    src: Audited1,
  },
  {
    link: "#",
    src: Audited2,
  },
].map((e) => ({ ...e, id: genID() }));
