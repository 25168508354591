import { SerializedFarmConfig } from "types";
import { serializeTokens } from "utils/tokens";

const serializedTokens = serializeTokens();

const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: "RHUB-LP",
    lpAddresses: {
      42261: "",
      42262: "0xc928bdecd1befba06e036260fbbbfda382a57130",
    },
    token: serializedTokens.wrose,
    quoteToken: serializedTokens.rhub,
  },
  // {
  //   pid: 1,
  //   lpSymbol: "RHUB-LP",
  //   lpAddresses: {
  //     42261: "",
  //     42262: "0x93c2fe0837b03a0cee6cf7d854730cbdbe29a8e7",
  //   },
  //   token: serializedTokens.usdt,
  //   quoteToken: serializedTokens.rhub,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: "RHUB-LP",
  //   lpAddresses: {
  //     42261: "",
  //     42262: "0xb925242a5a90ef7b339db9149fb5404a29ff2559",
  //   },
  //   quoteToken: serializedTokens.wrose,
  //   token: serializedTokens.usdt,
  // },
  {
    pid: 3,
    lpSymbol: "RHUB-LP",
    lpAddresses: {
      42261: "",
      42262: "0x1553cec909d1b34589c6a1126e071bfce474d577",
    },
    quoteToken: serializedTokens.rhub,
    token: serializedTokens.usdt,
  },
  {
    pid: 4,
    lpSymbol: "RHUB-LP",
    lpAddresses: {
      42261: "",
      42262: "0xea053c5e4a5db3f8bad0f9f89fd26e7eff1c75e7",
    },
    quoteToken: serializedTokens.wrose,
    token: serializedTokens.usdt,
  },
];

export default farms;
