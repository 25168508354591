import { useQuery } from "@tanstack/react-query";
import { ethers } from "ethers";
import {
  useRoseApeTokenAddress,
  useStakeMasterAddress,
} from "hooks/contracts/useContractAddresses";
import { useRoseApesNFTInterface } from "hooks/contracts/useInterfaces";
import { useAccount, useSigner } from "wagmi";

export default function useIsApprovedNFTs() {
  const { data: signer } = useSigner();
  const roseApesInterface = useRoseApesNFTInterface();
  const roseApeAddress = useRoseApeTokenAddress();
  const stakeMasterAddress = useStakeMasterAddress();
  const { address } = useAccount();

  const { data } = useQuery(
    ["isApprovedForAll", address],
    async () => {
      const nftContract = new ethers.Contract(
        roseApeAddress,
        roseApesInterface,
        signer
      );
      const result = nftContract.isApprovedForAll(address, stakeMasterAddress);
      return result;
    },
    {
      enabled: !!signer,
    }
  );

  return data;
}
