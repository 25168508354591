import { Token } from "@rosehub-tech/sdk";
import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";
import { useRouterInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { useState } from "react";
import { useStore } from "store/zustand";
import { removeLiquidity } from "utils/liquidityFunctions";
import { useGetWROSE } from "views/Swap/hooks/useGetAmountsOut";
import { useAccount, useProvider, useSigner } from "wagmi";

export default function useConfirmRemoveLiquidity(
  tokenA: Token,
  tokenB: Token,
  token0Amount: number,
  token1Amount: number,
  liquidityAmount: number
) {
  const [isLoading, setIsLoading] = useState(false);
  const chainId = useChainId();
  const { address } = useAccount();
  const routerInterface = useRouterInterface();
  const { data: signer } = useSigner();
  const provider = useProvider();
  const updateToast = useStore((state) => state.updateToast);
  const wroseAddress = useGetWROSE();
  const routerAddress = useRouterAddress();

  const onRemoveLiquidity = async (onSuccessClear?: () => void) => {
    setIsLoading(true);
    if (!signer || !provider) return;
    const routerContract = new ethers.Contract(
      routerAddress,
      routerInterface,
      signer
    );

    try {
      await removeLiquidity(
        tokenA?.address || wroseAddress,
        tokenB?.address || wroseAddress,
        liquidityAmount,
        "0",
        "0",
        routerContract,
        address as string,
        signer
      )
        .then((response) => {
          return response.hash;
        })
        .then(async (hash: string) => {
          return await provider.waitForTransaction(hash);
        })
        .then((receipt: any) => {
          const successMessage = `Removed liquidity for ${tokenA.symbol} and ${tokenB.symbol}!`;
          const txUrl = `${blockExplorers.get(chainId)}/tx/${
            receipt.transactionHash
          }`;
          updateToast({
            message: successMessage,
            url: txUrl,
            type: "success",
          });
        });
      !!onSuccessClear && onSuccessClear();
    } catch (error: any) {
      updateToast({
        message: `Transaction Failed ${error.message}`,
        url: "",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { onRemoveLiquidity, isLoading };
}
