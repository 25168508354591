import styled from "styled-components";
import { SerializedVaultsConfig } from "types";
import VaultCard from "./VaultCard";

const Container = styled.div`
  // width: 100%;
  // display: grid;
  // grid-template-columns: repeat(3, minmax(24rem, 28rem));
  // grid-auto-rows: 36rem;
  // gap: 2rem;
  // justify-content: center;
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.5rem;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
  }
`;

export default function VaultsGrid({
  vaults,
}: {
  vaults: SerializedVaultsConfig[];
}) {
  return (

    <Container>
      {!!vaults && vaults.length > 0 ? (
        vaults.map((vault, index) => {
          return <VaultCard key={vault.vaultId} vault={vault} />;
        })
      ) : (
        <p>No available vault right now</p>
      )}
    </Container>
  );
}
