import {
  ExternalProvider,
  JsonRpcSigner,
  Web3Provider,
} from "@ethersproject/providers";
import { Contract, ethers, Signer } from "ethers";
import { launchpad, SupportedChainId, tokenAddresses } from "connectors/chains";

import ROUTER from "abi/contracts/swap/RosePadSwapRouter.sol/RosePadSwapRouter.json";
import ERC20 from "abi/@openzeppelin/contracts/token/ERC20/ERC20.sol/ERC20.json";
import FACTORY from "abi/contracts/swap/RosePadSwapFactory.sol/RosePadSwapFactory.json";
import PAIR from "abi/contracts/swap/RosePadSwapPair.sol/RosePadSwapPair.json";
import { Token } from "@rosehub-tech/sdk";

export function getProvider() {
  if (!window?.ethereum) return undefined;
  return new ethers.providers.Web3Provider(window.ethereum as ExternalProvider);
}

export function getSigner(provider: Web3Provider): JsonRpcSigner {
  return provider.getSigner();
}

export function getRouter(address: string, signer: Signer) {
  return new Contract(address, ROUTER, signer);
}

export function getWeth(address: string, signer: Signer) {
  return new Contract(address, ERC20, signer);
}

export function getFactory(signer: Signer, chainId: SupportedChainId) {
  return new Contract(launchpad.get(chainId)?.FACTORY, FACTORY, signer);
}

export async function getSwapAllowance(
  accountAddress: string,
  tokenAddress: string,
  signer: Signer,
  chainId: SupportedChainId
) {
  const tokenContract = new Contract(tokenAddress, ERC20, signer);
  return await tokenContract.allowance(
    accountAddress,
    launchpad.get(chainId).ROUTER
  );
}

export async function getStakingAllowance(
  accountAddress: string,
  tokenAddress: string,
  spenderAddress: string,
  signer: Signer
) {
  const tokenContract = new Contract(tokenAddress, ERC20, signer);
  return await tokenContract
    .allowance(accountAddress, spenderAddress)
    .catch((error: any) => console.log("getStakingAllowance error", error));
}

export async function getLiquidityAllowance(
  accountAddress: string,
  tokenAddress: string,
  signer: Signer,
  chainId: SupportedChainId
) {
  const tokenContract = new Contract(tokenAddress, ERC20, signer);
  return await tokenContract.allowance(
    accountAddress,
    launchpad.get(chainId).ROUTER
  );
}

/**
 * This function checks if a ERC20 token exists for a given address
 *
 * @param address - The Ethereum address to be checked
 * @param signer - The current signer
 * @returns
 * - a boolean value
 */
export function doesTokenExist(address: string, signer: Signer) {
  try {
    return new Contract(address, ERC20, signer);
  } catch (err) {
    return false;
  }
}

export async function getDecimals(token: Contract) {
  const decimals = await token
    .decimals()
    .then((result: any) => {
      return result;
    })
    .catch((error: any) => {
      console.log("No tokenDecimals function for this token, set to 0");
      return 0;
    });
  return decimals;
}

/**
 * This function returns an object with 2 fields: `balance` which container's the account's balance in the particular token,
 * and `symbol` which is the abbreviation of the token name
 *
 * @param accountAddress - An Ethereum address of the current user's account
 * @param address - An Ethereum address of the token to check for (either a token or AUT)
 * @param provider - The current provider
 * @param signer - The current signer
 * @param weth_address
 * @param coins
 * @returns
 * - an object with balance and symbol
 */
export async function getBalanceAndSymbol(
  accountAddress: string,
  address: string,
  provider: Web3Provider,
  signer: Signer,
  weth_address: string,
  coins: any
) {
  try {
    if (!!accountAddress) {
      if (!!address && address === weth_address) {
        const balanceRaw = await provider.getBalance(accountAddress);

        return {
          balance: ethers.utils.formatEther(balanceRaw),
          symbol: coins[0].abbr,
        };
      } else {
        if (!!address) {
          const token = new Contract(address, ERC20, signer);
          const balanceRaw = await token.balanceOf(accountAddress);
          return {
            balance: ethers.utils.formatEther(balanceRaw),
          };
        }
      }
    }
  } catch (error) {
    console.log("The getBalanceAndSymbol function had an error!", error);
    return false;
  }
}

export async function approveSwap(
  routerContract: Contract,
  address1: string,
  amount: string,
  signer: Signer
) {
  const token1 = new Contract(address1, ERC20, signer);
  const tokenDecimals = await getDecimals(token1);
  const amountIn = ethers.utils.parseUnits(amount, tokenDecimals);
  return await token1.approve(routerContract.address, amountIn);
}

/**
 * This function swaps two particular tokens / AUT, it can handle switching from AUT to ERC20 token, ERC20 token to AUT, and ERC20 token to ERC20 token.
 * No error handling is done, so any issues can be caught with the use of .catch()
 *
 * @param address1 - An Ethereum address of the token to trade from (either a token or AUT)
 * @param address2 - An Ethereum address of the token to trade to (either a token or AUT)
 * @param amount - A float or similar number representing the value of address1's token to trade
 * @param routerContract - The router contract to carry out this trade
 * @param account - An Ethereum address of the current user's account
 * @param signer - The current signer
 *
 * @returns
 * - void
 */
export async function swapTokens(
  tokens: string[],
  isAddress1Native: boolean,
  isAddress2Native: boolean,
  amount: string,
  routerContract: Contract,
  account: string,
  signer: Signer
) {
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);

  const token1 = new Contract(tokens[0], ERC20, signer);
  const decimals = await getDecimals(token1);
  const amountIn = ethers.utils.parseUnits(amount, decimals);
  const amountOut = await routerContract.callStatic.getAmountsOut(
    amountIn,
    tokens
  );

  // ROSE to any token
  if (isAddress1Native) {
    return await routerContract.swapExactETHForTokens(
      amountOut[1],
      tokens,
      account,
      deadline,
      { value: amountIn }
    );
  }

  // Token -> ROSE
  if (isAddress2Native) {
    return await routerContract.swapExactTokensForETH(
      amountIn,
      amountOut[1],
      tokens,
      account,
      deadline
    );
  }

  return await routerContract.swapExactTokensForTokens(
    amountIn,
    amountOut[1],
    tokens,
    account,
    deadline
  );
}

export async function getNetwork(provider: Web3Provider) {
  const network = await provider?.getNetwork();
  return network?.chainId;
}

export async function getAccount() {
  //@ts-ignore
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  return accounts[0];
}

/**
 * This function returns the conversion rate between two token addresses
 *
 * @param address1 - An Ethereum address of the token to swaped from (either a token or AUT)
 * @param address2 - An Ethereum address of the token to swaped to (either a token or AUT)
 * @param amountIn - Amount of the token at address 1 to be swaped from
 * @param signer - The router contract to carry out this swap
 * @returns
 * - the amount out converted into a Number
 */
export async function getAmountOut(
  address1: string,
  address2: string,
  amountIn: string,
  signer: Signer,
  chainId: SupportedChainId
) {
  try {
    const routerAddress = launchpad.get(chainId)?.ROUTER;
    const routerContract = getRouter(routerAddress, signer);

    const token1 = new Contract(address1, ERC20, signer);

    const token1Decimals = await getDecimals(token1);

    const token2 = new Contract(address2, ERC20, signer);
    const token2Decimals = await getDecimals(token2);

    const values_out = await routerContract.getAmountsOut(
      ethers.utils.parseUnits(String(amountIn), token1Decimals),
      [address1, address2]
    );
    const amount_out = values_out[1] * 10 ** -token2Decimals;
    return Number(amount_out);
  } catch (error: any) {
    console.log("error", error);
    return new Error(error);
  }
}

/**
 * This function returns the conversion rate between two token addresses
 *
 * @param address1 - An Ethereum address of the token to swaped from (either a token or AUT)
 * @param address2 - An Ethereum address of the token to swaped to (either a token or AUT)
 * @param amountIn - Amount of the token at address 1 to be swaped from
 * @param signer - The router contract to carry out this swap
 * @returns
 * - the amount out converted into a Number
 */
export async function getAmountIn(
  address1: string,
  address2: string,
  amountOut: string,
  signer: Signer,
  chainId: SupportedChainId
) {
  try {
    const routerContract = getRouter(launchpad.get(chainId)?.ROUTER, signer);
    const token1 = new Contract(address1, ERC20, signer);
    const token1Decimals = await getDecimals(token1);

    const token2 = new Contract(address2, ERC20, signer);
    const token2Decimals = await getDecimals(token2);

    const values_in = await routerContract.getAmountsIn(
      ethers.utils.parseUnits(String(amountOut), token1Decimals),
      [address1, address2]
    );

    console.log("values_in", values_in[1] * 10 ** -token2Decimals);

    const amount_in = values_in[1] * 10 ** -token2Decimals;
    return Number(amount_in);
  } catch (error: any) {
    console.log("error", error);
    return new Error(error);
  }
}

/**
 * This function calls the pair contract to fetch the reserves stored in a the liquidity pool between the token of address1 and the token
 * of address2. Some extra logic was needed to make sure that the results were returned in the correct order, as
 * `pair.getReserves()` would always return the reserves in the same order regardless of which order the addresses were.
 *
 * @param address1 - An Ethereum address of the token to trade from (either a ERC20 token or AUT)
 * @param address2 - An Ethereum address of the token to trade to (either a ERC20 token or AUT)
 * @param pair- The pair contract for the two tokens
 * @param signer
 * @returns
 */
export async function fetchReserves(
  address1: string,
  address2: string,
  pair: Contract,
  signer: Signer
) {
  try {
    // Get decimals for each coin
    const coin1 = new Contract(address1, ERC20, signer);
    const coin2 = new Contract(address2, ERC20, signer);

    const coin1Decimals = await getDecimals(coin1);
    const coin2Decimals = await getDecimals(coin2);

    // Get reserves
    const reservesRaw = await pair.getReserves();

    // Put the results in the right order
    const results = [
      (await pair.token0()) === address1 ? reservesRaw[0] : reservesRaw[1],
      (await pair.token1()) === address2 ? reservesRaw[1] : reservesRaw[0],
    ];

    // Scale each to the right decimal place
    return [
      results[0] * 10 ** -coin1Decimals,
      results[1] * 10 ** -coin2Decimals,
    ];
  } catch (err) {
    console.log("error!");
    console.log(err);
    return [0, 0];
  }
}

/**
 * This function returns the reserves stored in a the liquidity pool between the token of address1 and the token
 * of address2, as well as the liquidity tokens owned by accountAddress for that pair.
 *
 * @param address1 - An Ethereum address of the token to trade from (either a token or AUT)
 * @param address2 - An Ethereum address of the token to trade to (either a token or AUT)
 * @param factory - The current factory
 * @param signer - The current signer
 * @param accountAddress
 * @returns
 */
export async function getReserves(
  address1: string,
  address2: string,
  signer: Signer,
  accountAddress: string,
  chainId: SupportedChainId
) {
  try {
    const factory = getFactory(signer, chainId);
    const pairAddress = await factory.getPair(address1, address2);
    const pair = new Contract(pairAddress, PAIR, signer);

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      const reservesRaw = await fetchReserves(address1, address2, pair, signer);
      const ownerLiquidity = await pair.balanceOf(accountAddress);
      const ownerLiquidityAmount = Number(
        ethers.utils.formatEther(ownerLiquidity)
      );

      return [
        reservesRaw[0].toPrecision(7),
        reservesRaw[1].toPrecision(7),
        ownerLiquidityAmount,
      ];
    } else {
      console.log("no reserves yet");
      return [];
    }
  } catch (err) {
    console.log("error!");
    console.log(err);
    return [];
  }
}

export const switchOrAddNetwork = () => {
  let provider = window.ethereum;
  //@ts-ignore
  return provider?.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0xa515",
        rpcUrls: ["https://testnet.emerald.oasis.dev/"],
        chainName: "Emerald Paratime Testnet",
        nativeCurrency: {
          name: "ROSE",
          decimals: 18,
          symbol: "ROSE",
        },
        blockExplorerUrls: ["https://testnet.emerald.oasis.dev/"],
      },
    ],
  });
};

export const getTokenAddresses = (
  chainId: SupportedChainId,
  tokenA: Token,
  tokenB?: Token
) => {
  const tokenAAddress =
    tokenA.symbol === "ROSE"
      ? tokenAddresses.get(chainId)?.WROSE
      : tokenA.address;
  const tokenBAddress =
    tokenB?.symbol === "ROSE"
      ? tokenAddresses.get(chainId)?.WROSE
      : tokenB?.address;
  return {
    addressA: tokenAAddress,
    addressB: tokenBAddress,
  };
};
