export const rarities = {
  legendary: [1401, 1163, 1666, 222, 955, 575, 72, 1388, 1186, 14],
  epic: [
    202, 167, 1702, 727, 429, 312, 138, 1245, 544, 813, 139, 1017, 29, 1302,
    1797, 1774, 240, 725, 91, 217, 1082, 1342, 1115, 571, 487,
  ],
  rare: [
    267, 1693, 513, 729, 338, 1309, 40, 854, 877, 675, 281, 410, 1380, 24, 766,
    1137, 1143, 555, 587, 178, 567, 124, 1103, 714, 1277, 1755, 1323, 23, 181,
    889, 1102, 1089, 618, 1061, 383, 61, 1005, 318, 885, 791, 355, 460, 1782,
    1022, 600, 993, 697, 1179, 164, 526, 1363, 185, 238, 1747, 329, 515, 768,
    747, 129, 1129, 1215, 1150, 413, 147, 1099, 3, 905, 1161, 775, 196, 821,
    253, 390, 1352, 298, 333, 77, 1745, 1073, 1119, 349, 427, 50, 310, 754, 762,
    371, 241, 573, 414, 500, 1361, 723, 757, 254, 1042, 639, 1160, 1123, 1320,
    1093, 464, 22, 742, 743, 963, 585, 1661, 1011, 1778, 540, 101, 698, 1088,
    1795, 264, 1732, 379, 1284, 194, 353, 1768, 431, 1054, 660, 311, 924, 772,
    608, 119, 591, 25, 470, 822, 648, 1224, 125, 370, 1273, 1770, 1383, 496,
    444, 678, 1192, 1313, 1231, 421, 1080, 75, 1257, 556, 1094, 612, 1178, 1098,
    1698, 149, 498, 857, 674, 726, 223, 1772, 690, 1230, 658, 375, 635, 1096,
    463, 31, 1362, 68, 1294, 468, 689, 820, 773, 404, 1023, 1281, 826, 316, 864,
    478, 808, 517, 1048, 531, 992, 304, 588, 844, 1303, 1223, 1206, 67, 1256,
    870, 441, 1252, 1228, 76, 195, 501, 1376, 972, 536, 560, 400, 1212, 488,
    1076, 440, 354, 942, 799, 869, 1337, 628, 623, 295, 10, 339, 1295, 1326,
    246, 1090, 1700, 1356, 367, 369, 1144, 206, 289, 1032, 583, 1405, 1184,
    1166, 606, 1670, 1705, 1285, 996, 1293, 953, 1691, 1056, 776, 262, 1686,
    1262, 454, 214, 931, 969, 527, 179, 1085, 840, 232, 923, 1169, 1353, 572,
    708, 462, 684, 537, 275, 156, 477, 952, 1754, 1238, 16, 1109, 529, 52, 1021,
    140, 594, 1261, 913, 1753, 1113, 586, 1208, 598, 1030, 516, 423, 1091, 192,
    451, 28, 1723, 1719, 1, 817, 1741, 1760, 1684, 506, 187, 1068, 512, 104,
    332, 1306, 300, 1731, 617, 190, 1070, 892, 247, 948, 730, 914, 1132, 348,
    1669, 57, 1182, 574, 1743, 1213, 882, 343, 495, 239, 1145, 968, 1348, 1248,
    1663, 1079, 1033, 604, 738, 636, 1004, 1014, 711, 1255, 1406, 2, 492, 235,
    611, 597, 1110, 1095, 319, 641, 1239, 1776, 15, 1075, 302, 642, 62, 1386,
    796, 746, 305, 939, 973, 1727, 215, 176, 789, 59, 1051, 323, 688, 325, 1555,
    1368, 1379, 981, 1134, 387, 679, 424, 1151, 550, 559, 780, 779,
  ],
  common: [
    1324, 55, 1364, 1674, 509, 231, 987, 331, 359, 1344, 229, 126, 745, 1746,
    1389, 1266, 671, 1307, 99, 1107, 904, 620, 1195, 69, 360, 1752, 392, 411,
    276, 250, 11, 94, 1234, 1358, 56, 535, 861, 432, 1718, 1010, 1784, 41, 1214,
    377, 136, 1365, 1779, 878, 841, 1242, 1189, 1176, 533, 584, 1616, 1689, 13,
    155, 1081, 1733, 1300, 1263, 1366, 36, 1398, 919, 1384, 1288, 1111, 309,
    1408, 21, 1796, 227, 237, 1722, 592, 1181, 1665, 433, 1346, 731, 1762, 1092,
    630, 1734, 815, 643, 1258, 1219, 1781, 1235, 831, 341, 767, 805, 883, 580,
    737, 443, 1298, 1133, 814, 398, 548, 1680, 703, 728, 1084, 313, 1330, 233,
    111, 1229, 673, 1761, 204, 518, 327, 357, 751, 292, 363, 123, 1031, 435,
    1106, 19, 401, 610, 761, 1016, 218, 935, 654, 925, 189, 1757, 1790, 1710,
    830, 1180, 1276, 453, 81, 715, 1041, 783, 183, 871, 1739, 347, 493, 1201,
    1786, 1385, 66, 862, 951, 1065, 484, 936, 665, 930, 915, 1193, 662, 1750,
    927, 1039, 980, 452, 866, 70, 627, 1125, 47, 551, 201, 1695, 1690, 380, 412,
    1400, 200, 890, 1035, 388, 1322, 1167, 1168, 283, 418, 1729, 1712, 88, 1728,
    193, 1286, 511, 959, 589, 765, 1703, 143, 1026, 569, 644, 163, 303, 759,
    1769, 1127, 1204, 633, 205, 259, 788, 694, 845, 120, 941, 1270, 1679, 770,
    1268, 794, 672, 661, 800, 874, 1265, 53, 326, 1008, 631, 1282, 802, 356,
    268, 1157, 1343, 209, 479, 78, 663, 949, 593, 260, 128, 1236, 244, 554, 879,
    1260, 100, 1241, 1409, 291, 1715, 386, 563, 471, 1025, 577, 676, 562, 999,
    299, 1045, 1312, 6, 448, 1220, 582, 1118, 1222, 439, 724, 625, 374, 1034,
    1407, 1187, 1742, 566, 602, 693, 1717, 461, 337, 491, 490, 653, 823, 946,
    532, 455, 159, 118, 1057, 207, 1357, 985, 1207, 184, 199, 601, 1763, 117,
    1798, 722, 1404, 1367, 898, 880, 756, 236, 306, 345, 590, 1046, 1764, 558,
    804, 718, 655, 1371, 106, 971, 86, 85, 1244, 1059, 834, 42, 32, 645, 166,
    1233, 687, 1064, 933, 991, 632, 1793, 503, 1396, 1271, 1354, 458, 744, 1351,
    180, 984, 1789, 358, 219, 1211, 637, 680, 297, 1759, 446, 988, 284, 1374,
    1154, 827, 1000, 833, 1165, 160, 944, 522, 855, 211, 758, 328, 811, 426,
    324, 9, 912, 1037, 177, 437, 409, 225, 650, 1006, 46, 397, 474, 1697, 1308,
    847, 1724, 1333, 417, 1716, 605, 1335, 784, 1038, 850, 272, 982, 1749, 1735,
    1296, 666, 317, 1078, 1117, 1373, 98, 709, 891, 1156, 1162, 570, 909, 394,
    17, 818, 807, 922, 958, 552, 97, 565, 681, 525, 1203, 64, 37, 1291, 667,
    519, 615, 1246, 121, 1146, 63, 670, 1377, 1709, 168, 279, 145, 1738, 252,
    1202, 530, 1152, 809, 962, 549, 1153, 1777, 419, 929, 1305, 1315, 165, 1159,
    1194, 832, 1347, 1049, 753, 1221, 395, 1237, 132, 1696, 755, 288, 843, 1131,
    1773, 51, 1243, 1673, 1321, 270, 43, 307, 315, 868, 87, 384, 49, 539, 1394,
    1067, 947, 507, 1198, 5, 812, 978, 494, 103, 873, 459, 786, 1785, 801, 137,
    1191, 910, 1138, 146, 521, 35, 760, 1707, 651, 838, 829, 1751, 964, 399,
    778, 1340, 849, 39, 208, 686, 647, 1077, 481, 122, 616, 1173, 44, 1676, 803,
    1120, 1209, 706, 656, 691, 1018, 695, 257, 1060, 1013, 896, 916, 977, 113,
    308, 161, 116, 998, 579, 621, 1737, 793, 1050, 888, 928, 1678, 736, 1681,
    60, 105, 1280, 1301, 152, 416, 798, 881, 365, 769, 1174, 1292, 1177, 994,
    186, 1185, 293, 546, 860, 420, 624, 1114, 1175, 657, 330, 895, 1112, 1128,
    749, 469, 48, 1069, 188, 148, 1254, 30, 1015, 943, 1040, 1720, 109, 1002,
    154, 975, 545, 1329, 960, 1029, 350, 1135, 287, 561, 1149, 1787, 846, 472,
    979, 447, 476, 266, 273, 38, 1027, 720, 1397, 1290, 1387, 212, 314, 514,
    1771, 950, 1325, 170, 974, 748, 391, 774, 172, 986, 902, 851, 638, 1304,
    932, 434, 704, 1047, 741, 920, 1685, 646, 692, 45, 271, 269, 54, 224, 1036,
    242, 707, 740, 153, 505, 1701, 130, 1066, 1327, 1794, 596, 1267, 334, 8,
    1756, 256, 1399, 1141, 1672, 538, 1147, 528, 415, 465, 445, 497, 1375, 282,
    482, 685, 296, 1381, 364, 1799, 396, 175, 1024, 739, 457, 1172, 71, 135,
    251, 504, 957, 1105, 1196, 1310, 131, 872, 848, 210, 173, 1730, 599, 763,
    542, 613, 1044, 1264, 20, 322, 1744, 1155, 1766, 12, 901, 499, 792, 127,
    547, 1072, 1299, 1664, 93, 852, 1402, 1683, 541, 1101, 1359, 1121, 263, 150,
    1205, 220, 248, 320, 576, 1142, 894, 733, 456, 1275, 557, 629, 1334, 112,
    27, 995, 700, 659, 777, 1250, 997, 1104, 342, 1171, 243, 381, 1783, 157,
    258, 475, 839, 1130, 699, 717, 603, 203, 607, 865, 108, 285, 524, 34, 79,
    65, 640, 198, 1392, 1792, 976, 710, 373, 785, 771, 595, 1391, 255, 1183,
    1058, 1009, 1210, 1190, 1122, 926, 1139, 1062, 897, 1726, 1071, 893, 1052,
    1126, 961, 1349, 372, 534, 1339, 33, 1251, 1736, 1662, 1188, 1704, 1259,
    483, 245, 151, 649, 719, 1232, 278, 508, 1318, 485, 1287, 1382, 1097, 1708,
    568, 1780, 80, 1225, 221, 92, 1331, 133, 226, 828, 26, 734, 622, 825, 1158,
    884, 917, 921, 1317, 1217, 1136, 1667, 197, 144, 1063, 449, 191, 1355, 908,
    368, 837, 158, 436, 1800, 171, 520, 428, 634, 1671, 810, 234, 430, 1721,
    1240, 750, 1197, 863, 752, 110, 1249, 1087, 797, 614, 543, 764, 467, 362,
    366, 721, 352, 713, 887, 934, 682, 911, 228, 790, 1360, 1216, 1395, 1053,
    1199, 301, 376, 1140, 677, 107, 294, 940, 735, 406, 466, 450, 73, 836, 1164,
    1247, 96, 7, 856, 114, 1668, 290, 1369, 886, 1278, 875, 1170, 1378, 182,
    115, 486, 853, 102, 1675, 990, 609, 389, 1740, 169, 1713, 335, 1007, 1765,
    787, 1688, 1028, 510, 806, 945, 966, 1350, 1108, 393, 265, 1253, 344, 859,
    989, 1682, 4, 346, 867, 824, 581, 1390, 664, 95, 965, 1403, 782, 578, 280,
    712, 683, 74, 405, 523, 249, 1083, 216, 1116, 819, 134, 407, 489, 1319, 564,
    1775, 918, 1274, 1283, 1314, 382, 277, 970, 1200, 696, 816, 162, 1341, 83,
    1699, 141, 626, 669, 900, 1328, 956, 1338, 174, 1694, 1677, 1019, 668, 89,
    1345, 1074, 619, 340, 903, 1714, 336, 1226, 795, 701, 652, 1012, 1218, 1687,
    1100, 1767, 1272, 1297, 408, 1706, 1758, 378, 702, 1788, 1393, 1003, 983,
    274, 842, 82, 90, 351, 213, 835, 1791, 1311, 422, 385, 876, 781, 1269, 716,
    1124, 261, 899, 18, 1148, 954, 142, 403, 1020, 58, 473, 967, 1227, 1748,
    858, 1711, 938, 1372, 1001, 906, 361, 480, 1055, 438, 705, 442, 1289, 1692,
    937, 1316, 402, 425, 1370, 1336, 732, 84, 1725, 1279, 1332, 502, 907, 230,
    553, 321, 286, 1086, 1043, 1453, 1509, 1482, 1518, 1508, 1488, 1505, 1493,
    1466, 1492, 1495, 1447, 1438, 1472, 1437, 1443, 1430, 1485, 1513, 1494,
    1524, 1452, 1515, 1526, 1471, 1519, 1468, 1461, 1470, 1517, 1435, 1465,
    1486, 1464, 1529, 1446, 1460, 1503, 1496, 1475, 1527, 1474, 1431, 1439,
    1501, 1462, 1504, 1458, 1444, 1507, 1463, 1497, 1459, 1490, 1487, 1498,
    1500, 1469, 1499, 1528, 1514, 1473, 1442, 1432, 1502, 1512, 1484, 1441,
    1480, 1448, 1489, 1521, 1451, 1456, 1436, 1467, 1516, 1481, 1445, 1434,
    1491, 1440, 1450, 1523, 1483, 1449, 1433, 1520, 1476, 1457, 1510, 1522,
    1525, 1477, 1511, 1454, 1506, 1478, 1455, 1479, 1559, 1563, 1592, 1567,
    1564, 1582, 1580, 1576, 1598, 1597, 1589, 1577, 1568, 1586, 1590, 1660,
    1550, 1575, 1557, 1594, 1569, 1572, 1571, 1581, 1570, 1565, 1560, 1596,
    1558, 1566, 1583, 1554, 1636, 1593, 1599, 1591, 1647, 1579, 1561, 1584,
    1574, 1651, 1573, 1551, 1634, 1587, 1578, 1556, 1585, 1639, 1553, 1633,
    1654, 1659, 1552, 1622, 1595, 1640, 1645, 1562, 1588, 1649, 1653, 1628,
    1620, 1621, 1646, 1631, 1627, 1630, 1650, 1652, 1648, 1632, 1629, 1624,
    1635, 1657, 1658, 1637, 1644, 1641, 1655, 1625, 1638, 1642, 1656, 1626,
    1623, 1643, 1602, 1606, 1601, 1603, 1607, 1600, 1608, 1545, 1422, 1532,
    1538, 1424, 1541, 1531, 1539, 1546, 1530, 1533, 1605, 1534, 1609, 1423,
    1421, 1604, 1540, 1428, 1535, 1544, 1537, 1543, 1542, 1549, 1414, 1548,
    1419, 1410, 1416, 1415, 1412, 1429, 1417, 1426, 1418, 1425, 1420, 1413,
    1427, 1411, 1547, 1536, 1614, 1612, 1615, 1610, 1618, 1619, 1613, 1611,
    1617,
  ],
};
