import {
  Currency,
  CurrencyAmount,
  Fraction,
  Percent,
  Trade,
  TradeType,
} from "@rosehub-tech/sdk";
import { INPUT_FRACTION_AFTER_FEE, ONE_HUNDRED_PERCENT } from "config/exchange";
import { useCallback } from "react";
import { useStore } from "store/zustand";
import { SwapField as Field, SwapField } from "types";

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
} {
  const [switchCurrencies, selectCurrency, typeInput] = useStore((state) => [
    state.switchCurrencies,
    state.selectCurrency,
    state.typeSwapInput,
  ]);
  const onSwitchTokens = useCallback(() => {
    switchCurrencies();
  }, [switchCurrencies]);

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      const id = currency?.isToken
        ? currency.address
        : currency?.isNative
        ? currency.symbol
        : "";

      selectCurrency(field, id as SwapField);
    },
    [selectCurrency]
  );

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      typeInput(field, typedValue);
    },
    [typeInput]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
  };
}

export function useSwapState() {
  const {
    independentField,
    typedValue,
    fieldInput,
    fieldOutput,
    updateSwapTx,
  } = useStore((state) => ({
    independentField: state.swapIndependentField,
    typedValue: state.swapTypedValue,
    fieldInput: state?.[SwapField.INPUT],
    fieldOutput: state?.[SwapField.OUTPUT],
    updateSwapTx: state.updateRecentSwapTx,
  }));
  return {
    independentField,
    typedValue,
    fieldInput,
    fieldOutput,
    updateSwapTx,
  };
}

// computes price breakdown for the trade
export function computeTradePriceBreakdown(
  trade?: Trade<Currency, Currency, TradeType> | null
): {
  priceImpactWithoutFee: Percent | undefined;
  realizedLPFee: CurrencyAmount<Currency> | undefined | null;
} {
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce<Fraction>(
          (currentFee: Fraction): Fraction =>
            currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT
        )
      );

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction =
    trade && realizedLPFee
      ? trade.priceImpact.subtract(realizedLPFee)
      : undefined;

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(
        priceImpactWithoutFeeFraction?.numerator,
        priceImpactWithoutFeeFraction?.denominator
      )
    : undefined;

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(
      trade.inputAmount.currency,
      realizedLPFee.multiply(trade.inputAmount.quotient).quotient
    );

  return {
    priceImpactWithoutFee: priceImpactWithoutFeePercent,
    realizedLPFee: realizedLPFeeAmount,
  };
}
