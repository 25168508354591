import { Controller } from "react-hook-form";
import styled from "styled-components";
import FormInput from "../FormInput/FormInput";
import FormSelect from "../FormSelect";
import { StepProps } from "../Fundraise";
import { projectStatus } from "../schema";

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export default function Step1({ control, errors }: StepProps) {
  return (
    <StepContainer>
      <Controller
        name="email"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            placeholder="juandelacruz@gmail.com"
            type="email"
            label="Email Address"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            ref={ref}
            type="text"
            label="Name"
            placeholder="Juan Dela Cruz"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="projectTitle"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            name="projectTitle"
            label="Project Title"
            placeholder="RoseHub Fundraiser"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="projectName"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="Project Name"
            placeholder="RoseHub Project"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="projectStatus"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormSelect
            label="Project Status"
            options={projectStatus}
            placeholder="Select the status of your project"
            {...rest}
            {...fieldState}
          />
        )}
      />
    </StepContainer>
  );
}
