import { memoize } from "lodash";
import { getAddress } from "@ethersproject/address";

export const isAddress = memoize((value: any): string | false => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
});
