import { Currency } from "@rosehub-tech/sdk";
import { blockExplorers } from "connectors/chains";
import { ethers, Signer } from "ethers";
import { useRouterInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import useSigner from "hooks/useSigner";
import { useState } from "react";
import { useStore } from "store/zustand";
import { addLiquidity } from "utils/liquidityFunctions";
import { useGetWROSE } from "views/Swap/hooks/useGetAmountsOut";
import { useAccount, useProvider } from "wagmi";
import useSortTokens from "./useSortTokens";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "services/firebase";
import useCreateLiquidityProvider from "./useCreateLiquidityProvider";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";
import { useNativeCurrency } from "./useCurrencyBalances";
import { UNIQUE_LP_STAKERS_FIREBASE_KEY } from "config/exchange";
import { useCheckLPStakerAddress } from "services/queries";

const logger = new ethers.utils.Logger("5.4.1");

export default function useConfirmAddLiquidity(
  currencyA: Currency,
  currencyB: Currency,
  tokenAValue: string,
  tokenBValue: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const chainId = useChainId();
  const { address } = useAccount();
  const routerInterface = useRouterInterface();
  const signer = useSigner();
  const provider = useProvider();
  const updateToast = useStore((state) => state.updateToast);
  const wroseAddress = useGetWROSE();
  const routerAddress = useRouterAddress();
  const native = useNativeCurrency();
  const [token0, token1, token0Value, token1Value] = useSortTokens(
    currencyA,
    currencyB,
    tokenAValue,
    tokenBValue
  );

  const liquidityPair = useCreateLiquidityProvider(
    token0,
    token1,
    token0Value,
    token1Value
  );
  const { data: LPStaker } = useCheckLPStakerAddress(address);

  const onConfirmAddLiquidity = async (onSuccessClear?: () => void) => {
    logger.info("Attempting to deploy liquidity...", token0Value, token1Value);
    setIsLoading(true);
    if (!signer || !provider) return;
    const routerContract = new ethers.Contract(
      routerAddress,
      routerInterface,
      signer
    );
    try {
      const isCurrencyANative = currencyA?.equals(native);
      const isCurrencyBNative = currencyB?.equals(native);

      await addLiquidity(
        token0?.address || wroseAddress,
        token1?.address || wroseAddress,
        isCurrencyANative,
        isCurrencyBNative,
        token0Value as string,
        token1Value as string,
        "0",
        "0",
        routerContract,
        address as string,
        signer as Signer
      )
        .then((response) => {
          return response.hash;
        })
        .then(async (hash: string) => {
          return await provider.waitForTransaction(hash);
        })
        .then(async (receipt: any) => {
          if (!!LPStaker) {
            //update lpstaker
            await updateDoc(doc(db, UNIQUE_LP_STAKERS_FIREBASE_KEY, address), {
              lastUpdated: new Date(),
              pairs: [...LPStaker.pairs, liquidityPair],
              transactions: [...LPStaker.transactions, receipt.transactionHash],
            });
          } else {
            //create lpstaker
            await setDoc(doc(db, UNIQUE_LP_STAKERS_FIREBASE_KEY, address), {
              lastUpdated: new Date(),
              createdAt: new Date(),
              address,
              pairs: [liquidityPair],
              transactions: [receipt.transactionHash],
            });
          }
          const successMessage = `Adding liquidity for ${token0?.symbol}/${token1?.symbol} pair is successful`;
          const txUrl = `${blockExplorers.get(chainId)}/tx/${
            receipt.transactionHash
          }`;
          updateToast({
            message: successMessage,
            url: txUrl,
            type: "success",
          });
        });
      !!onSuccessClear && onSuccessClear();
    } catch (error: any) {
      updateToast({
        message: `Transaction Failed ${error.message}`,
        url: "",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { onConfirmAddLiquidity, isLoading };
}
