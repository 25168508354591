import { ChainId } from "@rosehub-tech/sdk";
import { USDT } from "constants/tokens";
import { ethers } from "ethers";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";
import useChainId from "hooks/useChainId";
import { useGetWROSE } from "views/Swap/hooks/useGetAmountsOut";
import { erc20ABI, useAccount, useContractReads } from "wagmi";

export default function useLiquidityAllowances(
  address1: string,
  address2: string
) {
  const chainId = useChainId();
  const { address } = useAccount();
  const routerAddress = useRouterAddress();
  const wroseAddress = useGetWROSE();
  const { data, refetch, isLoading, isError } = useContractReads({
    contracts: [
      {
        addressOrName: address1 || wroseAddress,
        contractInterface: erc20ABI,
        functionName: "allowance",
        args: [address, routerAddress],
        chainId,
      },
      {
        addressOrName: address2 || wroseAddress,
        contractInterface: erc20ABI,
        functionName: "allowance",
        args: [address, routerAddress],
        chainId,
      },
    ],
    enabled: !!wroseAddress && !!routerAddress,
  });

  if (isLoading || isError || !data) {
    return undefined;
  }

  const token0 = data[0];
  const token1 = data[1];
  const isAddress1USDT = address1 === USDT[ChainId.EMERALD_MAINNET]?.address;
  const isAddress2USDT = address2 === USDT[ChainId.EMERALD_MAINNET]?.address;
  const decimals1 = isAddress1USDT ? 6 : 18;
  const decimals2 = isAddress2USDT ? 6 : 18;
  const token0Allowance = !!token0
    ? ethers.utils.formatUnits(token0, decimals1)
    : undefined;
  const token1Allowance = !!token1
    ? ethers.utils.formatUnits(token1, decimals2)
    : undefined;

  return {
    token0:
      !!token0Allowance && Number(token0Allowance) > 0
        ? token0Allowance
        : undefined,
    token1:
      !!token1Allowance && Number(token1Allowance) > 0
        ? token0Allowance
        : undefined,
    refetch,
  };
}
