import GradientButton from "components/GradientBtn";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useStore } from "store/zustand";
import styled from "styled-components";
import CalculatorInput from "views/Home/CalculatorForm/CalculatorInput";
import {
  CalculatorBody,
  InputContainer,
  InputLabel,
  InputLabelContainer,
  InputWrapper,
} from "views/Home/CalculatorForm/style";
import useConfirmStaking from "views/Home/hooks/useConfirmStaking";
import { StyledCloseIcon } from "views/Farms/AddFarmModal";

const StyledModal = styled(Modal)`

.modal-content {
  background: black;
  z-index: 20;
  transform-style: preserve-3d;

  .modal-title.h4,
  .modal-body p {
    font-family: "Nasalization";
  }

  .modal-header {
    border-bottom: 1px solid rgba(243, 8, 137, 0.4);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
  }

  .modal-footer {
    border-top: 1px solid rgba(243, 8, 137, 0.4);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
  }

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 100%;
    background-blend-mode: soft-light, soft-light, normal;
    background: radial-gradient(circle at left, #c97ebc, transparent),
      radial-gradient(circle at bottom, #6531b1, transparent),
      radial-gradient(circle at right, #16aff8, transparent),
      radial-gradient(circle at top, #dc0474, transparent);
    content: "";
    width: 500px;
    filter: blur(80px);
    transform: translateZ(-1px);
  }
`;

interface IncreaseStakeProps {
  show: boolean;
  onHide: () => void;
}
export default function IncreaseStakeModal({
  show,
  onHide,
}: IncreaseStakeProps) {
  const [rpad, rpadLP] = useStore((state) => [state.rpad, state.rpadLP]);
  const [rpadValue, setRpadValue] = useState<number | undefined>(undefined);
  const [rpadLpValue, setRpadLpValue] = useState<number | undefined>(undefined);

  const { onConfirmStaking, isLoading } = useConfirmStaking(
    rpadValue as number,
    rpadLpValue as number,
    0,
    false,
    true
  );

  const handleRPADChange = (value: number) => {
    setRpadValue(value);
  };
  const handleRPADLPChange = (value: number) => {
    setRpadLpValue(value);
  };

  const handleConfirm = () => {
    onConfirmStaking(() => {
      setRpadValue(undefined);
      setRpadLpValue(undefined);
      onHide();
    });
  };

  return (
    <StyledModal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Increase Stake</Modal.Title>
        <StyledCloseIcon onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <CalculatorBody>
          <InputWrapper>
            <InputLabelContainer>
              <InputLabel>Balance: {Number(rpad).toFixed(6)}</InputLabel>
              <InputLabel>RHUB</InputLabel>
            </InputLabelContainer>
            <InputContainer>
              <CalculatorInput onInputChange={handleRPADChange} />
            </InputContainer>
          </InputWrapper>
          <InputWrapper>
            <InputLabelContainer>
              <InputLabel>Balance: {Number(rpadLP).toFixed(6)}</InputLabel>
              <InputLabel>RHUB-LP</InputLabel>
            </InputLabelContainer>
            <InputContainer>
              <CalculatorInput onInputChange={handleRPADLPChange} />
            </InputContainer>
          </InputWrapper>
        </CalculatorBody>
      </Modal.Body>
      <Modal.Footer>
        <GradientButton label="Cancel" onClick={onHide} />
        <GradientButton
          label="Stake"
          onClick={handleConfirm}
          disabled={!rpadValue && !rpadLpValue}
          disabledText="Enter amount"
          loading={isLoading}
        />
      </Modal.Footer>
    </StyledModal>
  );
}
