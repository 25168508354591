import { useKeenSlider, KeenSliderPlugin } from "keen-slider/react";
import tier1 from "assets/images/tiers/Tier1_RoseHub.webp";
import tier2 from "assets/images/tiers/Tier2_RoseHub.webp";
import tier3 from "assets/images/tiers/Tier3_RoseHub.webp";
import tier4 from "assets/images/tiers/Tier4_RoseHub.webp";
import tier5 from "assets/images/tiers/Tier5_RoseHub.webp";
import { useEffect } from "react";

const nftTierImages = [
  {
    id: "tier1",
    img: tier1,
  },
  {
    id: "tier2",
    img: tier2,
  },
  {
    id: "tier3",
    img: tier3,
  },
  {
    id: "tier4",
    img: tier4,
  },
  {
    id: "tier5",
    img: tier5,
  },
];

const carousel: KeenSliderPlugin = (slider) => {
  const z = 300;
  function rotate() {
    const deg = 360 * slider.track.details.progress;
    slider.container.style.transform = `translateZ(-${z}px) rotateY(${-deg}deg)`;
  }
  slider.on("created", () => {
    const deg = 360 / slider.slides.length;
    slider.slides.forEach((element, idx) => {
      element.style.transform = `rotateY(${deg * idx}deg) translateZ(${z}px)`;
    });
    rotate();
  });
  slider.on("detailsChanged", rotate);
};

export default function TierKeenSlider({ points }: { points: number }) {
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      loop: true,
      selector: ".carousel__cell",
      renderMode: "custom",
      mode: "free-snap",
    },
    [carousel]
  );

  useEffect(() => {
    if (instanceRef?.current) {
      const slider = instanceRef?.current as any;
      if (points <= 2000) {
        slider?.moveToIdx(0);
        return;
      }
      if (points > 2000 && points <= 10000) {
        slider?.moveToIdx(1);
        return;
      }
      if (points > 10000 && points <= 20000) {
        slider?.moveToIdx(2);
        return;
      }
      if (points > 20000 && points <= 100000) {
        slider?.moveToIdx(3);
        return;
      }
      if (points > 100000) {
        slider?.moveToIdx(4);
        return;
      }
    }
  }, [points, instanceRef]);

  return (
    <div className="wrapper">
      <div className="scene">
        <div className="carousel keen-slider" ref={sliderRef}>
          {nftTierImages.map((tier, index) => (
            <div
              key={tier.id}
              className={`carousel__cell number-slide${index + 1}`}
            >
              <img
                src={tier.img}
                alt={tier.id}
                // width={356}
                className="keen-slide-image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
