import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import { useRHUBTokenAddress } from "hooks/contracts/useContractAddresses";
import useChainId from "hooks/useChainId";
import { useStore } from "store/zustand";
import {
  erc20ABI,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import useGetVaultAddress from "./useGetVaults";

export default function useApproveVaultAllowance(
  index: number,
  enabled: boolean,
  onReset: () => void
) {
  const { vaultAddress } = useGetVaultAddress(index);
  const chainId = useChainId();
  const updateToast = useStore((state) => state.updateToast);
  const rpadAddress = useRHUBTokenAddress();
  const { config } = usePrepareContractWrite({
    addressOrName: rpadAddress,
    contractInterface: erc20ABI,
    functionName: "approve",
    args: [vaultAddress, ethers.constants.MaxUint256],
    onError: (error) => {
      console.error("useApproveVaultAllowance error", error);
    },
  });
  const {
    write,
    isLoading: writeLoading,
    data: writeData,
  } = useContractWrite({ ...config, enabled: !!rpadAddress && enabled });

  const {
    isError,
    error: transactionError,
    isLoading: transactionLoading,
    isSuccess,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    onSettled: () => {
      console.log("useApproveRPAD settled");
    },
    onError: (error: any) => {
      console.log("useApproveVaultAllowance transaction error", error);
    },
    onSuccess: () => {
      updateToast({
        type: "success",
        message: "RHUB contract approved",
        url: `${blockExplorers.get(chainId)}/tx/${writeData?.hash}`,
      });
      onReset();
    },
    enabled: !!writeData?.hash,
  });

  return {
    onConfirmApprove: write,
    isLoading: writeLoading || transactionLoading,
    error: isError ? transactionError : undefined,
    isSuccess,
  };
}
