import GradientButton from "components/GradientBtn";
import moment from "moment";
import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStore } from "store/zustand";
import styled from "styled-components";
import { formatAmount } from "views/Home/CalculatorForm";
import CalculatorInput from "views/Home/CalculatorForm/CalculatorInput";
import {
  InputContainer,
  InputLabel,
  InputLabelContainer,
  InputWrapper,
} from "views/Home/CalculatorForm/style";
import useConfirmApprove from "./hooks/useApproveVaultAllowance";
import useConfirmVaultStaking from "./hooks/useConfirmVaultDeposit";
import useConfirmWidthraw from "./hooks/useConfirmWidthraw";
import useGetVaultInfo from "./hooks/useGetVaultInfo";
import {
  useGetTotalStakedAmount,
  useGetUserStakedAmount,
} from "./hooks/useGetVaults";
import useVaultAllowance from "./hooks/useVaultAllowance";
import { SerializedVaultsConfig } from "types";
import { useUSDTRhubAmount } from "views/Liquidity/hooks/useUSDTPrice";
import { doc, updateDoc } from "firebase/firestore";
import { VAULTS_TVL_KEY } from "config/exchange";
import { db } from "services/firebase";
import { ChainId } from "@rosehub-tech/sdk";

const Container = styled.div`
  display: grid;
  border-bottom: 1px solid #222325;
  -webkit-box-shadow: inset 0 0 2rem ${({ theme }) => theme.primary}80;
  -moz-box-shadow: inset 0 0 2rem ${({ theme }) => theme.primary}80;
  box-shadow: inset 0 0 2rem ${({ theme }) => theme.primary}80;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  width: 28rem;
  height: 38rem;

  @media screen and (max-width: 767px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

const Title = styled.h3`
  font-family: "Nasalization";
  font-size: 1.3rem;
  color: rgb(255, 0, 135);
  margin-top: 1.7rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DatesLabel = styled.h3`
  font-family: Nasalization;
  font-size: 1.5rem;
  font-size: 0.8125rem;
  margin-bottom: 0;

  span {
    font-weight: 500;
    margin-bottom: 0;
    color: rgb(255, 0, 135);
  }
`;

const Label = styled.p`
  opacity: 0.56;
  font-weight: 500;
  margin-bottom: 0;
`;

const APYLabel = styled.p`
  font-weight: 700;
  font-family: Nasalization;
  color: rgb(255, 0, 135);
  font-size: 1.5rem;
  margin-bottom: 0;

  span {
    font-family: "Jakarta Sans";
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-size: 0.8125rem;
    color: unset;
    opacity: 0.36;
  }
`;

const MaxLock = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Nasalization;
  opacity: 0.36;

  span {
    font-family: "Jakarta Sans";
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-size: 0.75rem;
  }
`;

const Footer = styled.p`
  font-family: Nasalization;
  font-size: 1.5rem;
  font-size: 0.8125rem;
  margin-bottom: 0;
  display: flex;
  align-center: center;
  gap: 8px;

  span {
    font-weight: 500;
    margin-bottom: 0;
    color: rgb(255, 0, 135);
  }
`;

const Stretch = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.primary}29;
  width: 100%;
  padding: 1rem 1.5rem 1rem 1.5rem;
  height: fit-content;
`;

interface VaultCardProps {
  vault: SerializedVaultsConfig;
}

export default function VaultCard({ vault }: VaultCardProps) {
  const vaultsId = vault.vaultId;
  const [rpadAmount, setRpadAmount] = useState(0);
  const [rpad] = useStore((state) => [state.rpad]);
  const vaultInfo = useGetVaultInfo(
    vaultsId,
    vault.contractAddress[ChainId.EMERALD_MAINNET]
  );
  const enabledWithdraw = useRef(false);
  const enabledDeposit = useRef(false);
  const enabledApprove = useRef(false);
  const allowance = useVaultAllowance(vaultsId);
  const totalStaked = useGetTotalStakedAmount(
    vaultsId,
    vault.contractAddress[ChainId.EMERALD_MAINNET]
  );
  const userStaked = useGetUserStakedAmount(vaultsId);

  const reset = () => {
    enabledWithdraw.current = false;
    enabledDeposit.current = false;
    enabledApprove.current = false;
    setRpadAmount(0);
    vaultInfo?.refetch();
    allowance?.refetch();
    userStaked?.refetch();
  };

  const { onConfirmApprove, isLoading: isApprovingAllowance } =
    useConfirmApprove(vaultsId, enabledApprove?.current, reset);
  const { onConfirmVaultStaking, isLoading } = useConfirmVaultStaking(
    rpadAmount,
    vaultsId,
    enabledDeposit?.current,
    reset
  );

  const { onConfirmWidthraw, isLoading: isWidthrawLoading } =
    useConfirmWidthraw(vaultsId, enabledWithdraw.current, reset);

  const buttonText = useMemo(() => {
    return !!rpadAmount &&
      (!allowance || (!!allowance && Number(allowance?.amount) < rpadAmount))
      ? "Approve"
      : "Deposit";
  }, [rpadAmount, allowance]);

  const [disabled, disabledText]: [boolean, string] = useMemo(() => {
    if (!rpadAmount) {
      return [true, "Enter amount"];
    }
    if (rpadAmount > rpad) {
      return [true, "Insufficient RHUB balance"];
    }
    return [false, ""];
  }, [rpadAmount, rpad]);

  const vaultDuration =
    !!vaultInfo && vaultInfo?.duration
      ? vaultInfo?.duration
      : (vault?.duration as number);
  const maxLockAmount =
    !!vaultInfo && vaultInfo?.maxLockAmount
      ? formatAmount(Number(vaultInfo?.maxLockAmount))
      : formatAmount(vault?.maxLockValue as number);
  const apy =
    !!vaultInfo && vaultInfo?.apy ? vaultInfo.apy : (vault?.apy as number);
  const startDate = !!vaultInfo?.startDate
    ? moment(vaultInfo.startDate)
    : undefined;
  const endDate = !!vaultInfo?.endDate ? moment(vaultInfo.endDate) : undefined;

  const handleRPADChange = (value: number) => {
    setRpadAmount(value);
  };

  const handleVaultDeposit = () => {
    if (buttonText === "Deposit") {
      enabledDeposit.current = true;
      startTransition(() => !!onConfirmVaultStaking && onConfirmVaultStaking());
    } else {
      enabledApprove.current = true;
      startTransition(() => onConfirmApprove());
    }
  };

  const handleVaultWithdraw = () => {
    enabledWithdraw.current = true;
    startTransition(() => {
      !!onConfirmWidthraw && onConfirmWidthraw();
    });
  };
  const rhubUsdtPrice = useUSDTRhubAmount(1);
  const totalRHUBStaked = Number(totalStaked?.totalStakedAmount);

  const rhubtoUSDValue = useMemo(() => {
    if (totalRHUBStaked > 0) {
      return totalRHUBStaked * rhubUsdtPrice;
    }
    return 0;
  }, [totalRHUBStaked, rhubUsdtPrice]);

  const handleUpdateTVL = useCallback(
    async (data) => {
      await updateDoc(
        doc(db, VAULTS_TVL_KEY, vault.contractAddress[ChainId.EMERALD_MAINNET]),
        data
      );
    },
    [vault]
  );

  useEffect(() => {
    if (!!rhubtoUSDValue) {
      console.log("rhubtoUSDValue", rhubtoUSDValue);
      handleUpdateTVL({
        rhub: rhubtoUSDValue,
      });
    }
  }, [rhubtoUSDValue, vault, handleUpdateTVL]);

  return (
    <Container>
      <Stretch>
        <Title>
          <img
            src={`/tokens/${vault?.stakingToken?.symbol}.png`}
            alt="rpad"
            style={{
              width: "2rem",
              marginRight: "0.5rem",
              borderRadius: "100%",
            }}
          />
          {`${vaultDuration}-Month ${vault?.stakingToken?.symbol} Vault`}
        </Title>
        <Item>
          <Label>Max Lock per Vault</Label>
          <MaxLock>
            {maxLockAmount} <span>{vault?.stakingToken?.symbol}</span>
          </MaxLock>
        </Item>
      </Stretch>
      <Stretch>
        <Item>
          <APYLabel>
            {apy}% <span>APY</span>
          </APYLabel>
        </Item>
        {!!startDate && !!endDate ? (
          <>
            {" "}
            <Item>
              <DatesLabel>
                Starts on <span>{startDate.format("LLL")}</span>
              </DatesLabel>
            </Item>
            <Item>
              <DatesLabel>
                End on <span>{endDate.format("LLL")}</span>
              </DatesLabel>
            </Item>
          </>
        ) : null}
      </Stretch>
      {!startDate && !endDate ? (
        <Stretch>
          <InputWrapper>
            <InputLabelContainer>
              <InputLabel onClick={() => setRpadAmount(Number(rpad))}>
                Balance:{" "}
                <a href={"#"} style={{ color: "hotpink" }}>
                  {Number(rpad).toFixed(6)}
                </a>
              </InputLabel>
              <InputLabel>{vault?.stakingToken?.symbol}</InputLabel>
            </InputLabelContainer>
            <InputContainer>
              <CalculatorInput
                value={String(rpadAmount)}
                onInputChange={handleRPADChange}
              />
            </InputContainer>
          </InputWrapper>
        </Stretch>
      ) : null}
      <Stretch>
        {!!startDate && !!endDate && vaultInfo?.hasFinished ? (
          <GradientButton
            onClick={handleVaultWithdraw}
            loading={isWidthrawLoading}
            label="Withdraw"
          />
        ) : null}
        {!startDate && !endDate ? (
          <GradientButton
            onClick={handleVaultDeposit}
            label={buttonText}
            loading={isLoading || isApprovingAllowance}
            disabled={disabled}
            disabledText={disabledText}
          />
        ) : null}
      </Stretch>
      <Stretch>
        <Item>
          <Footer>
            Your Stake{" "}
            <span>
              {`${userStaked.amount ? userStaked.amount : "0"}
              ${vault?.stakingToken?.symbol}`}
            </span>
          </Footer>
        </Item>
        <Item>
          <Footer>
            Earned{" "}
            <span>{`${Number(vaultInfo?.rewardAmount).toFixed(6)} ${
              vault?.earningToken?.symbol
            }`}</span>
          </Footer>
        </Item>
        <Item>
          <Footer>
            Total Staked{" "}
            <span>
              {`${
                !!totalStaked
                  ? Number(totalStaked.totalStakedAmount).toFixed(1)
                  : 0
              }
              ${vault?.stakingToken?.symbol}`}
            </span>
          </Footer>
        </Item>
      </Stretch>
    </Container>
  );
}
