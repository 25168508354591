import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {
  StyledCloseIcon,
  StyledModalHeader,
} from "views/Swap/styles/selectCurrencyStyles";
import React, { useState } from "react";
import styled from "styled-components";
import { useStore } from "store/zustand";
import { escapeRegExp } from "utils/regex";

export const StyledModal = styled(Modal)`
  width: 100%;

  .modal-content {
    background: linear-gradient(
      180deg,
      rgba(28, 28, 28, 0.2) 0%,
      rgba(2, 2, 2, 0.2) 100%,
      rgba(2, 2, 2, 0.2) 100%
    );
    box-shadow: inset 0px 4px 18px rgba(255, 0, 153, 0.4);
    backdrop-filter: blur(80px);
    transform-style: preserve-3d;
    min-height: 250px;

    .modal-title.h4,
    .modal-body p {
      font-family: "Nasalization";
    }

    .modal-header,
    .modal-footer {
      border-bottom: none;
      border-top: none;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
    }
  }
`;

const Title = styled.h2`
  font-size: 1rem !important;
  color: rgb(255, 0, 137) !important;
  font-family: "Nasalization";
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

const Subtitle = styled.h3`
  font-size: 0.825rem !important;
  color: rgb(255, 0, 137) !important;
  font-family: "Nasalization";
  text-transform: uppercase;
`;

const ButtonGroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StyledInput = styled.input`
  width: 80px;
  padding: 0.4rem 1rem;
  color: rgb(255, 0, 137);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 2px;
  outline: 0px;
`;

const SlippageButtonGroup = styled(ButtonGroup)`
  display: flex;
  align-items: center;

  &.btn {
    background-color: rgb(53, 53, 71);
  }

  & .btn.btn-primary {
    background: radial-gradient(circle at center, #e100ff8d, transparent 40%),
      radial-gradient(circle at top left, #ff9900, transparent 12%),
      radial-gradient(circle at top right, #ff0073, transparent 15%),
      radial-gradient(circle at bottom right, #ffbb00, transparent 30%),
      radial-gradient(circle at bottom left, #ff0073, transparent 25%),
      linear-gradient(96deg, #e7175d 0%, #f81f4e 100%) !important;
    box-shadow: none;
  }
`;

const SlippageButton = styled(Button)`
  color: #fff;
  background-color: rgb(53, 53, 71);
  padding: 0 1rem;
  height: 40px;
  border-radius: 1rem;
  font-weight: 600;
  outline: 0px;
  border: none;
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 76px;

  &:hover {
    cursor: pointer;
    color: #fff;
    background: radial-gradient(circle at center, #e100ff8d, transparent 40%),
      radial-gradient(circle at top left, #ff9900, transparent 12%),
      radial-gradient(circle at top right, #ff0073, transparent 15%),
      radial-gradient(circle at bottom right, #ffbb00, transparent 30%),
      radial-gradient(circle at bottom left, #ff0073, transparent 25%),
      linear-gradient(96deg, #e7175d 0%, #f81f4e 100%) !important;
  }

  &:focus {
    background: radial-gradient(circle at center, #e100ff8d, transparent 40%),
      radial-gradient(circle at top left, #ff9900, transparent 12%),
      radial-gradient(circle at top right, #ff0073, transparent 15%),
      radial-gradient(circle at bottom right, #ffbb00, transparent 30%),
      radial-gradient(circle at bottom left, #ff0073, transparent 25%),
      linear-gradient(96deg, #e7175d 0%, #f81f4e 100%) !important;
  }

  &:active {
    background: radial-gradient(circle at center, #e100ff8d, transparent 40%),
      radial-gradient(circle at top left, #ff9900, transparent 12%),
      radial-gradient(circle at top right, #ff0073, transparent 15%),
      radial-gradient(circle at bottom right, #ffbb00, transparent 30%),
      radial-gradient(circle at bottom left, #ff0073, transparent 25%),
      linear-gradient(96deg, #e7175d 0%, #f81f4e 100%) !important;
    color: #fff;
    border: none;
    box-shadow: none;
    outline: 0px;
  }
`;

const WarningText = styled.p`
  color: rgb(243, 132, 30);
  font-weight: 400;
  line-height: 1.5;
  margin-top: 8px;
  font-size: 0.825rem;
`;

interface SlippageSettingsProps {
  show: boolean;
  handleClose: () => void;
}

enum SlippageError {
  InvalidInput = "InvalidInput",
  RiskyLow = "RiskyLow",
  RiskyHigh = "RiskyHigh",
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);

export default function SlippageSettings({
  show,
  handleClose,
}: SlippageSettingsProps) {
  const [customSlippage, setCustomSlippage] = useState<string | number>("");
  const [updateSlippage, slippage] = useStore(
    ({ updateSlippage, slippage }) => [updateSlippage, slippage]
  );

  const handleCustomSlippageChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.validity.valid) {
      parseCustomSlippage(e.currentTarget.value.replace(/,/g, "."));
    }
  };

  function parseCustomSlippage(value: string) {
    if (value === "" || inputRegex.test(escapeRegExp(value))) {
      setCustomSlippage(value);

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt(
          (Number.parseFloat(value) * 100).toString()
        );
        if (
          !Number.isNaN(valueAsIntFromRoundedFloat) &&
          valueAsIntFromRoundedFloat < 5000
        ) {
          console.log("newSlippage", valueAsIntFromRoundedFloat);
          updateSlippage(valueAsIntFromRoundedFloat);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const slippageInputIsValid =
    customSlippage === "" ||
    (slippage / 100).toFixed(2) ===
      Number.parseFloat(customSlippage as string).toFixed(2);
  let slippageError: SlippageError | undefined;
  if (customSlippage !== "" && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput;
  } else if (slippageInputIsValid && slippage < 50) {
    slippageError = SlippageError.RiskyLow;
  } else if (slippageInputIsValid && slippage > 500) {
    slippageError = SlippageError.RiskyHigh;
  } else {
    slippageError = undefined;
  }

  return (
    <StyledModal
      show={show}
      onHide={handleClose}
      dialogClassName="rosepadModal"
    >
      <StyledModalHeader style={{ paddingLeft: "1.7rem" }}>
        <Modal.Title>Settings</Modal.Title>
        <StyledCloseIcon onClick={handleClose} />
      </StyledModalHeader>
      <Modal.Body style={{ padding: "1.7rem" }}>
        <Title>Swaps &amp; Liquidity</Title>
        <Subtitle>Slippage Tolerance</Subtitle>
        <ButtonGroupContainer>
          <SlippageButtonGroup>
            <SlippageButton
              variant={slippage === 10 ? "primary" : "default"}
              onClick={() => {
                updateSlippage(10);
                setCustomSlippage("");
              }}
            >
              {"0.1%"}
            </SlippageButton>
            <SlippageButton
              variant={slippage === 50 ? "primary" : "default"}
              onClick={() => {
                updateSlippage(50);
                setCustomSlippage("");
              }}
            >
              {"0.5%"}
            </SlippageButton>
            <SlippageButton
              variant={slippage === 100 ? "primary" : "default"}
              onClick={() => {
                updateSlippage(100);
                setCustomSlippage("");
              }}
            >
              {"1.0%"}
            </SlippageButton>
            <StyledInput
              value={customSlippage}
              onChange={handleCustomSlippageChange}
              onBlur={() => {
                parseCustomSlippage((slippage / 100).toFixed(2));
              }}
              placeholder={`${(slippage / 100).toFixed(2)}`}
            />{" "}
            <span> %</span>
          </SlippageButtonGroup>
          {!!slippageError ? (
            <WarningText>
              {slippageError === SlippageError.InvalidInput
                ? "Enter a valid slippage percentage"
                : slippageError === SlippageError.RiskyLow
                ? "Your transaction may fail"
                : "Your transaction may be frontrun"}
            </WarningText>
          ) : null}
        </ButtonGroupContainer>
      </Modal.Body>
    </StyledModal>
  );
}
