import { useEffect } from "react";
import { toast } from "react-toastify";
import { useStore } from "store/zustand";
import { toastOptions } from "views/Stake/constants";
import { ToastMessage } from "views/Swap/styles/style";

type PopupsProps = {
  children: React.ReactElement;
};
export default function Popups({ children }: PopupsProps) {
  const [toastData] = useStore((state) => [state.toastData, state.resetToast]);

  useEffect(() => {
    if (!!toastData.message) {
      toast(
        <ToastMessage>
          <span>{toastData.message}</span>
          {toastData?.url ? (
            <span>
              {" "}
              <a href={toastData.url} target="_blank" rel="noreferrer">
                View Transaction
              </a>
            </span>
          ) : null}
        </ToastMessage>,
        {
          ...toastOptions,
          type: toastData.type,
        }
      );
    }
  }, [toastData]);

  return children;
}
