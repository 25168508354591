import { useEffect } from "react";

export default function useOutsideListener(ref: any, setState: any) {
  useEffect(() => {
    const handleClickOutside = (event: any) =>
      //@ts-ignore
      ref?.current && !ref.current.contains(event.target) && setState(false);

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setState]);
}
