import styled from "styled-components";
import RefreshIcon from "assets/icons/refresh.svg";
import { Currency, Price } from "@rosehub-tech/sdk";

const SwapInfoContainer = styled.div`
  margin: 0 0 1rem;
  padding: 0 1.7rem;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  span {
    font-weight: 700;
  }
`;

const StyledRefreshIcon = styled.img`
  margin-left: 8px;
  font-size: 1rem;
  height: 16px;
  width: 16px;
  filter: invert(1);

  &:hover {
    cursor: pointer;
  }
`;

export default function SwapInfo({
  price,
  showInverted,
  setShowInverted,
}: {
  price: Price<Currency, Currency>;
  showInverted: boolean;
  setShowInverted: Function;
}) {
  const formattedPrice = showInverted
    ? price?.toSignificant(6)
    : price?.invert()?.toSignificant(6);

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency);
  const quoteCurrencySymbol =
    price?.quoteCurrency?.symbol === "RPAD"
      ? "RHUB"
      : price?.quoteCurrency.symbol;
  const baseCurrencySymbol =
    price?.baseCurrency?.symbol === "RPAD"
      ? "RHUB"
      : price?.baseCurrency?.symbol;
  const label = showInverted
    ? `${quoteCurrencySymbol} per ${baseCurrencySymbol}`
    : `${baseCurrencySymbol} per ${quoteCurrencySymbol}`;

  return (
    <SwapInfoContainer>
      <p>Price</p>
      {show ? (
        <p>
          <span>
            {" "}
            {formattedPrice ?? "-"} {label}
          </span>
          <StyledRefreshIcon
            src={RefreshIcon}
            alt="refresh"
            onClick={() => setShowInverted((old) => !old)}
          />
        </p>
      ) : null}
    </SwapInfoContainer>
  );
}
