import { useQuery } from "@tanstack/react-query";
import { ethers } from "ethers";
import { useMasterChefAddress } from "hooks/contracts/useContractAddresses";
import { useMasterChefInterface } from "hooks/contracts/useInterfaces";

import { useMemo } from "react";
import { useSigner } from "wagmi";
import farms from "../data/farms";

export default function useActiveLPs(): number[] {
  const { data: signer } = useSigner();
  const masterchefInterface = useMasterChefInterface();
  const masterchefAddress = useMasterChefAddress();
  const { data: lpMultipliers } = useQuery(
    ["lpMultipliers"],
    async () => {
      const contract = new ethers.Contract(
        masterchefAddress,
        masterchefInterface,
        signer
      );
      const lpMultipliers = await Promise.all([
        ...farms?.map(async (farm) => {
          const multiplier = await contract.BONUS_MULTIPLIER();
          return {
            pid: farm.pid,
            multiplier,
          };
        }),
      ]);
      return lpMultipliers;
    },
    {
      enabled: !!signer,
    }
  );

  const lpWithMultipliers = useMemo(() => {
    return lpMultipliers
      ?.map((lp) => {
        const parsedMultiplier = lp.multiplier?.toNumber();
        return Number(parsedMultiplier) > -1 ? lp.pid : -1;
      })
      .filter((multiplier) => multiplier > -1);
  }, [lpMultipliers]);

  return lpWithMultipliers;
}
