import { Currency, WNATIVE } from "@rosehub-tech/sdk";
import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import useChainId from "hooks/useChainId";
import { useMemo, useState } from "react";
import { useStore } from "store/zustand";
import tryParseAmount from "utils/tryParseAmount";
import { useCurrencyBalance } from "views/Liquidity/hooks/useCurrencyBalances";
import { useAccount, useNetwork, useSigner } from "wagmi";
import { useCallWithGasPrice } from "./useCallGasPrice";

const IWROSE = require("abi/contracts/interfaces/IWROSE.sol/IWROSE.json");

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE };
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  typedValue: string | undefined
): {
  wrapType: WrapType;
  execute?: undefined | (() => Promise<void>);
  inputError?: string;
  isLoading?: boolean;
} {
  const chainId = useChainId();
  const { data: signer } = useSigner();
  const network = useNetwork();
  const { address: account } = useAccount();
  const { callWithGasPrice } = useCallWithGasPrice();
  const [updateToast, updateRecentSwapTx] = useStore((state) => [
    state.updateToast,
    state.updateRecentSwapTx,
  ]);
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency);
  const [isLoading, setIsLoading] = useState(false);
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(
    () => tryParseAmount(typedValue, inputCurrency),
    [inputCurrency, typedValue]
  );

  const wroseContract = useMemo(
    () =>
      !network?.chain?.unsupported
        ? new ethers.Contract(WNATIVE[chainId]?.address, IWROSE, signer)
        : undefined,
    [chainId, network, signer]
  );

  return useMemo(() => {
    if (!wroseContract || !chainId || !inputCurrency || !outputCurrency)
      return NOT_APPLICABLE;

    const sufficientBalance =
      inputAmount && balance && !balance.lessThan(inputAmount);

    if (inputCurrency?.isNative && WNATIVE[chainId]?.equals(outputCurrency)) {
      return {
        isLoading,
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                setIsLoading(true);
                try {
                  const txReceipt = await callWithGasPrice(
                    wroseContract,
                    "deposit",
                    undefined,
                    {
                      value: `0x${inputAmount.quotient.toString(16)}`,
                    }
                  );
                  const amount = inputAmount.toSignificant(6);
                  const native = inputCurrency.symbol;
                  const wrap = WNATIVE[chainId].symbol;
                  updateToast({
                    message: `Successfully swapped ${amount} ${native} to ${wrap}`,
                    url: `${blockExplorers.get(chainId)}/tx/${txReceipt.hash}`,
                    type: "success",
                  });
                  updateRecentSwapTx(txReceipt?.hash);
                } catch (error) {
                  console.error("Could not deposit", error);
                  updateToast({
                    message: `Could not deposit!`,
                    url: ``,
                    type: "error",
                  });
                } finally {
                  setIsLoading(false);
                }
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : `Insufficient ${inputCurrency.symbol} balance`,
      };
    }
    if (WNATIVE[chainId]?.equals(inputCurrency) && outputCurrency?.isNative) {
      return {
        isLoading,
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  setIsLoading(true);
                  const txReceipt = await callWithGasPrice(
                    wroseContract,
                    "withdraw",
                    [`0x${inputAmount.quotient.toString(16)}`]
                  );
                  const amount = inputAmount.toSignificant(6);
                  const wrap = WNATIVE[chainId].symbol;
                  const native = outputCurrency.symbol;
                  updateToast({
                    message: `Successfully unwrapped ${amount} ${wrap} to ${native}`,
                    url: `${blockExplorers.get(chainId)}/tx/${txReceipt.hash}`,
                    type: "success",
                  });
                  updateRecentSwapTx(txReceipt?.hash);
                } catch (error) {
                  console.error("Could not withdraw", error);
                  updateToast({
                    message: `Could not withdraw!`,
                    url: ``,
                    type: "error",
                  });
                } finally {
                  setIsLoading(false);
                }
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : `Insufficient ${inputCurrency.symbol} balance`,
      };
    }
    return NOT_APPLICABLE;
  }, [
    wroseContract,
    chainId,
    inputCurrency,
    outputCurrency,
    inputAmount,
    balance,
    isLoading,
    callWithGasPrice,
    updateToast,
    updateRecentSwapTx,
  ]);
}
