import styled from "styled-components";
import { SerializedFarmConfig } from "types";
import GridItem from "./GridItem";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;

  @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.5rem;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
  }
`;

export default function FarmsGrid({
  farms,
}: {
  farms: SerializedFarmConfig[];
}) {
  return (
    <Container>
      {farms?.length > 0 ? (
        farms.map((farm) => <GridItem key={farm.pid} farm={farm} />)
      ) : (
        <p>No farms available at the moment.</p>
      )}
    </Container>
  );
}
