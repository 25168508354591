import { useQuery } from "@tanstack/react-query";
import { BigNumber, ethers } from "ethers";
import { useMasterChefAddress } from "hooks/contracts/useContractAddresses";
import {
  useERC20Interface,
  useSwapPairInterface,
} from "hooks/contracts/useInterfaces";
import { getDecimals } from "utils/ethereumFunctions";
import { useSigner } from "wagmi";

export default function useFarmLiquidityValue(
  lpTokenAddress: string
):
  | { liquidityAmount: BigNumber; reserve0: string; reserve1: string }
  | undefined {
  const { data: signer } = useSigner();
  const pairInterface = useSwapPairInterface();
  const erc20ABI = useERC20Interface();
  const masterChefAddress = useMasterChefAddress();
  const { data: liquidity } = useQuery(
    ["totalLiquidityValue", lpTokenAddress],
    async () => {
      const contract = new ethers.Contract(
        lpTokenAddress,
        pairInterface,
        signer
      );

      const balanceOf = await contract.balanceOf(masterChefAddress);
      const reserves = await contract.getReserves();
      const token0Address = await contract.token0();
      const token1Address = await contract.token1();

      const token0 = new ethers.Contract(token0Address, erc20ABI, signer);
      const token1 = new ethers.Contract(token1Address, erc20ABI, signer);
      const decimals0 = await getDecimals(token0);
      const decimals1 = await getDecimals(token1);

      return {
        balanceOf: balanceOf,
        reserves,
        decimals0,
        decimals1,
      };
    },
    {
      enabled: !!signer && !!lpTokenAddress,
      refetchInterval: 10000,
    }
  );

  const liquidityAmount = liquidity?.balanceOf;
  const reserves = liquidity?.reserves;
  const decimals0 = liquidity?.decimals0;
  const decimals1 = liquidity?.decimals1;

  const reserve0 =
    reserves?.length > 0
      ? ethers.utils.formatUnits(reserves[0], decimals0)
      : undefined;
  const reserve1 =
    reserves?.length > 1
      ? ethers.utils.formatUnits(reserves[1], decimals1)
      : undefined;

  return { liquidityAmount, reserve0, reserve1 };
}

export function useFarmLPAmount(lpTokenAddress: string) {
  const pairInterface = useSwapPairInterface();
  const masterChefAddress = useMasterChefAddress();
  const { data: signer } = useSigner();
  return useQuery(
    ["totalLiquidityValue", lpTokenAddress],
    async () => {
      const contract = new ethers.Contract(
        masterChefAddress,
        pairInterface,
        signer
      );

      const balanceOf = await contract.balanceOf(lpTokenAddress);
      console.log("balanceOf", balanceOf);
      return balanceOf;
    },
    {
      enabled: !!signer && !!lpTokenAddress,
    }
  );
}

export function useStakingLiquidityValue(
  lpTokenAddress: string
):
  | { liquidityAmount: BigNumber; reserve0: string; reserve1: string }
  | undefined {
  const { data: signer } = useSigner();
  const masterChefInterface = useSwapPairInterface();
  const erc20ABI = useERC20Interface();
  const masterChefAddress = useMasterChefAddress();
  const { data: liquidity } = useQuery(
    ["useStakingLiquidityValue", lpTokenAddress],
    async () => {
      const contract = new ethers.Contract(
        lpTokenAddress,
        masterChefInterface,
        signer
      );

      const balanceOf = await contract.balanceOf(masterChefAddress);
      const reserves = await contract.getReserves();
      const token0Address = await contract.token0();
      const token1Address = await contract.token1();

      const token0 = new ethers.Contract(token0Address, erc20ABI, signer);
      const token1 = new ethers.Contract(token1Address, erc20ABI, signer);
      const decimals0 = await getDecimals(token0);
      const decimals1 = await getDecimals(token1);

      return {
        balanceOf: balanceOf,
        reserves,
        decimals0,
        decimals1,
      };
    },
    {
      enabled: !!signer && !!lpTokenAddress,
      refetchInterval: 10000,
    }
  );

  const liquidityAmount = liquidity?.balanceOf;
  const reserves = liquidity?.reserves;
  const decimals0 = liquidity?.decimals0;
  const decimals1 = liquidity?.decimals1;

  const reserve0 =
    reserves?.length > 0
      ? ethers.utils.formatUnits(reserves[0], decimals0)
      : undefined;
  const reserve1 =
    reserves?.length > 1
      ? ethers.utils.formatUnits(reserves[1], decimals1)
      : undefined;

  return { liquidityAmount, reserve0, reserve1 };
}
