import { collection, getDoc, getDocs, doc } from "firebase/firestore";
import { db } from "./firebase";
import {
  FARMS_TVL_KEY,
  PROJECTS_FIREBASE_KEY,
  STAKING_TVL_KEY,
  UNIQUE_LP_STAKERS_FIREBASE_KEY,
  UNIQUE_WALLET_LIST_FIREBASE_KEY,
  VAULTS_TVL_KEY,
} from "config/exchange";
import { useQuery } from "@tanstack/react-query";
import { FormSchemaType } from "views/Fundraise/schema";

async function getUserLength() {
  const dbCollection = collection(db, UNIQUE_LP_STAKERS_FIREBASE_KEY);
  try {
    const data = await getDocs(dbCollection);
    const length = data.docs.length;
    return length;
  } catch (error) {
    console.log("getUserLength error", error);
  }
}

export function useUserLength() {
  return useQuery(["userLength"], getUserLength);
}

async function getFirebaseWalletList(address) {
  try {
    const uniqueWalletSnapshot = await getDoc(
      doc(db, UNIQUE_WALLET_LIST_FIREBASE_KEY, address)
    );
    return uniqueWalletSnapshot.exists();
  } catch (error) {
    console.log("getUserLength error", error);
  }
}

export function useGetIsWalletExist(address: string) {
  return useQuery(
    [UNIQUE_WALLET_LIST_FIREBASE_KEY],
    async () => await getFirebaseWalletList(address)
  );
}

async function getStaticValues() {
  const dbCollection = collection(db, "static");
  try {
    const data = await getDocs(dbCollection);
    const tvl = data.docs.map((doc) => doc.data().tvl);
    const ave_apy = data.docs.map((doc) => doc.data().ave_apy);
    return { tvl: tvl[0], ave_apy: ave_apy[0] };
  } catch (error) {
    console.log("getUserLength error", error);
  }
}

export function useStaticValues() {
  return useQuery(["staticValues"], getStaticValues);
}

export function useCheckLPStakerAddress(address: string) {
  return useQuery(["useCheckLPStakerAddress", address], async () => {
    const uniqueLPStakersSnapshot = await getDoc(
      doc(db, UNIQUE_LP_STAKERS_FIREBASE_KEY, address)
    );
    if (uniqueLPStakersSnapshot.exists()) {
      return uniqueLPStakersSnapshot.data();
    }
    return false;
  });
}

export function useGetProjects(address: string) {
  return useQuery(["useGetProjects", address], async () => {
    const dbCollection = collection(db, PROJECTS_FIREBASE_KEY);
    try {
      const projectsSnapshot = await getDocs(dbCollection);
      const projectsList = projectsSnapshot.docs?.map((project) => {
        return {
          id: project.id,
          data: project.data(),
        };
      });
      console.log("useGetProjects", projectsList);
      return {
        live: projectsList?.filter(
          (project) => project?.data.launchStatus === "live"
        ),
        ended: projectsList?.filter(
          (project) => project?.data.launchStatus === "ended"
        ),
        upcoming: projectsList?.filter(
          (project) => project?.data.launchStatus === "upcoming"
        ),
      };
    } catch (error) {
      console.log("useGetProjects error", error);
    }
  });
}

export interface ProjectItem extends FormSchemaType {
  entryStatus: string;
  launchStatus: string;
}

export function useGetProject(projectId: string) {
  return useQuery(
    ["useGetProject", projectId],
    async () => {
      const projectRef = doc(db, PROJECTS_FIREBASE_KEY, projectId);
      const projectSnapshot = await getDoc(projectRef);
      const projectData = projectSnapshot.data();
      return projectData as ProjectItem;
    },
    {
      enabled: !!projectId,
    }
  );
}

export function useVaultsTVL() {
  return useQuery(["vaults-tvl"], async () => {
    const dbCollection = collection(db, VAULTS_TVL_KEY);
    try {
      const tvlSnapshot = await getDocs(dbCollection);
      const vaultsTVL = tvlSnapshot.docs.map((doc) => doc.data());
      const tvls = vaultsTVL.map((vault) => vault.rhub);
      const totalTVL = tvls.reduce((sum, value) => sum + value, 0);
      return totalTVL;
    } catch (error: any) {
      console.log("useVaultsTVL error", error);
    }
  });
}

export function useStakingTVL() {
  return useQuery(["staking-tvl"], async () => {
    const dbCollection = collection(db, STAKING_TVL_KEY);
    try {
      const tvlSnapshot = await getDocs(dbCollection);
      const tvl = tvlSnapshot.docs.map((doc) => doc.data());
      return tvl[0];
    } catch (error: any) {
      console.log("useVaultsTVL error", error);
    }
  });
}

export function useFarmsTVL() {
  return useQuery(["farms-tvl"], async () => {
    const dbCollection = collection(db, FARMS_TVL_KEY);
    try {
      const tvlSnapshot = await getDocs(dbCollection);
      const farmsTVL = tvlSnapshot.docs.map((doc) => doc.data());
      const rhubTVLs = farmsTVL.map((vault) => vault.rhub);
      const rhubLPTVLs = farmsTVL.map((vault) => vault.rhubLP);
      const totalRHUB = rhubTVLs.reduce((sum, value) => sum + value, 0);
      const totalRHUBLP = rhubLPTVLs.reduce((sum, value) => sum + value, 0);
      return {
        rhub: totalRHUB,
        rhubLP: totalRHUBLP,
      };
    } catch (error: any) {
      console.log("useVaultsTVL error", error);
    }
  });
}
