import styled from "styled-components";

export const IconImage = styled.img`
  width: 2rem;
  filter: invert(29%) sepia(89%) saturate(7498%) hue-rotate(319deg)
    brightness(99%) contrast(111%);
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const Container = styled.div`
  width: 100%;
`;

export const FormBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(450px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 4rem;
  }
`;

export const NFTSliderImages = styled.div`
  display: grid;
  place-content: center;
  gap: 1rem;
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NFTSliderImageContainer = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: rgb(104 5 62 / 6%) 0px 56px 72px -72px;
  width: 16rem;
  height: 26rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  z-index: 50;
`;

export const NFTSliderNextImageContainer = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: rgb(104 5 62 / 6%) 0px 56px 72px -72px;
  width: 16rem;
  height: 26rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 5rem), -50%) scale(0.7) perspective(16em)
    rotateY(-12deg);
  z-index: 49;
`;

export const NFTSlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

export const SliderButton = styled.button`
  border: 1px solid rgba(255, 255, 255, 0.04);
  padding: 1rem;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  transform: translate(-50%, 0);
  z-index: 2;
  display: grid;
  place-content: center;
  cursor: pointer;
  background-color: rgba(255, 0, 135, 0.16);
  position: absolute;
`;

export const SliderIconContainer = styled.div`
  width: 1.2rem;
  background-color: rgba(255, 0, 135, 0.16);

  padding: 1rem;
`;

export const SliderIcon = styled.img`
  width: 1.2rem;
  filter: invert(29%) sepia(89%) saturate(7498%) hue-rotate(319deg)
    brightness(99%) contrast(111%) opacity(0.72);
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 450px;

  & .slick-slider.center {
    display: flex;
    align-items: center;
    height: 100%;
  }

  @media (max-width: 1024px) {
    min-width: 100%;
    margin: 0 !important;
  }
`;

export const CalculatorHeader = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 1rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1.7rem;
  border-bottom: 1px solid rgba(243, 8, 137, 0.224);
`;

export const CalculatorFooter = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  border-top: 1px solid rgba(255, 0, 135, 0.16);
  border-bottom: 1px solid rgba(255, 0, 135, 0.16);
  padding: 1.7rem 0;

  h4 {
    font-family: Nasalization;
    opacity: 0.36;
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    font-family: Nasalization;
    color: rgb(255, 0, 135);
    display: inline-flex;
    gap: 0.5rem;
    align-items: flex-end;
    margin-right: 4px;
  }

  span {
    font-family: "Jakarta Sans";
    color: rgb(255, 0, 135);
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-size: 0.75rem;
    transition: all 360ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    text-overflow: ellipsis;
  }
`;

export const RPADContent = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  font-family: Nasalization;
  margin-right: 4px;
  opacity: 0.36;

  span {
    font-family: "Jakarta Sans";
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-size: 0.75rem;
    transition: all 360ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    text-overflow: ellipsis;
  }
`;

export const CalculatorAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 1.7rem;
`;

export const CalculatorBody = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1.7rem;
`;

export const CalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: black;
  box-shadow: rgb(255 0 128 / 45%) 0px 0px 1.4rem inset;
  border-radius: 1rem;
  width: fit-content;
  height: fit-content;
`;

export const Tier = styled.h2`
  font-family: Nasalization;
  color: rgb(255, 0, 135);
  text-transform: uppercase;
  margin-bottom: 2rem;
  text-align: center;
`;

export const FormColumnTitle = styled.h3`
  font-family: Nasalization;
  color: rgb(255, 0, 135);
  font-size: 1.17rem;
  margin-bottom: 0;
`;

export const FormColumnLink = styled.span`
  color: rgb(255, 0, 135);
  font-size: 0.8125rem;
  cursor: pointer;
  font-family: "Jakarta Sans";
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FormColumnDesc = styled.p`
  font-family: Nasalization;
  opacity: 0.36;
  font-size: 0.8125rem;
  margin-bottom: 0;
`;

export const InputWrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.133);
  border-radius: 0.72rem;
  padding: 0.5rem 1rem;

  &:focus-within {
    background-color: rgba(243, 73, 158, 0.11);
    border-color: rgb(255, 7, 143);
  }
`;

export const InputLabel = styled.label`
  font-size: 0.8rem;
`;

export const InputLabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
`;

export const InputSymbol = styled.div`
  height: 100%;
`;

export const TripleActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  margin: 0 2rem;
`;
