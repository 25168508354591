import { useSwapPairInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { Currency, CurrencyAmount, Pair } from "@rosehub-tech/sdk";
import { useCallback, useMemo } from "react";
import { wrappedCurrency } from "types/WrappedCurrency";
import { useContractInfiniteReads, useSigner } from "wagmi";
import { useStore } from "store/zustand";
import { ethers } from "ethers";
import { isEmpty } from "lodash";
import { Field } from "types";
import { useQuery } from "@tanstack/react-query";

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function usePairs(
  currencies: [Currency | undefined, Currency | undefined][]
): [PairState, Pair | null][] {
  const chainId = useChainId();
  const { data: signer } = useSigner();
  const swapInterface = useSwapPairInterface();

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies]
  );

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB)
            ? Pair.getAddress(tokenA, tokenB)
            : undefined;
        } catch (error: any) {
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`
          );

          return undefined;
        }
      }),
    [tokens]
  );

  const getReservesContracts = useMemo(() => {
    if (pairAddresses?.length > 0) {
      return pairAddresses.map((pairAddress) => ({
        addressOrName: pairAddress,
        contractInterface: swapInterface,
        functionName: "getReserves",
        chainId,
      }));
    }
    return [];
  }, [chainId, pairAddresses, swapInterface]);

  const { data: results } = useContractInfiniteReads({
    cacheKey: "liquidityReserves",
    contracts: () => getReservesContracts,
    enabled:
      getReservesContracts?.length > 0 && !!signer && pairAddresses?.length > 0,
    onError: (error) => {
      console.log("getReserves error", error);
    },
  });

  return useMemo(() => {
    if (!!results && results?.pages?.length > 0) {
      return results?.pages[0]?.map((res, i) => {
        const tokenA = !!tokens[i] ? tokens[i][0] : undefined;
        const tokenB = !!tokens[i] ? tokens[i][1] : undefined;

        if (!tokenA || !tokenB || tokenA.equals(tokenB))
          return [PairState.INVALID, null];
        if (!res) return [PairState.NOT_EXISTS, null];
        const reserve0 = res[0];
        const reserve1 = res[1];
        const [token0, token1] = tokenA.sortsBefore(tokenB)
          ? [tokenA, tokenB]
          : [tokenB, tokenA];

        return [
          PairState.EXISTS,
          new Pair(
            CurrencyAmount.fromRawAmount(token0, reserve0),
            CurrencyAmount.fromRawAmount(token1, reserve1)
          ),
        ];
      });
    }
  }, [results, tokens]);
}

export function usePair(
  currencyA?: Currency,
  currencyB?: Currency
): [PairState, Pair | null] {
  const chainId = useChainId();
  const { data: signer } = useSigner();
  const pairInterface = useSwapPairInterface();

  const pairAddress = useMemo(() => {
    if (!currencyA || !currencyB) {
      return undefined;
    }
    const token0 = wrappedCurrency(currencyA, chainId);
    const token1 = wrappedCurrency(currencyB, chainId);
    if (token0.equals(token1)) return undefined;
    return Pair?.getAddress(token0, token1);
  }, [chainId, currencyA, currencyB]);

  const { data: reserves } = useQuery(
    ["pairReserves", pairAddress],
    async () => {
      const contract = new ethers.Contract(pairAddress, pairInterface, signer);
      const reserves = await contract.getReserves();
      return reserves;
    },
    {
      enabled: !isEmpty(pairAddress) && !!signer,
    }
  );

  return useMemo(() => {
    const tokenA = wrappedCurrency(currencyA, chainId);
    const tokenB = wrappedCurrency(currencyB, chainId);
    if (!tokenA || !tokenB || tokenA.equals(tokenB))
      return [PairState.INVALID, null];
    if (!reserves) return [PairState.NOT_EXISTS, null];
    const [token0, token1] = tokenA.sortsBefore(tokenB)
      ? [tokenA, tokenB]
      : [tokenB, tokenA];
    const reserve0 = reserves[0];
    const reserve1 = reserves[1];
    return [
      PairState.EXISTS,
      new Pair(
        CurrencyAmount.fromRawAmount(token0, reserve0),
        CurrencyAmount.fromRawAmount(token1, reserve1)
      ),
    ];
  }, [chainId, currencyA, currencyB, reserves]);
}

export function useMintState() {
  return useStore((state) => [
    state.independentField,
    state.typedValue,
    state.otherTypedValue,
  ]);
}

export function useLiquidityInputHandlers(noLiquidity: boolean | undefined): {
  onFieldAInput: (typedValue: string) => void;
  onFieldBInput: (typedValue: string) => void;
} {
  const typeInput = useStore((state) => state.typeInput);

  const onFieldAInput = useCallback(
    (typedValue: string) => {
      typeInput(Field.CURRENCY_A, typedValue, noLiquidity === true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noLiquidity]
  );
  const onFieldBInput = useCallback(
    (typedValue: string) => {
      typeInput(Field.CURRENCY_B, typedValue, noLiquidity === true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noLiquidity]
  );

  return {
    onFieldAInput,
    onFieldBInput,
  };
}
