import { useCallback, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import isEmpty from "lodash/isEmpty";
import PlusIcon from "assets/icons/plus.svg";
import ArrowIcon from "assets/icons/arrow.svg";
import {
  CalculatorAction,
  CalculatorBody,
  CalculatorContainer,
  CalculatorFooter,
  CalculatorHeader,
  Container,
  FooterContent,
  FormBox,
  FormColumn,
  FormColumnDesc,
  FormColumnTitle,
  IconWrapper,
  InputContainer,
  InputLabel,
  InputLabelContainer,
  InputSymbol,
  InputWrapper,
  IconImage,
  RPADContent,
  Tier,
  FormColumnLink,
  TitleContainer,
} from "./style";
import GradientButton from "components/GradientBtn";
import CalculatorInput from "./CalculatorInput";
import { useStore } from "store/zustand";
import NFTListModal from "views/Stake/NFTListModal";
import useConfirmStaking from "../hooks/useConfirmStaking";
import GradientBtnAlt from "components/GradientBtnAlt";
import useUserStaked from "views/Stake/hooks/useUserStaked";
import useRPADAllowance from "views/Stake/hooks/useRPADAllowance";
import {
  useApproveLP,
  useApproveNFTs,
  useApproveRoseHub,
} from "views/Stake/hooks/useApproveAllowance";
import YourStakedCard from "views/Stake/YourStaked";
import useIsApprovedNFTs from "views/Stake/hooks/useIsApprovedNFTs";
import { ethers } from "ethers";
import useGetStakingPoints from "../hooks/useGetStakingPoints";
// import { TierSlider } from "./TierSlider";
import TierKeenSlider from "./TierSliderV2";

export default function CalculatorForm() {
  const [rpadIn, setRpadIn] = useState<number | undefined>();
  const [rpadLPIn, setRpadLPIn] = useState<number | undefined>();
  const [lockPeriod, setLockPeriod] = useState("365");
  const [showNFTListModal, setShowNFTListModal] = useState(false);
  const [
    selectedNFTIds,
    rpad,
    rpadLP,
    resetNFTSelection,
    user,
    stakedRPAD,
    locktime,
    legendaryCount,
    stakedNFTs,
  ] = useStore((state) => [
    state.selectedNFTIds,
    state.rpad,
    state.rpadLP,
    state.resetSelection,
    state.user,
    state.stakedRPAD,
    state.lockTime,
    state.legendaryCount,
    state.stakedNFTs,
  ]);
  const [showCalculator, setShowCalculator] = useState(true);
  const refetch = useUserStaked();

  const allowance = useRPADAllowance();
  const isAllNFTsApproved = useIsApprovedNFTs();
  const { onConfirmStaking, isLoading } = useConfirmStaking(
    rpadIn as number,
    rpadLPIn as number,
    Number(lockPeriod)
  );

  const totalBasisPoints = useGetStakingPoints(selectedNFTIds);
  const calcNFTBoost = totalBasisPoints / 100 + 1;

  useEffect(() => {
    if (!!stakedRPAD && stakedRPAD > 0) {
      setShowCalculator(false);
    }
    return () => resetNFTSelection();
  }, [resetNFTSelection, stakedRPAD]);

  const calcXRPAD = useMemo(() => {
    const legendaryBoosterPoints = legendaryCount * 2000;
    if (rpadIn || rpadLPIn) {
      const decreasePerDay =
        (((rpadIn || 0) + (rpadLPIn || 0) * 2.5) * calcNFTBoost) / 365;

      const rewardGivenLockPeriod = (365 - Number(lockPeriod)) * decreasePerDay;

      return (
        ((rpadIn || 0) * 1 + (rpadLPIn || 0) * 2.5) * calcNFTBoost -
        rewardGivenLockPeriod +
        legendaryBoosterPoints
      );
    }
    return legendaryBoosterPoints;
  }, [calcNFTBoost, legendaryCount, lockPeriod, rpadIn, rpadLPIn]);

  const calcRPAD = useMemo(() => {
    const apyReward = 0.2 / 365;
    const decreasePerDay =
      (((rpadIn || 0) + (rpadLPIn || 0) * 2.5) * calcNFTBoost) / 365;
    const lockDaysFromInput = Number(lockPeriod) + 1;
    const halfLockDays = Number(lockPeriod) / 2;
    const fixedDecrease = Number(decreasePerDay.toFixed(2));

    const boosterPoints = 0;
    return (
      (fixedDecrease * lockDaysFromInput * (Number(lockPeriod) - halfLockDays) +
        boosterPoints) *
      apyReward
    );
  }, [calcNFTBoost, lockPeriod, rpadIn, rpadLPIn]);

  const lockedDate = useMemo(() => {
    const unixTime =
      !!user && user?.lockEndTime ? user.lockEndTime.toNumber() : undefined;
    const currentEndDate =
      !!unixTime && unixTime > 0 ? moment.unix(unixTime) : undefined;
    let newEndDate = moment().add(Number(lockPeriod), "days");

    if (newEndDate.isBefore(currentEndDate)) {
      newEndDate = currentEndDate as Moment;
    }
    return newEndDate.format("LLL");
  }, [lockPeriod, user]);

  const handleConfirm = useCallback(() => {
    onConfirmStaking(() => refetch());
  }, [onConfirmStaking, refetch]);

  const handleRPADChange = (value: number) => {
    setRpadIn(value);
  };

  const handleRPADLPChange = (value: number) => {
    setRpadLPIn(value);
  };

  const handleLockPeriodChange = (value: number) => {
    if (value <= 365) {
      setLockPeriod(value.toString());
    } else {
      setLockPeriod("365");
    }
  };

  const handleNFTListModal = () => {
    setShowNFTListModal((old) => !old);
  };

  const { write: approveRPAD, isLoading: rpadApprovalLoading } =
    useApproveRoseHub();
  const { write: approveRPADLP, isLoading: rpadLPApprovalLoading } =
    useApproveLP();
  const { write: approveNFT, isLoading: nftApprovalLoading } = useApproveNFTs();

  const [isDisabled, disabledText] = useMemo(() => {
    if (!!rpadIn && rpadIn > rpad) {
      return [true, "Insufficient RHUB balance."];
    }
    if (!!rpadLPIn && rpadLPIn > rpadLP) {
      return [true, "Insufficient RHUB-LP balance."];
    }
    return [
      !rpadIn && !rpadLPIn && selectedNFTIds.length === 0,
      "Enter amount",
    ];
  }, [rpad, rpadIn, rpadLP, rpadLPIn, selectedNFTIds]);

  const CalculatorButton = useMemo(() => {
    if (isDisabled) {
      return (
        <GradientButton label="Stake" disabled disabledText={disabledText} />
      );
    }

    if (!allowance) {
      return <GradientButton label="Approve RHUB" onClick={approveRPAD} />;
    }

    if (
      !!allowance &&
      !!allowance.tokens &&
      !!rpadIn &&
      ((!!allowance?.tokens.rpad && rpadIn > allowance?.tokens.rpad) ||
        !allowance.tokens.rpad)
    ) {
      return (
        <GradientButton
          label="Approve RHUB"
          onClick={approveRPAD}
          loading={rpadApprovalLoading}
        />
      );
    }

    if (
      !!allowance &&
      !!allowance?.tokens &&
      !!rpadLPIn &&
      ((!!allowance?.tokens?.rpadLP && rpadLPIn > allowance?.tokens?.rpadLP) ||
        !allowance?.tokens?.rpadLP)
    ) {
      return (
        <GradientButton
          width={200}
          label="Approve RHUB-LP"
          onClick={approveRPADLP}
          loading={rpadLPApprovalLoading}
        />
      );
    }

    if (!isAllNFTsApproved && selectedNFTIds.length > 0) {
      return (
        <GradientButton
          label="Approve NFTs"
          onClick={approveNFT}
          loading={nftApprovalLoading}
        />
      );
    }

    if (!!allowance && (!!rpadIn || !!rpadLPIn || selectedNFTIds.length > 0)) {
      return (
        <GradientButton
          label="Stake"
          onClick={handleConfirm}
          loading={isLoading}
        />
      );
    }
  }, [
    isDisabled,
    allowance,
    rpadIn,
    rpadLPIn,
    isAllNFTsApproved,
    selectedNFTIds.length,
    disabledText,
    approveRPAD,
    rpadApprovalLoading,
    approveRPADLP,
    rpadLPApprovalLoading,
    approveNFT,
    nftApprovalLoading,
    handleConfirm,
    isLoading,
  ]);

  const handleShowCalculator = useCallback(() => {
    setShowCalculator((old) => !old);
  }, []);

  const tierPoints = useMemo(() => {
    const userPoints =
      !!user && user?.points ? ethers.utils.formatEther(user?.points) : 0;
    return !!user && !showCalculator ? userPoints : calcXRPAD;
  }, [calcXRPAD, showCalculator, user]);

  return (
    <Container>
      <Tier>Tiers</Tier>
      <FormBox>
        <FormColumn>
          {!showCalculator && (!!stakedRPAD || stakedNFTs?.length > 0) ? (
            <YourStakedCard onShowCalculator={handleShowCalculator} />
          ) : (
            <CalculatorContainer>
              <CalculatorHeader>
                <div>
                  <TitleContainer>
                    <FormColumnTitle>xRHUB Calculator</FormColumnTitle>
                    <FormColumnLink
                      onClick={() => setShowCalculator((old) => !old)}
                    >
                      Show Your Stake
                    </FormColumnLink>
                  </TitleContainer>
                  <FormColumnDesc>
                    Calculate your xRHUB depending on the amount of staked
                    tokens and your lock time.
                  </FormColumnDesc>
                </div>
              </CalculatorHeader>
              <CalculatorBody>
                <InputWrapper>
                  <InputLabelContainer onClick={() => setRpadIn(rpad)}>
                    <InputLabel>Balance: {Number(rpad).toFixed(6)}</InputLabel>
                    <InputLabel>RHUB</InputLabel>
                  </InputLabelContainer>
                  <InputContainer>
                    <CalculatorInput
                      onInputChange={handleRPADChange}
                      value={rpadIn?.toString()}
                    />
                  </InputContainer>
                </InputWrapper>
                <InputWrapper>
                  <InputLabelContainer onClick={() => setRpadLPIn(rpadLP)}>
                    <InputLabel>
                      Balance: {Number(rpadLP).toFixed(6)}
                    </InputLabel>
                    <InputLabel>RHUB-LP</InputLabel>
                  </InputLabelContainer>
                  <InputContainer>
                    <CalculatorInput
                      onInputChange={handleRPADLPChange}
                      value={rpadLPIn?.toString()}
                    />
                  </InputContainer>
                </InputWrapper>
                <InputWrapper style={{ border: "none" }}>
                  <GradientBtnAlt
                    onClick={handleNFTListModal}
                    label={
                      !isEmpty(selectedNFTIds) && selectedNFTIds?.length > 0
                        ? `Selected RoseApes (${selectedNFTIds.length})`
                        : "Select a RoseApe NFT"
                    }
                  />
                </InputWrapper>
                <IconWrapper>
                  <IconImage src={PlusIcon} alt="plus" />
                </IconWrapper>
                <InputWrapper>
                  <InputLabel>Lock Period</InputLabel>
                  <InputContainer>
                    <CalculatorInput
                      onInputChange={handleLockPeriodChange}
                      value={lockPeriod}
                      limit={365}
                    />
                    <InputSymbol>Days</InputSymbol>
                  </InputContainer>
                </InputWrapper>
                <IconWrapper>
                  <IconImage src={ArrowIcon} alt="plus" />
                </IconWrapper>
              </CalculatorBody>
              <CalculatorFooter>
                <FooterContent>
                  <h2>{formatAmount(calcXRPAD)}</h2>
                  <span>xRHUB</span>
                </FooterContent>
                <RPADContent>
                  {formatAmount(calcRPAD)} <span>RHUB</span>{" "}
                  <span>20% APY</span>
                </RPADContent>
                <h4>locked until {lockedDate}</h4>
              </CalculatorFooter>
              <CalculatorAction>{CalculatorButton}</CalculatorAction>
            </CalculatorContainer>
          )}
        </FormColumn>
        <FormColumn style={{ margin: "0 20px" }}>
          {/* <TierSlider points={tierPoints as number} /> */}
          <TierKeenSlider points={tierPoints as number} />
        </FormColumn>
      </FormBox>
      <NFTListModal show={showNFTListModal} onHide={handleNFTListModal} />
    </Container>
  );
}

export function formatAmount(amount: number) {
  const num = Number(amount);
  return Math.abs(num) > 999
    ? `${Number(Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1)}K`
    : Number(Math.sign(num) * Math.abs(num)).toFixed(1);
}
