import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import useChainId from "hooks/useChainId";
import { useStore } from "store/zustand";
import { useGetWROSE } from "views/Swap/hooks/useGetAmountsOut";
import {
  erc20ABI,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import isEmpty from "lodash/isEmpty";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";

export default function useApproveLiquidityAllowance(
  tokenAddress: string,
  refetch: () => void
) {
  const chainId = useChainId();
  const updateToast = useStore((state) => state.updateToast);
  const routerAddress = useRouterAddress();
  const wroseAddress = useGetWROSE();
  const { config } = usePrepareContractWrite({
    addressOrName: tokenAddress || wroseAddress,
    contractInterface: erc20ABI,
    functionName: "approve",
    args: [routerAddress, ethers.constants.MaxUint256],
    onError: (error) => {
      console.log("useApproveLiquidityAllowance error", error);
      refetch();
    },
    enabled: !isEmpty(wroseAddress) && !isEmpty(routerAddress),
  });
  const {
    write,
    data: writeData,
    isLoading: writeLoading,
  } = useContractWrite(config);
  const {
    isError,
    isLoading: transactionLoading,
    error: transactionError,
    isSuccess,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    onSettled: () => {
      console.log("useApproveLiquidityAllowance settled");
      refetch();
    },
    onSuccess: () => {
      updateToast({
        type: "success",
        message: "Contract approved",
        url: `${blockExplorers.get(chainId)}/tx/${writeData?.hash}`,
      });
    },
    enabled: !isEmpty(writeData?.hash),
  });
  return {
    write,
    isLoading: writeLoading || transactionLoading,
    error: isError ? transactionError : undefined,
    isSuccess,
  };
}
