import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import { useStakeVaultInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { useStore } from "store/zustand";
import useRPADBalance from "views/Stake/hooks/useRPADBalance";
import {
  useContractWrite,
  usePrepareContractWrite,
  useSigner,
  useWaitForTransaction,
} from "wagmi";
import useGetVaultAddress, { useGetTotalStakedAmount } from "./useGetVaults";

export default function useConfirmVaultStaking(
  rpadIn: number,
  index: number,
  enabled: boolean,
  refetchVaultInfo: () => void
) {
  const { data: signer } = useSigner();
  const updateToast = useStore((state) => state.updateToast);
  const { vaultAddress } = useGetVaultAddress(index);
  const vaultInterface = useStakeVaultInterface();
  const chainId = useChainId();
  const { refreshBalance } = useRPADBalance();
  const ts = useGetTotalStakedAmount(index, vaultAddress);

  const rpadAmountIn = ethers.utils.parseEther(rpadIn.toString());
  const { config } = usePrepareContractWrite({
    addressOrName: vaultAddress,
    contractInterface: vaultInterface,
    functionName: "deposit",
    args: [rpadAmountIn],
  });

  const {
    write,
    data: depositData,
    isLoading: isDepositing,
  } = useContractWrite({
    ...config,
    onError: (error: any) => {
      console.log("error", error.message);
      updateToast({
        message: `${error?.code}`,
        url: "",
        type: "error",
      });
    },
    enabled: !!signer && enabled && rpadIn > 0,
  });

  const { isError, isLoading: isWaitingDepositTransaction } =
    useWaitForTransaction({
      confirmations: 2,
      hash: depositData?.hash,
      onSuccess: () => {
        const successMessage = `Successfully staked ${rpadIn} RHUB`;
        const txUrl = `${blockExplorers.get(chainId)}/tx/${depositData?.hash}`;
        updateToast({
          message: successMessage,
          url: txUrl,
          type: "success",
        });
        ts?.refetch();
        refreshBalance();
        refetchVaultInfo();
      },
      onError: (error: any) => {
        console.log("error", error.code);
        updateToast({
          message: `${error?.code}`,
          url: "",
          type: "error",
        });
        console.log("stake error", error);
      },
    });
  return {
    onConfirmVaultStaking: write,
    isLoading: isDepositing || isWaitingDepositTransaction,
    isError,
  };
}
