import glassIcon from "assets/icons/glass.svg";

import HeaderPad from "components/HeaderPad";
import Input from "components/Input";
import Toggle from "components/Toggle";
import useDebounce from "hooks/useDebounce";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import farms from "./data/farms";
import Grid from "./Grid";
import { useGetFarmsLength } from "./hooks/useGetFarms";
import useLPWithUserBalance from "./hooks/useLPWithUserBalance";
import isEmpty from "lodash/isEmpty";
import { useAccount, useNetwork } from "wagmi";
import useActiveLPs from "./hooks/useActiveLPs";
// import Options from "components/Options";
// import Choose from "components/Choose";
// import Dropdown from "components/Dropdown";
// import { CHOOSE_OPTIONS, SORT_BYS, VIEW_OPTIONS } from "constants/consts";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  background: ${({ theme }) => theme.bg};
  padding-bottom: 5rem;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: min(1920px, calc(100% - 16rem));
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 1024px) {
    max-width: calc(100% - 1rem);
  }
`;

const Title = styled.h2`
  font-family: "Nasalization";
  color: ${({ theme }) => theme.primary};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const More = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

export default function Farms() {
  const [isStakedOnly, setIsStakedOnly] = useState(false);
  const { isConnected } = useAccount();
  const network = useNetwork();
  const isNetworkUnsupported = network?.chain?.unsupported || false;
  // const [sortBy, setSortBy] = useState(SORT_BYS[0]);
  // const [viewOption, setViewOption] = useState(VIEW_OPTIONS[1]);
  // const [chooseOption, setChooseOption] = useState(CHOOSE_OPTIONS[0]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const { count } = useGetFarmsLength();
  const idsWithStaked = useLPWithUserBalance(farms, isStakedOnly);
  const activeFarmsIds = useActiveLPs();

  const farmsList = useMemo(() => {
    if (!isConnected) return farms;
    if (isNetworkUnsupported) return [];
    //set default farms list
    const list =
      !!count && count > 0 && isConnected ? farms.slice(0, count) : farms;
    //check active farm list
    const activeFarms =
      activeFarmsIds?.length > 0
        ? list.filter((farm) => activeFarmsIds.includes(farm.pid))
        : [];

    //return empty list if no active farms
    if (!isEmpty(debouncedSearchText) && isConnected) {
      //filter activeFarms with user staked and search text
      if (isStakedOnly) {
        return activeFarms?.filter(
          (l) =>
            (l.quoteToken.symbol
              ?.toLowerCase()
              ?.includes(debouncedSearchText.toLowerCase()) ||
              l?.token?.symbol
                ?.toLowerCase()
                ?.includes(debouncedSearchText.toLowerCase())) &&
            idsWithStaked?.includes(l.pid)
        );
      } else {
        //filter activeFarms based on search text
        return activeFarms?.filter(
          (farm) =>
            farm.quoteToken.symbol
              ?.toLowerCase()
              ?.includes(debouncedSearchText.toLowerCase()) ||
            farm?.token?.symbol
              ?.toLowerCase()
              ?.includes(debouncedSearchText.toLowerCase())
        );
      }
    }
    //filter activeFarms based on user staked
    if (isStakedOnly && isConnected) {
      return activeFarms?.filter((l) => idsWithStaked?.includes(l.pid));
    }
    return activeFarms;
  }, [
    isConnected,
    isNetworkUnsupported,
    count,
    activeFarmsIds,
    debouncedSearchText,
    isStakedOnly,
    idsWithStaked,
  ]);

  return (
    <Wrapper>
      {" "}
      <Container>
        <HeaderPad />
        <HeaderContainer>
          <Title>Farms</Title>
          <More>
            <Toggle
              checked={isStakedOnly}
              onChange={() => setIsStakedOnly(!isStakedOnly)}
              label="Staked only"
            />
            {/* <Choose
              items={CHOOSE_OPTIONS}
              selected={chooseOption}
              onChange={setChooseOption}
            /> */}
            <Input
              icon={glassIcon}
              placeholder="Search Farms"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchText(e.currentTarget.value)
              }
            />
            {/* <Dropdown
              label="Sort By:"
              items={SORT_BYS}
              selected={sortBy}
              onChange={setSortBy}
            />
            <Options
              items={VIEW_OPTIONS}
              selected={viewOption}
              onChange={setViewOption}
            /> */}
          </More>
        </HeaderContainer>
        {/* {viewOption.id === VIEW_OPTIONS[0].id ? (
          <Table farmLength={count} />
        ) : (
        )} */}
        <Grid farms={farmsList} />
      </Container>
    </Wrapper>
  );
}
