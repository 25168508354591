import GradientButton from "components/GradientBtn";
import moment from "moment";
import { useMemo, useState } from "react";
import { useStore } from "store/zustand";
import styled from "styled-components";
import { formatAmount } from "views/Home/CalculatorForm";
import ExtendLockTimeModal from "./ExtendLocktimeModal";
import IncreaseStakeModal from "./IncreaseStakeModal";
import GradientBtnAlt from "components/GradientBtnAlt";
import NFTListModal from "./NFTListModal";
import useConfirmStaking from "views/Home/hooks/useConfirmStaking";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: black;
  box-shadow: rgb(255 0 128 / 45%) 0px 0px 1.4rem inset;
  border-radius: 1rem;
  height: fit-content;
  width: 100%;
  max-width: 635.27px;
`;

const Header = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 1rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1.7rem;
  border-bottom: 1px solid rgba(243, 8, 137, 0.224);
`;

const Title = styled.h2`
  font-family: Nasalization;
  color: rgb(255, 0, 135);
  font-size: 1.17rem;
  margin-bottom: 0;
`;

const ShowLink = styled.span`
  color: rgb(255, 0, 135);
  cursor: pointer;
  font-size: 0.8125rem;
`;

const Points = styled.h2`
  font-family: Nasalization;
  color: rgb(255, 0, 135);
  margin-bottom: 0;

  span {
    font-size: 0.8125rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.7rem;
`;

const PointsContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 1.7rem;
`;

const ContentValue = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.17rem;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.7rem;
`;

const ViewNFTWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

interface YourStakedCardProps {
  onShowCalculator: () => void;
}

export default function YourStakedCard({
  onShowCalculator,
}: YourStakedCardProps) {
  const [showNFTModal, setShowNFTModal] = useState(false);
  const [showIncreaseStake, setShowIncreaseStake] = useState(false);
  const [showExtendLocktime, setShowExtendLocktime] = useState(false);
  const [
    user,
    locktime,
    stakedRPAD,
    stakedRPADLP,
    apy,
    rewardAmount,
    stakedNFTs,
    xRHUBPoints,
  ] = useStore((state) => [
    state.user,
    state.lockTime,
    state.stakedRPAD,
    state.stakedRPADLP,
    state.apy,
    state.rewardAmount,
    state.stakedNFTs,
    state.points,
  ]);
  const {
    onConfirmWithdrawAllNfts,
    onConfirmWithdrawDeposits,
    isLoading: isWithdrawingNFTs,
  } = useConfirmStaking();

  const handleNFTModal = () => {
    setShowNFTModal((old) => !old);
  };

  const lockDate = useMemo(() => {
    if (Number(locktime) > 0) {
      return moment().add(locktime, "days").format("LL");
    }
    return null;
  }, [locktime]);

  const isUnlocked = useMemo(() => {
    if (!!lockDate) {
      return moment().isAfter(lockDate);
    }
    return true;
  }, [lockDate]);

  return (
    <Container>
      <Header>
        <Title>Your Stake</Title>
        <ShowLink onClick={onShowCalculator}>Show calculator</ShowLink>
      </Header>
      <ContentContainer>
        <PointsContainer>
          <Points>
            {!!xRHUBPoints ? xRHUBPoints.toFixed(2) : 0} <span>xRHUB</span>
          </Points>
          <p>
            {isUnlocked
              ? `Lock time period is finished`
              : `locked until ${lockDate} (${locktime} days)`}
          </p>
        </PointsContainer>
      </ContentContainer>
      <ContentContainer>
        <ContentValue>
          <p>Staked RHUB</p>
          <p>{formatAmount(stakedRPAD)} RHUB</p>
        </ContentValue>
        <ContentValue>
          <p>Staked RHUB-LP</p>
          <p>{formatAmount(stakedRPADLP)} RHUB-LP</p>
        </ContentValue>
        <ContentValue>
          <p>Staked NFTs</p>
          <p>{stakedNFTs?.length} NFTs</p>
        </ContentValue>
      </ContentContainer>
      {stakedNFTs?.length > 0 ? (
        <ViewNFTWrapper>
          {" "}
          <GradientBtnAlt
            onClick={handleNFTModal}
            label={`View ${stakedNFTs?.length} Staked NFTs`}
          />
        </ViewNFTWrapper>
      ) : null}
      <ContentContainer>
        <ContentValue>
          <p>Estimated APY</p>
          <p>{formatAmount(apy)}%</p>
        </ContentValue>
        <ContentValue>
          <p>Pending Rewards</p>
          <p>{formatAmount(rewardAmount)} RHUB</p>
        </ContentValue>
      </ContentContainer>
      <Actions>
        <GradientButton
          fullWidth
          label="Extend lock time"
          onClick={() => setShowExtendLocktime((old) => !old)}
          disabled={locktime >= 365}
          disabledText="Maximum lock time reached"
        />
        <GradientButton
          fullWidth
          label="Increase stake"
          onClick={() => setShowIncreaseStake((old) => !old)}
        />
        <GradientButton
          fullWidth
          label="Withdraw"
          disabled={!isUnlocked}
          onClick={onConfirmWithdrawDeposits}
          disabledText="Withdraw"
        />
        <GradientButton
          fullWidth
          label="Withdraw All NFTs"
          disabled={!isUnlocked || stakedNFTs.length === 0}
          onClick={onConfirmWithdrawAllNfts}
          loading={isWithdrawingNFTs}
          disabledText="Withdraw All NFTs"
        />
      </Actions>
      <IncreaseStakeModal
        show={showIncreaseStake}
        onHide={() => setShowIncreaseStake((old) => !old)}
      />
      <ExtendLockTimeModal
        show={showExtendLocktime}
        onHide={() => setShowExtendLocktime((old) => !old)}
      />
      <NFTListModal show={showNFTModal} onHide={handleNFTModal} viewOnly />
    </Container>
  );
}
