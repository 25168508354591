import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderComponent from "../../components/HeaderPad";
import { Header, MainContainer, MainWrapper, Title } from "./styles";
import CalculatorForm from "views/Home/CalculatorForm";

export default function Stake() {
  return (
    <MainWrapper>
      <MainContainer>
        <HeaderComponent />{" "}
        <Header>
          <Title>Staking</Title>
        </Header>
        <Row>
          <Col>
            <CalculatorForm />
          </Col>
        </Row>
      </MainContainer>
    </MainWrapper>
  );
}
