import GlowContainer from "components/GlowContainer";
import HeaderPad from "components/HeaderPad";
import IconBtn from "components/IconBtn";
import gearIcon from "assets/icons/gear.svg";
import CurrencyInput from "./CurrencyInput";
import { useCallback, useState } from "react";
import TradeSummary from "./TradeSummary";
import SlippageSettings from "components/SlippageSettings";
import { Col } from "react-bootstrap";
import GradientButton from "components/GradientBtn";
import useApproveSwapAllowance from "./hooks/useApproveSwapAllowance";
import { Currency } from "@rosehub-tech/sdk";
import {
  useSwapActionHandlers,
  useSwapState,
} from "./hooks/useSwapActionHandlers";
import { SwapField as Field } from "types";
import {
  useDerivedSwapInfo,
  useUserSlippageTolerance,
} from "./hooks/useDerivedSwapInfo";
import { useCurrency } from "views/Liquidity/hooks/useCurrencyBalances";
import useChainId from "hooks/useChainId";
import {
  Body,
  Container,
  Header,
  Heading,
  Options,
  Sub,
  SwapButtonContainer,
  SwapCardContainer,
  Title,
} from "./styles/style";
import { wrappedCurrency } from "types/WrappedCurrency";
import useConfirmSwap from "./hooks/useConfirmSwap";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import SwapInfo from "./SwapInfo";
import useWrapCallback, { WrapType } from "./hooks/useWrapCallback";

export default function Swap() {
  const chainId = useChainId();
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const { onSwitchTokens, onCurrencySelection, onUserInput } =
    useSwapActionHandlers();
  const { independentField, typedValue, fieldInput, fieldOutput } =
    useSwapState();
  const inputCurrencyId = fieldInput?.currencyId || "";
  const outputCurrencyId = fieldOutput?.currencyId || "";

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);

  const [showInverted, setShowInverted] = useState<boolean>(false);
  const [allowedSlippage] = useUserSlippageTolerance();

  const {
    trade: derivedTrade,
    // currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
    isTokenApproved,
  } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency
  );

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
    isLoading: isSwappingNative,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : derivedTrade;

  const selectedTokenInput = wrappedCurrency(
    currencies?.[Field.INPUT],
    chainId
  );
  const selectedTokenOutput = wrappedCurrency(
    currencies?.[Field.OUTPUT],
    chainId
  );

  const { write: approveAllowance, isLoading: isApprovingAllowance } =
    useApproveSwapAllowance(selectedTokenInput?.address);

  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const formattedAmounts = {
    [independentField as string]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const { onConfirmSwap, isLoading: isSwapProcessing } = useConfirmSwap(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    formattedAmounts[Field.INPUT],
    formattedAmounts[Field.OUTPUT]
  );

  const handleInputSelect = useCallback(
    (currencyInput: Currency) => {
      onCurrencySelection(Field.INPUT, currencyInput);
      onUserInput(Field.OUTPUT, "0");
    },
    [onCurrencySelection, onUserInput]
  );

  const handleOutputSelect = useCallback(
    (currencyOutput: Currency) => {
      onCurrencySelection(Field.OUTPUT, currencyOutput);
      onUserInput(Field.OUTPUT, "0");
    },
    [onCurrencySelection, onUserInput]
  );

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  const handleSettingsModal = () => {
    setShowSettingsModal((old) => !old);
  };

  const handleApproveAllowance = () => {
    !!approveAllowance && approveAllowance();
  };

  const isValid = !swapInputError;

  const handleConfirmSwap = () => {
    if (!isTokenApproved) {
      handleApproveAllowance();
      return;
    }
    onConfirmSwap(() => {
      onUserInput(Field.INPUT, "0");
      onUserInput(Field.OUTPUT, "0");
    });
  };

  return (
    <>
      <Container>
        <HeaderPad />
        <Col>
          <SwapCardContainer>
            <GlowContainer>
              <Header>
                <Heading>
                  <Title>Exchange</Title>
                  <Sub>Trade tokens instantly</Sub>
                </Heading>
                <Options>
                  <IconBtn icon={gearIcon} onClick={handleSettingsModal} />
                </Options>
              </Header>
              <Body>
                <CurrencyInput
                  label={
                    independentField === Field.OUTPUT && trade
                      ? "From (estimated)"
                      : "From"
                  }
                  token={selectedTokenInput}
                  onSelectToken={handleInputSelect}
                  onCurrencyInputChange={handleTypeInput}
                  currencyValue={formattedAmounts[Field.INPUT] as string}
                  currency={inputCurrency}
                  showNative
                />

                <HiOutlineSwitchVertical
                  onClick={onSwitchTokens}
                  style={{ margin: "0.5rem 0", cursor: "pointer" }}
                />

                <CurrencyInput
                  label={
                    independentField === Field.INPUT && trade
                      ? "To (estimated)"
                      : "To"
                  }
                  token={selectedTokenOutput}
                  onSelectToken={handleOutputSelect}
                  onCurrencyInputChange={handleTypeOutput}
                  currencyValue={formattedAmounts[Field.OUTPUT] as string}
                  currency={outputCurrency}
                  showNative
                />
              </Body>
              <SwapInfo
                price={trade?.executionPrice}
                showInverted={showInverted}
                setShowInverted={setShowInverted}
              />
              <SwapButtonContainer>
                {showWrap ? (
                  <GradientButton
                    label={
                      wrapInputError ??
                      (wrapType === WrapType.WRAP
                        ? "Wrap"
                        : wrapType === WrapType.UNWRAP
                        ? "Unwrap"
                        : null)
                    }
                    width={180}
                    disabled={!!wrapInputError}
                    disabledText={wrapInputError}
                    onClick={onWrap}
                    loading={isSwappingNative}
                  />
                ) : (
                  <GradientButton
                    label={swapInputError || "Swap"}
                    width={180}
                    disabled={!isValid && swapInputError !== "Approve"}
                    disabledText={swapInputError}
                    onClick={handleConfirmSwap}
                    loading={isSwapProcessing || isApprovingAllowance}
                  />
                )}
              </SwapButtonContainer>
            </GlowContainer>
            <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          </SwapCardContainer>
        </Col>
      </Container>
      <SlippageSettings
        show={showSettingsModal}
        handleClose={handleSettingsModal}
      />
    </>
  );
}
