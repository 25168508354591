import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Input from "components/Input";
import Toggle from "components/Toggle";
import glassIcon from "assets/icons/glass.svg";
import HeaderPad from "components/HeaderPad";
import { useGetVaultLength } from "./hooks/useGetVaults";
import { useAccount } from "wagmi";
import { SerializedVaultsConfig } from "types";
import { vaults } from "./data/vaults";
import useVaultsWithBalance from "./hooks/useVaultsWithBalance";
import useDebounce from "hooks/useDebounce";
import { isEmpty } from "lodash";
import Grid from "./Grid";
// import Choose from "components/Choose";
// import Dropdown from "components/Dropdown";
// import Options from "components/Options";
// import { CHOOSE_OPTIONS, SORT_BYS, VIEW_OPTIONS } from "constants/consts";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  background: ${({ theme }) => theme.bg};
  padding-bottom: 5rem;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: min(1920px, calc(100% - 16rem));
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 1024px) {
    max-width: calc(100% - 1rem);
  }
`;

const Title = styled.h2`
  font-family: "Nasalization";
  color: ${({ theme }) => theme.primary};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const More = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const Subtitle = styled.p`
  opacity: 0.56;
  font-weight: 500;
  margin-bottom: 0;
`;

// const VaultsContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   gap: 32px;
//   min-height: 280px;
//   place-items: center;
// `;

export default function Vaults() {
  const { isConnected } = useAccount();
  const [isStakedOnly, setIsStakedOnly] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [sortBy, setSortBy] = useState(SORT_BYS[0]);
  // const [viewOption, setViewOption] = useState(VIEW_OPTIONS[0]);
  // const [chooseOption, setChooseOption] = useState(CHOOSE_OPTIONS[0]);
  const vaultsWithBalance = useVaultsWithBalance(isStakedOnly);
  const vaultsLength = useGetVaultLength();
  const debouncedSearchText = useDebounce(searchText, 500);

  const vaultsList: SerializedVaultsConfig[] = useMemo(() => {
    if (!isConnected) return vaults;
    const list = !!vaultsLength ? [...vaults.slice(0, vaultsLength)] : vaults;
    if (!isEmpty(debouncedSearchText)) {
      if (isStakedOnly) {
        return list?.filter(
          (l) =>
            (l.name?.includes(debouncedSearchText.toLowerCase()) ||
              l?.stakingToken?.symbol
                ?.toLowerCase()
                ?.includes(debouncedSearchText.toLowerCase())) &&
            vaultsWithBalance?.includes(l.vaultId)
        );
      } else {
        return list?.filter(
          (l) =>
            l.name?.includes(debouncedSearchText.toLowerCase()) ||
            l?.stakingToken?.symbol
              ?.toLowerCase()
              ?.includes(debouncedSearchText.toLowerCase())
        );
      }
    }

    if (isStakedOnly && isConnected) {
      return list?.filter((l) => vaultsWithBalance?.includes(l.vaultId));
    }

    return list;
  }, [
    isConnected,
    vaultsLength,
    debouncedSearchText,
    isStakedOnly,
    vaultsWithBalance,
  ]);
  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  return (
    <Wrapper>
      <Container>
        <HeaderPad />
        <Header>
          <div>
            <Title>Vaults</Title>
            <Subtitle>One time deposit per wallet only</Subtitle>
          </div>
          <More>
            <Toggle
              checked={isStakedOnly}
              onChange={() => setIsStakedOnly(!isStakedOnly)}
              label="Staked only"
            />
            <Input
              icon={glassIcon}
              placeholder="Search Farms"
              vault={searchText}
              onChange={handleSearchText}
            />
          </More>
        </Header>
        <Grid vaults={vaultsList} />
      </Container>
    </Wrapper>
  );
}
