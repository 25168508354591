import { ethers } from "ethers";
import { useStakeVaultInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { useMemo } from "react";
import { useAccount, useContractReads } from "wagmi";
import { vaults } from "../data/vaults";

export default function useVaultsWithBalance(isEnabled: boolean): number[] {
  const chainId = useChainId();
  const vaultInterface = useStakeVaultInterface();
  const { address } = useAccount();
  const { data: userVaultInfos } = useContractReads({
    contracts: [
      ...vaults.map((v) => ({
        // @ts-ignore
        addressOrName: v.contractAddress[chainId],
        contractInterface: vaultInterface,
        functionName: "userInfo",
        args: [address],
        chainId,
      })),
    ],
    enabled: isEnabled && !!chainId && chainId > 0,
  });

  const vaultsWithStakedBalance = useMemo(() => {
    if (!isEnabled) return [];
    if (!!userVaultInfos && userVaultInfos?.length) {
      const mappedLpInfos = userVaultInfos.map((userVaultInfo, index) => {
        const staked = !!userVaultInfo?.amount
          ? ethers.utils.formatEther(userVaultInfo.amount)
          : undefined;
        const stakedAmount = Number(staked);

        if (!isNaN(stakedAmount) && stakedAmount > 0) {
          return vaults[index].vaultId;
        }
        return undefined;
      });
      return mappedLpInfos.filter((amount) => Number(amount) >= 0);
    }
  }, [userVaultInfos, isEnabled]);

  return vaultsWithStakedBalance as number[];
}
