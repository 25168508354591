import { tokenAddresses } from "connectors/chains";
import { ChainId, JSBI, Percent, Token } from "@rosehub-tech/sdk";
import { ChainTokenList } from "types";
import { emeraldMainnetTokens, emeraldTestnetTokens } from "constants/tokens";
import BigNumber from "bignumber.js";

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.OASIS_MAINNET]: [],
  [ChainId.EMERALD_MAINNET]: [
    tokenAddresses.get(ChainId.EMERALD_MAINNET).WROSE,
    tokenAddresses.get(ChainId.EMERALD_MAINNET).USDT,
    tokenAddresses.get(ChainId.EMERALD_MAINNET).RHUB,
  ],
  [ChainId.EMERALD_TESTNET]: [
    tokenAddresses.get(ChainId.EMERALD_TESTNET).WROSE,
    tokenAddresses.get(ChainId.EMERALD_TESTNET).USDT,
    tokenAddresses.get(ChainId.EMERALD_TESTNET).RHUB,
  ],
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.OASIS_MAINNET]: [],
  [ChainId.EMERALD_MAINNET]: [
    tokenAddresses.get(ChainId.EMERALD_MAINNET).WROSE,
    tokenAddresses.get(ChainId.EMERALD_MAINNET).USDT,
    tokenAddresses.get(ChainId.EMERALD_MAINNET).RHUB,
  ],
  [ChainId.EMERALD_TESTNET]: [
    tokenAddresses.get(ChainId.EMERALD_TESTNET).WROSE,
    tokenAddresses.get(ChainId.EMERALD_TESTNET).USDT,
    tokenAddresses.get(ChainId.EMERALD_TESTNET).RHUB,
  ],
};

export const PINNED_PAIRS: {
  readonly [chainId in ChainId]?: [Token, Token][];
} = {
  [ChainId.EMERALD_MAINNET]: [
    [emeraldMainnetTokens.rhub, emeraldMainnetTokens.wrose],
    [emeraldMainnetTokens.rhub, emeraldMainnetTokens.usdt],
    [emeraldMainnetTokens.wrose, emeraldMainnetTokens.usdt],
  ],
  [ChainId.EMERALD_TESTNET]: [
    [emeraldTestnetTokens.rhub, emeraldTestnetTokens.usdt],
    [emeraldTestnetTokens.rhub, emeraldTestnetTokens.wrose],
    [emeraldTestnetTokens.wrose, emeraldTestnetTokens.usdt],
  ],
};

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] };
} = {
  [ChainId.EMERALD_MAINNET]: {},
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] };
} = {
  [ChainId.EMERALD_MAINNET]: {},
};

export const BIG_INT_ZERO = JSBI.BigInt(0);
export const BIG_INT_TEN = JSBI.BigInt(10);
export const BIPS_BASE = JSBI.BigInt(10000);
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE);

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_ROSE: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)); // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(
  JSBI.BigInt(50),
  BIPS_BASE
);

export const ZERO_PERCENT = new Percent("0");
export const ONE_HUNDRED_PERCENT = new Percent("1");

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE);
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE);

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(
  JSBI.BigInt(100),
  BIPS_BASE
); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(
  JSBI.BigInt(300),
  BIPS_BASE
); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(
  JSBI.BigInt(500),
  BIPS_BASE
); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(
  JSBI.BigInt(1500),
  BIPS_BASE
); // 15%

export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);
export const BIG_TWO = new BigNumber(2);
export const BIG_NINE = new BigNumber(9);
export const BIG_TEN = new BigNumber(10);
export const OASIS_BLOCK_TIME = 6;
export const BLOCKS_PER_YEAR = (60 / OASIS_BLOCK_TIME) * 60 * 24 * 365; // 10512

export const UNIQUE_LP_STAKERS_FIREBASE_KEY = "unique-lp-stakers";
export const UNIQUE_WALLET_LIST_FIREBASE_KEY = "unique-wallet-addresses";
export const PROJECTS_FIREBASE_KEY = "projects";
export const VAULTS_TVL_KEY = "vaults-tvl";
export const STAKING_TVL_KEY = "staking-tvl";
export const FARMS_TVL_KEY = "farms-tvl";
