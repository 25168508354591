import { WNATIVE } from "@rosehub-tech/sdk";
import { ethers } from "ethers";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";
import { useRouterInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { useContractRead } from "wagmi";

export default function useGetAmountsOut(
  tokenValue: string,
  address1: string,
  address2: string
) {
  const routerInterface = useRouterInterface();
  const routerAddress = useRouterAddress();
  const wroseAddress = useGetWROSE();
  const { data: outputValue, refetch } = useContractRead({
    addressOrName: routerAddress,
    contractInterface: routerInterface,
    functionName: "getAmountsOut",
    args: [
      !!tokenValue
        ? ethers.utils.parseEther(tokenValue)
        : ethers.constants.Zero,
      [
        address1 || (wroseAddress as string),
        address2 || (wroseAddress as string),
      ],
    ],
    enabled: !!wroseAddress && !!tokenValue,
  });
  return {
    outputValue: !!outputValue
      ? ethers.utils.formatEther(outputValue[1])
      : undefined,
    refetch,
  };
}

export function useGetWROSE(): string {
  const chainId = useChainId();
  const routerInterface = useRouterInterface();
  const routerAddress = useRouterAddress();
  const { data: address } = useContractRead({
    addressOrName: routerAddress,
    contractInterface: routerInterface,
    functionName: "WROSE",
  });

  return address?.toString() || WNATIVE[chainId]?.address;
}
