import styled from "styled-components";
import HeaderComponent from "../../components/HeaderPad";
import { useParams } from "react-router";
import { useGetProject } from "services/queries";
import { PROJECT_STATUS } from "constants/consts";
import { random, startCase } from "lodash";
import { T_IMAGES } from "mock";
import millify from "millify";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  background: ${({ theme }) => theme.bg};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 5rem 0;
  margin: 2rem auto 0 auto;
  max-width: min(1920px, calc(100% - 16rem));

  @media (max-width: 1024px) {
    max-width: calc(100% - 1rem);
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Title = styled.h1`
  font-family: "Nasalization";
  text-transform: uppercase;
`;

const Status: any = styled.p`
  border-radius: 5rem;
  padding: 0.25rem 0.72rem;
  font-family: "Nasalization";
  text-transform: uppercase;
  z-index: 1;
  width: fit-content;
  background-color: ${({ status, theme }: any) =>
    status.match(PROJECT_STATUS.open)
      ? theme.alert
      : status.match(PROJECT_STATUS.upcoming)
      ? theme.warning
      : status.match(PROJECT_STATUS.soldout)
      ? theme.success
      : ""};
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;

const DetailTitle = styled.h3`
  font-family: "Nasalization";
  color: ${({ theme }) => theme.primary};
`;

const Value = styled.h4`
  white-space: nowrap;
  font-family: "Nasalization";
  font-weight: 400;
  font-size: 1.5rem;
`;

const ProjectDetailsContainer = styled.div`
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

export default function Project() {
  const { pid } = useParams();
  const { data: project } = useGetProject(pid as string);

  const fundraisingGoal = !!project?.planToRaiseUSD
    ? millify(Number(project.planToRaiseUSD))
    : 0;
  const fundedAmount = !!project?.fundraisedUSD
    ? millify(Number(project.fundraisedUSD))
    : 0;
  return (
    <Wrapper>
      <HeaderComponent />
      <Container>
        {" "}
        <Row>
          <Title>{project?.projectTitle}</Title>
          <Status status={project?.entryStatus}>
            {startCase(project?.launchStatus)}
          </Status>
        </Row>
        <ImageContainer>
          <Image src={T_IMAGES[random(0, T_IMAGES.length - 1)]} />
        </ImageContainer>
        <ProjectDetailsContainer>
          <Row>
            <DetailTitle>Project Status</DetailTitle>
            <Value>{project?.projectStatus?.label}</Value>
          </Row>
          <Row>
            <DetailTitle>Date of IDO</DetailTitle>
            <Value>{project?.dateOfIDO}</Value>
          </Row>
          <Row>
            <DetailTitle>Date of TGE</DetailTitle>
            <Value>{project?.dateOfTGE || "Unavailable"}</Value>
          </Row>
          <Row>
            <DetailTitle>Fundraising Stage</DetailTitle>
            <Value>{project?.fundraisingStage?.label}</Value>
          </Row>
          <Row>
            <DetailTitle>Funded Amount</DetailTitle>
            <Value>${fundedAmount}</Value>
          </Row>
          <Row>
            <DetailTitle>Fundraising Goal</DetailTitle>
            <Value>${fundraisingGoal}</Value>
          </Row>
          <Row>
            <DetailTitle>Fund Allocation</DetailTitle>
            <Value>{project?.fundAllocation || "Unavailable"}</Value>
          </Row>
          <Row>
            <DetailTitle>KYC Status</DetailTitle>
            <Value>{project?.kycStatus?.label}</Value>
          </Row>
          <Row>
            <DetailTitle>Token Blockchain</DetailTitle>
            <Value>{project?.tokenBlockchain}</Value>
          </Row>
          <Row>
            <DetailTitle>Token Use Case</DetailTitle>
            <Value>{project?.tokenUseCase}</Value>
          </Row>
          <Row>
            <DetailTitle>Extra Services</DetailTitle>
            <Value>{project?.extraServices?.label}</Value>
          </Row>
          <Row>
            <DetailTitle>Contact Person</DetailTitle>
            <Value>{project?.name}</Value>
          </Row>
          <Row>
            <DetailTitle>Contact @</DetailTitle>
            <Value>{project?.email}</Value>
          </Row>
          <Row>
            <DetailTitle>Official Website</DetailTitle>
            <Value>{project?.websiteUrl || "Unavailable"}</Value>
          </Row>
        </ProjectDetailsContainer>
      </Container>
    </Wrapper>
  );
}
