import styled from "styled-components";

const Container = styled.div`
  background-color: black;
  width: fit-content;
  height: fit-content;
  margin: auto;
  box-shadow: inset 0 0 2rem #ff008780;
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  min-width: ${({ width }: { width: number }) =>
    !!width ? `${width}px` : "575px"};

  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
`;

export default function GlowContainer({ children, width }: any) {
  return <Container width={width}>{children}</Container>;
}
