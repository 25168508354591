import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { CgClose } from "react-icons/cg";
import useGetLockInfo, { useGetLockInfoLength } from "./hooks/useGetLockInfo";
import caretIcon from "assets/icons/caret.svg";
import { useState } from "react";
import GradientButton from "components/GradientBtn";
import GradientBtnAlt from "components/GradientBtnAlt";
import moment from "moment";
import { ethers } from "ethers";
import ConfirmEmergencyUnlockModal from "./ConfirmEmergencyUnlockModal";
import useConfirmUnlock from "./hooks/useConfirmUnlock";

export const StyledCloseIcon = styled(CgClose)`
  opacity: 0.64;

  &:hover {
    cursor: pointer;
  }
`;
export const StyledModal = styled(Modal)`
  width: 100%;

  .modal-content {
    background: linear-gradient(
      180deg,
      rgba(28, 28, 28, 0.2) 0%,
      rgba(2, 2, 2, 0.2) 100%,
      rgba(2, 2, 2, 0.2) 100%
    );
    box-shadow: inset 0px 4px 18px rgba(255, 0, 153, 0.4);
    backdrop-filter: blur(80px);
    transform-style: preserve-3d;
    min-height: 270px;

    .modal-title.h4,
    .modal-body p {
      font-family: "Nasalization";
    }

    .modal-header,
    .modal-footer {
      border-bottom: none;
      border-top: none;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
    }
  }
`;

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid rgba(243, 8, 137, 0.4);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;

  .modal-title.h4 {
    font-size: 1.15rem !important;
    color: rgb(255, 0, 137) !important;
  }
`;

const LockedRewardContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primary}29;
  padding: 1rem 1.5rem 1rem 1.5rem;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Main = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 0.25fr;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1fr);
    gap: 2rem;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.p`
  opacity: 0.56;
  font-weight: 500;
  margin-bottom: 0;
`;

const Value = styled.h3`
  white-space: nowrap;
  font-size: 1rem;
`;

const Caret = styled.button`
  display: grid;
  place-content: center;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.75rem 0.64rem;
  border-radius: 0.5rem;
  width: fit-content;
  height: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.inputBG};
  }
`;

const CaretIcon = styled.img.attrs({ src: caretIcon })`
  width: 0.7rem;
  filter: invert(1);
`;

const Actions = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  height: 0;
  opacity: 0;
  visibility: hidden;

  @media (max-width: 1024px) {
    align-self: center;
  }

  &.show-more {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  flex-direction: column;
  margin-top: 12px;
  x @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const EmergencyUnlockWarning = styled.span`
  font-size: 0.8125rem;
  opacity: 0.56;
  text-align: left;
  margin-top: 0.5rem;

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const EmptyContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
`;

export default function WithdrawalsModal({
  handleClose,
  show,
}: {
  handleClose: () => void;
  show: boolean;
}) {
  const { data: lockInfo, refetch: refetchLockInfo } = useGetLockInfo();
  const { data: lockInfoLength, refetch: refetchLockInfoLength } =
    useGetLockInfoLength();
  const [showEmergency, setShowEmergency] = useState(false);

  const handleEmergencyModal = () => {
    setShowEmergency((old) => !old);
    // refetch();
  };
  return (
    <StyledModal show={show} onHide={handleClose}>
      <StyledModalHeader>
        <Modal.Title>Pending withdrawals of earned rewards</Modal.Title>
        <StyledCloseIcon onClick={handleClose} />
      </StyledModalHeader>
      <Modal.Body>
        {lockInfoLength > 0 ? (
          Array.from(Array(lockInfoLength).keys()).map((index) => {
            const lockEndTime =
              lockInfo?.[1]?.length > 0 ? lockInfo[1][index] : undefined;
            const lockEndDate = !!lockEndTime
              ? moment.unix(lockEndTime).format("ll")
              : undefined;
            const lockedAmount =
              lockInfo?.[2]?.length > 0
                ? ethers.utils.formatEther(lockInfo[2][index])
                : "0";

            return (
              <LockedRewardItem
                key={index}
                index={index}
                lockEndDate={lockEndDate}
                lockedAmount={lockedAmount}
                refetch={() => {
                  refetchLockInfo();
                  refetchLockInfoLength();
                }}
              />
            );
          })
        ) : (
          <EmptyContainer>
            <p>You have no upcoming withdrawals at the moment.</p>
          </EmptyContainer>
        )}
        <Row>
          <GradientBtnAlt onClick={handleEmergencyModal} label="Unlock All" />
          <EmergencyUnlockWarning>
            *Unlock All will have 50% penalty deduction from each pending
            withdrawal.
          </EmergencyUnlockWarning>
        </Row>
      </Modal.Body>
      <ConfirmEmergencyUnlockModal
        show={showEmergency}
        handleClose={handleEmergencyModal}
      />
    </StyledModal>
  );
}

interface LockedRewardProps {
  lockEndDate: string;
  lockedAmount: string;
  index: number;
  refetch: () => void;
}

function LockedRewardItem({
  lockedAmount,
  lockEndDate,
  index,
  refetch,
}: LockedRewardProps) {
  const [open, setOpen] = useState(false);

  const { confirmUnlock, isLoading } = useConfirmUnlock(index, () => refetch());

  return (
    <LockedRewardContainer>
      {" "}
      <Main>
        <Item>
          <Label>Amount</Label>
          <Value>{Number(lockedAmount)?.toFixed(6)} RHUB</Value>
        </Item>
        <Item>
          <Label>Unlock Date</Label>
          <Value>{lockEndDate}</Value>
        </Item>
        <Caret onClick={() => setOpen((old) => !old)}>
          <CaretIcon />
        </Caret>
      </Main>
      <Actions className={open && "show-more"}>
        <Row>
          <GradientButton
            width={180}
            onClick={confirmUnlock}
            label="Unlock"
            disabled={moment(lockEndDate).isAfter(moment())}
            disabledText="Unlock"
            disabledWidth={180}
            loading={isLoading}
          />
        </Row>
      </Actions>
    </LockedRewardContainer>
  );
}
