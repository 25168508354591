import styled from "styled-components";

const Container = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 0.5rem;
  background-color: rgba(31, 31, 31, 0.5);
  max-height: 3rem;

  &:hover {
    background-color: rgb(45, 45, 45);
  }
`;
const Icon = styled.img`
  width: 1.2rem;
  filter: invert(1);
`;

const Label = styled.p`
  margin: 0;
  padding: 0;
`;

export default function IconBtn({ icon, label, onClick = () => null }: any) {
  return (
    <Container onClick={() => onClick()}>
      <Icon src={icon} />

      {label && <Label>{label}</Label>}
    </Container>
  );
}
