import { ethers } from "ethers";
import { useRewardLockAddress } from "hooks/contracts/useContractAddresses";
import { useRewardLockInterface } from "hooks/contracts/useInterfaces";
import { useQuery } from "@tanstack/react-query";
import { useAccount, useSigner } from "wagmi";

export default function useGetLockInfo() {
  const { address } = useAccount();
  const rewardLockAddress = useRewardLockAddress();
  const rewardLockInterface = useRewardLockInterface();
  const { data: signer } = useSigner();

  const { data, refetch } = useQuery(
    ["useGetLockInfo", rewardLockAddress, address],
    async () => {
      const rewardLockContract = new ethers.Contract(
        rewardLockAddress,
        rewardLockInterface,
        signer
      );
      const result = await rewardLockContract.getLockInfo(address);
      return result;
    },
    {
      enabled: !!signer,
    }
  );

  return {
    data,
    refetch,
  };
}

export function useGetLockInfoLength() {
  const { address } = useAccount();
  const rewardLockAddress = useRewardLockAddress();
  const rewardLockInterface = useRewardLockInterface();
  const { data: signer } = useSigner();

  const { data, refetch } = useQuery(
    ["useGetLockInfoLength", rewardLockAddress, address],
    async () => {
      const rewardLockContract = new ethers.Contract(
        rewardLockAddress,
        rewardLockInterface,
        signer
      );
      const result = await rewardLockContract.getLockInfoLength(address);
      return result;
    },
    {
      enabled: !!signer,
    }
  );

  return {
    data: data?.toNumber(),
    refetch,
  };
}
