import { launchpad, tokenAddresses } from "connectors/chains";
import useChainId from "hooks/useChainId";

export function useMultiCallAddress(): string {
  const chainId = useChainId();
  return launchpad.get(chainId)?.MULTICALL;
}

export function useRewardLockAddress(): string {
  const chainId = useChainId();
  return launchpad.get(chainId)?.REWARD_LOCK;
}

export function useRouterAddress(): string {
  const chainId = useChainId();
  return launchpad.get(chainId)?.ROUTER;
}

export function useStakeMasterAddress(): string {
  const chainId = useChainId();
  return launchpad.get(chainId)?.STAKE_MASTER;
}

export function useStakeVaultFactoryAddress(): string {
  const chainId = useChainId();
  return launchpad.get(chainId)?.STAKE_VAULT_FACTORY;
}

export function useFactoryAddress(): string {
  const chainId = useChainId();
  return launchpad.get(chainId)?.FACTORY;
}

export function useMasterChefAddress(): string {
  const chainId = useChainId();
  return launchpad.get(chainId)?.MASTERCHEFV2;
}

export function useNFTStakingAddress(): string {
  const chainId = useChainId();
  return launchpad.get(chainId)?.NFT_STAKING_POINTS;
}

export function useRoseApeTokenAddress(): string {
  const chainId = useChainId();
  return tokenAddresses.get(chainId)?.ROSE_APES_NFT;
}

export function useRHUBTokenAddress(): string {
  const chainId = useChainId();
  return tokenAddresses.get(chainId)?.RHUB;
}

export function useLPTokenAddress(): string {
  const chainId = useChainId();
  return tokenAddresses.get(chainId)?.RHUB_LP;
}

export function useUSDTTokenAddress(): string {
  const chainId = useChainId();
  return tokenAddresses.get(chainId)?.USDT;
}
