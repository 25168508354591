import { genID } from "utils/functions";
import Partner1 from "assets/images/Venture.png";
import Partner2 from "assets/images/KV_wide.png";
import Partner3 from "assets/images/RoseApes.jpg";
import Partner4 from "assets/images/Hivemind.png";
// import Partner5 from "assets/images/moonwhale.png";
import Partner6 from "assets/images/dc.png";
import Partner7 from "assets/images/cogitent.png";
import Partner8 from "assets/images/proofid.svg";
import Partner9 from "assets/images/KPC_White.png";
import Partner10 from "assets/images/Chainzeeper.jpeg";
import Partner11 from "assets/images/peckshield2.png";
export default [
  {
    link: "https://oddiyana.ventures/",
    src: Partner1,
  },
  {
    link: "https://cogitent.ventures/",
    src: Partner7,
  },
  {
    link: "https://www.youtube.com/c/KryptoPlayboy",
    src: Partner9,
  },
  {
    link: "https://digitsclub.com/",
    src: Partner6,
  },
  {
    link: "https://kventurecapital.com/",
    src: Partner2,
  },
  {
    link: "https://roseape.io",
    src: Partner3,
  },
  {
    link: "https://www.hivemind.fund/",
    src: Partner4,
  },
  {
    link: "https://chainzeeper.io/",
    src: Partner10,
  },
  {
    link: "https://t.co/LpAotnO4Ia",
    src: Partner11,
  },
  // {
  //   link: "https://moonwhale.ventures/moonwhale",
  //   src: Partner5,
  // },
  {
    link: "https://proofid.me",
    src: Partner8,
  },
  {
    link: "https://twitter.com/cogitentv",
    src: Partner6,
  },
].map((e) => ({ ...e, id: genID() }));
