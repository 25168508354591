import HeaderPad from "components/HeaderPad";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import AddLiquidity from "./AddLiquidity";
import Liquidity from "./Liquidity";

const Container = styled.div`
  height: 100%;
  max-width: min(1920px, calc(100% - 16rem));
  margin: 0 auto;

  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
    margin: 0;
    max-width: 100%;
  }
`;

export default function LiquidityContainer() {
  const [showLiquidity, setShowLiquidity] = useState(false);

  const handleShowAddLiquidity = () => setShowLiquidity((old) => !old);
  return (
    <Container>
      <HeaderPad />
      <Row>
        <Col>
          {!showLiquidity && (
            <Liquidity onShowLiquidity={handleShowAddLiquidity} />
          )}
          {showLiquidity && (
            <AddLiquidity onShowLiquidity={handleShowAddLiquidity} />
          )}
        </Col>
      </Row>
    </Container>
  );
}
