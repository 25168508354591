import { Controller } from "react-hook-form";
import FormInput from "../FormInput/FormInput";
import FormSelect from "../FormSelect";
import { StepProps } from "../Fundraise";
import { extraServices } from "../schema";
import { StepContainer } from "./Step1";

export default function Step4({ control }: StepProps) {
  return (
    <StepContainer>
      <Controller
        name="mvpUrl"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="Do you have MVP? Provide URL. "
            placeholder="https://app.rosehub.io"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="listingName"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="Planned CEX/DEX listings, if any"
            placeholder="Binance, PancakeSwap"
            {...rest}
          />
        )}
      />
      <Controller
        name="teamProfileUrl"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="Team Profile"
            placeholder="https://linkedin.com/in/rosehub"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="extraServices"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormSelect
            options={extraServices}
            label="Extra Services"
            placeholder="Advisory, Marketing, Audit"
            {...rest}
            {...fieldState}
          />
        )}
      />
    </StepContainer>
  );
}
