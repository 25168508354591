import { Currency } from "@rosehub-tech/sdk";
import { CgClose } from "react-icons/cg";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/ButtonGroup";
import Slider from "rc-slider";

import GradientBtnAlt from "components/GradientBtnAlt";
import GradientButton from "components/GradientBtn";
import { useMemo, useState } from "react";
import useConfirmRemoveLiquidity from "./hooks/useConfirmRemoveLiquidity";
import usePairAllowance, {
  useApprovePairAllowance,
} from "./hooks/usePairAllowance";
import { useStore } from "store/zustand";
import "rc-slider/assets/index.css";

export const StyledCloseIcon = styled(CgClose)`
  opacity: 0.64;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledModal = styled(Modal)`
  width: 100%;

  .modal-content {
    background: linear-gradient(
      180deg,
      rgba(28, 28, 28, 0.2) 0%,
      rgba(2, 2, 2, 0.2) 100%,
      rgba(2, 2, 2, 0.2) 100%
    );
    box-shadow: inset 0px 4px 18px rgba(255, 0, 153, 0.4);
    backdrop-filter: blur(80px);
    transform-style: preserve-3d;
    min-height: 350px;

    .modal-title.h4,
    .modal-body p {
      font-family: "Nasalization";
    }

    .modal-header,
    .modal-footer {
      border-bottom: none;
      border-top: none;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
    }
  }
`;

export const StyledModalHeader = styled(Modal.Header)`
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
  border-bottom: 1px solid rgba(243, 8, 137, 0.224) !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  h4 {
    font-size: 1.15rem !important;
    color: rgb(255, 0, 137) !important;
    font-weight: bold;
    margin-bottom: 4px;
  }

  p {
    margin-bottom: 0;
    opacity: 0.64;
  }
`;

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 1.7rem;

  button {
    width: 50%;
  }
`;

const ContentTitle = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 24px;
  margin-left: 1rem;
`;

const Content = styled.div`
  padding: 1.7rem;
  border: 1px solid rgba(243, 8, 137, 0.224);
  border-radius: 8px;
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    color: rgb(255, 0, 137) !important;
    font-weight: 700;
  }
`;

const PercentButtonGroup = styled(ButtonGroup)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const PercentButton = styled(Button)`
  color: #fff;
  background-color: rgb(53, 53, 71);
  padding: 0 1rem;
  height: 32px;
  border-radius: 1rem;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: rgba(53, 53, 71, 0.4);
  }
`;

const TokenReceiveItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Label = styled.p`
  opacity: 0.56;
  font-weight: 500;
  margin-bottom: 0;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Value = styled.p`
  color: #fff;
  margin-bottom: 0;
  font-weight: 500;
`;

const TokenImage = styled.img`
  border-radius: 100px;
`;

const FooterItem = styled.div`
  display: flex;
  items: center;
  margin-top: 1rem;
  padding: 0 1rem;
  width: 100%;
  justify-content: space-between;
`;

interface RemoveLiquidityModalProps {
  images: {
    token0: string;
    token1: string;
  };
  currencies: {
    token0: Currency;
    token1: Currency;
  };
  pair: any;
  poolTokenPercentage: any;
  token0Deposited: any;
  token1Deposited: any;
  userPoolBalance: any;
  show: boolean;
  handleClose: () => void;
}

export default function RemoveLiquidityModal({
  token0Deposited,
  token1Deposited,
  show,
  currencies,
  handleClose,
  images,
  userPoolBalance,
  pair,
}: RemoveLiquidityModalProps) {
  const [removePercentage, setRemovePercentage] = useState(0);
  const symbol0 = currencies.token0.symbol;
  const symbol1 = currencies.token1.symbol;
  const pairSymbol = `${symbol0}-${symbol1}`;
  const [slippage] = useStore((state) => [state.slippage]);
  const parsedSlippage = Number(slippage);
  const decreaseFromSlippage =
    parsedSlippage === 50
      ? 0.995
      : parsedSlippage === 10
      ? 0.999
      : parsedSlippage === 100
      ? 0.99
      : 1 - parsedSlippage / 100 / 100;

  const token0Amount = useMemo(() => {
    return (
      token0Deposited?.toSignificant(5) *
      (removePercentage / 100) *
      decreaseFromSlippage
    );
  }, [token0Deposited, removePercentage, decreaseFromSlippage]);

  const token1Amount = useMemo(() => {
    return (
      token1Deposited?.toSignificant(5) *
      (removePercentage / 100) *
      decreaseFromSlippage
    );
  }, [token1Deposited, removePercentage, decreaseFromSlippage]);

  const poolBalanceAmount = useMemo(() => {
    if (removePercentage === 100) {
      return userPoolBalance?.toSignificant(18);
    }
    return userPoolBalance?.toSignificant(5) * (removePercentage / 100);
  }, [removePercentage, userPoolBalance]);

  const allowance = usePairAllowance(pair.liquidityToken.address);

  const { write: approveAllowance, isLoading: isApprovingAllowance } =
    useApprovePairAllowance(pair.liquidityToken.address, allowance?.refetch);

  const { isLoading, onRemoveLiquidity } = useConfirmRemoveLiquidity(
    currencies?.token0?.wrapped,
    currencies?.token1?.wrapped,
    token0Amount,
    token1Amount,
    poolBalanceAmount
  );

  const handleConfirm = () => {
    onRemoveLiquidity(() => {
      handleClose();
    });
  };

  const handleApprove = () => {
    approveAllowance();
  };

  return (
    <StyledModal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <StyledModalHeader>
        <Modal.Title>
          <div>
            <h4>{`Remove ${pairSymbol} Liquidity`}</h4>
            <p>{`To receive ${symbol0} and ${symbol1}`}</p>
          </div>
        </Modal.Title>
        <StyledCloseIcon onClick={handleClose} />
      </StyledModalHeader>
      <Modal.Body>
        <div>
          <ContentTitle>Amount</ContentTitle>
          <Content style={{ marginBottom: 16 }}>
            <div>
              <h2>{removePercentage}%</h2>
              <Slider
                value={removePercentage}
                onChange={(value) => setRemovePercentage(value as number)}
                railStyle={{ backgroundColor: "rgb(53, 53, 71)" }}
                trackStyle={{ backgroundColor: "rgb(255, 0, 137)" }}
              />
            </div>
            <div>
              <PercentButtonGroup aria-label="remove-percentage-slider">
                <PercentButton onClick={() => setRemovePercentage(25)}>
                  25%
                </PercentButton>
                <PercentButton onClick={() => setRemovePercentage(50)}>
                  50%
                </PercentButton>
                <PercentButton onClick={() => setRemovePercentage(75)}>
                  75%
                </PercentButton>
                <PercentButton onClick={() => setRemovePercentage(100)}>
                  Max
                </PercentButton>
              </PercentButtonGroup>
            </div>
          </Content>
        </div>
        <ContentTitle>Receive</ContentTitle>
        <Content>
          <TokenReceiveItem>
            <Label>
              <TokenImage src={images.token0} alt="token0" width={24} />
              {symbol0}
            </Label>
            <Value>{token0Amount.toFixed(6)}</Value>
          </TokenReceiveItem>
          <TokenReceiveItem>
            <Label>
              {" "}
              <TokenImage src={images.token1} alt="token1" width={24} />
              {symbol1}
            </Label>
            <Value>{token1Amount.toFixed(6)}</Value>
          </TokenReceiveItem>
        </Content>
        <FooterItem>
          <Label>Pool Balance</Label>
          <Value>{userPoolBalance?.toSignificant(5)}</Value>
        </FooterItem>
        <FooterItem>
          <Label>Slippage Tolerance</Label>
          <Value>{slippage / 100}%</Value>
        </FooterItem>

        <ModalActions>
          <GradientBtnAlt label="Cancel" onClick={handleClose} />
          {!!allowance?.amount && Number(allowance.amount) > 0 ? (
            <GradientButton
              label="Remove"
              onClick={handleConfirm}
              loading={isLoading}
              disabled={removePercentage === 0}
              disabledText={"Remove"}
            />
          ) : (
            <GradientButton
              label="Approve RHUB-LP"
              onClick={handleApprove}
              loading={isApprovingAllowance}
              disabled={removePercentage === 0}
              disabledText={"Approve RHUB-LP"}
            />
          )}
        </ModalActions>
      </Modal.Body>
    </StyledModal>
  );
}
