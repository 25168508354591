import { ethers } from "ethers";
import { useStakeVaultInterface } from "hooks/contracts/useInterfaces";
import { useAccount, useProvider } from "wagmi";
// import useGetVaultAddress from "./useGetVaults";
import { useQuery } from "@tanstack/react-query";
import dayjs from "utils/dayjs";

export default function useGetVaultInfo(index: number, vaultAddress: string) {
  const provider = useProvider();
  // const { vaultAddress } = useGetVaultAddress(index);
  const vaultInterface = useStakeVaultInterface();
  const { address: account } = useAccount();

  const { data: vaultInfo, refetch } = useQuery(
    ["vaultInfo", index, vaultAddress, account],
    async () => {
      try {
        const contract = new ethers.Contract(
          vaultAddress,
          vaultInterface,
          provider
        );
        const infos = await Promise.all([
          contract.userInfo(account),
          contract.apy(),
          contract.lockDuration(),
          contract.pendingRewards(account),
          contract.cap(),
        ]);
        return infos;
      } catch (error: any) {
        console.log("error", error);
      }
    },
    {
      enabled: !!vaultAddress,
      refetchInterval: 5000,
    }
  );

  if (!!vaultInfo) {
    const userInfo = vaultInfo[0];
    const apyRewards = vaultInfo[1];
    const lockDuration = vaultInfo[2];
    const pendingRewards = vaultInfo[3];
    const cap = vaultInfo[4];
    const startDateInUnix: number = userInfo?.startTime?.toNumber();
    const endDateInUnix: number = userInfo?.endTime?.toNumber();
    const apy = apyRewards?.toNumber();
    const duration = lockDuration?.toNumber() / 60 / 60 / 24 / 30;
    const reward = !!pendingRewards
      ? ethers.utils.formatEther(pendingRewards)
      : 0;
    const maxCap = !!cap ? ethers.utils.formatEther(cap) : 0;
    return {
      endDate:
        endDateInUnix > 0
          ? dayjs(new Date(endDateInUnix * 1000)).format("LLL")
          : undefined,
      startDate:
        startDateInUnix > 0
          ? dayjs(new Date(startDateInUnix * 1000)).format("LLL")
          : undefined,
      hasFinished:
        endDateInUnix > 0 && startDateInUnix > 0
          ? dayjs().isAfter(dayjs(new Date(endDateInUnix * 1000)))
          : false,
      apy,
      duration,
      rewardAmount: reward,
      maxLockAmount: maxCap,
      refetch,
    };
  }

  return undefined;
}
