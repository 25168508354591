import glassIcon from "assets/icons/glass.svg";
import Input from "components/Input";
import { PROJECT_CATEGORIES } from "constants/consts";
import { startCase } from "lodash";
import { T_PROJECTS } from "mock";
import styled from "styled-components";
import ProjectCard, { Project } from "../../components/ProjectCard";
import HeaderComponent from "../../components/HeaderPad";
import { useState } from "react";
import { getRpadFormSubmissionsAxios } from "../../services/api";
import { useGetProjects } from "services/queries";
import { useAccount } from "wagmi";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  background: ${({ theme }) => theme.bg};
  padding-bottom: 5rem;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: min(1920px, calc(100% - 16rem));

  @media (max-width: 1024px) {
    max-width: calc(100% - 1rem);
  }
`;
const Columns = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  column-gap: 2rem;
  row-gap: 5rem;
`;

const Column = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Title = styled.h2`
  font-family: "Nasalization";
  color: ${({ theme }) => theme.primary};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5rem;
`;

const More = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const ColumnTitle = styled.h3`
  font-family: "Nasalization";
  height: min-content;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

const ColumnItems = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-rows: 20rem;
  gap: 2rem;
  grid-auto-flow: dense;
`;

const EmptyMsg = styled.p`
  opacity: 0.56;
`;

export default function Projects() {
  const { address } = useAccount();
  const { data: projects } = useGetProjects(address as string);
  const liveProjects = projects?.live || [];
  const upcomingProjects = projects?.upcoming || [];
  const endedProjects = projects?.ended || [];

  return (
    <Wrapper>
      <Container>
        <HeaderComponent />
        <Header>
          <Title>Projects</Title>
          <More>
            <Input icon={glassIcon} placeholder="Search Projects" />
          </More>
        </Header>
        <Columns>
          <Column key={"Live"}>
            <ColumnTitle>Live</ColumnTitle>
            {liveProjects?.length === 0
              ? liveProjects.map(() => (
                  <EmptyMsg>Sorry. No {startCase("Live")} Projects</EmptyMsg>
                ))
              : null}
            <ColumnItems>
              {liveProjects?.length > 0
                ? liveProjects.map((project) => (
                    <ProjectCard
                      project={project as Project}
                      key={project.id}
                    />
                  ))
                : null}
            </ColumnItems>
          </Column>
          <Column key={"Upcoming"}>
            <ColumnTitle>Upcoming</ColumnTitle>
            {upcomingProjects?.length === 0
              ? upcomingProjects.map(() => (
                  <EmptyMsg>
                    Sorry. No {startCase("Upcoming")} Projects
                  </EmptyMsg>
                ))
              : null}
            <ColumnItems>
              {upcomingProjects?.length > 0
                ? upcomingProjects.map((project) => (
                    <ProjectCard
                      project={project as Project}
                      key={project.id}
                    />
                  ))
                : null}
            </ColumnItems>
          </Column>
          <Column key={"Finished"}>
            <ColumnTitle>Finished</ColumnTitle>
            {endedProjects?.length === 0
              ? endedProjects.map(() => (
                  <EmptyMsg>
                    Sorry. No {startCase("Finished")} Projects
                  </EmptyMsg>
                ))
              : null}
            <ColumnItems>
              {endedProjects?.length > 0
                ? endedProjects.map((project) => (
                    <ProjectCard
                      project={project as Project}
                      key={project.data.name}
                    />
                  ))
                : null}
            </ColumnItems>
          </Column>
        </Columns>
      </Container>
    </Wrapper>
  );
}
