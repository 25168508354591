import { genID } from "utils/functions";

export default [
  {
    title: "What is Oasis Network?",
    description:
      "The Oasis Network is the first scalable PoS layer 1 privacy-enabled blockchain. ParaTimes on the Oasis Network can leverage confidential computing. The Oasis Network designed the first ever confidential ParaTime with support for confidential smart contracts. Oasis main layer is consensus layer, which is scalable and secured with proof-of-stake consensus run by a decentralized set of validator nodes. What separates Oasis is that it has ParaTime layers that can be developed in isolation to meet needs of each application. Currently there is Emerald ParaTime which has EVM enabled. Cipher focusing on privacy features will come out later this year with smart contracts.",
  },
  {
    title: "What are some projects building on Oasis?",
    description:
      "Oasis has over 200 projects under development as seen by accelerator/grant applications. A lot of projects on Oasis will leverage privacy enabled technology e.g. taking control of your data and zero-knowledge proofs. Other examples include online gambling, fintech and health data handling, data marketplace and data tokenization, proof-ID, LP IL solutions, privacy-enabled DEX and algo stablecoins.",
  },
  {
    title: "What is RoseHub?",
    description:
      "🌹RoseHub is a first premium Launchpad-DEX-NFT platform on Oasis Network. It is a go-to platform for fundraising, trading, and connecting to Oasis community. \nRoseHub will provide a platform to launch hand-picked projects on Oasis Network that aims to bring real impact and value to the communities while contributing to adoption of Oasis blockchain.\n For project owners, we will connect you to communities within Oasis ecosystem, provide access to capital and trading platform as well as advice with marketing and development.",
  },
  {
    title: "Are you multichain platform? ",
    description:
      "Currently we focus on bringing mass adoption to Oasis Network. However, integrating other EVM-compatible networks is possible.",
  },
  {
    title: "What is the difference between RoseHub and other launchpads? ",
    description:
      "Launchpads is a competitive landscape. We address this by choosing the chain that has one of the highest VC backing, highest growth potential and unique tech and trending market positioning (privacy tech). As a launchpad, we not only will have a launchpad but also a fully operational and liquid DEX with an ambitious roadmap. Lastly, we will have unique platform tiers that include NFTs as well as variables that give the most value proposition to token holders (e.g., time-based with LP rewards).",
  },
  {
    title: "What are RoseHub partners? ",
    description:
      "Our list of partners grows every week. Currently we have over a few communities and more than 10 established VCs as partners. To keep up to date, please visit our website at RoseHub.io or read medium articles at rosehub.medium.com.",
  },
  {
    title: "Where is your team from? ",
    description:
      "Our team is from all over the world! Core members come from Canada, Europe, and Asia. To read more about the core team visit our website at rosehub.io. We have multiple non-core team members as well that are working for us full or part-time.",
  },
  {
    title: "What are the official channels for RoseHub? ",
    description:
      "👨Telegram Group: https://t.me/RoseHubMedia ; 👨‍✈️ Telegram Channel: https://t.me/RoseHubAnn ; 💬 Twitter RoseHub: https://twitter.com/RoseHubMedia ; 💬 Twitter NFTs: https://twitter.com/rose_apes ; 📝 Medium: https://rosehub.medium.com/ ; 🎧 Discord: https://discord.gg/5D4kNTXDCe",
  },
  {
    title: "What time zone do you use? ",
    description: "We always use UTC timezone.",
  },
  {
    title: "What are the launchpad tiers? ",
    description:
      "Tiers are as below. The higher the Pool Weight (W) the higher is the allocation to IDOs. Read more here https://rosehub.medium.com/rosepad-launchpad-tiers-ccd56a83e148 Gibbon Tier 1–200 xRHUB with 2W\n Bonobo Tier 2–2 000 xRHUB with 9W\n Chimpanzee Tier 3–10 000 xRHUB with 30W\n Gorilla Tier 4–20 000 xRHUB with 100W\n Orangutan Tier 5–100 000 xRHUB with 350W",
  },
  {
    title: "How do I obtain tiers? ",
    description:
      "To obtain xRHUB required for tiers you need to either stake and lock RHUB or RHUB-LP token (liquidity provider). In addition, RoseApe NFTs will boost xRHUB. The system to obtain xRHUB contain 4 variables: RHUB staking, RHUB-LP staking, The length of time you lock the above, locking RoseApe NFTs",
  },
  {
    title: "How do I get platform IDO allocation? ",
    description:
      "To get guaranteed allocation to IDOs on our platform you need to hold one of our five tiers. The higher the tier the higher the allocation given based on pool weight you hold. Read more here: https://rosehub.medium.com/rosehub-launchpad-tiers-ccd56a83e148",
  },
  {
    title: "How do I get whitelisted for IDO? ",
    description:
      "Once you have obtained a tier and the IDO project is live, you will need to click “Whitelist” on the project specific page to whitelist your connected wallet and be eligible to swap to the IDO token later. Whitelisting is not automatic.",
  },
  {
    title: "How do I swap for tokens? ",
    description:
      "Once you have obtained the tier and are whitelisted you will be notified of your maximum allocation in token amount of that IDO. Once sale is live, you will be able to swap from USDT, ROSE or RHUB (depending on project) via our platform.",
  },
  {
    title: "Where can I find official articles from RoseHub? ",
    description:
      "All our official articles are published on medium here https://rosehub.medium.com/. We will also post any new articles on social media as they come out.",
  },
  {
    title: "What does vesting mean? ",
    description:
      "The vesting period, also called the token lockup period, refers to a period in which the tokens sold in the IDO stage are prevented from being sold for a specific period. After that period post-TGE is complete, certain amounts are unlocked and are tradable.",
  },
  {
    title: "Where can I find official articles from RoseHub? ",
    description:
      "All our official articles are published on medium here https://rosehub.medium.com/. We will also post any new articles on social media as they come out.",
  },
  {
    title: "Where do I claim IDO tokens? ",
    description:
      "You can claim our token via a claiming portal on our platform, built exclusively for this purpose.",
  },
  {
    title: "Where can I buy RPRHUBAD? ",
    description:
      "Vesting tracking is provided by our partner “DigitsClub” at https://trackdigits.com/. Please read more about partnership at https://rosehub.medium.com/rosepad-digits-club-partnership-95203443bebc",
  },
  {
    title: "Where can I buy RHUB? ",
    description:
      "First IDO on our audited platform will be RHUB. Whitelisted users will be able to swap. Whitelisting campaign to be announced. Follow our socials to stay on top of it. RoseApe NFT holders will have an automatic whitelist spot.",
  },
  {
    title: "When is your IDO/TGE? ",
    description: "End of Q2 2022.",
  },
  {
    title:
      "Will there be a lock period for those taking part in the RoseHub IDO? ",
    description: "Yes, vesting will be the same for everyone. Details TBA.",
  },
  {
    title: "What is the total supply of RHUB? ",
    description:
      "Total supply is 100MM RHUB out of which 35% is allocated to platform staking rewards.",
  },
  {
    title: "What is circulating supply? ",
    description:
      "Circulating supply at TGE is 1,130,000 RHUB or 1.13% of total supply.",
  },
].map((e) => ({ ...e, id: genID() }));
