import { Controller } from "react-hook-form";
import styled from "styled-components";
import FormInput from "../FormInput/FormInput";
import FormSelect from "../FormSelect";
import { StepProps } from "../Fundraise";
import { fundraisingStages } from "../schema";
import { StepContainer } from "./Step1";

export const StepContainerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;

export default function Step2({ control }: StepProps) {
  return (
    <StepContainer>
      <Controller
        name="dateOfIDO"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="date"
            label="Date of IDO"
            placeholder="December 10, 2022"
            {...rest}
            {...fieldState}
          />
        )}
      />

      <Controller
        name="dateOfTGE"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="date"
            label="Date of TGE"
            placeholder="December 10, 2022"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="fundraisingStage"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormSelect
            options={fundraisingStages}
            label="Stage of Fundraising"
            placeholder="Select the stage of your project"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="fundraisedUSD"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="How much funds you have raised in USD?"
            placeholder="100, 000 USD"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="planToRaiseUSD"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="How much are you planning to raise in USD?"
            placeholder="100, 000 USD"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="fundAllocation"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="What is your fund allocation e.g. marketing 
        or development?"
            placeholder="Marketing"
            {...rest}
            {...fieldState}
          />
        )}
      />
    </StepContainer>
  );
}
