import useChainId from "hooks/useChainId";
import { CurrencyAmount, JSBI, Token } from "@rosehub-tech/sdk";
import { useEffect, useMemo } from "react";
import { isAddress } from "utils/isAddress";
import { erc20ABI, useAccount, useContractReads } from "wagmi";
import { useStore } from "store/zustand";
/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }, boolean] {
  const chainId = useChainId();
  const swapRecentTx = useStore((state) => state.recentSwapTx);
  const validatedTokens: Token[] = useMemo(
    () =>
      tokens?.filter(
        (t?: Token): t is Token => isAddress(t?.address) !== false
      ) ?? [],
    [tokens]
  );

  const validatedTokenAddresses = useMemo(
    () => validatedTokens.map((vt) => vt.address),
    [validatedTokens]
  );

  const {
    data: balances,
    isLoading,
    refetch,
  } = useContractReads({
    contracts: [
      ...validatedTokenAddresses.map((token) => ({
        addressOrName: token,
        contractInterface: erc20ABI,
        functionName: "balanceOf",
        args: [address],
        chainId,
        staleTime: 5_000,
        cacheTime: 60_000,
      })),
    ],
  });

  useEffect(() => {
    if (!!swapRecentTx) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapRecentTx]);

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{
              [tokenAddress: string]: CurrencyAmount<Token> | undefined;
            }>((memo, token, i) => {
              const value = balances?.[i];
              const amount = value ? JSBI.BigInt(value.toString()) : undefined;
              if (amount) {
                memo[token.address] = CurrencyAmount.fromRawAmount(
                  token,
                  amount
                );
              }
              return memo;
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    isLoading,
  ];
}

// get the balance for a single token/account combo
export function useTokenBalance(
  account?: string,
  token?: Token
): CurrencyAmount<Token> | undefined {
  const tokenBalances = useTokenBalances(account, [token]);
  if (!token) return undefined;
  return tokenBalances[token.address];
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: CurrencyAmount<Token> | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0];
}
