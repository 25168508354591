import styled from "styled-components";
import Header from "../../components/Header";
import HeroStats from "./HeroStats";
import GradientButton, { GradientBtnDark } from "components/GradientBtn";
import { useNavigate } from "react-router";

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
  padding: 0 0 7rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: rgb(178, 26, 240);
  background: radial-gradient(ellipse at top center, #f0931e, transparent 30%),
    radial-gradient(circle at bottom left, #ff3c60, transparent 45%),
    radial-gradient(circle at bottom right, #fe008c, transparent 50%),
    radial-gradient(ellipse at bottom center, #fe008c, transparent 60%),
    linear-gradient(
      324deg,
      rgba(178, 26, 240, 1) 0%,
      rgba(202, 62, 184, 1) 21%,
      rgba(246, 122, 23, 1) 100%
    );

  @media (max-width: 1024px) {
    height: auto;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: min(1920px, calc(100% - 16rem));
  margin: auto;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  padding-top: 5rem;

  @media (max-width: 1024px) {
    padding-top: 2rem;
    grid-template-columns: 100%;
    max-width: calc(100% - 2rem);
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  @media (max-width: 1024px) {
    text-align: center;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-family: "Nasalization";
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 2em;
`;

const Description = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.8em;
  margin-bottom: 1rem;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header />
      <Content>
        <Main>
          <Title style={{ fontSize: "32px" }}>
            Fundraising and Trading <br /> Platform on Oasis
          </Title>
          <Description>
            All-in-one DeFi platform with aggregated liquidity.
            <br /> Launchpad with NFTs and innovative tier system.
            <br /> Fundraising supported by industry-leading creators and funds.
          </Description>
          <Options>
            <GradientButton
              onClick={() => navigate("/exchange")}
              label="Trade"
            />
            <GradientBtnDark
              onClick={() =>
                // window.open("https://forms.gle/sVC9aDUqqtPoSFff7", "_blank")
                navigate("/fundraise")
              }
              label="Fundraise"
            />
          </Options>
        </Main>
        <HeroStats />
      </Content>
    </Container>
  );
};

export default Hero;
