import { ChainId, Token } from "@rosehub-tech/sdk";

// a list of tokens by chain
export type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

export type TranslatableText =
  | string
  | {
      key: string;
      data?: {
        [key: string]: string | number;
      };
    };
export interface Address {
  42262: string;
  42261?: string;
}

export interface SerializedToken {
  chainId: number;
  address: string;
  decimals: number;
  symbol?: string;
  name?: string;
  projectLink?: string;
  logoURI?: string;
}

export enum PoolIds {
  poolBasic = "poolBasic",
  poolUnlimited = "poolUnlimited",
}

export type IfoStatus = "idle" | "coming_soon" | "live" | "finished";

interface IfoPoolInfo {
  saleAmount: string;
  raiseAmount: string;
  cakeToBurn: string;
  distributionRatio: number; // Range [0-1]
}

export interface Ifo {
  id: string;
  isActive: boolean;
  address: string;
  name: string;
  currency: Token;
  token: Token;
  releaseBlockNumber: number;
  articleUrl: string;
  campaignId: string;
  tokenOfferingPrice: number;
  description?: string;
  twitterUrl?: string;
  telegramUrl?: string;
  version: number;
  vestingTitle?: string;
  [PoolIds.poolBasic]?: IfoPoolInfo;
  [PoolIds.poolUnlimited]: IfoPoolInfo;
}

export enum VaultCategory {
  "COMMUNITY" = "Community",
  "CORE" = "Core",
  "ROSEHUB" = "RoseHub", // Pools using native ROSE behave differently than pools using a token
  "AUTO" = "Auto",
}

interface FarmConfigBaseProps {
  pid: number;
  lpSymbol: string;
  lpAddresses: Address;
  multiplier?: string;
  isCommunity?: boolean;
  auctionHostingStartSeconds?: number;
  auctionHostingEndDate?: string;
  dual?: {
    rewardPerBlock: number;
    earnLabel: string;
    endBlock: number;
  };
}

export interface SerializedFarmConfig extends FarmConfigBaseProps {
  token: SerializedToken;
  quoteToken: SerializedToken;
}

export interface DeserializedFarmConfig extends FarmConfigBaseProps {
  token: Token;
  quoteToken: Token;
}

interface VaultsConfigBaseProps {
  vaultId: number;
  name?: string;
  contractAddress: Address;
  vaultCategory: VaultCategory;
  tokenPerBlock: string;
  isFinished?: boolean;
  enableEmergencyWithdraw?: boolean;
  version?: number;
  apy?: number;
  maxLockValue?: number;
  duration: number;
}

export interface SerializedVaultsConfig extends VaultsConfigBaseProps {
  earningToken: SerializedToken;
  stakingToken: SerializedToken;
}

export interface DeserializedPoolConfig extends VaultsConfigBaseProps {
  earningToken: Token;
  stakingToken: Token;
}

// Note: this status is slightly different compared to 'status' config
// from Farm Auction smart contract
export enum AuctionStatus {
  ToBeAnnounced, // No specific dates/blocks to display
  Pending, // Auction is scheduled but not live yet (i.e. waiting for startBlock)
  Open, // Auction is open for bids
  Finished, // Auction end block is reached, bidding is not possible
  Closed, // Auction was closed in smart contract
}

export enum Field {
  CURRENCY_A = "CURRENCY_A",
  CURRENCY_B = "CURRENCY_B",
}

export enum SwapField {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}
