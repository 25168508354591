import { ChainId } from "@rosehub-tech/sdk";
import { BIG_ZERO } from "config/exchange";
import { USDT } from "constants/tokens";
import { ethers } from "ethers";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";
import { erc20ABI, useAccount, useContractRead } from "wagmi";
import { useGetWROSE } from "./useGetAmountsOut";

export default function useSwapAllowance(tokenAddress: string) {
  const { address } = useAccount();
  const wroseAddress = useGetWROSE();
  const routerAddress = useRouterAddress();
  const {
    data: tokenAllowance,
    isError,
    isLoading,
    refetch,
  } = useContractRead({
    addressOrName: tokenAddress || wroseAddress,
    contractInterface: erc20ABI,
    functionName: "allowance",
    args: [address, routerAddress],
    enabled: !!wroseAddress,
  });

  if (isError || isLoading || !tokenAllowance) return undefined;

  const isTokenUSDT = tokenAddress === USDT[ChainId.EMERALD_MAINNET]?.address;
  const decimals = isTokenUSDT ? 6 : 18;
  const tokenAllowanceAmount = !!tokenAllowance
    ? ethers.utils.formatUnits(tokenAllowance, decimals)
    : undefined;

  return {
    amount:
      !!tokenAllowance && Number(tokenAllowanceAmount) > 0
        ? tokenAllowanceAmount
        : BIG_ZERO,
    refetchSwapAllowance: refetch,
  };
}
