import { blockExplorers } from "connectors/chains";
import { useStakeVaultInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { useStore } from "store/zustand";
import {
  useContractWrite,
  usePrepareContractWrite,
  useSigner,
  useWaitForTransaction,
} from "wagmi";
import useGetVaultAddress, { useGetTotalStakedAmount } from "./useGetVaults";

export default function useConfirmWidthraw(
  index: number,
  enabled: boolean,
  onClear: () => void
) {
  const { vaultAddress } = useGetVaultAddress(index);
  const vaultInterface = useStakeVaultInterface();
  const { data: signer } = useSigner();
  const chainId = useChainId();
  const updateToast = useStore((state) => state.updateToast);
  const ts = useGetTotalStakedAmount(index, vaultAddress);

  const { config } = usePrepareContractWrite({
    addressOrName: vaultAddress,
    contractInterface: vaultInterface,
    functionName: "withdraw",
    enabled: enabled && !!signer,
  });

  const { write, data, isLoading: isDepositing } = useContractWrite(config);

  const { isError, isLoading: isWaitingForTransaction } = useWaitForTransaction(
    {
      confirmations: 2,
      hash: data?.hash,
      enabled: !!data?.hash,
      onSuccess: () => {
        const successMessage = `Successfully widthrawn your staked RHUB`;
        const txUrl = `${blockExplorers.get(chainId)}/tx/${data.hash}`;
        updateToast({
          message: successMessage,
          url: txUrl,
          type: "success",
        });
        onClear();
        ts?.refetch();
      },
      onError: (error: any) => {
        console.log("error", error?.data?.message || error?.toString());
        updateToast({
          message: `Transaction Failed ${error.message || error?.toString()}`,
          url: "",
          type: "error",
        });
      },
    }
  );

  return {
    onConfirmWidthraw: write,
    isLoading: isWaitingForTransaction || isDepositing,
    isError,
  };
}
