import gearIcon from "assets/icons/gear.svg";
import GlowContainer from "components/GlowContainer";
import GradientBtn from "components/GradientBtn";
import GradientBtnAlt from "components/GradientBtnAlt";
import IconBtn from "components/IconBtn";
import SlippageSettings from "components/SlippageSettings";
import useTokens from "hooks/useTokens";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAccount } from "wagmi";
import { usePairs, PairState } from "./hooks/usePairs";
import { useTokenBalancesWithLoadingIndicator } from "./hooks/useTokenBalances";
import {
  toLiquidityToken,
  useTrackedTokenPairs,
} from "./hooks/useTrackedTokenPairs";
import {
  Ask,
  Body,
  Container,
  Header,
  Heading,
  Options,
  Prompt,
  Sub,
  Title,
} from "./styles/LiquidityStyles";
import { ListItem } from "./UserLiquidityPools";

interface LiquidityProps {
  onShowLiquidity: Function;
}

export default function Liquidity({ onShowLiquidity }: LiquidityProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const token0Symbol = new URLSearchParams(location.search).get("token0");
  const token1Symbol = new URLSearchParams(location.search).get("token1");
  const { address } = useAccount();
  const [showSlippage, setShowSlippage] = useState(false);

  useEffect(() => {
    if (!!token0Symbol && !!token1Symbol) {
      onShowLiquidity();
    }
  }, [onShowLiquidity, token0Symbol, token1Symbol]);

  const handleSlippageModal = () => {
    setShowSlippage((old) => !old);
  };

  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({
        liquidityToken: toLiquidityToken(tokens),
        tokens,
      })),
    [trackedTokenPairs]
  );

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );
  const [pairBalances, fetchingPairBalances] =
    //@ts-ignore
    useTokenBalancesWithLoadingIndicator(address ?? undefined, liquidityTokens);

  // fetch the reserves for all pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        pairBalances[liquidityToken.address]?.greaterThan("0")
      ),
    [tokenPairsWithLiquidityTokens, pairBalances]
  );

  const liquidityPairs = usePairs(
    liquidityTokensWithBalances.map(({ tokens }) => tokens)
  );
  const allPairsWithLiquidity = liquidityPairs
    ?.filter(
      ([pairState, pair]) => pairState === PairState.EXISTS && Boolean(pair)
    )
    .map(([, pair]) => pair);

  const renderBody = () => {
    if (!address) {
      return <Ask>Connect to a wallet to view your liquidity.</Ask>;
    }
    if (fetchingPairBalances) {
      return <Ask>Loading</Ask>;
    }
    if (!!allPairsWithLiquidity && allPairsWithLiquidity?.length > 0) {
      return allPairsWithLiquidity.map((pair, index) => (
        <ListItem key={index} pair={pair} onShowLiquidity={onShowLiquidity} />
      ));
    }
    return <Ask>No liquidity found.</Ask>;
  };

  const tokens = useTokens();
  return (
    <Container>
      <GlowContainer width={575}>
        <Header>
          <Heading>
            <Title>Your Liquidity</Title>
            <Sub>Add liquidity to receive LP tokens</Sub>
          </Heading>
          <Options>
            <IconBtn icon={gearIcon} onClick={handleSlippageModal} />
          </Options>
        </Header>
        <Body>
          {renderBody()}
          <Prompt>
            <Ask>Don't see a pool you joined?</Ask>
            <GradientBtnAlt
              label="Find other LP tokens"
              onClick={() => navigate("/farms")}
            />
          </Prompt>
          <GradientBtn
            animateOnHover
            onClick={() => {
              onShowLiquidity();
              navigate({
                search: `?token0=${tokens?.rhub?.symbol}&token1=${tokens?.usdt?.symbol}`,
              });
            }}
            label="Add Liquidity"
          />
        </Body>
      </GlowContainer>
      <SlippageSettings show={showSlippage} handleClose={handleSlippageModal} />
    </Container>
  );
}
