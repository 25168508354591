import GradientButton from "components/GradientBtn";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useStore } from "store/zustand";
import styled from "styled-components";
import CalculatorInput from "views/Home/CalculatorForm/CalculatorInput";
import {
  CalculatorBody,
  InputContainer,
  InputLabel,
  InputSymbol,
  InputWrapper,
} from "views/Home/CalculatorForm/style";
import useConfirmStaking from "views/Home/hooks/useConfirmStaking";
import { StyledCloseIcon } from "views/Farms/AddFarmModal";

const StyledModal = styled(Modal)`

.modal-content {
  background: black;
  z-index: 20;
  transform-style: preserve-3d;

  .modal-title.h4,
  .modal-body p {
    font-family: "Nasalization";
  }

  .modal-header {
    border-bottom: 1px solid rgba(243, 8, 137, 0.4);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
  }

  .modal-footer {
    border-top: 1px solid rgba(243, 8, 137, 0.4);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
  }

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 100%;
    background-blend-mode: soft-light, soft-light, normal;
    background: radial-gradient(circle at left, #c97ebc, transparent),
      radial-gradient(circle at bottom, #6531b1, transparent),
      radial-gradient(circle at right, #16aff8, transparent),
      radial-gradient(circle at top, #dc0474, transparent);
    content: "";
    width: 500px;
    filter: blur(80px);
    transform: translateZ(-1px);
  }
`;

const LockDate = styled.h4`
  font-family: "Nasalization";
  opacity: 0.36;
  font-weight: 400;
  font-size: 0.8125rem;
`;

interface ExtendLockTimeProps {
  show: boolean;
  onHide: () => void;
}
export default function ExtendLockTimeModal({
  show,
  onHide,
}: ExtendLockTimeProps) {
  const [lockPeriod, setLockPeriod] = useState("365");
  const [user, lockTime] = useStore((state) => [state.user, state.lockTime]);
  const combinedLockTime = Number(lockPeriod) + lockTime;

  const { onConfirmStaking, isLoading } = useConfirmStaking(
    0,
    0,
    combinedLockTime,
    true
  );

  const handleLockPeriod = (value: number) => {
    setLockPeriod(value.toString());
  };

  const handleConfirm = () => {
    onConfirmStaking(() => {
      setLockPeriod("");
      onHide();
    });
  };

  const lockDate = useMemo(() => {
    const unixTime =
      !!user && user?.lockEndTime ? user.lockEndTime.toNumber() : undefined;
    const currentEndDate =
      !!unixTime && unixTime > 0 ? moment.unix(unixTime) : undefined;
    let newEndDate = moment(currentEndDate).add(Number(lockPeriod), "days");

    if (newEndDate.isBefore(currentEndDate)) {
      newEndDate = currentEndDate as Moment;
    }
    return newEndDate.format("LL");
  }, [lockPeriod, user]);

  return (
    <StyledModal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Increase Stake</Modal.Title>
        <StyledCloseIcon onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <CalculatorBody>
          <InputWrapper>
            <InputLabel>Lock Period</InputLabel>
            <InputContainer>
              <CalculatorInput
                onInputChange={handleLockPeriod}
                value={lockPeriod}
                limit={365}
              />
              <InputSymbol>Days</InputSymbol>
            </InputContainer>
          </InputWrapper>
          <LockDate>
            {combinedLockTime > 365
              ? "You can only extend your stake for a maximum of 365 days"
              : `lock date will be extended until ${lockDate}`}
          </LockDate>
        </CalculatorBody>
      </Modal.Body>
      <Modal.Footer>
        <GradientButton label="Cancel" onClick={onHide} />
        <GradientButton
          label="Extend"
          onClick={handleConfirm}
          disabled={!lockPeriod || combinedLockTime > 365}
          disabledText="Extend"
          loading={isLoading}
        />
      </Modal.Footer>
    </StyledModal>
  );
}
