import { useMemo } from "react";
import styled from "styled-components";
import useUserStaked from "views/Stake/hooks/useUserStaked";
import {
  useFarmsTVL,
  useStakingTVL,
  useUserLength,
  useVaultsTVL,
} from "services/queries";
import millify from "millify";

const HeroStatsContainer = styled.div`
  border-radius: 1rem;
  box-shadow: rgb(255 0 135 / 50%) 0px 0px 5rem inset;
  background: radial-gradient(
      at center center,
      rgba(255, 0, 115, 0.1),
      transparent 40%
    ),
    radial-gradient(
      circle at left top,
      rgba(255, 0, 113, 0.25),
      transparent 12%
    ),
    radial-gradient(
      circle at right top,
      rgba(255, 0, 113, 0.25),
      transparent 15%
    ),
    radial-gradient(
      circle at right bottom,
      rgba(255, 0, 113, 0.25),
      transparent 30%
    ),
    radial-gradient(
      circle at left bottom,
      rgba(255, 0, 113, 0.25),
      transparent 25%
    ),
    linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(11, 0, 13) 100%);
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 2rem 3rem;
  gap: 1.25rem;

  @media (max-width: 1024px) {
    padding: 3rem 15px;
    margin-top: 40px;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const HeroStat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;

  h4 {
    font-family: Nasalization;
    color: white;
    font-weight: 400;
    margin: 0.125rem 0px;
    font-size: 1rem;
  }

  h1 {
    font-family: Nasalization;
    color: rgb(255, 0, 135);
    font-weight: 400;
    margin: 0.125rem 0px;
    font-size: 2rem;
  }

  p {
    margin: 1rem 0;
  }
`;

export default function HeroStats() {
  useUserStaked();
  const { data: length } = useUserLength();
  const { data: vaultsTvl } = useVaultsTVL();
  const { data: stakingTvl } = useStakingTVL();
  const { data: farmsTvl } = useFarmsTVL();

  const rhubTotalTVL = useMemo(() => {
    if (!!vaultsTvl && !!stakingTvl && !!farmsTvl) {
      return (
        Number(vaultsTvl) + Number(stakingTvl?.rhub) + Number(farmsTvl?.rhub)
      );
    }
    return 0;
  }, [vaultsTvl, stakingTvl, farmsTvl]);

  const rhubLPTotalTVL = useMemo(() => {
    if (!!stakingTvl && !!farmsTvl) {
      return Number(stakingTvl?.rhubLP) + Number(farmsTvl?.rhubLP);
    }
    return 0;
  }, [stakingTvl, farmsTvl]);

  return (
    <HeroStatsContainer>
      <HeroStat>
        <h4>RHUB Staked</h4>

        <h1>{`${millify(rhubTotalTVL)} USD`}</h1>
      </HeroStat>
      <HeroStat>
        <h4>Unique Stakers</h4>
        <h1>{length}</h1>
      </HeroStat>
      <HeroStat>
        <h4>RHUB-LP Staked</h4>
        <h1>{`${millify(rhubLPTotalTVL || 0)} USD`}</h1>
      </HeroStat>
      <HeroStat></HeroStat>
      <HeroStat>
        <h4>RoseHub Token Contract</h4>
        <a
          style={{ color: "white", fontSize: "14px" }}
          href={
            "https://explorer.emerald.oasis.dev/address/0x17933E09231306c16A5d6ebc5E55511eCadd00E3/transactions"
          }
        >
          0x17933E09231306c16A5d6ebc5E55511eCadd00E3
        </a>
      </HeroStat>
    </HeroStatsContainer>
  );
}
