import { Currency, Percent, Price } from "@rosehub-tech/sdk";
import { Field } from "types";
import {
  Extras,
  MoreInfoHead,
  TradeInfoAmount,
  TradeInfoContainer,
} from "../views/Liquidity/styles/AddLiquidityStyles";

export default function PairInfo({
  price,
  poolTokenPercentage,
  currencies,
}: {
  price: Price<Currency, Currency>;
  poolTokenPercentage: Percent;
  currencies: { [field in Field]?: Currency };
}) {
  if (!price) return null;

  const symbol0 = currencies[Field.CURRENCY_A]?.symbol;
  const symbol1 = currencies[Field.CURRENCY_B]?.symbol;
  return (
    <Extras>
      <MoreInfoHead>Prices and pool share</MoreInfoHead>
      <TradeInfoContainer>
        <div>
          <TradeInfoAmount>{price?.toSignificant(5)}</TradeInfoAmount>
          <p>
            {symbol1 ?? ""} per {symbol0 ?? ""}
          </p>
        </div>
        <div>
          <TradeInfoAmount>{price?.invert()?.toSignificant(5)}</TradeInfoAmount>
          {!!currencies && !!price ? (
            <p>
              {symbol0 ?? ""} per {symbol1 ?? ""}
            </p>
          ) : null}
        </div>
        <div>
          <TradeInfoAmount>
            {!!currencies && !!price && poolTokenPercentage
              ? `${
                  poolTokenPercentage.toFixed(2) === "0.00"
                    ? "<0.01"
                    : poolTokenPercentage.toFixed(2)
                }%`
              : "<0.01"}
          </TradeInfoAmount>
          <p>Share of Pool</p>
        </div>
      </TradeInfoContainer>
    </Extras>
  );
}
