import { useMemo } from "react";
import { CurrencyAmount, Token } from "@rosehub-tech/sdk";
import { useContractRead, useSigner } from "wagmi";
import { useERC20Interface } from "hooks/contracts/useInterfaces";

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(
  token?: Token
): CurrencyAmount<Token> | undefined {
  const { data: signer } = useSigner();
  const erc20ABI = useERC20Interface();

  const { data: totalSupply } = useContractRead({
    addressOrName: token?.isToken ? token.address : undefined,
    contractInterface: erc20ABI,
    functionName: "totalSupply",
    enabled: !!signer,
  });

  return useMemo(
    () =>
      token && totalSupply
        ? CurrencyAmount.fromRawAmount(token, totalSupply.toString())
        : undefined,
    [token, totalSupply]
  );
}

export default useTotalSupply;
