import { getAddress } from "@ethersproject/address";
import styled from "styled-components";
import metamaskIcon from "assets/images/metamask.png";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const WalletButton = styled.button`
  font-family: "Nasalization";
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 0.75rem 1.75rem;
  border-radius: 5rem;
  height: fit-content;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: radial-gradient(circle at center, #e100ff8d, transparent 40%),
    radial-gradient(circle at top left, #ff9900, transparent 12%),
    radial-gradient(circle at top right, #ff0073, transparent 15%),
    radial-gradient(circle at bottom right, #ffbb00, transparent 30%),
    radial-gradient(circle at bottom left, #ff0073, transparent 25%),
    linear-gradient(96deg, #e7175d 0%, #f81f4e 100%);
  background-size: 400%;

  &:hover,
  label:hover {
    cursor: pointer;
  }

  &:hover {
    animation: animate 8s linear infinite;
  }

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(45deg, #f15523, #ef3224, #7c3697);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
  }

  @media (max-width: 1024px) {
    width: 100%;
    text-align: center;
    margin-top: 8px;
  }
`;

const WrongNetwork = styled.button`
  text-align: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #dc3545;
  border-radius: 100px;
  border: none;
  font-weight: 700;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }

  button {
    @media (max-width: 1024px) {
      width: 90%;
      margin: 8px auto;
    }
  }

  span {
    @media (max-width: 1024px) {
      font-size: 0.85rem;
    }
  }
`;

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddressValid(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function getShortenAddress(address: string, chars = 4): string {
  const parsedAddress = isAddressValid(address);
  if (!parsedAddress) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsedAddress.substring(0, chars + 2)}...${parsedAddress.substring(
    42 - chars
  )}`;
}
interface WalletProps {
  fullWidth?: boolean;
  label?: string;
}

export default function Wallet({
  fullWidth,
  label = "Connect Wallet",
}: WalletProps) {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <div
            {...(!mounted && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                  <WalletButton onClick={openConnectModal} type="button">
                    Connect Wallet
                  </WalletButton>
                );
              }

              if (chain.unsupported) {
                return (
                  <WrongNetwork onClick={openChainModal} type="button">
                    Wrong network
                  </WrongNetwork>
                );
              }

              return (
                <WalletButton onClick={openAccountModal} type="button">
                  <img
                    alt={chain.name ?? "Chain icon"}
                    src={metamaskIcon}
                    style={{ width: 24, height: 24 }}
                  />
                  {account.displayName}
                </WalletButton>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
