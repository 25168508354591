import { CID } from "connectors/chains";
import { flatten } from "lodash";
import { useStore } from "store/zustand";
import styled from "styled-components";
import { rarities } from "utils/rarity";
import GradientButton from "./GradientBtn";

const Wrapper: any = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  gap: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    button {
      width: 260px !important;
    }
  }
`;

export const NFTImage = styled.img`
  width: 90%;
  height: auto;
  margin: 0 auto;
`;

const NFTPoints = styled.span`
  position: absolute;
  top: 10px;
  left: 20px;
  color: #ff0087;
  background: #fff;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: 700;
`;

const getNFTImage = (id: number) => {
  return `https://rosehub.mypinata.cloud/ipfs/${CID}/${id}.png?img-width=1000&image-height=1000&img-fit=contain&img-dpr=0.5&img-quality=50&img-format=auto`;
};

export enum Rarities {
  COMMON = "Common",
  RARE = "Rare",
  EPIC = "Epic",
  LEGENDARY = "Legendary",
}

export const getRarity = (id: number) => {
  const flattenedCommons = flatten(rarities.common);
  const flattenedRares = flatten(rarities.rare);
  const flattenedEpics = flatten(rarities.epic);
  const flattenedLegendaries = flatten(rarities.legendary);
  const parsedId = Number(id);

  if (flattenedCommons.includes(parsedId)) {
    return Rarities.COMMON;
  }

  if (flattenedRares.includes(parsedId)) {
    return Rarities.RARE;
  }

  if (flattenedEpics.includes(parsedId)) {
    return Rarities.EPIC;
  }

  if (flattenedLegendaries.includes(parsedId)) {
    return Rarities.LEGENDARY;
  }

  return "Unknown";
};

interface NFTThumbProps {
  id: number;
  view?: boolean;
}

export default function NFTThumb({ id, view }: NFTThumbProps) {
  const [selectedNFTIds, updateNFTSelection] = useStore((state) => [
    state.selectedNFTIds,
    state.updateSelection,
  ]);

  const handleSelectNFT = async () => {
    updateNFTSelection(id);
  };

  return (
    <NFTCard
      id={id}
      onSelectNft={handleSelectNFT}
      selectedNFTIds={selectedNFTIds}
      view={!!view}
    />
  );
}

const NFTCard = ({
  id,
  onSelectNft,
  selectedNFTIds,
  view,
}: {
  id: number;
  selectedNFTIds: number[];
  onSelectNft: (id: string) => void;
  view: boolean;
}) => {
  const url = getNFTImage(id);

  return (
    <Wrapper>
      <NFTImage loading="lazy" src={url} alt={`RoseApe #${id}`} />
      <NFTPoints>{getRarity(id)}</NFTPoints>
      {view ? (
        <GradientButton
          label={`RoseApe #${id}`}
          onClick={onSelectNft}
          width={260}
        />
      ) : (
        <GradientButton
          label={
            selectedNFTIds.includes(id)
              ? `Selected RoseApe #${id}`
              : `Select RoseApe #${id}`
          }
          onClick={onSelectNft}
          width={260}
        />
      )}
    </Wrapper>
  );
};
