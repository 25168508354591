import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  padding: 1.7rem;
  border-bottom: 1px solid #f3088939;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 1.7rem;
`;

export const Inputs = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  .container-fluid {
    margin-bottom: 16px;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h3`
  font-family: "Nasalization";
  margin-bottom: 0.5rem;
  font-size: 1.17rem;
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Sub = styled.p`
  font-family: "Nasalization";
  opacity: 0.64;
  margin-bottom: 0;
  margin-left: 35px;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Extras = styled.div`
  margin: 3rem 0 0;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 24px;
  width: 100%;
`;

export const MoreInfoHead = styled.p`
  font-size: 0.9rem;
  font-family: Nasalization;
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
`;

export const TradeInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
`;

export const TradeInfoAmount = styled.p`
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: rgb(247, 248, 250);
  font-size: 0.9rem;
  opacity: 1;
`;

export const BackIcon = styled.img`
  width: 1rem;
  filter: invert(1);
  transform: rotate(90deg);

  &:hover {
    cursor: pointer;
  }
`;
