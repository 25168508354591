import { Currency, Pair, Token } from "@rosehub-tech/sdk";
import useChainId from "hooks/useChainId";
import { wrappedCurrency } from "types/WrappedCurrency";
import { RHUB, USDT } from "utils/tokens";

export function useSortTokenPair(pair: Pair) {
  let tokenA = pair?.token0;
  let tokenB = pair?.token1;
  if (!pair) return undefined;
  if (
    pair?.token1.equals(RHUB[pair?.token1?.chainId]) ||
    pair?.token0.equals(USDT[pair?.token0?.chainId])
  ) {
    tokenA = pair?.token1;
    tokenB = pair?.token0;
  }

  return [tokenA, tokenB];
}

export default function useSortLiquidityTokens(
  currencyA: Currency,
  currencyB: Currency,
  token0Value: string,
  token1Value: string
): [Token, Token, string, string] {
  const chainId = useChainId();
  const token0 = wrappedCurrency(currencyA, chainId);
  const token1 = wrappedCurrency(currencyB, chainId);
  let tokenA = token0;
  let tokenB = token1;
  let tokenAValue = token0Value;
  let tokenBValue = token1Value;
  if (
    token1?.equals(RHUB[token1?.chainId]) ||
    token0?.equals(USDT[token0?.chainId])
  ) {
    tokenA = token1;
    tokenB = token0;
    tokenAValue = token1Value;
    tokenBValue = token0Value;
  }
  return [tokenA, tokenB, tokenAValue, tokenBValue];
}
