import { Controller } from "react-hook-form";
import FormInput from "../FormInput";
import { StepProps } from "../Fundraise";
import { StepContainer } from "./Step1";
import { StepContainerRow } from "./Step2";

export default function Step5({ control }: StepProps) {
  return (
    <StepContainer>
      <StepContainerRow>
        <Controller
          name="websiteUrl"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <FormInput
              type="text"
              label="Official Website"
              placeholder="https://rosehub.io"
              {...rest}
              {...fieldState}
            />
          )}
        />
        <Controller
          name="telegram"
          control={control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <FormInput
              type="text"
              label="Telegram URL"
              placeholder="https://t.me/RoseHubMedia"
              {...rest}
              {...fieldState}
            />
          )}
        />
      </StepContainerRow>
      <StepContainerRow>
        <Controller
          name="twitter"
          control={control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <FormInput
              type="text"
              label="Twitter"
              placeholder="https://twitter.com/RoseHubMedia"
              {...rest}
              {...fieldState}
            />
          )}
        />
        <Controller
          name="discord"
          control={control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <FormInput
              type="text"
              label="Discord Server URL"
              placeholder="https://discord.gg/5D4kNTXDCe"
              {...rest}
              {...fieldState}
            />
          )}
        />
      </StepContainerRow>
      <StepContainerRow>
        <Controller
          name="mediumOrBlog"
          control={control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <FormInput
              type="text"
              label="Medium or other blog URL"
              placeholder="http://rosehub.medium.com/"
              {...rest}
            />
          )}
        />
        <Controller
          name="otherChannels"
          control={control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <FormInput
              type="text"
              label="Other channels"
              placeholder="https://t.me/RoseHubAnn"
              {...rest}
              {...fieldState}
            />
          )}
        />
      </StepContainerRow>
      <Controller
        name="contactNumber"
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="Contact Person"
            placeholder="Juan dela Cruz"
            {...rest}
            {...fieldState}
          />
        )}
      />
    </StepContainer>
  );
}
