import useChainId from "hooks/useChainId";
import {
  ChainId,
  Currency,
  CurrencyAmount,
  JSBI,
  Pair,
  Price,
  Token,
  WNATIVE,
} from "@rosehub-tech/sdk";
import { useMemo } from "react";
import { USDT } from "utils/tokens";
import { PairState, usePairs } from "./usePairs";
import { isAddress } from "utils/isAddress";
import { RHUB, ROSE } from "constants/tokens";
import { multiplyPriceByAmount } from "./useLPValues";
import { useQuery } from "@tanstack/react-query";
import { ethers } from "ethers";
import { useSwapPairInterface } from "hooks/contracts/useInterfaces";
import { useSigner } from "wagmi";
/**
 * Returns the price in USDT of the input currency
 * @param currency currency to compute the USDT price of
 */
export default function useUSDTPrice(
  currency?: Currency
): Price<Currency, Currency> | undefined {
  const chainId = useChainId();
  const wrapped = currency?.wrapped;
  const wnative = WNATIVE[chainId] || WNATIVE[ChainId.EMERALD_MAINNET];
  const usdt = USDT[chainId] || USDT[ChainId.EMERALD_MAINNET];

  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [
        chainId &&
        wrapped &&
        (wnative?.equals(wrapped) ||
          wrapped.symbol === WNATIVE[chainId]?.symbol)
          ? undefined
          : currency,
        chainId ? wnative : undefined,
      ],
      [
        wrapped?.equals(usdt) || wrapped.symbol === USDT[chainId]?.symbol
          ? undefined
          : wrapped,
        usdt,
      ],
      [chainId ? wnative : undefined, usdt],
    ],
    [wnative, usdt, chainId, currency, wrapped]
  );

  const tokenPairResults = usePairs(tokenPairs);

  const [
    [rosePairState, rosePair],
    [usdtPairState, usdtPair],
    [usdtRosePairState, usdtRosePair],
  ] = tokenPairResults;

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined;
    }

    const isROSEPairExist =
      usdtPair &&
      usdtPairState === PairState.EXISTS &&
      usdtPair.reserve0.greaterThan("0") &&
      usdtPair.reserve1.greaterThan("0");

    if (
      wrapped.equals(wnative) ||
      wrapped.symbol === WNATIVE[chainId]?.symbol
    ) {
      if (isROSEPairExist) {
        const price = usdtPair.priceOf(wnative);
        return new Price(currency, usdt, price.denominator, price.numerator);
      }
      return undefined;
    }

    if (wrapped.equals(usdt)) {
      return new Price(usdt, usdt, "1", "1");
    }

    const isRosePairExist =
      rosePair &&
      rosePairState === PairState.EXISTS &&
      rosePair.reserve0.greaterThan("0") &&
      rosePair.reserve1.greaterThan("0");

    const isUsdtRosePairExist =
      usdtRosePair &&
      usdtRosePairState === PairState.EXISTS &&
      usdtRosePair.reserve0.greaterThan("0") &&
      usdtRosePair.reserve1.greaterThan("0");

    const rosePairROSEAmount = isRosePairExist && rosePair?.reserveOf(wnative);
    const rosePairROSEUSDTValue: JSBI =
      rosePairROSEAmount && isROSEPairExist && isUsdtRosePairExist
        ? usdtRosePair.priceOf(wnative).quote(rosePairROSEAmount).quotient
        : JSBI.BigInt(0);

    // all other tokens
    // first try the usdt pair
    if (
      isROSEPairExist &&
      usdtPair.reserveOf(usdt).greaterThan(rosePairROSEUSDTValue)
    ) {
      const price = usdtPair.priceOf(wrapped);
      return new Price(currency, usdt, price.denominator, price.numerator);
    }
    if (isRosePairExist && isUsdtRosePairExist) {
      if (
        usdtRosePair.reserveOf(usdt).greaterThan("0") &&
        rosePair.reserveOf(wnative).greaterThan("0")
      ) {
        const roseUsdtPrice = usdtRosePair.priceOf(usdt);
        const currencyRosePrice = rosePair.priceOf(wnative);
        const usdtPrice = roseUsdtPrice.multiply(currencyRosePrice).invert();
        return new Price(
          currency,
          usdt,
          usdtPrice.denominator,
          usdtPrice.numerator
        );
      }
    }

    return undefined;
  }, [
    currency,
    wrapped,
    chainId,
    wnative,
    usdt,
    rosePair,
    usdtRosePair,
    usdtPairState,
    usdtPair,
    rosePairState,
    usdtRosePairState,
  ]);
}

export const usePriceByPairs = (
  currencyA?: Currency,
  currencyB?: Currency,
  lpAddress?: string
) => {
  const [tokenA, tokenB] = [currencyA?.wrapped, currencyB?.wrapped];

  const pairAddress = !!lpAddress ? lpAddress : getLpAddress(tokenA, tokenB);
  const { data: signer } = useSigner();
  const pairInterface = useSwapPairInterface();

  const { data: price } = useQuery(
    ["usePairPrice", pairAddress],
    async () => {
      const pairContract = new ethers.Contract(
        pairAddress,
        pairInterface,
        signer
      );
      const reserves = await pairContract.getReserves();

      if (!reserves) {
        return null;
      }
      const reserve0 = reserves[0];
      const reserve1 = reserves[1];
      const [token0, token1] = tokenA.sortsBefore(tokenB)
        ? [tokenA, tokenB]
        : [tokenB, tokenA];

      const pair = new Pair(
        CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
        CurrencyAmount.fromRawAmount(token1, reserve1.toString())
      );

      const price = pair.priceOf(tokenB);
      return price;
    },
    {
      enabled: !!signer && !!pairAddress,
    }
  );

  return price;
};

export const getLpAddress = (
  token1: string | Token,
  token2: string | Token,
  chainId: number = ChainId.EMERALD_MAINNET
) => {
  let token1AsTokenInstance = token1;
  let token2AsTokenInstance = token2;
  if (!token1 || !token2) {
    return null;
  }
  if (typeof token1 === "string" || token1 instanceof String) {
    const checksummedToken1Address = isAddress(token1);
    if (!checksummedToken1Address) {
      return null;
    }
    token1AsTokenInstance = new Token(chainId, checksummedToken1Address, 18);
  }
  if (typeof token2 === "string" || token2 instanceof String) {
    const checksummedToken2Address = isAddress(token2);
    if (!checksummedToken2Address) {
      return null;
    }
    token2AsTokenInstance = new Token(chainId, checksummedToken2Address, 18);
  }

  return Pair.getAddress(
    token1AsTokenInstance as Token,
    token2AsTokenInstance as Token
  );
};

export const useRhubUsdtPrice = (): Price<Currency, Currency> | undefined => {
  const chainId = useChainId();
  // Default rhub, if no rhub address found
  const rhub: Token = RHUB[chainId]
    ? RHUB[chainId]
    : RHUB[ChainId.EMERALD_MAINNET];
  return usePriceByPairs(USDT[rhub?.chainId], rhub);
};

export const useUSDTCurrencyAmount = (
  currency?: Currency,
  amount?: number
): number | undefined => {
  const usdtPrice = useUSDTPrice(currency);
  if (!amount) {
    return undefined;
  }
  if (usdtPrice) {
    return multiplyPriceByAmount(usdtPrice, amount);
  }
  return undefined;
};

export const useUSDTRhubAmount = (amount: number): number | undefined => {
  const rhubUsdtPrice = useRhubUsdtPrice();
  if (!rhubUsdtPrice || !amount) return undefined;
  return multiplyPriceByAmount(rhubUsdtPrice, amount);
};

export const useROSEUsdtPrice = (
  enabled: boolean
): Price<Currency, Currency> | undefined => {
  const chainId = useChainId();
  const rose: Token = ROSE[chainId]
    ? ROSE[chainId]
    : ROSE[ChainId.EMERALD_MAINNET];
  return usePriceByPairs(USDT[rose?.chainId], rose);
};

export const useUSDTRoseAmount = (amount: number): number | undefined => {
  const rhubUsdtPrice = useROSEUsdtPrice(!!amount && amount > 0);
  if (!amount || !rhubUsdtPrice) return undefined;
  return multiplyPriceByAmount(rhubUsdtPrice, amount);
};
