import {
  Currency,
  CurrencyAmount,
  JSBI,
  Pair,
  Percent,
  Price,
  Token,
  WNATIVE,
} from "@rosehub-tech/sdk";
import { BIG_INT_ZERO } from "config/exchange";
import useChainId from "hooks/useChainId";
import { useMemo } from "react";
import { Field } from "types";
import { wrappedCurrency } from "types/WrappedCurrency";
import tryParseAmount from "utils/tryParseAmount";
import { useAccount } from "wagmi";
import useCurrencyBalances from "./useCurrencyBalances";
import useLiquidityAllowances from "./useLiquidityAllowances";
import { PairState, useMintState, usePair } from "./usePairs";
import useTotalSupply from "./useTokenSupply";

export enum ErrorTypes {
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  INSUFFICIENT_ALLOWANCE = "INSUFFICIENT_ALLOWANCE",
  INVALID_PAIR = "INVALID_PAIR",
  DISCONNECTED = "DISCONNECTED",
  NO_TOKEN_BALANCE = "NO_TOKEN_BALANCE",
  NO_INPUT = "NO_INPUT",
}

export function useDerivedMintInfo(
  currencyA: Currency | undefined,
  currencyB: Currency | undefined
): {
  dependentField: Field;
  currencies: { [field in Field]?: Currency };
  pair?: Pair | null;
  pairState: PairState;
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> };
  price?: Price<Currency, Currency>;
  noLiquidity?: boolean;
  liquidityMinted?: CurrencyAmount<Token>;
  poolTokenPercentage?: Percent;
  error?: string;
  errorType?: string;
  addError?: string;
} {
  const { address: account } = useAccount();
  const chainId = useChainId();
  const [independentField, typedValue, otherTypedValue] = useMintState();

  const dependentField =
    independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A;

  // tokens
  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA ?? undefined,
      [Field.CURRENCY_B]: currencyB ?? undefined,
    }),
    [currencyA, currencyB]
  );

  // pair
  const [pairState, pair] = usePair(
    currencies[Field.CURRENCY_A],
    currencies[Field.CURRENCY_B]
  );

  const totalSupply = useTotalSupply(pair?.liquidityToken);

  const noLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(totalSupply && JSBI.equal(totalSupply.quotient, BIG_INT_ZERO)) ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.quotient, BIG_INT_ZERO) &&
        JSBI.equal(pair.reserve1.quotient, BIG_INT_ZERO)
    );

  // balances
  const balances = useCurrencyBalances(account ?? undefined, [
    currencies[Field.CURRENCY_A],
    currencies[Field.CURRENCY_B],
  ]);
  const currencyBalances: { [field in Field]?: CurrencyAmount<Currency> } = {
    [Field.CURRENCY_A]: balances[0],
    [Field.CURRENCY_B]: balances[1],
  };

  //allowance
  const token0 = wrappedCurrency(currencyA, chainId);
  const token1 = wrappedCurrency(currencyB, chainId);
  const allowances = useLiquidityAllowances(token0?.address, token1?.address);

  // amounts
  const independentAmount: CurrencyAmount<Currency> | undefined =
    tryParseAmount(typedValue, currencies[independentField]);
  const dependentAmount: CurrencyAmount<Currency> | undefined = useMemo(() => {
    if (noLiquidity) {
      if (otherTypedValue && currencies[dependentField]) {
        return tryParseAmount(otherTypedValue, currencies[dependentField]);
      }
      return undefined;
    }
    if (independentAmount) {
      // we wrap the currencies just to get the price in terms of the other token
      const wrappedIndependentAmount = independentAmount?.wrapped;
      const [tokenA, tokenB] = [currencyA?.wrapped, currencyB?.wrapped];
      if (tokenA && tokenB && wrappedIndependentAmount && pair) {
        const dependentCurrency =
          dependentField === Field.CURRENCY_B ? currencyB : currencyA;
        const dependentTokenAmount =
          dependentField === Field.CURRENCY_B
            ? pair.priceOf(tokenA).quote(wrappedIndependentAmount)
            : pair.priceOf(tokenB).quote(wrappedIndependentAmount);
        return dependentCurrency?.isNative ||
          dependentCurrency?.symbol === WNATIVE[chainId]?.symbol
          ? CurrencyAmount.fromRawAmount(
              dependentCurrency,
              dependentTokenAmount.quotient
            )
          : dependentTokenAmount;
      }
      return undefined;
    }
    return undefined;
  }, [
    noLiquidity,
    independentAmount,
    otherTypedValue,
    currencies,
    dependentField,
    currencyA,
    currencyB,
    pair,
    chainId,
  ]);

  const parsedAmounts: {
    [field in Field]: CurrencyAmount<Currency> | undefined;
  } = useMemo(
    () => ({
      [Field.CURRENCY_A]:
        independentField === Field.CURRENCY_A
          ? independentAmount
          : dependentAmount,
      [Field.CURRENCY_B]:
        independentField === Field.CURRENCY_A
          ? dependentAmount
          : independentAmount,
    }),
    [dependentAmount, independentAmount, independentField]
  );

  const price = useMemo(() => {
    if (noLiquidity) {
      const {
        [Field.CURRENCY_A]: currencyAAmount,
        [Field.CURRENCY_B]: currencyBAmount,
      } = parsedAmounts;
      if (currencyAAmount && currencyBAmount) {
        return new Price(
          currencyAAmount.currency,
          currencyBAmount.currency,
          currencyAAmount.quotient,
          currencyBAmount.quotient
        );
      }
      return undefined;
    }
    const wrappedCurrencyA = currencyA?.wrapped;
    return pair && wrappedCurrencyA
      ? pair.priceOf(wrappedCurrencyA)
      : undefined;
  }, [currencyA, noLiquidity, pair, parsedAmounts]);

  // liquidity minted
  const liquidityMinted = useMemo(() => {
    const {
      [Field.CURRENCY_A]: currencyAAmount,
      [Field.CURRENCY_B]: currencyBAmount,
    } = parsedAmounts;
    const [tokenAmountA, tokenAmountB] = [
      currencyAAmount?.wrapped,
      currencyBAmount?.wrapped,
    ];
    if (pair && totalSupply && tokenAmountA && tokenAmountB) {
      try {
        return pair.getLiquidityMinted(totalSupply, tokenAmountA, tokenAmountB);
      } catch (error) {
        console.error(error);
        return undefined;
      }
    }
    return undefined;
  }, [parsedAmounts, pair, totalSupply]);

  const poolTokenPercentage = useMemo(() => {
    if (liquidityMinted && totalSupply) {
      return new Percent(
        liquidityMinted.quotient,
        totalSupply.add(liquidityMinted).quotient
      );
    }
    return undefined;
  }, [liquidityMinted, totalSupply]);

  let error: string | undefined;
  let errorType: ErrorTypes | undefined;
  let addError: string | undefined;
  if (!account) {
    errorType = ErrorTypes.DISCONNECTED;
    error = "Connect Wallet";
  }

  if (!allowances) {
    errorType = ErrorTypes.INSUFFICIENT_ALLOWANCE;
    addError = `Approve ${currencies[Field.CURRENCY_A]?.symbol}`;
  }

  if (!!allowances && !allowances?.token0 && !currencyA?.isNative) {
    errorType = ErrorTypes.INSUFFICIENT_ALLOWANCE;
    addError = `Approve ${currencies[Field.CURRENCY_A]?.symbol}`;
  }

  if (!!allowances && !allowances?.token1 && !currencyB?.isNative) {
    errorType = ErrorTypes.INSUFFICIENT_ALLOWANCE;
    addError = `Approve ${currencies[Field.CURRENCY_B]?.symbol}`;
  }

  const {
    [Field.CURRENCY_A]: currencyAAmount,
    [Field.CURRENCY_B]: currencyBAmount,
  } = parsedAmounts;

  if (
    currencyAAmount &&
    currencyBAmount &&
    currencyBalances?.[Field.CURRENCY_A]?.equalTo(0) &&
    currencyBalances?.[Field.CURRENCY_B]?.equalTo(0)
  ) {
    errorType = ErrorTypes.NO_TOKEN_BALANCE;
    error = error ?? "No token balance";
  }

  if (!parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
    errorType = ErrorTypes.NO_INPUT;
    addError = "Enter an amount";
  }

  if (
    !!currencyAAmount &&
    currencyBalances?.[Field.CURRENCY_A]?.lessThan(currencyAAmount)
  ) {
    errorType = ErrorTypes.INSUFFICIENT_BALANCE;
    addError = `Insufficient ${currencies[Field.CURRENCY_A]?.symbol} balance`;
  }

  if (
    !!currencyBAmount &&
    currencyBalances?.[Field.CURRENCY_B]?.lessThan(currencyBAmount)
  ) {
    errorType = ErrorTypes.INSUFFICIENT_BALANCE;
    addError = `Insufficient ${currencies[Field.CURRENCY_B]?.symbol} balance`;
  }

  if (pairState === PairState.INVALID) {
    errorType = ErrorTypes.INVALID_PAIR;
    addError = "Choose a valid pair";
  }

  return {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
    errorType,
    addError,
  };
}
