import { useCallback, useEffect, useState } from "react";
import { escapeRegExp, inputRegex } from "utils/regex";
import { Input } from "./style";

interface CalculatorInputProps {
  onInputChange: (value: number) => void;
  value?: string;
  limit?: number;
}
export default function CalculatorInput({
  onInputChange,
  value,
  limit,
}: CalculatorInputProps) {
  const [userInput, setUserInput] = useState(value || "");

  const enforcer = useCallback(
    (nextUserInput: string) => {
      if (
        nextUserInput === "" ||
        inputRegex.test(escapeRegExp(nextUserInput))
      ) {
        if (!!limit && Number(nextUserInput) > limit) {
          setUserInput(limit.toString());
          onInputChange(limit);
          return;
        }
        setUserInput(nextUserInput);
        onInputChange(Number(nextUserInput));
      }
    },
    [limit, onInputChange]
  );

  useEffect(() => {
    if (!!value) {
      enforcer(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      inputMode="decimal"
      autoComplete="off"
      autoCorrect="off"
      value={userInput}
      onChange={(event) => {
        const value = event.target.value.replace(/,/g, ".");
        enforcer(value);
      }}
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={"0"}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  );
}
