import { useEffect } from "react";
import { erc20ABI, useAccount, useContractReads } from "wagmi";
import useChainId from "hooks/useChainId";
import { useStore } from "store/zustand";
import { ethers } from "ethers";
import {
  useLPTokenAddress,
  useRHUBTokenAddress,
} from "hooks/contracts/useContractAddresses";

export default function useRPADBalance() {
  const chainId = useChainId();
  const { address } = useAccount();
  const rpadAddress = useRHUBTokenAddress();
  const lpAddress = useLPTokenAddress();
  const [updateRPADBalance, updateRPADLPBalance] = useStore((state) => [
    state.updateRPADBalance,
    state.updateRPADLPBalance,
  ]);
  const {
    data: tokenBalances,
    refetch,
    isSuccess,
    isLoading,
  } = useContractReads({
    contracts: [
      {
        addressOrName: rpadAddress,
        contractInterface: erc20ABI,
        functionName: "balanceOf",
        args: [address],
        chainId,
      },
      {
        addressOrName: lpAddress,
        contractInterface: erc20ABI,
        functionName: "balanceOf",
        args: [address],
        chainId,
      },
    ],
    enabled: !!rpadAddress && !!lpAddress,
  });

  useEffect(() => {
    if (
      !isLoading &&
      !!tokenBalances &&
      tokenBalances.every(Boolean) &&
      isSuccess
    ) {
      const rpadBalance = ethers.utils.formatEther(tokenBalances[0]);
      const rpadLPBalance = ethers.utils.formatEther(tokenBalances[1]);
      updateRPADBalance(Number(rpadBalance));
      updateRPADLPBalance(Number(rpadLPBalance));
    }
  }, [
    isLoading,
    isSuccess,
    tokenBalances,
    updateRPADBalance,
    updateRPADLPBalance,
  ]);

  return {
    refreshBalance: refetch,
  };
}
