import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: min(1920px, calc(100% - 16rem));
  margin: 0 auto;

  @media screen and (max-width: 1023px) {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  padding: 1.7rem;
  border-bottom: 1px solid #f3088939;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 1.7rem;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
`;

export const Title = styled.h3`
  font-family: "Nasalization";
  font-size: 1.17rem;
  margin-bottom: 0;
`;

export const Sub = styled.p`
  font-family: "Nasalization";
  opacity: 0.64;
  font-size: 0.8125rem;
  margin-bottom: 0;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Message = styled.p`
  font-size: 0.9rem;
`;

export const Prompt = styled.div`
  font-size: 0.9rem;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const Ask = styled.p`
  font-size: 0.9rem;
  margin-bottom: 0;
`;
