import {
  ChainId,
  Currency,
  CurrencyAmount,
  JSBI,
  NATIVE,
  Native,
  NativeCurrency,
  Token,
} from "@rosehub-tech/sdk";
import { isAddressValid } from "components/Wallet";
import { tokenAddresses } from "connectors/chains";
import { useMultiCallAddress } from "hooks/contracts/useContractAddresses";
import { useMulticallInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { isAddress } from "utils/isAddress";
import { useContractInfiniteReads, useToken as useWagmiToken } from "wagmi";
import { useTokenBalances } from "./useTokenBalances";

export default function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount<Currency> | undefined)[] {
  const tokens = useMemo(
    () =>
      currencies?.filter((currency): currency is Token => currency?.isToken) ??
      [],
    [currencies]
  );

  const tokenBalances = useTokenBalances(account, tokens);
  const containsNative: boolean = useMemo(
    () => currencies?.some((currency) => currency?.isNative) ?? false,
    [currencies]
  );
  const uncheckedAddresses = useMemo(
    () => (containsNative ? [account] : []),
    [containsNative, account]
  );

  const nativeBalance = useNativeBalances(uncheckedAddresses);

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined;
        if (currency?.isToken) return tokenBalances[currency.address];
        if (currency?.isNative) return nativeBalance[account];
        return undefined;
      }) ?? [],
    [account, currencies, nativeBalance, tokenBalances]
  );
}

export function useCurrencyBalance(
  account?: string,
  currency?: Currency
): CurrencyAmount<Currency> | undefined {
  return useCurrencyBalances(account, [currency])[0];
}

/**
 * Returns a map of the given addresses to their eventually consistent BNB balances.
 */
export function useNativeBalances(
  uncheckedAddresses?: (string | undefined)[]
): {
  [address: string]: CurrencyAmount<Native> | undefined;
} {
  const chainId = useChainId();
  const multicallInterface = useMulticallInterface();
  const multicallAddress = useMultiCallAddress();
  const native = useNativeCurrency();
  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? orderBy(
            uncheckedAddresses
              .map(isAddress)
              .filter((a): a is string => a !== false)
          )
        : [],
    [uncheckedAddresses]
  );
  const getMulticallContracts = useMemo(() => {
    if (addresses?.length > 0) {
      return addresses.map((address) => ({
        addressOrName: multicallAddress,
        contractInterface: multicallInterface,
        functionName: "getEthBalance",
        args: [address],
        chainId,
      }));
    }
    return [];
  }, [addresses, multicallAddress, multicallInterface, chainId]);

  const { data: results } = useContractInfiniteReads({
    cacheKey: "useCurrencyBalances",
    contracts: () => getMulticallContracts,
    enabled: !!getMulticallContracts && getMulticallContracts?.length > 0,
    onError: (error) => {
      console.log("getReserves error", error);
    },
  });

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount<Native> }>(
        (memo, address, i) => {
          const value = results?.pages?.[i]?.[0];
          if (value)
            memo[address] = CurrencyAmount.fromRawAmount(
              native,
              JSBI.BigInt(value.toString())
            );
          return memo;
        },
        {}
      ),
    [addresses, results, native]
  );
}

export function useNativeCurrency(): NativeCurrency {
  const chainId = useChainId();

  return useMemo(() => {
    try {
      return Native.onChain(chainId);
    } catch (e) {
      return Native.onChain(ChainId.EMERALD_MAINNET);
    }
  }, [chainId]);
}

export function useCurrency(
  currencyId: string | undefined
): Currency | Token | null | undefined {
  const chainId = useChainId();
  const native = useNativeCurrency();
  let tokenAddress = isAddress(currencyId);
  if (!tokenAddress) {
    tokenAddress = tokenAddresses?.get(chainId)?.[currencyId];
  }
  const isNative =
    currencyId === native?.symbol || currencyId === NATIVE[chainId]?.symbol;

  const token = useToken(isNative ? undefined : (tokenAddress as string));
  return isNative ? native : token;
}

export function useToken(
  tokenAddress?: string | undefined
): Token | undefined | null {
  const chainId = useChainId();

  const address = isAddressValid(tokenAddress);
  const { data: token } = useWagmiToken({
    address: tokenAddress as string,
    enabled: !!tokenAddress && !!address,
  });

  return !!token
    ? new Token(
        chainId,
        tokenAddress as string,
        token?.decimals,
        token?.symbol,
        token?.name
      )
    : undefined;
}
