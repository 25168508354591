import styled from "styled-components";

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  background: ${({ theme }) => theme.bg};
  padding-bottom: 5rem;
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: min(1920px, calc(100% - 16rem));
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 1023px) {
    max-width: 100vw;
    padding: 0 1rem;
  }
`;

export const Title = styled.h2`
  font-family: "Nasalization";
  color: ${({ theme }) => theme.primary};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const More = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`;

export const Container = styled.div`
  overflow: scroll;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: calc(100vh - 16rem);
  min-height: 16rem;

  button {
    text-align: center;
  }

  h1 {
    font-family: "Nasalization";
    color: #ff0087;
    margin-top: 24px;
  }

  .card {
    background: transparent;
  }

  .card-subtitle {
    text-align: center;
  }
`;
