import Home from "views/Home";

const HOME_ROUTES = [
  {
    id: "home",
    label: "Home",
    link: "/",
    scrollLink: "home",
    component: (params: any) => <Home {...params} />,
  },
  // {
  //   id: "roadmap",
  //   label: "ROADMAP",
  //   scrollLink: "roadmap",
  // },
  {
    id: "projects",
    label: "Projects",
    link: "/projects",
  },
  {
    id: "tiers",
    label: "Tiers",
    link: "/tiers",
  },
  {
    id: "trade",
    label: "Trade",
    link: "/trade/exchange",
    children: [
      {
        id: "exchange",
        label: "Exchange",
        link: "/exchange",
      },
      {
        id: "liquidity",
        label: "Liquidity",
        link: "/liquidity",
      },
      {
        id: "dex",
        label: "Aggregator",
        redirect: true,
        link: "https://dex.rosehub.io",
      },
    ],
  },

  // {
  //   id: "team",
  //   label: "TEAM",
  //   scrollLink: "team",
  // },
  // {
  //   id: "faq",
  //   label: "FAQ",
  //   link: "/faq",
  // },

  // {
  //   id: "trade",
  //   label: "Trade",
  //   link: "/trade/exchange",
  //   children: [
  //     {
  //       id: "exchange",
  //       label: "Exchange",
  //       link: "/trade/exchange",
  //     },
  //     {
  //       id: "liquidity",
  //       label: "Liquidity",
  //       link: "/trade/liquidity",
  //     },
  //   ],
  // },
  {
    id: "vaults",
    label: "Vaults",
    link: "/vaults",
  },
  {
    id: "farms",
    label: "Farms",
    link: "/farms",
  },
  {
    id: "claim",
    label: "Claim",
    redirect: true,
    link: "https://claim.rosehub.io",
  },
  {
    id: "bridge",
    label: "Bridge",
    link: "/bridge",
    children: [
      {
        id: "wormhole",
        label: "Wormhole",
        redirect: true,
        link: "https://www.portalbridge.com/#/transfer",
      },
      {
        id: "multichain",
        label: "MultiChain",
        redirect: true,
        link: "https://app.multichain.org/#/router",
      },
    ],
  },
  {
    id: "nfts",
    label: "NFTS",
    redirect: true,
    link: "https://roseape.io",
  },
  // {
  //   id: "team",
  //   label: "Team",
  //   scrollLink: "team",
  // },
  {
    id: "faqs",
    label: "FAQ",
    link: "/faq",
  },
];

export const OTHER_ROUTES = [
  {
    id: "home",
    label: "Home",
    link: "/",
  },
  {
    id: "projects",
    label: "Projects",
    link: "/projects",
  },
  {
    id: "tiers",
    label: "Tiers",
    link: "/tiers",
  },

  {
    id: "trade",
    label: "Trade",
    link: "/trade/exchange",
    children: [
      {
        id: "exchange",
        label: "Exchange",
        link: "/exchange",
      },
      {
        id: "liquidity",
        label: "Liquidity",
        link: "/liquidity",
      },
      {
        id: "dex",
        label: "Aggregator",
        redirect: true,
        link: "https://dex.rosehub.io",
      },
    ],
  },
  {
    id: "vaults",
    label: "Vaults",
    link: "/vaults",
  },
  {
    id: "claim",
    label: "Claim",
    redirect: true,
    link: "https://claim.rosehub.io",
  },
  {
    id: "bridge",
    label: "Bridge",
    link: "/bridge",
    children: [
      {
        id: "wormhole",
        label: "Wormhole",
        redirect: true,
        link: "https://www.portalbridge.com/#/transfer",
      },
      {
        id: "multichain",
        label: "MultiChain",
        redirect: true,
        link: "https://app.multichain.org/#/router",
      },
    ],
  },
  {
    id: "farms",
    label: "Farms",
    link: "/farms",
  },
  {
    id: "nfts",
    label: "NFTS",
    redirect: true,
    link: "https://roseape.io",
  },
];

export const FOOTER_ROUTES = [
  {
    id: "home",
    label: "Home",
    link: "/",
  },
  {
    id: "nfts",
    label: "NFTS",
    redirect: true,
    link: "https://roseape.io",
  },
  // {
  //   id: "team",
  //   label: "Team",
  //   scrollLink: "team",
  // },
  {
    id: "faqs",
    label: "FAQ",
    link: "/faq",
  },
];

export default HOME_ROUTES;
