import { zodResolver } from "@hookform/resolvers/zod";
import Container from "components/Container";
import GradientButton from "components/GradientBtn";
import { useMemo, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { addDoc, collection } from "firebase/firestore";
import { db } from "services/firebase";
import {
  Control,
  FieldErrorsImpl,
  SubmitHandler,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import styled from "styled-components";
import { defaultValues, FormSchema, FormSchemaType } from "./schema";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import { useMutation } from "@tanstack/react-query";

const StepperContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledForm = styled.form`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormActions = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

export type StepProps = {
  errors: FieldErrorsImpl<FormSchemaType>;
  control: Control<FormSchemaType, any>;
  register: UseFormRegister<FormSchemaType>;
};

interface FormData extends FormSchemaType {
  entryStatus: string;
  launchStatus: string;
}

export default function Fundraise() {
  const {
    handleSubmit,
    trigger,
    register,
    control,
    formState: { errors },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });
  const [activeStep, setActiveStep] = useState(0);
  const { mutate, isLoading } = useMutation(async (data: FormData) => {
    return await addDoc(collection(db, "projects"), data);
  });

  const step = useMemo(() => {
    switch (activeStep) {
      case 0:
        return <Step1 errors={errors} register={register} control={control} />;
      case 1:
        return <Step2 errors={errors} register={register} control={control} />;
      case 2:
        return <Step3 errors={errors} register={register} control={control} />;
      case 3:
        return <Step4 errors={errors} register={register} control={control} />;
      case 4:
        return <Step5 errors={errors} register={register} control={control} />;
      default:
        return null;
    }
  }, [activeStep, register, errors, control]);

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    const isStep5Validated = await trigger(["contactNumber"]);
    if (!isStep5Validated) return;

    const projectData: FormData = {
      ...data,
      entryStatus: "draft",
      launchStatus: "upcoming",
    };
    mutate(projectData);
    try {
    } catch (error: any) {
      console.log("onSubmit fundraise error", error);
    }
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      const isStep1Validated = await trigger([
        "name",
        "email",
        "projectStatus",
      ]);
      if (!isStep1Validated) return;
    }
    if (activeStep === 1) {
      const isStep2Validated = await trigger([
        "dateOfIDO",
        "fundraisingStage",
        "planToRaiseUSD",
      ]);
      if (!isStep2Validated) return;
    }
    if (activeStep === 2) {
      const isStep3Validated = await trigger([
        "tokenBlockchain",
        "tokenUseCase",
        "planToRaiseUSD",
      ]);
      if (!isStep3Validated) return;
    }
    if (activeStep === 3) {
      const isStep4Validated = await trigger(["extraServices"]);
      if (!isStep4Validated) return;
    }

    setActiveStep((old) => old + 1);
  };

  return (
    <Container>
      <Stepper activeStep={activeStep}>
        <Step label="Basic Information" />
        <Step label="Fundraising Details" />
        <Step label="Token and KYC Details" />
        <Step label="Project and other references" />
        <Step label="Contact and social links" />
      </Stepper>
      <StepperContentContainer>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <div>{step}</div>
          <FormActions>
            <GradientButton
              disabled={activeStep === 0}
              onClick={() => setActiveStep((old) => old - 1)}
              disabledText={"Previous"}
              disabledWidth={160}
              label="Previous"
            />

            {activeStep === 4 ? (
              <GradientButton type="submit" label="Done" />
            ) : (
              <GradientButton
                onClick={handleNext}
                label="Next"
                loading={isLoading}
              />
            )}
          </FormActions>
        </StyledForm>
      </StepperContentContainer>
    </Container>
  );
}
