import { Route, Routes } from "react-router";
import styled from "styled-components";
import Calendar from "views/Calendar";
import Exchange from "views/Swap";
import Vaults from "views/Vaults";
import Home from "views/Home";
import FAQ from "views/FAQ";
import Trade from "../views/Trade";
import Liquidity from "views/Liquidity";
// import NFT from "views/NFT";
import Farms from "views/Farms";
import Stake from "views/Stake";
import Project from "views/Project";
import Projects from "views/Projects";
import Fundraise from "views/Fundraise";

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  margin: 0 auto 0 auto;
`;

const View = () => {
  return (
    <Container>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/faq" element={<FAQ />} />
        {/* // Staking */}
        <Route path="tiers" element={<Stake />} />
        {/* // Trade */}
        <Route path="trade" element={<Trade />} />
        <Route path="exchange" element={<Exchange />} />
        <Route path="liquidity" element={<Liquidity />} />
        {/* // projects */}
        <Route path="projects" element={<Projects />} />
        <Route path="projects/calendar" element={<Calendar />} />
        <Route path="projects/:pid" element={<Project />} />
        <Route path="fundraise" element={<Fundraise />} />
        <Route path="vaults" element={<Vaults />} />
        <Route path="farms" element={<Farms />} />

        {/* <Route path="trade" element={<Trade />}>
          <Route path="exchange" element={<Exchange />}></Route>
          <Route path="liquidity" element={<Liquidity />}></Route>
          <Route path="*" element={<Navigate replace to="exchange" />} />
        </Route>
       
      
        <Route path="projects" element={<Projects />}></Route>
        <Route path="nfts" element={<NFT />}></Route>
        <Route path="projects/calendar" element={<Calendar />}></Route>
        <Route path="projects/:pid" element={<Project />}></Route> */}
      </Routes>
    </Container>
  );
};

export default View;
