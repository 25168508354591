import { useQuery } from "@tanstack/react-query";
import NFTThumb from "components/NFTThumb";
import { ethers } from "ethers";
import { useRoseApeTokenAddress } from "hooks/contracts/useContractAddresses";
import { useRoseApesNFTInterface } from "hooks/contracts/useInterfaces";
import { range } from "lodash";
import { useEffect, useState } from "react";
import { useStore } from "store/zustand";
import styled from "styled-components";
import { useAccount, useContractRead, useSigner } from "wagmi";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  min-height: 100vh;
  background: ${({ theme }) =>
    `
    radial-gradient(circle at -5% 30%, #f0080816, transparent 10%),
    radial-gradient(circle at 120% 30%, #f0ba081a, transparent 10%),
    radial-gradient(circle at bottom center, #000000, transparent 50%), 
    radial-gradient(circle at bottom left, #fd117f6a, transparent 5%), 
    radial-gradient(circle at bottom right, #fd117f6a, transparent 5%), 
    radial-gradient(circle at -5% 70%, #f0087432, transparent 10%), 
    radial-gradient(circle at 105% 80%, #7408f031, transparent 15%), 
    ${theme.bg}
    `};
  padding-bottom: 5rem;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 5rem 0;
  margin: 2rem auto 0 auto;
  max-width: min(1920px, calc(100% - 16rem));
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 1024px) {
    max-width: calc(100% - 1rem);
  }
`;

const Title = styled.h2`
  font-family: "Nasalization";
  color: ${({ theme }) => theme.primary};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
`;

const Collection = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 16rem);
  grid-auto-rows: 24rem;
  gap: 1rem;

  @media (max-width: 1024px) {
    grid-auto-rows: 26rem;

    .modal-footer button {
      width: 100% !important;
    }
  }
`;

const LoadingText = styled.div`
  display: grid;
  place-items: center;

  @media (max-width: 1024px) {
    place-items: start;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Subtitle = styled.p`
  font-family: Nasalization;
  margin-bottom: 0;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BoostPoints = styled.span`
  font-weight: 700;
  opacity: 0.36;
  font-size: 0.8125rem;
`;

export default function NFTList({ view = false }: { view?: boolean }) {
  // const [sortBy, setSortBy] = useState(SORT_BYS[0]);
  const [nftIds, setNftIds] = useState<any[]>([]);
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const nftContractAddress = useRoseApeTokenAddress();
  const roseApesInterface = useRoseApesNFTInterface();
  const stakedNfts = useStore((state) => state.stakedNFTs);
  const { data: nfts, isLoading: isLoadingNFts } = useContractRead({
    addressOrName: nftContractAddress,
    contractInterface: roseApesInterface,
    functionName: "balanceOf",
    args: [address],
    enabled: !!nftContractAddress && !view,
  });

  const numberOfNfts = !!nfts ? nfts.toNumber() : 0;

  const { data: nftTokens, isLoading: isParsingNFTTokens } = useQuery(
    ["nftTokens", numberOfNfts, address],
    async () => {
      const nftContract = new ethers.Contract(
        nftContractAddress,
        roseApesInterface,
        signer
      );
      const nftTokens = await Promise.all([
        ...range(numberOfNfts).map(
          async (tokenIndex) =>
            await nftContract.tokenOfOwnerByIndex(address, tokenIndex)
        ),
      ]);
      return nftTokens;
    },
    {
      enabled: !!signer && numberOfNfts > 0,
    }
  );

  useEffect(() => {
    if (!!nftTokens && !view) {
      const cleanNFtIds = nftTokens?.filter(Boolean);
      if (cleanNFtIds.length > 0) {
        setNftIds(cleanNFtIds.map((result) => result.toString()));
      }
    }
  }, [nftTokens, view]);

  const loadingNfts = isLoadingNFts || isParsingNFTTokens;
  return (
    <Wrapper>
      <Container>
        <Header>
          <HeaderWrapper>
            <Title>NFTs</Title>
            <Subtitle>
              <BoostPoints>Rarity Boost Points </BoostPoints> |{" "}
              <BoostPoints> Epic 5% </BoostPoints> |{" "}
              <BoostPoints>Rare 3%</BoostPoints> |{" "}
              <BoostPoints>Common 2%</BoostPoints> |{" "}
              <BoostPoints>Legendary 2000 xRHUB Points</BoostPoints>
            </Subtitle>
            <Subtitle>
              <BoostPoints>Note: Maximum of 20% Boost Points only</BoostPoints>
            </Subtitle>
          </HeaderWrapper>
          {/* <More>
            <Input icon={glassIcon} placeholder="Search NFTs" />
            <Dropdown
              label="Sort By:"
              items={SORT_BYS}
              selected={sortBy}
              onChange={setSortBy}
            />
          </More> */}
        </Header>
        <Body>
          <Collection>
            {loadingNfts ? (
              <LoadingText>
                <span>Loading NFTs...</span>
              </LoadingText>
            ) : null}
            {!loadingNfts && nftIds.length === 0 && stakedNfts?.length === 0 ? (
              <LoadingText>
                <span>You don't have any NFT yet.</span>
              </LoadingText>
            ) : null}
            {!loadingNfts && nftIds?.length > 0
              ? nftIds.map((nftId: number) => (
                  <NFTThumb key={nftId} id={nftId} view={view} />
                ))
              : null}
            {view && !!stakedNfts && stakedNfts?.length > 0
              ? stakedNfts.map((nftId: number) => (
                  <NFTThumb key={nftId} id={nftId} view={view} />
                ))
              : null}
          </Collection>
        </Body>
      </Container>
    </Wrapper>
  );
}
