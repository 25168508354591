import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { useMasterChefInterface } from "hooks/contracts/useInterfaces";
import { useAccount, useSigner } from "wagmi";
import { BLOCKS_PER_YEAR } from "config/exchange";
import { useQuery } from "@tanstack/react-query";
import { useMasterChefAddress } from "hooks/contracts/useContractAddresses";
import { useMemo } from "react";

export function useGetFarmsLength() {
  const { data: signer } = useSigner();
  const masterChefAddress = useMasterChefAddress();
  const masterchefInterface = useMasterChefInterface();
  const { data: farmLength, isLoading } = useQuery(
    ["useGetFarmsLength", masterChefAddress],
    async () => {
      const farmContract = new ethers.Contract(
        masterChefAddress,
        masterchefInterface,
        signer
      );
      const result = await farmContract.poolLength();
      return result;
    },
    {
      enabled: !!signer,
    }
  );

  return {
    count: farmLength?.toNumber() || 0,
    isLoading,
  };
}

export default function useGetFarmInfo(index: number | undefined) {
  const { data: signer } = useSigner();
  const masterchefInterface = useMasterChefInterface();
  const masterchefAddress = useMasterChefAddress();
  const { address } = useAccount();
  const {
    data: farmData,
    refetch,
    isLoading,
  } = useQuery(
    ["useGetFarmInfo", index, masterchefAddress],
    async () => {
      const farmContract = new ethers.Contract(
        masterchefAddress,
        masterchefInterface,
        signer
      );
      const results = await Promise.all([
        farmContract.userInfo(index, address),
        farmContract.rewardPerBlock(),
        farmContract.BONUS_MULTIPLIER(),
        farmContract.pendingReward(index, address),
        farmContract.totalAllocPoint(),
        farmContract.poolInfo(index),
        farmContract.rewardLockDuration(),
      ]);
      return results;
    },
    {
      enabled: !!signer,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: 60000,
    }
  );
  return useMemo(() => {
    if (!farmData) return undefined;
    const farmInfo = farmData[0];
    const rewardPerBlock = farmData[1];
    const bonusMultiplier = farmData[2];
    const pendingRewards = farmData[3];
    const totalAllocPoint = farmData[4];
    const poolInfo = farmData[5];
    const rewardLockDuration = farmData[6];
    const lockDuration = !!rewardLockDuration
      ? rewardLockDuration?.toNumber()
      : undefined;

    const stakedAmount = farmInfo?.amount || farmInfo?.[0];

    const poolAllocPt = !!poolInfo?.allocPoint
      ? ethers.utils.formatEther(poolInfo.allocPoint)
      : undefined;

    const totalAllocPt = !!totalAllocPoint
      ? ethers.utils.formatEther(totalAllocPoint)
      : undefined;

    const farmWeight =
      !!poolAllocPt && !!totalAllocPoint
        ? BigNumber(poolAllocPt).div(BigNumber(totalAllocPt)).toNumber()
        : undefined;
    return {
      refetch,
      farmWeight,
      stakedAmount: !!stakedAmount
        ? ethers.utils.formatEther(stakedAmount)
        : undefined,
      multiplier: bonusMultiplier?.toNumber() || undefined,
      apr: !!rewardPerBlock
        ? ethers.utils.formatEther(rewardPerBlock)
        : undefined,
      pendingRewards: !!pendingRewards
        ? ethers.utils.formatEther(pendingRewards)
        : undefined,
      lockDuration: !!lockDuration ? lockDuration / 60 / 60 / 24 : undefined,
      isLoading,
    };
  }, [farmData, refetch, isLoading]);
}

/**
 * Get farm APR value in %
 * @param farmWeight allocationPoint / totalAllocationPoint
 * @param rhubUsdtPrice RHUB price in USD
 * @param farmLiquidityUsd Total farm liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  chainId: number,
  farmWeight: BigNumber,
  rhubUsdtPrice: BigNumber,
  farmLiquidityUsd: BigNumber,
  farmAddress: string,
  regularRHUBPerBlock: number
): { rhubRewardsApr: number; lpRewardsApr?: number } => {
  const yearlyRhubRewardAllocation = farmWeight
    ? farmWeight.times(BLOCKS_PER_YEAR * regularRHUBPerBlock)
    : new BigNumber(NaN);

  const rhubRewardsApr = yearlyRhubRewardAllocation
    .times(rhubUsdtPrice)
    .div(farmLiquidityUsd)
    .times(100);

  let rhubRewardsAprAsNumber = null;
  if (!rhubRewardsApr.isNaN() && rhubRewardsApr.isFinite()) {
    rhubRewardsAprAsNumber = rhubRewardsApr.toNumber();
  }
  // const lpRewardsApr = getLpApr(chainId)[farmAddress?.toLowerCase()] ?? 0;
  return { rhubRewardsApr: rhubRewardsAprAsNumber };
};

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice)
    .times(tokenPerBlock)
    .times(BLOCKS_PER_YEAR);
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(
    totalStaked
  );
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber();
};
