import { Token } from "@rosehub-tech/sdk";

type TokenPair = {
  token0: {
    amount: string;
    symbol: string;
  };
  token1: {
    amount: string;
    symbol: string;
  };
};

export default function useCreateLiquidityProvider(
  token0: Token,
  token1: Token,
  token0Amount: string,
  token1Amount: string
): TokenPair | undefined {
  if (!token0 || !token1 || !token0Amount || !token1Amount) return undefined;

  const tokenA = {
    symbol: token0.symbol,
    amount: token0Amount,
  };
  const tokenB = {
    symbol: token1.symbol,
    amount: token1Amount,
  };

  return {
    token0: tokenA,
    token1: tokenB,
  } as TokenPair;
}
