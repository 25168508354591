import { SerializedVaultsConfig, VaultCategory } from "types";
import { serializeTokens } from "utils/tokens";

const serializedTokens = serializeTokens();

export const vaults: SerializedVaultsConfig[] = [
  {
    vaultId: 0,
    name: "3-Month RHUB Vault",
    stakingToken: serializedTokens.rhub,
    earningToken: serializedTokens.rhub,
    vaultCategory: VaultCategory.ROSEHUB,
    contractAddress: {
      42262: "0xdee0ee41c46b7a3092fe83d6442610eea7537662",
      42261: "",
    },
    tokenPerBlock: "1",
    isFinished: false,
    maxLockValue: 100000,
    apy: 60,
    duration: 3,
  },
  {
    vaultId: 1,
    name: "9-Month RHUB Vault",
    stakingToken: serializedTokens.rhub,
    earningToken: serializedTokens.rhub,
    vaultCategory: VaultCategory.ROSEHUB,
    contractAddress: {
      42262: "0x07ab27e8cfb5f943b740f98631f4077ebef1684f",
      42261: "",
    },
    tokenPerBlock: "1",
    isFinished: false,
    maxLockValue: 120000,
    apy: 80,
    duration: 9,
  },
  {
    vaultId: 2,
    name: "12-Month RHUB Vault",
    stakingToken: serializedTokens.rhub,
    earningToken: serializedTokens.rhub,
    vaultCategory: VaultCategory.ROSEHUB,
    contractAddress: {
      42262: "0x4ea0ae7bed8296d40c46f834346041a15424e19b",
      42261: "",
    },
    tokenPerBlock: "1",
    isFinished: false,
    maxLockValue: 80000,
    apy: 200,
    duration: 12,
  },
];
