import React, { forwardRef, useState } from "react";
import {
  FieldError,
  Noop,
  RefCallBack,
  UseFormRegister,
} from "react-hook-form";
import styled from "styled-components";
import { FormSchemaType } from "../schema";

export const FormElementWrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.133);
  border-radius: 0.72rem;
  padding: 0.5rem 1rem;
  transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  max-width: 500px;

  &:focus-within {
    background-color: rgba(243, 73, 158, 0.11);
    border-color: rgb(255, 7, 143);
  }
`;

export const FormElementContainer = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
`;

const StyledFormInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;

  &::placeholder {
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const FormInputLabel = styled.p`
  font-size: 0.8rem;
`;

export const StyledErrorMessage = styled.p`
  color: red;
  font-size: 0.8124rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

interface FormInputProps {
  type?: string;
  onChange: (event: React.ChangeEvent) => void;
  onBlur?: Noop;
  value: string | number;
  defaultValue?: string;
  placeholder?: string;
  label?: string;
  name: string;
  error?: FieldError;
}

const FormInput = forwardRef((props: FormInputProps, ref: any) => {
  const {
    type,
    defaultValue,
    placeholder,
    label,
    name: inputName,
    error,
    onChange,
    onBlur,
    value,
  } = props;

  return (
    <FormElementWrapper>
      {label ? <FormInputLabel>{label}</FormInputLabel> : null}
      <FormElementContainer>
        <StyledFormInput
          name={inputName}
          type={type}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          defaultValue={defaultValue}
          placeholder={placeholder}
          ref={ref}
        />
      </FormElementContainer>
      {error ? <StyledErrorMessage>{error?.message}</StyledErrorMessage> : null}
    </FormElementWrapper>
  );
});

export default FormInput;
