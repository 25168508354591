import React, { RefObject, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  SelectCurrencyInputContainer,
  SelectCurrencyListContainer,
  SelectCurrencyWrapper,
  StyledDivider,
  StyledModal,
  StyledModalHeader,
  StyledInput,
  StyledCloseIcon,
  StyledListGroup,
  StyledListGroupItem,
} from "./styles/selectCurrencyStyles";
import { getTokenIcon } from "./CurrencyInput";
import useChainId from "hooks/useChainId";
import { Token } from "@rosehub-tech/sdk";
import { tokens } from "utils/tokens";
import { useNativeCurrency } from "views/Liquidity/hooks/useCurrencyBalances";

interface SelectCurrencyModalProps {
  show: boolean;
  handleClose: () => void;
  onSelectToken?: (token: Token) => void;
  addNative?: boolean;
}

export default function SelectCurrencyModal({
  show,
  handleClose,
  onSelectToken,
  addNative,
}: SelectCurrencyModalProps) {
  const inputRef = useRef<HTMLInputElement>();
  const [searchTokenInput, setSearchTokenInput] = useState("");
  const chainId = useChainId();
  const native = useNativeCurrency();

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {};
  const handleSelectItem = (token: Token) => {
    !!onSelectToken && onSelectToken(token);
    handleClose();
  };

  const allowedTokens = useMemo(() => {
    let tokenList = tokens();
    if (addNative) {
      return { native, ...tokenList };
    }
    return tokenList;
  }, [addNative, native]);

  return (
    <StyledModal show={show} onHide={handleClose}>
      <StyledModalHeader>
        <Modal.Title>Select a token</Modal.Title>
        <StyledCloseIcon onClick={handleClose} />
      </StyledModalHeader>
      <Modal.Body>
        <SelectCurrencyWrapper>
          <SelectCurrencyInputContainer>
            <StyledInput
              type="text"
              placeholder={"Search name or paste address"}
              value={searchTokenInput}
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={(e) => setSearchTokenInput(e.target.value)}
              onKeyDown={handleEnter}
            />
          </SelectCurrencyInputContainer>
          <StyledDivider />
          <SelectCurrencyListContainer>
            <StyledListGroup>
              {!!allowedTokens &&
                Object.keys(allowedTokens)?.map(
                  (key: string, index: number) => {
                    const token = allowedTokens[key];
                    return (
                      <StyledListGroupItem
                        key={`${token.address}-${index}`}
                        onClick={() => handleSelectItem(token)}
                      >
                        <div className="tokenIcon">
                          {getTokenIcon(token, chainId)}
                        </div>
                        <div>
                          <p>{token.symbol}</p>
                          <p>{token.name}</p>
                        </div>
                      </StyledListGroupItem>
                    );
                  }
                )}
            </StyledListGroup>
          </SelectCurrencyListContainer>
        </SelectCurrencyWrapper>
      </Modal.Body>
    </StyledModal>
  );
}
