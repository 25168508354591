import styled, { keyframes } from "styled-components";

const Pulse = keyframes`
    0%   {background-size: 100% 100%;}
    25%  {background-size: 300% 300%;}
    50%  {background-size: 200% 200%;}
    75%  {background-size: 400% 350%;}
    100% {background-size: 100% 100%;}
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  margin-right: 8px;

  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 4px solid #e100ff8d;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const Inset = styled.div`
  width: calc(100% - 0.2rem);
  height: calc(100% - 0.2rem);
  position: absolute;
  border-radius: 5rem;
  top: 0.1rem;
  left: 0.1rem;
`;

interface ButtonProps {
  fullWidth: boolean;
  margin: number | string;
  bg: string;
  width: number;
}

const Button: any = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0.75rem 1.5rem;
  border-radius: 5rem;
  height: 40px;
  width: ${({ fullWidth, width }: { fullWidth: boolean; width: number }) =>
    width > 0 && !fullWidth ? `${width}px` : fullWidth ? "100%" : "160px"};
  display: flex !important;
  justify-content: center !important;
  position: relative;
  background: ${({ bg }: any) => bg};
  overflow: hidden;
  transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1);
  display: flex;
  justify-content: ${({ fullWidth }: ButtonProps) =>
    fullWidth ? "center" : "space-between"};
  align-items: center;
  margin: ${({ margin }: ButtonProps) => (!!margin ? margin : "0.5rem")};

  span {
    z-index: 10;
    font-family: "Nasalization";
    font-size: 0.8125rem;
  }

  &::before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ disabled }: any) =>
      disabled
        ? "transparent"
        : `radial-gradient(circle at center, #e100ff8d, transparent 40%),
    radial-gradient(circle at top left, #ff9900, transparent 12%),
    radial-gradient(circle at top right, #ff0073, transparent 15%),
    radial-gradient(circle at bottom right, #ffbb00, transparent 30%),
    radial-gradient(circle at bottom left, #ff0073, transparent 25%),
    linear-gradient(96deg, #e7175d 0%, #f81f4e 100%)`};
    transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1);
    animation: 1400ms ease 0s infinite normal none running
      ${({ animate }: any) => animate && Pulse};
    background-repeat: no-repeat;
  }

  &::after {
    opacity: 0;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ disabled }: any) =>
      disabled
        ? "transparent"
        : `radial-gradient(circle at top right, #e100ff8d, transparent 30%),
    radial-gradient(circle at top right, #ffbb00, transparent 60%),
    radial-gradient(circle at left center, #ff9900, transparent 30%),
    radial-gradient(circle at top left, #ff0073, transparent 50%),
    radial-gradient(circle at bottom right, #e100ff8d, transparent 30%),
    radial-gradient(circle at bottom right, #ff9900, transparent 80%),
    radial-gradient(circle at bottom left, #ff0073, transparent 50%),
    linear-gradient(180deg, #e7175d 0%, #f31847 100%);
  transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1)`};
    animation: 820ms ease 0s infinite normal none running
      ${({ animate, animateOnHover }: any) =>
        (animate || animateOnHover) && Pulse};
    background-repeat: no-repeat;
  }
`;

interface GradientButtonProps {
  label: string | React.ReactElement;
  bg?: string;
  stroked?: boolean;
  color?: string;
  onClick?: Function;
  animate?: boolean;
  animateOnHover?: boolean;
  style?: Object;
  disabled?: boolean;
  loading?: boolean;
  type?: string;
  formId?: string;
  fullWidth?: boolean;
  margin?: number | string;
  disabledText?: string;
  width?: number;
  disabledWidth?: number;
}

export default function GradientButton({
  label,
  bg,
  stroked = false,
  onClick,
  animate = false,
  animateOnHover = false,
  style,
  type,
  formId,
  fullWidth,
  loading,
  disabled,
  margin,
  disabledText,
  width,
  disabledWidth,
}: GradientButtonProps) {
  if (disabled) {
    return (
      <Button
        disabled
        fullWidth={fullWidth}
        width={disabledWidth > 0 ? disabledWidth : 250}
      >
        <span>{disabledText}</span>
      </Button>
    );
  }

  return (
    <Button
      stroked={stroked}
      animate={animate}
      animateOnHover={animateOnHover}
      onClick={onClick}
      bg={bg}
      style={style}
      type={type}
      formId={formId}
      disabled={loading}
      fullWidth={fullWidth}
      margin={margin}
      width={width}
    >
      {stroked && <Inset />}
      {loading ? (
        <>
          <Spinner /> <span>Pending</span>
        </>
      ) : (
        <span> {label}</span>
      )}
    </Button>
  );
}

const DarkButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.75rem 1.75rem;
  border-radius: 5rem;
  height: 40px;
  width: 126px;
  display: grid;
  place-content: center;
  position: relative;
  overflow: hidden;
  transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;

  div {
    background-color: black;
    width: calc(100% - 0.2rem);
    height: calc(100% - 0.2rem);
    position: absolute;
    border-radius: 5rem;
    top: 0.1rem;
    left: 0.1rem;
  }

  label {
    color: rgb(255, 0, 115);
    font-weight: 500;
    z-index: 1;
  }

  &:before {
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
          circle at center center,
          rgba(225, 0, 255, 0.553),
          transparent 40%
        )
        no-repeat,
      radial-gradient(circle at left top, rgb(255, 153, 0), transparent 12%),
      radial-gradient(circle at right top, rgb(255, 0, 115), transparent 15%),
      radial-gradient(circle at right bottom, rgb(255, 187, 0), transparent 30%),
      radial-gradient(circle at left bottom, rgb(255, 0, 115), transparent 25%),
      linear-gradient(96deg, rgb(231, 23, 93) 0%, rgb(248, 31, 78) 100%);
    transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    animation: 1400ms ease 0s infinite normal none running none;
    background-repeat: no-repeat;
  }

  &:after {
    opacity: 0;
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
          circle at right top,
          rgba(225, 0, 255, 0.553),
          transparent 30%
        )
        no-repeat,
      radial-gradient(circle at right top, rgb(255, 187, 0), transparent 60%),
      radial-gradient(circle at left center, rgb(255, 153, 0), transparent 30%),
      radial-gradient(circle at left top, rgb(255, 0, 115), transparent 50%),
      radial-gradient(
        circle at right bottom,
        rgba(225, 0, 255, 0.553),
        transparent 30%
      ),
      radial-gradient(circle at right bottom, rgb(255, 153, 0), transparent 80%),
      radial-gradient(circle at left bottom, rgb(255, 0, 115), transparent 50%),
      linear-gradient(rgb(231, 23, 93) 0%, rgb(243, 24, 71) 100%);
    transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    animation: 820ms ease 0s infinite normal none running iALYRg;
    background-repeat: no-repeat;
  }
`;

interface GradientBtnDarkProps {
  onClick?: Function;
  label: string;
}

export const GradientBtnDark = ({
  onClick,

  label,
}: GradientBtnDarkProps) => {
  return (
    <DarkButton onClick={() => !!onClick && onClick()}>
      <div />
      <label>{label}</label>
    </DarkButton>
  );
};
