import {
  launchpad,
  SupportedChainId,
  tokenAddresses,
} from "../connectors/chains";
// import { JsonRpcSigner } from "@ethersproject/providers";
import { Contract, ethers, Signer } from "ethers";
import { range } from "lodash";

const ERC20 = require("abi/@openzeppelin/contracts/token/ERC20/ERC20.sol/ERC20.json");
const STAKEPOOL = require("abi/contracts/launchpad/RosePadStakeMaster.sol/RosePadStakeMaster.json");
const NFT = require("abi/contracts/mocks/MockNFT.sol/MockNFT.json");

export async function getNFTBaseURI(
  chainId: SupportedChainId,
  signer: Signer | undefined,
  id: number
) {
  if (!signer) return;
  const nftContract = new Contract(
    tokenAddresses.get(chainId).ROSE_APES_NFT,
    NFT,
    signer
  );
  console.log("nftContract", nftContract);
  const baseURI = await nftContract.baseURI();

  return baseURI;
}

export async function getStakingUser(
  stakingContract: Contract,
  accountAddress: string
) {
  const user = await stakingContract.getUserInfo(accountAddress);
  return user;
}

export async function getTotalRPADStaked(stakingContract: Contract) {
  return await stakingContract.totalRhubAmount();
}

export function getStakingContract(signer: Signer, chainId: SupportedChainId) {
  // console.log("stakingContract", chainId);
  return new Contract(launchpad.get(chainId)?.STAKE_MASTER, STAKEPOOL, signer);
}

export async function approveStakeRPADLP(
  spenderAddress: string,
  tokenAddress: string,
  signer: Signer
) {
  const maxAmount = ethers.constants.MaxUint256;
  const ercToken = new Contract(tokenAddress, ERC20, signer);
  const result = await ercToken.approve(spenderAddress, maxAmount);
  return result;
}

export async function approveStakeAmount(
  spenderAddress: string,
  signer: Signer,
  chainId: SupportedChainId
) {
  const maxAmount = ethers.constants.MaxUint256;
  const ercToken = new Contract(
    tokenAddresses.get(chainId).RHUB,
    ERC20,
    signer
  );
  const result = await ercToken.approve(spenderAddress, maxAmount);
  return result;
}

export async function approveAllNft(signer: Signer, chainId: SupportedChainId) {
  const nftContract = new Contract(
    tokenAddresses.get(chainId).ROSE_APES_NFT,
    NFT,
    signer
  );
  return await nftContract.setApprovalForAll(
    launchpad.get(chainId).STAKE_MASTER,
    true
  );
}

export async function confirmStaking(
  signer: Signer,
  rpadAmount: number,
  lpTokenAmount: number,
  nftIds: number[],
  daysLocked: number,
  chainId: SupportedChainId
) {
  const stakingContract = getStakingContract(signer, chainId);
  const amountIn = ethers.utils.parseUnits(rpadAmount.toString(), 18);
  const rpadLPAmountIn = ethers.utils.parseUnits(lpTokenAmount.toString(), 18);
  const minLockDuration = await stakingContract.MIN_LOCK_DURATION();

  return await stakingContract.deposit(
    amountIn,
    rpadLPAmountIn,
    nftIds,
    minLockDuration * daysLocked
  );
}

export async function withdrawDeposits(
  signer: Signer,
  chainId: SupportedChainId
) {
  const stakingContract = getStakingContract(signer, chainId);
  return await stakingContract.withdraw();
}

export async function withdrawAllNfts(
  signer: Signer,
  chainId: SupportedChainId
) {
  const stakingContract = getStakingContract(signer, chainId);
  return await stakingContract.withdrawAllNFTs();
}

export async function widthraw(
  signer: Signer,
  accountAddress: string,
  chainId: SupportedChainId
) {
  const stakingContract = getStakingContract(signer, chainId);
  return await stakingContract.withdraw();
}

export async function widthrawRewards(
  signer: Signer,
  chainId: SupportedChainId
) {
  const stakingContract = getStakingContract(signer, chainId);
  return await stakingContract.harvest();
}

export async function getNftList(
  accountAddress: string,
  signer: Signer,
  chainId: SupportedChainId
) {
  const nftContract = new Contract(
    tokenAddresses.get(chainId).ROSE_APES_NFT,
    NFT,
    signer
  );
  const nftBalance = await nftContract.balanceOf(accountAddress);
  const balance = Number(nftBalance.toString());
  const nftTokens = range(balance).map(async (tokenIndex) => {
    return await nftContract.tokenOfOwnerByIndex(accountAddress, tokenIndex);
  });
  const nfts = await Promise.all(nftTokens).then((results) => [...results]);
  return nfts;
}
