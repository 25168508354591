import { ethers } from "ethers";
import { useStakeMasterAddress } from "hooks/contracts/useContractAddresses";
import { useStakeMasterInterface } from "hooks/contracts/useInterfaces";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "store/zustand";
import { useAccount, useSigner } from "wagmi";
import {
  useUSDTRhubAmount,
  useUSDTRoseAmount,
} from "views/Liquidity/hooks/useUSDTPrice";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "services/firebase";
import { STAKING_TVL_KEY } from "config/exchange";
import { useStakingLiquidityValue } from "views/Liquidity/hooks/useFarmLiquidityValue";

const today = moment();

export default function useUserStaked() {
  const { address, isConnected, isDisconnected } = useAccount();
  const { data: signer } = useSigner();
  const stakeMasterInterface = useStakeMasterInterface();
  const [
    updateStakedNFTs,
    updateStakedRPAD,
    updateStakedRPADLP,
    updateAPY,
    updateLockTime,
    updateReward,
    updateUser,
    updateTotalRPADStaked,
    updateTotalRPADLPStaked,
    resetStakedValues,
    updatePoints,
  ] = useStore((state) => [
    state.updateStakedNFTs,
    state.updateStakedRPAD,
    state.updateStakedRPADLP,
    state.updateAPY,
    state.updateLockTime,
    state.updateReward,
    state.updateUser,
    state.updateTotalRPADStaked,
    state.updateTotalRPADLPStaked,
    state.resetStakedValues,
    state.updatePoints,
  ]);
  const contractAddress = useStakeMasterAddress();
  const rhubUSD = useUSDTRhubAmount(1);
  const roseUSD = useUSDTRoseAmount(1);

  const { data, refetch } = useQuery(
    ["useUserStaked", contractAddress],
    async () => {
      const stakingContract = new ethers.Contract(
        contractAddress,
        stakeMasterInterface,
        signer
      );

      const results = await Promise.all([
        stakingContract.getUserInfo(address),
        stakingContract.pendingRewards(address),
        stakingContract.apyBasisPoints(),
        stakingContract.totalRhubAmount(),
        stakingContract.totalLpTokenAmount(),
        stakingContract.lpToken(),
      ]);
      return results;
    },
    {
      enabled: !!signer,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isDisconnected) {
      resetStakedValues();
    }
  }, [isDisconnected, resetStakedValues]);

  const handleUpdateStakingRHUBTVL = useCallback(
    async (data) => {
      await updateDoc(doc(db, STAKING_TVL_KEY, contractAddress), data);
    },
    [contractAddress]
  );

  const liquidity = useStakingLiquidityValue(
    data?.length > 0 ? data[5] : undefined
  );

  useEffect(() => {
    if (!!data && data?.length > 0 && isConnected) {
      const userInfo = data[0];
      const rhubAmount = userInfo?.rpadAmount || userInfo[0];
      const rhubLPAmount = userInfo?.lpTokenAmount || userInfo[1];
      const nftIds = userInfo?.nftIds || userInfo[2];
      const lockEndTime = userInfo?.lockEndTime || userInfo[7];
      const xRHUBPoints = userInfo?.points || userInfo[9];
      const rpadAmount = !!rhubAmount
        ? ethers.utils.formatEther(rhubAmount)
        : undefined;
      const rpadLPAmount = !!rhubLPAmount
        ? ethers.utils.formatEther(rhubLPAmount)
        : undefined;
      const stakedNfts =
        nftIds?.length > 0 ? nftIds.map((id: number) => id.toString()) : [];
      const endTime = !!lockEndTime
        ? moment.unix(lockEndTime.toNumber())
        : undefined;
      const points = !!xRHUBPoints ? ethers.utils.formatEther(xRHUBPoints) : 0;

      const minLockout = !!endTime ? moment.duration(endTime?.diff(today)) : 0;
      updatePoints(Number(points));
      updateUser(userInfo);
      updateStakedRPAD(Number(rpadAmount));
      updateStakedRPADLP(Number(rpadLPAmount));
      updateStakedNFTs(stakedNfts);
      if (!!minLockout) {
        updateLockTime(Math.ceil(minLockout.asDays()));
      }

      const pendingRewards = data[1];
      const reward = !!pendingRewards
        ? ethers.utils.formatEther(pendingRewards)
        : undefined;
      if (!!reward) {
        updateReward(Number(reward));
      }

      const apyBasisPoints = data[2];
      const apy = apyBasisPoints?.toNumber();
      const apyPercent = Number(apy) / 100;
      updateAPY(apyPercent);

      const totalRPADStaked = data[3];
      const totalRPADStakedFormatted = !!totalRPADStaked
        ? ethers.utils.formatEther(totalRPADStaked)
        : undefined;
      if (!!totalRPADStakedFormatted) {
        updateTotalRPADStaked(Number(totalRPADStakedFormatted));
      }

      const totalRPADLPStaked = data[4];
      const totalRPADLPStakedFormatted = !!totalRPADLPStaked
        ? ethers.utils.formatEther(totalRPADLPStaked)
        : undefined;
      if (!!totalRPADLPStakedFormatted) {
        updateTotalRPADLPStaked(Number(totalRPADLPStakedFormatted));
      }
      if (!!totalRPADLPStakedFormatted) {
        const rhubInUSDT = Number(totalRPADStakedFormatted) * Number(rhubUSD);
        const rhubLPInUSD =
          Number(totalRPADLPStakedFormatted) *
          (Number(liquidity.reserve1) / Number(totalRPADLPStakedFormatted)) *
          roseUSD *
          2;
        handleUpdateStakingRHUBTVL({
          rhub: rhubInUSDT,
          rhubLP: rhubLPInUSD,
        });
      }
    }
  }, [
    handleUpdateStakingRHUBTVL,
    rhubUSD,
    roseUSD,
    data,
    updateAPY,
    updateLockTime,
    updateReward,
    updateStakedNFTs,
    updateStakedRPAD,
    updateStakedRPADLP,
    updateTotalRPADLPStaked,
    updateTotalRPADStaked,
    updateUser,
    isConnected,
    updatePoints,
    liquidity?.reserve1,
  ]);

  return refetch;
}
