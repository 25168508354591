import { Interface } from "ethers/lib/utils";

const STAKEVAULTFACTORY = require("abi/contracts/launchpad/RosePadStakeVaultFactory.sol/RosePadStakeVaultFactory.json");
const STAKEVAULT = require("abi/contracts/launchpad/RosePadStakeVault.sol/RosePadStakeVault.json");
const MASTERCHEF = require("abi/contracts/launchpad/RosePadMasterChef.sol/RoseHubMasterChef.json");
const STAKEMASTER = require("abi/contracts/launchpad/RosePadStakeMaster.sol/RosePadStakeMaster.json");
const NFT = require("abi/contracts/mocks/MockNFT.sol/MockNFT.json");
const ROUTER = require("abi/contracts/swap/RosePadSwapRouter.sol/RosePadSwapRouter.json");
const SWAPFACTORY = require("abi/contracts/swap/RosePadSwapFactory.sol/RosePadSwapFactory.json");
const SWAPPAIR = require("abi/contracts/swap/RosePadSwapPair.sol/RosePadSwapPair.json");
const ERC20 = require("abi/@openzeppelin/contracts/token/ERC20/ERC20.sol/ERC20.json");
const MULTICALL = require("abi/@openzeppelin/contracts/utils/Multicall.sol/Multicall.json");
const NFT_STAKING_POINTS = require("abi/contracts/launchpad/RosePadNFTStakingPoints.sol/RosePadNFTStakingPoints.json");
const IWROSE = require("abi/contracts/interfaces/IWROSE.sol/IWROSE.json");
const REWARD_LOCK = require("abi/contracts/utils/RewardLock.sol/RewardLock.json");
const NFTRARITYDICT = require("abi/contracts/utils/RosePadNFTRarityDict.sol/RosePadNFTRarityDict.json");

export function useRewardLockInterface() {
  return new Interface(REWARD_LOCK);
}

export function useNFTStakingPointsInterface() {
  return new Interface(NFT_STAKING_POINTS);
}

export function useMulticallInterface() {
  return new Interface(MULTICALL);
}

export function useERC20Interface() {
  return new Interface(ERC20);
}

export function useStakeVaultFactoryInterface() {
  return new Interface(STAKEVAULTFACTORY);
}

export function useSwapFactoryInterface() {
  return new Interface(SWAPFACTORY);
}

export function useStakeVaultInterface() {
  return new Interface(STAKEVAULT);
}

export function useMasterChefInterface() {
  return new Interface(MASTERCHEF);
}

export function useStakeMasterInterface() {
  return new Interface(STAKEMASTER);
}

export function useRoseApesNFTInterface() {
  return new Interface(NFT);
}

export function useRouterInterface() {
  return new Interface(ROUTER);
}

export function useSwapPairInterface() {
  return new Interface(SWAPPAIR);
}

export function useWROSEInterface() {
  return new Interface(IWROSE);
}

export function useNFTRarityDictInterface() {
  return new Interface(NFTRARITYDICT);
}
