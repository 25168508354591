import { Currency } from "@rosehub-tech/sdk";
import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";
import { useRouterInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import useSigner from "hooks/useSigner";
import { useState } from "react";
import { useStore } from "store/zustand";
import { wrappedCurrency } from "types/WrappedCurrency";
import { swapTokens } from "utils/ethereumFunctions";
import { useNativeCurrency } from "views/Liquidity/hooks/useCurrencyBalances";
import { useAccount, useProvider } from "wagmi";

export default function useConfirmSwap(
  currencyA: Currency,
  currencyB: Currency,
  tokenAValue: string,
  tokenBValue: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const { address: account } = useAccount();
  const chainId = useChainId();
  const signer = useSigner();
  const provider = useProvider();
  const routerInterface = useRouterInterface();
  const routerAddress = useRouterAddress();
  const [updateToast, updateRecentSwapTx] = useStore((state) => [
    state.updateToast,
    state.updateRecentSwapTx,
  ]);
  const native = useNativeCurrency();
  const tokenA = wrappedCurrency(currencyA, chainId);
  const tokenB = wrappedCurrency(currencyB, chainId);

  const onConfirmSwap = async (clearOnSuccess?: () => void) => {
    if (!signer || !provider) return;
    setIsLoading(true);
    try {
      const routerContract = new ethers.Contract(
        routerAddress,
        routerInterface,
        signer
      );

      const isCurrencyANative = currencyA.equals(native);
      const isCurrencyBNative = currencyB.equals(native);
      await swapTokens(
        [tokenA?.address, tokenB?.address],
        isCurrencyANative,
        isCurrencyBNative,
        tokenAValue,
        routerContract,
        account as string,
        signer
      )
        .then((response) => {
          return response.hash;
        })
        .then(async (hash) => {
          return await provider.waitForTransaction(hash, 2);
        })
        .then((receipt) => {
          const successMessage = `Successfully swap ${tokenAValue} ${currencyA.symbol} for ${tokenBValue} ${currencyB.symbol}`;
          const txUrl = `${blockExplorers.get(chainId)}/tx/${
            receipt.transactionHash
          }`;
          updateToast({
            message: successMessage,
            url: txUrl,
            type: "success",
          });
          updateRecentSwapTx(txUrl);
        });
      !!clearOnSuccess && clearOnSuccess();
    } catch (error: any) {
      console.log("handleSwap error", error);
      updateToast({
        message: `Transaction Failed ${error.message}`,
        url: "",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return { onConfirmSwap, isLoading };
}
