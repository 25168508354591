import useChainId from "hooks/useChainId";
import { ChainId, Pair, Token } from "@rosehub-tech/sdk";
import { useMemo } from "react";
import { useStore } from "store/zustand";
import { deserializeToken } from "utils/serialized";
import farms from "views/Farms/data/farms";
import { PINNED_PAIRS } from "config/exchange";

/**
 * Returns all the pairs of tokens that are tracked by the user for the current chain ID.
 */
export function useTrackedTokenPairs(): [Token, Token][] {
  const chainId = useChainId();

  const pinnedPairs = useMemo(
    () => (chainId ? PINNED_PAIRS[chainId] ?? [] : []),
    [chainId]
  );

  const farmPairs: [Token, Token][] = useMemo(
    () =>
      chainId === ChainId.EMERALD_MAINNET
        ? farms.map((farm) => [
            deserializeToken(farm.quoteToken),
            deserializeToken(farm.token),
          ])
        : [],
    [chainId]
  );

  // pairs saved by users
  const savedSerializedPairs = useStore((state) => state.pairs);

  const userPairs: [Token, Token][] = useMemo(() => {
    if (!chainId || !savedSerializedPairs) return [];
    const forChain = savedSerializedPairs[chainId];
    if (!forChain) return [];

    return Object.keys(forChain).map((pairId) => {
      return [
        deserializeToken(forChain[pairId].token0),
        deserializeToken(forChain[pairId].token1),
      ];
    });
  }, [savedSerializedPairs, chainId]);

  const combinedList = useMemo(
    () => userPairs.concat(pinnedPairs).concat(farmPairs),
    [userPairs, pinnedPairs, farmPairs]
  );

  return useMemo(() => {
    // dedupes pairs of tokens in the combined list
    const keyed = combinedList.reduce<{ [key: string]: [Token, Token] }>(
      (memo, [tokenA, tokenB]) => {
        const sorted = tokenA.sortsBefore(tokenB);
        const key = sorted
          ? `${tokenA.address}:${tokenB.address}`
          : `${tokenB.address}:${tokenA.address}`;
        if (memo[key]) return memo;
        memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA];
        return memo;
      },
      {}
    );

    return Object.keys(keyed).map((key) => keyed[key]);
  }, [combinedList]);
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toLiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  return new Token(
    tokenA.chainId,
    Pair.getAddress(tokenA, tokenB),
    18,
    "RHUB-LP",
    "RoseHub LPs"
  );
}
