import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { CgClose } from "react-icons/cg";
import { useEffect, useState } from "react";
import GradientButton from "components/GradientBtn";
import GradientBtnAlt from "components/GradientBtnAlt";
import isEmpty from "lodash/isEmpty";
import {
  InputWrapper,
  InputLabelContainer,
  InputLabel,
  InputContainer,
} from "views/Home/CalculatorForm/style";
import CalculatorInput from "views/Home/CalculatorForm/CalculatorInput";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/ButtonGroup";
import Slider from "rc-slider";

import "rc-slider/assets/index.css";

export const StyledCloseIcon = styled(CgClose)`
  opacity: 0.64;

  &:hover {
    cursor: pointer;
  }
`;
export const StyledModal = styled(Modal)`
  width: 100%;

  .modal-content {
    background: linear-gradient(
      180deg,
      rgba(28, 28, 28, 0.2) 0%,
      rgba(2, 2, 2, 0.2) 100%,
      rgba(2, 2, 2, 0.2) 100%
    );
    box-shadow: inset 0px 4px 18px rgba(255, 0, 153, 0.4);
    backdrop-filter: blur(80px);
    transform-style: preserve-3d;
    min-height: 250px;

    .modal-title.h4,
    .modal-body p {
      font-family: "Nasalization";
    }

    .modal-header,
    .modal-footer {
      border-bottom: none;
      border-top: none;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
    }
  }
`;

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid rgba(243, 8, 137, 0.4);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;

  .modal-title.h4 {
    font-size: 1.15rem !important;
    color: rgb(255, 0, 137) !important;
  }
`;
export const SelectCurrencyWrapper = styled.div``;

export const SelectCurrencyHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #f3088939;
`;

export const SelectCurrencyInputContainer = styled.div``;

export const SelectCurrencyListContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledDivider = styled.div``;

export const StyledInput = styled.input`
  background: transparent;
  width: 100%;
  position: relative;
  outline: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: textfield;
  background: transparent;
  box-shadow: none;
  text-align: left;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  border: 1px solid #382a32;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;

  &::placeholder {
    color: gray;
  }

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  & [type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 1.7rem;

  button {
    width: 50%;
  }
`;

const ContentTitle = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 24px;
  margin-left: 1rem;
`;

const Content = styled.div`
  padding: 1.7rem;
  border: 1px solid rgba(243, 8, 137, 0.224);
  border-radius: 8px;
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    color: rgb(255, 0, 137) !important;
    font-weight: 700;
  }
`;

const PercentButtonGroup = styled(ButtonGroup)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const PercentButton = styled(Button)`
  color: #fff;
  background-color: rgb(53, 53, 71);
  padding: 0 1rem;
  height: 32px;
  border-radius: 1rem;
  font-weight: 600;
  outline: none;
  border: 0px;
  box-shadow: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: rgba(53, 53, 71, 0.4);
  }
`;

export default function UnstakeModal({
  handleClose,
  show,
  onConfirmUnstake,
  onChangeAmount,
  loading,
  stakedAmount,
}: {
  handleClose: () => void;
  onChangeAmount: (amount: number) => void;
  onConfirmUnstake: (amount: number) => void;
  show: boolean;
  loading: boolean;
  stakedAmount: string;
}) {
  const [inputValue, setInputValue] = useState("");
  const [unstakePercentage, setUnstakePercentage] = useState(0);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    onChangeAmount(Number(value));
  };

  const handleConfirm = () => {
    if (!!inputValue && !isNaN(Number(inputValue))) {
      onConfirmUnstake(Number(inputValue));
      setInputValue("");
    }
  };

  let error: string | undefined;
  if (!inputValue && !loading) {
    error = "Enter an amount";
  }

  if (!isEmpty(inputValue) && Number(inputValue) > Number(stakedAmount)) {
    error = "Insufficient amount";
  }

  useEffect(() => {
    if (Number(stakedAmount) > 0) {
      const amount = Number(stakedAmount) * (Number(unstakePercentage) / 100);
      setInputValue(amount?.toString());
    }
  }, [unstakePercentage, stakedAmount]);

  return (
    <StyledModal show={show} onHide={handleClose}>
      <StyledModalHeader>
        <Modal.Title>Unstake RHUB token</Modal.Title>
        <StyledCloseIcon onClick={handleClose} />
      </StyledModalHeader>
      <Modal.Body>
        <div>
          <ContentTitle>Amount</ContentTitle>
          <Content style={{ marginBottom: 16 }}>
            <div>
              <h2>{unstakePercentage}%</h2>
              <Slider
                value={unstakePercentage}
                onChange={(value) => setUnstakePercentage(value as number)}
                railStyle={{ backgroundColor: "rgb(53, 53, 71)" }}
                trackStyle={{ backgroundColor: "rgb(255, 0, 137)" }}
              />
            </div>
            <div>
              <PercentButtonGroup aria-label="remove-percentage-slider">
                <PercentButton onClick={() => setUnstakePercentage(25)}>
                  25%
                </PercentButton>
                <PercentButton onClick={() => setUnstakePercentage(50)}>
                  50%
                </PercentButton>
                <PercentButton onClick={() => setUnstakePercentage(75)}>
                  75%
                </PercentButton>
                <PercentButton onClick={() => setUnstakePercentage(100)}>
                  Max
                </PercentButton>
              </PercentButtonGroup>
            </div>
          </Content>
        </div>
        <InputWrapper>
          <InputLabelContainer>
            <InputLabel
              onClick={() => !!stakedAmount && setInputValue(stakedAmount)}
            >
              RHUB
            </InputLabel>
            <InputLabel
              onClick={() => !!stakedAmount && setInputValue(stakedAmount)}
            >
              Balance:{" "}
              <a href={"#"} style={{ color: "hotpink" }}>
                {Number(stakedAmount)?.toFixed(6) ?? 0}
              </a>
            </InputLabel>
          </InputLabelContainer>
          <InputContainer>
            <CalculatorInput
              value={inputValue}
              onInputChange={(value: number) =>
                handleInputChange(value.toString())
              }
            />
          </InputContainer>
        </InputWrapper>
        <ModalActions>
          <GradientBtnAlt label="Cancel" onClick={handleClose} />
          <GradientButton
            label="Unstake"
            onClick={handleConfirm}
            loading={loading}
            disabled={!!error}
            disabledText={error}
            disabledWidth={150}
          />
        </ModalActions>
      </Modal.Body>
    </StyledModal>
  );
}
