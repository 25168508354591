import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import { useRouterAddress } from "hooks/contracts/useContractAddresses";
import {
  useERC20Interface,
  useSwapPairInterface,
} from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { isEmpty } from "lodash";
import { useStore } from "store/zustand";
import {
  useAccount,
  useContractWrite,
  useSigner,
  useWaitForTransaction,
  useQuery,
  usePrepareContractWrite,
} from "wagmi";

export default function usePairAllowance(pairAddress: string) {
  const { address } = useAccount();
  const erc20ABI = useERC20Interface();
  const { data: signer } = useSigner();
  const routerAddress = useRouterAddress();

  const { data: tokenAllowance, refetch } = useQuery(
    ["tokenAllowance", pairAddress],
    async () => {
      const contract = new ethers.Contract(pairAddress, erc20ABI, signer);
      const allowance = await contract.allowance(address, routerAddress);
      return allowance;
    },
    {
      enabled: !isEmpty(pairAddress) && !!signer,
    }
  );

  return {
    amount: !!tokenAllowance
      ? ethers.utils.formatEther(tokenAllowance)
      : undefined,
    refetch,
  };
}

export function useApprovePairAllowance(
  pairAddress: string,
  refetch?: () => void
) {
  const chainId = useChainId();
  const updateToast = useStore((state) => state.updateToast);
  const routerAddress = useRouterAddress();
  const pairInterface = useSwapPairInterface();
  const { config } = usePrepareContractWrite({
    addressOrName: pairAddress,
    contractInterface: pairInterface,
    functionName: "approve",
    args: [routerAddress, ethers.constants.MaxUint256],
    onError: (error) => {
      console.log("usePairAllowance error", error);
    },
    enabled: !isEmpty(pairAddress),
  });
  const {
    write,
    data: writeData,
    isLoading: writeLoading,
  } = useContractWrite(config);
  const {
    isError,
    isLoading: transactionLoading,
    error: transactionError,
    isSuccess,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    onSettled: () => {
      console.log("useApprovePairAllowance settled");
      !!refetch && refetch();
    },
    onSuccess: () => {
      updateToast({
        type: "success",
        message: "Contract approved",
        url: `${blockExplorers.get(chainId)}/tx/${writeData?.hash}`,
      });
      !!refetch && refetch();
    },
    enabled: !isEmpty(writeData?.hash),
  });
  return {
    write,
    isLoading: writeLoading || transactionLoading,
    error: isError ? transactionError : undefined,
    isSuccess,
  };
}
