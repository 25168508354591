import { useQuery } from "@tanstack/react-query";
import { ethers } from "ethers";
import { useMasterChefAddress } from "hooks/contracts/useContractAddresses";
import { useMasterChefInterface } from "hooks/contracts/useInterfaces";
import { useMemo } from "react";
import { SerializedFarmConfig } from "types";
import { useAccount, useSigner } from "wagmi";

export default function useLPWithUserBalance(
  farms: SerializedFarmConfig[],
  isEnabled: boolean
): number[] {
  const { data: signer } = useSigner();
  const masterchefInterface = useMasterChefInterface();
  const masterchefAddress = useMasterChefAddress();
  const { address } = useAccount();
  const { data: userLpInfos } = useQuery(
    ["userLpInfos"],
    async () => {
      const contract = new ethers.Contract(
        masterchefAddress,
        masterchefInterface,
        signer
      );
      const userLpInfos = await Promise.all([
        ...farms?.map(async (f) => await contract.userInfo(f.pid, address)),
      ]);
      return userLpInfos;
    },
    {
      enabled: !!signer,
    }
  );

  const lpWithStakedBalance = useMemo(() => {
    if (!isEnabled) return [];
    if (!!userLpInfos && userLpInfos?.length > 0) {
      const mappedLpInfos = userLpInfos.map((userLpInfo, index) => {
        const staked = !!userLpInfo?.amount
          ? ethers.utils.formatEther(userLpInfo.amount)
          : undefined;
        const stakedAmount = Number(staked);

        if (!isNaN(stakedAmount) && stakedAmount > 0) {
          return farms[index].pid;
        }
        return undefined;
      });
      return mappedLpInfos.filter((amount) => Number(amount) >= 0);
    }
  }, [userLpInfos, farms, isEnabled]);

  return lpWithStakedBalance as number[];
}
