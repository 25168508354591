import { ethers } from "ethers";
import {
  useRHUBTokenAddress,
  useStakeVaultFactoryAddress,
} from "hooks/contracts/useContractAddresses";
import { useQuery } from "@tanstack/react-query";
import { erc20ABI, useAccount, useSigner } from "wagmi";
import useGetVaultAddress from "./useGetVaults";

export default function useVaultAllowance(index: number) {
  const { address } = useAccount();
  const vaultFactoryAddress = useStakeVaultFactoryAddress();
  const rpadAddress = useRHUBTokenAddress();
  const { vaultAddress } = useGetVaultAddress(index);
  const { data: signer } = useSigner();
  const { data: vaultAllowance, refetch } = useQuery(
    ["useVaultAllowance", index, vaultAddress, address],
    async () => {
      const vaultContract = new ethers.Contract(rpadAddress, erc20ABI, signer);
      const allowance = await vaultContract.allowance(address, vaultAddress);

      return allowance;
    },
    {
      enabled: !!signer && index > -1,
    }
  );

  const allowanceAmount = !!vaultAllowance
    ? ethers.utils.formatEther(vaultAllowance)
    : undefined;

  return {
    amount: !!allowanceAmount ? allowanceAmount : undefined,
    vaultAddress: vaultFactoryAddress,
    refetch,
  };
}
