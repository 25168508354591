import React from "react";
import { createRoot } from "react-dom/client";
import AOS from "aos";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig, createClient, createStorage } from "wagmi";
import Popups from "providers/Popup";
import store from "store/store";
import App from "./App";
import { chains, provider } from "utils/connectors";
import {
  RainbowKitProvider,
  connectorsForWallets,
  wallet,
  DisclaimerComponent,
  Theme,
} from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./fontface.css";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@rainbow-me/rainbowkit/styles.css";
import "index.css";
import { ChainId } from "@rosehub-tech/sdk";

AOS.init({
  duration: 600,
});
const queryClient = new QueryClient();
const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [wallet.metaMask({ chains })],
  },
]);
const client = createClient({
  connectors,
  autoConnect: true,
  provider: provider,
  storage: createStorage({ storage: window.localStorage }),
});

const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
  <Text>
    By connecting your wallet, you agree to the{" "}
    <Link href="https://rosehub.io/faq">Terms of Service</Link> and acknowledge
    you have read and understand the protocol{" "}
    <Link href="https://rosehub.io/faq">Disclaimer</Link>
  </Text>
);

const customTheme: Theme = {
  blurs: {
    modalOverlay: "blur(1px)",
  },
  colors: {
    accentColor: "",
    accentColorForeground: "",
    actionButtonBorder: "",
    actionButtonBorderMobile: "",
    actionButtonSecondaryBackground: "",
    closeButton: "",
    closeButtonBackground: "",
    connectButtonBackground: "",
    connectButtonBackgroundError: "",
    connectButtonInnerBackground: "",
    connectButtonText: "",
    connectButtonTextError: "",
    connectionIndicator: "",
    error: "",
    generalBorder: "",
    generalBorderDim: "",
    menuItemBackground: "",
    modalBackdrop: "",
    modalBackground: "#000",
    modalBorder: "#000",
    modalText: "",
    modalTextDim: "",
    modalTextSecondary: "",
    profileAction: "",
    profileActionHover: "",
    profileForeground: "",
    selectedOptionBorder: "",
    standby: "",
    downloadBottomCardBackground: "",
    downloadTopCardBackground: "",
  },
  fonts: {
    body: "Jakarta Sans",
  },
  radii: {
    actionButton: "",
    connectButton: "",
    menuButton: "",
    modal: "1rem",
    modalMobile: "1rem",
  },
  shadows: {
    connectButton: "",
    dialog: "",
    profileDetailsAction: "",
    selectedOption: "",
    selectedWallet: "",
    walletLogo: "",
  },
};

function AppWithCallbackAfterRender() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <WagmiConfig client={client}>
          <RainbowKitProvider
            theme={customTheme}
            chains={chains}
            initialChain={ChainId.EMERALD_MAINNET}
            coolMode={true}
            appInfo={{
              appName: "RoseHub",
              disclaimer: Disclaimer,
            }}
            modalSize="compact"
          >
            <Provider store={store}>
              <Popups>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </Popups>
            </Provider>
          </RainbowKitProvider>
        </WagmiConfig>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<AppWithCallbackAfterRender />);
