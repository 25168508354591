import { ChainId, Token } from "@rosehub-tech/sdk";
import { SerializedToken } from "types";
import { WrappedTokenInfo } from "types/WrappedTokenInfo";

export function serializeToken(token: WrappedTokenInfo): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
    projectLink: token.projectLink,
    logoURI: token instanceof WrappedTokenInfo ? token.logoURI : undefined,
  };
}

export function deserializeToken(serializedToken: SerializedToken): Token {
  if (serializedToken?.logoURI) {
    return new WrappedTokenInfo(
      {
        chainId: serializedToken?.chainId || ChainId.EMERALD_MAINNET,
        address: serializedToken?.address,
        decimals: serializedToken?.decimals || 18,
        symbol: serializedToken?.symbol || "",
        name: serializedToken?.name || "",
        logoURI: serializedToken?.logoURI,
      },
      []
    );
  }
  return new Token(
    serializedToken?.chainId || ChainId.EMERALD_MAINNET,
    serializedToken?.address,
    serializedToken?.decimals || 18,
    serializedToken?.symbol || "",
    serializedToken?.name || "",
    serializedToken?.projectLink
  );
}
