import { ChainId } from "@rosehub-tech/sdk";
import { Chain } from "wagmi";

export enum SupportedChainId {
  OASIS_MAINNET = 26863,
  EMERALD_TESTNET = 42261,
}

export const emeraldMainnet: Chain = {
  id: 42262,
  name: "Emerald Paratime Mainnet",
  network: "Oasis Emerald ParaTime Mainnet",
  nativeCurrency: {
    decimals: 18,
    name: "Oasis Network",
    symbol: "ROSE",
  },
  rpcUrls: {
    default: "https://emerald.oasis.dev",
  },
  blockExplorers: {
    default: {
      name: "Oasis Network Explorer",
      url: "https://explorer.emerald.oasis.dev/",
    },
  },
  testnet: false,
};

export const emeraldTestnet: Chain = {
  id: 42261,
  name: "Emerald Paratime Testnet",
  network: "Oasis Emerald ParaTime Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Oasis Network",
    symbol: "ROSE",
  },
  rpcUrls: {
    default: "https://testnet.emerald.oasis.dev/",
  },
  blockExplorers: {
    default: {
      name: "Oasis Network Explorer",
      url: "https://testnet.explorer.emerald.oasis.dev/",
    },
  },
  testnet: true,
};

export const emeraldMainnetChain = {
  chainId: 42262,
  chainName: "Emerald Paratime Mainnet",
  nativeCurrency: {
    decimals: 18,
    name: "Oasis Network",
    symbol: "ROSE",
  },
  rpcUrls: ["https://emerald.oasis.dev"],
  blockExplorerUrls: ["https://explorer.emerald.oasis.dev/"],
};

export const emeraldTestnetChain = {
  chainId: 42261,
  chainName: "Emerald Paratime Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Oasis Network",
    symbol: "ROSE",
  },
  rpcUrls: ["https://testnet.emerald.oasis.dev/"],
  blockExplorerUrls: ["https://testnet.explorer.emerald.oasis.dev/"],
};

export const CID = "QmTymciMsxGjGbG24oVigctR87oDFfZugwKutpP3p5HSGc"; //for getting NFTs thru IPFS
export const MasterChefStartBlock = 1040000;

export enum EmeraldLaunchpadContracts {
  "FEE_TO_SETTER" = "0x026306F1306646caAb41d2e89802E489A12a958b",
  "SWAP_FEE_TO" = "0x026306F1306646caAb41d2e89802E489A12a958b",
  "FACTORY" = "0x69ef1662D9544b007D5B8FF827C86793D8F04E79",
  "ROUTER" = "0x237101787c75DD2e2da8902502b520640D480511",
  "INIT_CODE_HASH" = "0xadb8ced8622f5a41096bc3443794fdcb518f1f9d9aa5b5c98fa1f9bda862638b",
  "STAKE_MASTER" = "0x69e00E80914DBd56c7eb6Ee50Aa2f65dBf755Ba5",
  "STAKE_VAULT_FACTORY" = "0xa2421735Cbd6B06B70ba4429614742F166994a9E",
  "REWARD_CONTAINER" = "0xEED9470F76984C718A1645dDc39F2E6F785932AE",
  "MULTICALL" = "0x6caf99C2A637925710b62105241FFE6CA810A543",
  "MASTERCHEFV1" = "0xA781E553833000975CE103908FC920aa0e46c4B8",
  "MASTERCHEFV2" = "0x3B6E4355a49F177109D0Cdf0505cA902FeC3D87E",
  "NFT_STAKING_POINTS" = "0x699e07625d44e773CD040eEb840c596F737B8c53",
  "TOKEN_LOCK" = "0xa4d14328ACCD55AeBE4B1636aA995674D175266F",
  "REWARD_LOCK" = "0x85E5A58404Ffe50B8d53fb9d5886Ba79C832f930",
}

export enum OasisLaunchpadContracts {
  "FEE_TO_SETTER" = "0x44155Dd0d90085864c072e41E2c55664e5FB55Ad",
  "SWAP_FEE_TO" = "0x509Cb383063076Ff02edE4dDdDEb3708CA615923",
  "FACTORY" = "0x8fA72dE7e5635188F0fbcA122C86D67Dd8429fF1",
  "ROUTER" = "0xf55A500eC4B9bf134AEA2F67084d2b81FAee79A2",
  "INIT_CODE_HASH" = "0xadb8ced8622f5a41096bc3443794fdcb518f1f9d9aa5b5c98fa1f9bda862638b",
  "NFT_STAKING_POINTS" = "0x30D2169f64034fe244D4B5a3FB8ce877573EfcEF",
  "STAKE_MASTER" = "0x2bA7CCAc9b17EF6e6A5dd52F1951617d2CFb1F62",
  "STAKE_VAULT_FACTORY" = "0x53182A58C499800a0eEdB3b25bA61bB2Bb3818D1",
  "REWARD_CONTAINER" = "0x6fdA3ca134fcfa5ece103f9f4C45fA0e5A47fe4f",
  "MASTERCHEF" = "",
  "MASTERCHEFV2" = "0x76A6dddDEE87FaD1D49BCeBbD55efB39a832c033",
  "MULTICALL" = "0xa72ed25526445F69EC50929d9C8AF85E8647d93F",
  "MASTERCHEFSTARTBLOCK" = "3136382",
  "TOKEN_LOCK" = "0xE77f5182477FBDDC53655c665Ab1d6291295407D",
  "REWARD_LOCK" = "0xd1C71778c361E3Dc961331D732F64Af1b5f3c0ff",
}

export enum EmeraldTokenAddresses {
  "RHUB" = "0xE0b7eA0f9426a5d22Cb0197c881147f75F893520",
  "RHUB_LP" = "0x44B275e6b00DE9ab196D7c9cD8fF727829DcF4C6",
  "ROSE_APES_NFT" = "0xEA20b923c1b95e5A98f45689c6dcBd1bc13fFFae",
  "WROSE" = "0x966be6f726C6D600Fd199aF3E6396cC4930cFbCC",
  "USDT" = "0x0Cb878e857E59468c2eeEBE722770a26a0a146E1",
}

export enum OasisTokenAddresses {
  "RHUB" = "0x17933E09231306c16A5d6ebc5E55511eCadd00E3",
  "RHUB_LP" = "0xc928bdECD1BEFbA06E036260FbbbFda382A57130",
  "ROSE_APES_NFT" = "0xA011Ad0c02259019B0a8eF1c6f818191a031adA9",
  "WROSE" = "0x21C718C22D52d0F3a789b752D4c2fD5908a8A733",
  "USDT" = "0xdC19A122e268128B5eE20366299fc7b5b199C8e3",
}

export const networks = new Map();
networks.set(ChainId.EMERALD_TESTNET, emeraldTestnetChain);
networks.set(ChainId.EMERALD_MAINNET, emeraldMainnetChain);

export const CHAIN_IDS_TO_NAMES = {
  [ChainId.EMERALD_MAINNET]: "emerald_paratime_mainnet",
  [ChainId.EMERALD_TESTNET]: "emerald_paratime_testnet",
};

export const ALL_SUPPORTED_CHAIN_IDS: ChainId[] = Object.values(ChainId).filter(
  (id) => typeof id === "number"
) as ChainId[];

export const blockExplorers = new Map();
blockExplorers.set(
  ChainId.EMERALD_MAINNET,
  emeraldMainnet.blockExplorers?.default.url
);
blockExplorers.set(
  ChainId.EMERALD_TESTNET,
  emeraldTestnet.blockExplorers?.default.url
);

export const launchpad = new Map();
launchpad.set(ChainId.EMERALD_TESTNET, EmeraldLaunchpadContracts);
launchpad.set(ChainId.EMERALD_MAINNET, OasisLaunchpadContracts);

export const tokenAddresses = new Map();
tokenAddresses.set(ChainId.EMERALD_TESTNET, EmeraldTokenAddresses);
tokenAddresses.set(ChainId.EMERALD_MAINNET, OasisTokenAddresses);
