import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { CgClose } from "react-icons/cg";
import { ListGroup, ListGroupItem } from "react-bootstrap";

export const StyledListGroup = styled(ListGroup)`
  width: 100%;
`;

export const StyledListGroupItem = styled(ListGroupItem)`
  background: transparent;
  color: #fff;
  display: flex;
  align-items: center;

  .tokenIcon {
    margin-right: 0.5rem;
  }

  img {
    width: 2rem;
    height: 2rem;
  }

  p {
    margin-bottom: 0;
    font-size: 1rem;
  }

  p:last-child {
    font-size: 0.7rem;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const StyledCloseIcon = styled(CgClose)`
  opacity: 0.64;

  &:hover {
    cursor: pointer;
  }
`;
export const StyledModal = styled(Modal)`
  width: 100%;

  .modal-content {
    background: linear-gradient(
      180deg,
      rgba(28, 28, 28, 0.2) 0%,
      rgba(2, 2, 2, 0.2) 100%,
      rgba(2, 2, 2, 0.2) 100%
    );
    box-shadow: inset 0px 4px 18px rgba(255, 0, 153, 0.4);
    backdrop-filter: blur(80px);
    transform-style: preserve-3d;
    min-height: 350px;

    .modal-title.h4,
    .modal-body p {
      font-family: "Nasalization";
    }

    .modal-header,
    .modal-footer {
      border-bottom: none;
      border-top: none;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
    }
  }
`;

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid rgba(243, 8, 137, 0.224) !important;
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;

  .modal-title.h4 {
    font-size: 1.15rem !important;
    color: rgb(255, 0, 137) !important;
  }
`;
export const SelectCurrencyWrapper = styled.div``;

export const SelectCurrencyHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #f3088939;
`;

export const SelectCurrencyInputContainer = styled.div``;

export const SelectCurrencyListContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledDivider = styled.div``;

export const StyledInput = styled.input`
  background: transparent;
  width: 100%;
  position: relative;
  outline: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: textfield;
  background: transparent;
  box-shadow: none;
  text-align: left;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  border: 1px solid #382a32;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;

  &::placeholder {
    color: gray;
  }

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  & [type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
