import { Modal } from "react-bootstrap";
import NFTList from "views/NFT";
import styled from "styled-components";
import { StyledCloseIcon } from "views/Swap/styles/selectCurrencyStyles";
import GradientButton from "components/GradientBtn";
import { useStore } from "store/zustand";

import "bootstrap/dist/css/bootstrap.css";

export const StyledModal = styled(Modal)`
  width: 100%;
  min-width: 100%;

  .modal-content {
    background: black;
    z-index: 20;
    transform-style: preserve-3d;

    .modal-title.h4,
    .modal-body p {
      font-family: "Nasalization";
    }

    .modal-header {
      border-bottom: 1px solid rgba(243, 8, 137, 0.4);
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
    }

    .modal-footer {
      border-top: 1px solid rgba(243, 8, 137, 0.4);
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
    }

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 10;
      margin-left: auto;
      margin-right: auto;
      display: block;
      height: 100%;
      background-blend-mode: soft-light, soft-light, normal;
      background: radial-gradient(circle at left, #c97ebc, transparent),
        radial-gradient(circle at bottom, #6531b1, transparent),
        radial-gradient(circle at right, #16aff8, transparent),
        radial-gradient(circle at top, #dc0474, transparent);
      content: "";
      width: 500px;
      filter: blur(80px);
      transform: translateZ(-1px);
    }
  }
`;
interface NFTListModalProps {
  show: boolean;
  onHide: () => void;
  viewOnly?: boolean;
}
export default function NFTListModal({
  show,
  onHide,
  viewOnly,
}: NFTListModalProps) {
  const [resetSelection] = useStore((state) => [state.resetSelection]);
  return (
    <StyledModal show={show} onHide={onHide} fullscreen>
      <Modal.Header>
        <Modal.Title>Rose Ape NFTs</Modal.Title>
        <StyledCloseIcon onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <NFTList view={!!viewOnly} />
      </Modal.Body>
      <Modal.Footer>
        {viewOnly ? (
          <GradientButton onClick={onHide} label="Close" />
        ) : (
          <>
            <GradientButton
              label="Clear Selection"
              onClick={resetSelection}
              width={300}
            />
            <GradientButton
              label="Apply Selection"
              onClick={onHide}
              width={300}
            />
          </>
        )}
      </Modal.Footer>
    </StyledModal>
  );
}
