import { Controller } from "react-hook-form";
import FormInput from "../FormInput";
import FormSelect from "../FormSelect";
import { StepProps } from "../Fundraise";
import { kycStatus } from "../schema";
import { StepContainer } from "./Step1";

export default function Step3({ control }: StepProps) {
  return (
    <StepContainer>
      {" "}
      <Controller
        name="tokenBlockchain"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="Token Blockchain"
            placeholder="Oasis network"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="tokenUseCase"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormInput
            type="text"
            label="Token Use Case"
            placeholder="A decentralized cryptocurrency for a NFT marketplace"
            {...rest}
            {...fieldState}
          />
        )}
      />
      <Controller
        name="kycStatus"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <FormSelect
            options={kycStatus}
            label="KYC Status"
            placeholder="Ready for KYC"
            {...rest}
            {...fieldState}
          />
        )}
      />
    </StepContainer>
  );
}
