import { serializeToken } from "utils/serialized";
import { SerializedToken } from "types";
import { ChainId, Token, WNATIVE } from "@rosehub-tech/sdk";

const { EMERALD_MAINNET, EMERALD_TESTNET } = ChainId;

interface TokenList {
  [symbol: string]: Token;
}

export const defineTokens = <T extends TokenList>(t: T) => t;

export const mainnetTokens = defineTokens({
  rhub: new Token(
    EMERALD_MAINNET,
    "0x17933E09231306c16A5d6ebc5E55511eCadd00E3",
    18,
    "RHUB",
    "RoseHub",
    "https://rosehub.io/"
  ),
  usdt: new Token(
    EMERALD_MAINNET,
    "0xdC19A122e268128B5eE20366299fc7b5b199C8e3",
    6,
    "USDT",
    "Tether USD",
    "https://tether.to/"
  ),
  wrose: WNATIVE[EMERALD_MAINNET],
} as any);

export const testnetTokens = defineTokens({
  rhub: new Token(
    EMERALD_TESTNET,
    "0xE0b7eA0f9426a5d22Cb0197c881147f75F893520",
    18,
    "RHUB",
    "RoseHub",
    "https://rosehub.io/"
  ),
  usdt: new Token(
    EMERALD_TESTNET,
    "0x0Cb878e857E59468c2eeEBE722770a26a0a146E1",
    18,
    "USDT",
    "Tether USD",
    "https://tether.to/"
  ),
  wrose: WNATIVE[EMERALD_TESTNET],
} as any);

export const tokens = () => {
  // const provider = getProvider();
  // const chainId = getNetwork(provider as Web3Provider) || CHAIN_ID;
  // console.log("chainId tokens", chainId);
  // if (!!chainId && Number(chainId) === EMERALD_MAINNET) {
  //   return Object.keys(mainnetTokens).reduce((accum, key) => {
  //     return {
  //       ...accum,
  //       [key]: mainnetTokens[key],
  //     };
  //   }, {} as typeof testnetTokens & typeof mainnetTokens);
  // }
  return mainnetTokens;
};

const unserializedTokens = tokens();

type SerializedTokenList = Record<
  keyof typeof unserializedTokens,
  SerializedToken
>;

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce(
    (accum, key) => {
      return { ...accum, [key]: serializeToken(unserializedTokens[key]) };
    },
    {} as SerializedTokenList
  );

  return serializedTokens;
};

const USDT_MAINNET = new Token(
  EMERALD_MAINNET,
  "0xdC19A122e268128B5eE20366299fc7b5b199C8e3",
  6,
  "USDT",
  "USD Tether",
  "https://tether.to/"
);

const USDT_TESTNET = new Token(
  EMERALD_TESTNET,
  "0x0Cb878e857E59468c2eeEBE722770a26a0a146E1",
  18,
  "USDT",
  "USD Tether",
  "https://tether.to/"
);

//@ts-ignore
export const USDT: Record<ChainId, Token> = {
  [ChainId.EMERALD_MAINNET]: USDT_MAINNET,
  [ChainId.EMERALD_TESTNET]: USDT_TESTNET,
};

const RHUB_MAINNET = new Token(
  EMERALD_MAINNET,
  "0x17933E09231306c16A5d6ebc5E55511eCadd00E3",
  18,
  "RHUB",
  "RoseHub",
  "https://rosehub.io/"
);

const RHUB_TESTNET = new Token(
  EMERALD_TESTNET,
  "0xE0b7eA0f9426a5d22Cb0197c881147f75F893520",
  18,
  "RHUB",
  "RoseHub",
  "https://rosehub.io/"
);

export const RHUB: Record<ChainId, Token> = {
  [ChainId.OASIS_MAINNET]: RHUB_MAINNET,
  [ChainId.EMERALD_MAINNET]: RHUB_MAINNET,
  [ChainId.EMERALD_TESTNET]: RHUB_TESTNET,
};

export default unserializedTokens;
