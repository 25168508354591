import { ethers } from "ethers";
import {
  useNFTStakingAddress,
  useRoseApeTokenAddress,
} from "hooks/contracts/useContractAddresses";
import {
  useNFTRarityDictInterface,
  useNFTStakingPointsInterface,
} from "hooks/contracts/useInterfaces";
import { useMemo, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSigner } from "wagmi";

export default function useGetStakingPoints(nftIds: number[]) {
  const { data: signer } = useSigner();
  const nftContractAddress = useNFTStakingAddress();
  const nftStakingInterface = useNFTStakingPointsInterface();
  const queryResponse = useRef();

  const { data: basisPoints } = useQuery(
    ["useGetStakingPoints", nftContractAddress],
    async () => {
      const nftContract = new ethers.Contract(
        nftContractAddress,
        nftStakingInterface,
        signer
      );
      const result = await nftContract.getPointsByTokenIds(nftIds);
      queryResponse.current = result;
      return result?.basisPts;
    },
    {
      enabled: !!signer && nftIds?.length > 0,
      refetchInterval: !queryResponse?.current ? 5000 : Infinity,
    }
  );

  return useMemo(() => {
    if (basisPoints?.length > 0 && nftIds?.length > 0) {
      const parsedBasisPoints = basisPoints?.map((pt) => pt?.toNumber() / 100);
      const totalBasisPoints = parsedBasisPoints.reduce((a, b) => a + b);
      if (totalBasisPoints > 20) {
        return 20;
      }
      return totalBasisPoints;
    }
    return 0;
  }, [basisPoints, nftIds]);
}

export function useNFTStakingPoints(id: number | undefined) {
  const { data: signer } = useSigner();
  const nftContractAddress = useNFTStakingAddress();
  const nftStakingInterface = useNFTStakingPointsInterface();
  const queryResponse = useRef();

  const { data: basisPoints } = useQuery(
    ["useNFTStakingPoints", id, nftContractAddress],
    async () => {
      const nftContract = new ethers.Contract(
        nftContractAddress,
        nftStakingInterface,
        signer
      );
      const result = await nftContract.getPointsByTokenId(id);
      queryResponse.current = result;
      return result?.basisPts;
    },
    {
      enabled: !!signer && !!id,
      refetchInterval: !queryResponse?.current ? 5000 : Infinity,
    }
  );

  return useMemo(() => {
    if (!!basisPoints) {
      return basisPoints?.toNumber() / 100;
    }
    return 0;
  }, [basisPoints]);
}

export function useGetNFTRarity(id: number) {
  const { data: signer } = useSigner();
  const roseApeAddress = useRoseApeTokenAddress();
  const nftRarityInterface = useNFTRarityDictInterface();

  return useQuery(
    ["useGetNFTRarity", id, roseApeAddress],
    async () => {
      const nftContract = new ethers.Contract(
        roseApeAddress,
        nftRarityInterface,
        signer
      );
      const result = await nftContract.getRarityOf(id);

      console.log("useGetNFTRarity result", result);
      return result;
    },
    {
      enabled: !!signer && !!id,
    }
  );
}
