import { ChainId } from "@rosehub-tech/sdk";
import { getRarity, Rarities } from "components/NFTThumb";
import { INITIAL_ALLOWED_SLIPPAGE } from "config/exchange";
import { tokenAddresses } from "connectors/chains";
import { BigNumber } from "ethers";
import { parseUnits, Result } from "ethers/lib/utils";
import { TypeOptions } from "react-toastify";
import { Field, SerializedToken, SwapField } from "types";
import { GAS_PRICE } from "views/Swap/hooks/useCallGasPrice";
import create, { StateCreator } from "zustand";

interface RoseApeNFTState {
  selectedNFTIds: Array<number>;
  legendaryCount: number;
  updateSelection: (by: number) => void;
  resetSelection: () => void;
}

const createNFTListSlice: StateCreator<RoseApeNFTState> = (set) => ({
  selectedNFTIds: [],
  legendaryCount: 0,
  updateSelection: (by) =>
    set((state) => {
      const idRarity = getRarity(by);
      const isLegendary = idRarity === Rarities.LEGENDARY;
      if (state.selectedNFTIds.includes(by)) {
        return {
          selectedNFTIds: state.selectedNFTIds.filter((id) => id !== by),
          legendaryCount: isLegendary
            ? state.legendaryCount - 1
            : state.legendaryCount,
        };
      }
      return {
        selectedNFTIds: [...state.selectedNFTIds, by],
        legendaryCount: isLegendary
          ? state.legendaryCount + 1
          : state.legendaryCount,
      };
    }),
  resetSelection: () => set(() => ({ selectedNFTIds: [], legendaryCount: 0 })),
});

interface SlippageSlice {
  slippage: number;
  updateSlippage: (by: number) => void;
  resetSlippage: () => void;
}

const createSlippageSlice: StateCreator<SlippageSlice> = (set) => ({
  slippage: INITIAL_ALLOWED_SLIPPAGE,
  updateSlippage: (by: number) => set((state) => ({ slippage: by })),
  resetSlippage: () => set((state) => ({ slippage: INITIAL_ALLOWED_SLIPPAGE })),
});

export type UserData = {
  lockEndTime: BigNumber | undefined;
  lockStartTime: BigNumber | undefined;
  boosterBasisPoints: BigNumber;
  boosterFixedPoints: BigNumber;
  lastNftDepositTime: BigNumber;
  lpTokenAmount: BigNumber;
  nftIds: BigNumber;
  points: BigNumber;
  rewardRealised: BigNumber;
  rpadAmount: BigNumber;
};
interface StakedTokensAndNFTSlice {
  stakedRPAD: number;
  stakedRPADLP: number;
  stakedNFTs: number[];
  totalRPADStaked: number;
  totalRPADLPStaked: number;
  points: number;
  apy: number;
  lockTime: number;
  rewardAmount: number;
  user: Result | undefined;
  updateStakedRPAD: (newValue: number) => void;
  updateStakedRPADLP: (newValue: number) => void;
  updateStakedNFTs: (nftIds: number[]) => void;
  updateAPY: (apy: number) => void;
  updateLockTime: (lockTime: number) => void;
  updateReward: (amount: number) => void;
  updateUser: (user: Result) => void;
  updateTotalRPADStaked: (amount: number) => void;
  updateTotalRPADLPStaked: (amount: number) => void;
  updatePoints: (points: number) => void;
  resetStakedValues: () => void;
}

const createStakedTokensAndNFT: StateCreator<StakedTokensAndNFTSlice> = (
  set
) => ({
  points: 0,
  stakedRPAD: 0,
  stakedRPADLP: 0,
  stakedNFTs: [],
  apy: 0,
  lockTime: 0,
  rewardAmount: 0,
  user: undefined,
  totalRPADLPStaked: 0,
  totalRPADStaked: 0,
  updatePoints: (points: number) => set(() => ({ points })),
  updateTotalRPADLPStaked: (amount) =>
    set((state) => ({ totalRPADLPStaked: amount })),
  updateTotalRPADStaked: (amount) =>
    set((state) => ({ totalRPADStaked: amount })),
  updateStakedRPAD: (newValue: number) => set(() => ({ stakedRPAD: newValue })),
  updateStakedRPADLP: (newValue: number) =>
    set(() => ({ stakedRPADLP: newValue })),
  updateStakedNFTs: (nftIds: number[]) =>
    set(() => ({ stakedNFTs: [...nftIds] })),
  updateAPY: (newAPY: number) => set(() => ({ apy: newAPY })),
  updateLockTime: (newLockTime: number) =>
    set(() => ({ lockTime: newLockTime })),
  updateReward: (newAmount: number) => set(() => ({ rewardAmount: newAmount })),
  updateUser: (newUser: Result) => set(() => ({ user: newUser })),
  resetStakedValues: () =>
    set(() => ({ stakedNFTs: [], stakedRPADLP: 0, stakedRPAD: 0 })),
});

interface WalletBalanceSlice {
  rpad: number;
  updateRPADBalance: (newBalance: number) => void;
  rpadLP: number;
  updateRPADLPBalance: (newBalance: number) => void;
  resetBalance: () => void;
}

const createWalletBalanceSlice: StateCreator<WalletBalanceSlice> = (set) => ({
  rpad: 0,
  rpadLP: 0,
  updateRPADBalance: (balance: number) => set((state) => ({ rpad: balance })),
  updateRPADLPBalance: (balance: number) =>
    set((state) => ({ rpadLP: balance })),
  resetBalance: () => set(() => ({ rpad: 0, rpadLP: 0 })),
});

type ToastData = {
  message: string;
  url: string;
  type: TypeOptions;
};

interface ToastSlice {
  toastData: ToastData;
  updateToast: (data: ToastData) => void;
  resetToast: () => void;
}

const createToastSlice: StateCreator<ToastSlice> = (set) => ({
  toastData: {
    message: "",
    url: "",
    type: "success",
  },
  updateToast: (data: ToastData) => set((state) => ({ toastData: data })),
  resetToast: () =>
    set(() => ({
      toastData: {
        message: "",
        url: "",
        type: "success",
      },
    })),
});

export type PairToken = {
  id: string;
  symbol: string;
  img: string;
};

interface RemoveLiquiditySlice {
  removeLiquidity: boolean;
  updateRemoveLiquidity: (newValue: boolean) => void;
  updateRemoveLiquidityPair: (newPair: PairToken[]) => void;
  pairToRemove: PairToken[];
}

const createRemoveLiquiditySlice: StateCreator<RemoveLiquiditySlice> = (
  set
) => ({
  removeLiquidity: false,
  updateRemoveLiquidity: (newValue: boolean) =>
    set(() => ({ removeLiquidity: newValue })),
  pairToRemove: [],
  updateRemoveLiquidityPair: (newPair: PairToken[]) =>
    set(() => ({ pairToRemove: newPair })),
});

function pairKey(token0Address: string, token1Address: string) {
  return `${token0Address};${token1Address}`;
}

const currentTimestamp = () => new Date().getTime();

export interface SerializedPair {
  token0: SerializedToken;
  token1: SerializedToken;
}

interface AddLiquiditySlice {
  pairs: {
    [chainId: number]: {
      // keyed by token0Address:token1Address
      [key: string]: SerializedPair;
    };
  };
  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };
  timestamp: number;
  addSerializedPair: (serializedPair: SerializedPair) => void;
  addSerializedToken: (token: SerializedToken) => void;
}

const createAddLiquiditySlice: StateCreator<AddLiquiditySlice> = (set) => ({
  pairs: {},
  tokens: {},
  timestamp: currentTimestamp(),
  addSerializedPair: (serializedPair: SerializedPair) =>
    set((state) => {
      if (
        serializedPair.token0.chainId === serializedPair.token1.chainId &&
        serializedPair.token0.address !== serializedPair.token1.address
      ) {
        const { chainId } = serializedPair.token0;
        state.pairs[chainId] = state.pairs[chainId] || {};
        state.pairs[chainId][
          pairKey(serializedPair.token0.address, serializedPair.token1.address)
        ] = serializedPair;
        state.timestamp = currentTimestamp();
      }
      return state;
    }),
  addSerializedToken: (token: SerializedToken) =>
    set((state) => {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[token.chainId] = state.tokens[token.chainId] || {};
      state.tokens[token.chainId][token.address] = token;
      state.timestamp = currentTimestamp();
      return state;
    }),
});

interface MintLiquiditySlice {
  independentField: Field;
  typedValue: string;
  otherTypedValue: string;
  typeInput: (field: Field, typedValue: string, noLiquidity: boolean) => void;
  resetMintInput: () => void;
}

const createMintLiquiditySlice: StateCreator<MintLiquiditySlice> = (set) => ({
  independentField: Field.CURRENCY_A,
  typedValue: "",
  otherTypedValue: "",
  typeInput: (field: Field, typedValue: string, noLiquidity: boolean) =>
    set((state) => {
      if (noLiquidity) {
        // they're typing into the field they've last typed in
        if (field === state.independentField) {
          return {
            independentField: field,
            typedValue,
          };
        }
        // they're typing into a new field, store the other value
        return {
          independentField: field,
          typedValue,
          otherTypedValue: state.typedValue,
        };
      }
      return {
        independentField: field,
        typedValue,
        otherTypedValue: "",
      };
    }),
  resetMintInput: () =>
    set(() => ({
      independentField: Field.CURRENCY_A,
      typedValue: "",
      otherTypedValue: "",
    })),
});

export type PairDataNormalized = {
  time: number;
  token0Id: string;
  token1Id: string;
  reserve0: number;
  reserve1: number;
}[];

export type DerivedPairDataNormalized = {
  time: number;
  token0Id: string;
  token1Id: string;
  token0DerivedBNB: number;
  token1DerivedBNB: number;
}[];

interface SwapSlice {
  swapIndependentField: SwapField;
  [SwapField.INPUT]: {
    currencyId: string | undefined;
  };
  [SwapField.OUTPUT]: {
    currencyId: string | undefined;
  };
  swapTypedValue: string;
  pairDataById: Record<number, Record<string, PairDataNormalized>> | null;
  derivedPairDataById: Record<
    number,
    Record<string, DerivedPairDataNormalized>
  > | null;
  recipient: string | null;
  //actions
  replaceSwapState: (
    typedValue: string,
    recipient: string | null,
    field: SwapField,
    inputCurrencyId: string,
    outputCurrencyId: string
  ) => void;
  selectCurrency: (field: SwapField, currencyId: string) => void;
  switchCurrencies: () => void;
  typeSwapInput: (field: SwapField, typedValue: string) => void;
  updateRecentSwapTx: (tx: string) => void;
  recentSwapTx: string;
}

const createSwapSlice: StateCreator<SwapSlice> = (set) => ({
  swapIndependentField: SwapField.INPUT,
  [SwapField.INPUT]: {
    currencyId:
      tokenAddresses.get(ChainId.EMERALD_MAINNET)?.RHUB ||
      tokenAddresses.get(ChainId.EMERALD_TESTNET)?.RHUB,
  },
  [SwapField.OUTPUT]: {
    currencyId:
      tokenAddresses.get(ChainId.EMERALD_MAINNET)?.USDT ||
      tokenAddresses.get(ChainId.EMERALD_TESTNET)?.USDT,
  },
  recentSwapTx: "",
  swapTypedValue: "",
  pairDataById: {},
  derivedPairDataById: {},
  recipient: null,
  updateRecentSwapTx: (tx: string) => set(() => ({ recentSwapTx: tx })),
  typeSwapInput: (field: SwapField, typedValue: string) =>
    set((state) => {
      return {
        ...state,
        swapIndependentField: field,
        swapTypedValue: typedValue,
      };
    }),
  replaceSwapState: (
    typedValue: string,
    recipient: string | null,
    field: SwapField,
    inputCurrencyId: string,
    outputCurrencyId: string
  ) =>
    set((state) => {
      return {
        [SwapField.INPUT]: {
          currencyId: inputCurrencyId,
        },
        [SwapField.OUTPUT]: {
          currencyId: outputCurrencyId,
        },
        swapIndependentField: field,
        swapTypedValue: typedValue,
        recipient,
        pairDataById: state.pairDataById,
        derivedPairDataById: state.derivedPairDataById,
      };
    }),
  selectCurrency: (field: SwapField, currencyId: string) =>
    set((state) => {
      const otherField =
        field === SwapField.INPUT ? SwapField.OUTPUT : SwapField.INPUT;
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          swapIndependentField:
            state.swapIndependentField === SwapField.INPUT
              ? SwapField.OUTPUT
              : SwapField.INPUT,
          [field]: { currencyId },
          [otherField]: { currencyId: state[field].currencyId },
        };
      }
      // the normal case
      return {
        [field]: { currencyId },
      };
    }),
  switchCurrencies: () =>
    set((state) => {
      return {
        ...state,
        swapIndependentField:
          state.swapIndependentField === SwapField.INPUT
            ? SwapField.OUTPUT
            : SwapField.INPUT,
        [SwapField.INPUT]: { currencyId: state[SwapField.OUTPUT].currencyId },
        [SwapField.OUTPUT]: { currencyId: state[SwapField.INPUT].currencyId },
      };
    }),
});

const GAS_PRICE_GWEI = {
  default: parseUnits(GAS_PRICE.default, "gwei").toString(),
  fast: parseUnits(GAS_PRICE.fast, "gwei").toString(),
  instant: parseUnits(GAS_PRICE.instant, "gwei").toString(),
  testnet: parseUnits(GAS_PRICE.testnet, "gwei").toString(),
};

interface GasPriceState {
  gasPrice: string;
  updateGasPrice: (gasPrice: string) => void;
}
const createGasPriceSlice: StateCreator<GasPriceState> = (set) => ({
  gasPrice: GAS_PRICE_GWEI.default,
  updateGasPrice: (gasPrice: string) => set((state) => ({ gasPrice })),
});

export const useStore = create<
  SlippageSlice &
    RoseApeNFTState &
    StakedTokensAndNFTSlice &
    WalletBalanceSlice &
    ToastSlice &
    RemoveLiquiditySlice &
    AddLiquiditySlice &
    MintLiquiditySlice &
    SwapSlice &
    GasPriceState
>()((...payload) => ({
  ...createSlippageSlice(...payload),
  ...createNFTListSlice(...payload),
  ...createStakedTokensAndNFT(...payload),
  ...createWalletBalanceSlice(...payload),
  ...createToastSlice(...payload),
  ...createRemoveLiquiditySlice(...payload),
  ...createAddLiquiditySlice(...payload),
  ...createMintLiquiditySlice(...payload),
  ...createSwapSlice(...payload),
  ...createGasPriceSlice(...payload),
}));
