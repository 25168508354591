import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsChevronDown } from "react-icons/bs";
import SelectCurrencyModal from "./SelectCurrencyModal";
import { escapeRegExp, inputRegex } from "utils/regex";
import isNaN from "lodash/isNaN";
import { ChainId, Currency, Token, WNATIVE } from "@rosehub-tech/sdk";
import useChainId from "hooks/useChainId";
import { useCurrencyBalance } from "views/Liquidity/hooks/useCurrencyBalances";
import { useAccount } from "wagmi";
import { useStore } from "store/zustand";

const StyledChevronDown = styled(BsChevronDown)`
  margin-left: 4px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.133);
  border-radius: 0.72rem;
  padding: 0.5rem 1rem;

  &:focus-within {
    background-color: rgba(243, 73, 158, 0.11);
    border-color: rgb(255, 7, 143);
  }
`;

export const InputLabel = styled.label`
  font-size: 0.8rem;
`;

export const InputLabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
`;

const StyledButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  font-weight: 600;
  text-transform: uppercase;

  .btn-primary {
    outline: none;
    border: none;
    background: transparent;
  }

  .btn-primary.dropdown-toggle {
    background: transparent;
    outline: none;

    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-toggle::after {
    margin-left: 8px;
    display: none;
  }
`;

const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const Logo = styled.img`
  height: 18px;
  width: 18px;
  border-radius: 100%;
  margin-right: 4px;
`;

export function getTokenIcon(token: Token, chainId: ChainId) {
  const tokenSymbol = token?.symbol || WNATIVE[chainId]?.symbol;

  return (
    <Logo src={`/tokens/${tokenSymbol?.toUpperCase()}.png`} alt={tokenSymbol} />
  );
}

interface CurrencyInputProps {
  label: string;
  tokenIcon?: React.ReactNode;
  token?: Token;
  onSelectToken?: (token: Token) => void;
  onCurrencyInputChange?: (value: string) => void;
  currencyValue: string;
  balance?: number | undefined;
  onFocus?: () => void;
  onBlur?: () => void;
  currency?: Currency;
  showNative?: boolean;
}

export default function CurrencyInput({
  label,
  token,
  onSelectToken,
  onCurrencyInputChange,
  currencyValue,
  balance,
  onFocus,
  onBlur,
  currency,
  showNative,
}: CurrencyInputProps) {
  const recentSwapTx = useStore((state) => state.recentSwapTx);
  const chainId = useChainId();
  const { address } = useAccount();
  const [userInput, setUserInput] = useState("");
  const [showCoinListModal, setShowCoinListModal] = useState(false);
  const selectedCurrencyBalance = useCurrencyBalance(
    address || undefined,
    currency || undefined
  );

  useEffect(() => {
    setUserInput(currencyValue);
  }, [currencyValue, recentSwapTx]);

  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      setUserInput(nextUserInput);
      !!onCurrencyInputChange && onCurrencyInputChange(nextUserInput);
    }
  };

  const handleMaxBalance = () => {
    if (!!currency) {
      setUserInput(selectedCurrencyBalance.toSignificant(6));
      !!onCurrencyInputChange &&
        onCurrencyInputChange(selectedCurrencyBalance.toSignificant(6));
    }
  };

  return (
    <InputWrapper>
      <InputLabelContainer>
        <InputLabel>{label}</InputLabel>
        <InputLabel onClick={handleMaxBalance}>
          Balance: <a href={"#"} style={{color:"hotpink"}}>{!!currency ? selectedCurrencyBalance?.toSignificant(6) : 0}</a>
        </InputLabel>
      </InputLabelContainer>
      <InputContainer>
        <StyledInput
          inputMode="decimal"
          autoComplete="off"
          autoCorrect="off"
          value={userInput}
          onChange={(event) => {
            const value = event.target.value.replace(/,/g, ".");
            enforcer(value);
          }}
          type="text"
          pattern="^[0-9]*[.,]?[0-9]*$"
          placeholder={"0.0"}
          minLength={1}
          maxLength={79}
          spellCheck="false"
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {!!token ? (
          <TokenContainer>
            {!!token ? getTokenIcon(token, chainId) : null}
            <StyledButton onClick={() => setShowCoinListModal((old) => !old)}>
              {currency?.symbol || token?.symbol}
              <StyledChevronDown />
            </StyledButton>
          </TokenContainer>
        ) : null}
      </InputContainer>
      <SelectCurrencyModal
        show={showCoinListModal}
        handleClose={() => setShowCoinListModal((old) => !old)}
        onSelectToken={onSelectToken || undefined}
        addNative={showNative}
      />
    </InputWrapper>
  );
}
