import { ethers } from "ethers";
import { useStakeVaultFactoryAddress } from "hooks/contracts/useContractAddresses";
import {
  useStakeVaultFactoryInterface,
  useStakeVaultInterface,
} from "hooks/contracts/useInterfaces";
import { useQuery } from "@tanstack/react-query";
import { useAccount, useSigner } from "wagmi";

export function useGetVaultLength() {
  const vaultFactoryAddress = useStakeVaultFactoryAddress();
  const vaultFactoryInterface = useStakeVaultFactoryInterface();
  const { data: signer } = useSigner();

  const { data: vaults } = useQuery(
    ["useGetVaultLength"],
    async () => {
      const vaultContract = new ethers.Contract(
        vaultFactoryAddress,
        vaultFactoryInterface,
        signer
      );
      return await vaultContract.getVaultLength();
    },
    {
      enabled: !!signer,
    }
  );

  return vaults?.toNumber();
}

export const useGetTotalStakedAmount = (
  index: number,
  vaultAddress: string
) => {
  // const { vaultAddress } = useGetVaultAddress(index);
  const vaultInterface = useStakeVaultInterface();
  const { data: signer } = useSigner();

  const { data: amount, refetch } = useQuery(
    ["useGetTotalStakedAmount", index],
    async () => {
      const vaultContract = new ethers.Contract(
        vaultAddress,
        vaultInterface,
        signer
      );
      return await vaultContract.totalAmount();
    },
    {
      enabled: !!signer,
    }
  );

  if (!!amount) {
    return {
      totalStakedAmount: !!amount ? ethers.utils.formatEther(amount) : 0,
      refetch,
    };
  }

  return undefined;
};

export const useGetUserStakedAmount = (index: number) => {
  const { address } = useAccount();
  const { vaultAddress } = useGetVaultAddress(index);
  const vaultInterface = useStakeVaultInterface();
  const { data: signer } = useSigner();

  const { data: userInfo, refetch } = useQuery(
    ["useGetUserStakedAmount", index, vaultAddress],
    async () => {
      const vaultContract = new ethers.Contract(
        vaultAddress,
        vaultInterface,
        signer
      );
      const userInfo = await vaultContract.userInfo(address);
      return userInfo;
    },
    {
      enabled: !!signer,
    }
  );
  return {
    amount: !!userInfo?.amount
      ? ethers.utils.formatEther(userInfo.amount)
      : undefined,
    refetch,
  };
};

export default function useGetVaultAddress(index: number) {
  const factoryInterface = useStakeVaultFactoryInterface();
  const stakeVaultFactoryAddress = useStakeVaultFactoryAddress();
  const { data: signer } = useSigner();

  const { data: vaultAddress, refetch } = useQuery(
    ["useGetVaultAddress", index, stakeVaultFactoryAddress],
    async () => {
      const factoryContract = new ethers.Contract(
        stakeVaultFactoryAddress,
        factoryInterface,
        signer
      );
      return await factoryContract.vaults(index);
    },
    {
      enabled: !!signer,
    }
  );

  return { vaultAddress: String(vaultAddress), refetch };
}
