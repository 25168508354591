import diamond from "assets/icons/diamond.svg";
import rocket from "assets/icons/rocket.svg";
import logo from 'assets/images/launch.png';
import { genID } from "utils/functions";
import { ROADMAP_MILESTONES } from "./consts";


export default [
  {
    title: "Beginning",
    time: "2021 Q4",
    icon: rocket,
    items: [
      "Idea Formation",
      "Team Formation",
      "Website Development",
      "Strategy",
      "Preliminary NFT art",
    ],
    status: ROADMAP_MILESTONES.current,
  },
  {
    title: "NFTs and UI",
    time: "2022 Q1",
    icon: diamond,
    items: [
      "NFT Minting",
      "NFT Marketplace Listing",
      "RoseHub UI",
      "Marketing Campaigns",
      "Team Expansion",
    ],
    status: ROADMAP_MILESTONES.current,
  },
  {
    title: "Fundraising and Dev",
    time: "2022 Q2",
    icon: rocket,
    items: [
      "Platform Specifications",
      "Fundraising",
      "Preliminary UI",
      "Smart Contracts Dev",
      "Partnerships",
    ],
    status: ROADMAP_MILESTONES.current,
  },
  {
    title: "Launch",
    time: "2022 Q3",
    icon: logo,
    items: [
      "Further Fundraising",
      "Platform Audit",
      "Platform Testing",
      "Oasis DEX Aggregator",
      "Platform Launch",
      "Vaults and Farms"
    ],
    status: ROADMAP_MILESTONES.upcoming,
  },
  {
    title: "Post-Launch",
    time: "2022 Q4",
    icon: diamond,
    items: [
      "Collaborations",
      "First IDOs",
      "Additional DEX Pairs",
      "Vaults and Farms",
      "Liquidity and TVL Growth",
      "Exchange Listings"
    ],
    status: ROADMAP_MILESTONES.current,
  },
  {
    title: "Expansion",
    time: "2023 H1",
    icon: rocket,
    items: [
      "UI Upgrades",
      "New Sponsored Listings",
      "Multi-chain launchpad",
      "Strategic Collaborations",
      "New IDOs",
    ],
    status: ROADMAP_MILESTONES.current,
  },
  {
  title: "DEX Expansion",
  time: "2023 H2",
  icon: diamond,
  items: [
    "Multi-Chain DEX",
    "Cross-chain liquidity",
    "Full Trading Platform Dev",
    "Limit Orders *Oracles*",
  ],
  status: ROADMAP_MILESTONES.current,
}
].map((e) => ({ ...e, id: genID() }));
