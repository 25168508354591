// import brandIcon from "assets/icons/brand.svg";
// import logoIcon from "assets/icons/logo.svg";
import menuIcon from "assets/icons/menu.svg";
import closeIcon from "assets/icons/close.svg";
import ROUTES from "constants/Routes";
import styled from "styled-components";
import IconBtn from "./IconBtn";
import NavItem from "./NavItem";
// import RedLogo from "../assets/images/launch.png";
import useNav from "hooks/useNav";
import Wallet from "./Wallet";
import rosehubLogo from "assets/images/RoseHub_White.svg";

const Container = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;
  max-width: min(1920px, calc(100% - 16rem));
  margin: 0 auto;
  background: none !important;

  @media screen and (max-width: 1023px) {
    margin: 0;
    max-width: unset;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 1.7rem;
  }
`;

const Branding = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 0 1rem;
`;

const Logo = styled.img.attrs({ src: rosehubLogo })`
  width: 10rem;
`;

const Routes = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
`;

const Options = styled.div`
  padding-bottom: 1rem;
  padding-top: 0;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  gap: 1.6rem;
  flex-wrap: wrap;

  .linksContainer {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    flex-wrap: wrap;

    &:first-child {
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    padding-bottom: 0;
  }
`;

const MenuButton = styled.div`
  display: none;
  z-index: 1;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const MobileMenuContainer = styled.div`
  display: none;
  @media screen and (max-width: 1023px) {
    &.active {
      display: flex;
      position: absolute;
      padding: 1.7rem;
      inset: 0px;
      width: 100%;
      height: 100vh;
      text-align: center;
      background: linear-gradient(
        rgba(0, 0, 0, 0.675) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      -webkit-mask-image: linear-gradient(rgb(0, 0, 0) 100%, transparent);
      backdrop-filter: blur(36px);
      flex-direction: column;
      z-index: 10000;
    }
  }
`;

export const MobileMenuAction = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const MobileMenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
`;

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useNav(false);

  return (
    <>
      <Container>
        <Branding>
          <Logo />
        </Branding>

        <Options>
          <div className="linksContainer">
            <Routes>
              {ROUTES.map((e) => (
                <NavItem key={e.id} {...e} />
              ))}
              <Wallet />
            </Routes>
          </div>

          <MobileMenuContainer className={navbarOpen ? "active" : ""}>
            <MobileMenuAction>
              <MenuButton>
                <IconBtn
                  icon={closeIcon}
                  onClick={() => setNavbarOpen((old: boolean) => !old)}
                />
              </MenuButton>
            </MobileMenuAction>
            <MobileMenuLinks>
              {ROUTES.map((e) => (
                <NavItem key={e.id} {...e} />
              ))}
              <Wallet />
            </MobileMenuLinks>
          </MobileMenuContainer>
          <MenuButton>
            <IconBtn
              icon={menuIcon}
              onClick={() => setNavbarOpen((old: boolean) => !old)}
            />
          </MenuButton>
        </Options>
      </Container>
    </>
  );
};

export default Header;
