import logoIcon from "assets/images/RoseHub_White.svg";
import menuIcon from "assets/icons/menu.svg";
import styled from "styled-components";
import IconBtn from "./IconBtn";
import Wallet from "./Wallet";
import { useState } from "react";
import closeIcon from "assets/icons/close.svg";
import NavItem from "./NavItem";
import { OTHER_ROUTES } from "constants/Routes";
import {
  MobileMenuAction,
  MobileMenuContainer,
  MobileMenuLinks,
} from "./Header";

const Container = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;
  background: none !important;
  position: relative;
`;

const Branding = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 0 1rem;
`;

const Logo = styled.img.attrs({ src: logoIcon })`
  width: 10rem;
`;

const Options = styled.div`
  padding-bottom: 1rem;
  padding-top: 0;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  gap: 1.6rem;

  span {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    &:first-child {
      @media (max-width: 1024px) {
        display: none;
      }
    }

    &:last-child {
      z-index: 1;
      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
`;

const MenuButton = styled.div`
  display: none;
  z-index: 1;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const HeaderPad = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
      <Container>
        <Branding>
          <Logo />
        </Branding>

        <Options>
          <span>
            {OTHER_ROUTES.map((e) => (
              <NavItem key={e.id} {...e} />
            ))}
            <Wallet />
          </span>

          <MobileMenuContainer className={navbarOpen ? "active" : ""}>
            <MobileMenuAction>
              <MenuButton>
                <IconBtn
                  icon={closeIcon}
                  onClick={() => setNavbarOpen((old: boolean) => !old)}
                />
              </MenuButton>
            </MobileMenuAction>
            <MobileMenuLinks>
              {OTHER_ROUTES.map((e) => (
                <NavItem key={e.id} {...e} />
              ))}
              <Wallet />
            </MobileMenuLinks>
          </MobileMenuContainer>
          <MenuButton>
            <IconBtn
              icon={menuIcon}
              onClick={() => setNavbarOpen((old: boolean) => !old)}
            />
          </MenuButton>
        </Options>
      </Container>
    </>
  );
};

export default HeaderPad;
