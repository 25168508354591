import { FC, forwardRef } from "react";
import { FieldError, Noop } from "react-hook-form";
import {
  FormElementContainer,
  FormElementWrapper,
  FormInputLabel,
  StyledErrorMessage,
} from "../FormInput/FormInput";
import { SelectOption } from "../schema";
import Select, { ControlProps, components, PropsValue } from "react-select";

const formSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "none",
    color: "white",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white",
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
    zIndex: 1001,
  }),
  option: (styles) => ({
    ...styles,
    color: "white",
    backgroundColor: "black",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "black",
  }),
  indicatorSeparator: (styles) => ({
    display: "none",
  }),
};

interface FormSelectProps {
  label?: string;
  options: SelectOption[];
  name: string;
  error?: FieldError;
  onChange: (newValue: SelectOption) => void;
  onBlur?: Noop;
  value: PropsValue<SelectOption>;
  placeholder: string;
}

const ControlComponent = (props: ControlProps<SelectOption, false>) => (
  <components.Control {...props} />
);

const FormSelect = forwardRef((props: FormSelectProps, ref: any) => {
  const { label, options, onBlur, onChange, value, name, placeholder, error } =
    props;

  return (
    <FormElementWrapper>
      {label ? <FormInputLabel>{label}</FormInputLabel> : null}
      <FormElementContainer>
        <Select
          name={name}
          placeholder={placeholder}
          options={options}
          components={{ Control: ControlComponent }}
          classNamePrefix="form-select"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          styles={formSelectStyles}
        />
      </FormElementContainer>
      {error ? <StyledErrorMessage>{error?.message}</StyledErrorMessage> : null}
    </FormElementWrapper>
  );
});

export default FormSelect;
