import { useCallback } from "react";
import { TransactionResponse } from "@ethersproject/providers";
import { Contract, CallOverrides } from "@ethersproject/contracts";
import get from "lodash/get";
import useChainId from "hooks/useChainId";
import { useFeeData, useNetwork } from "wagmi";
import { ChainId } from "@rosehub-tech/sdk";
import { parseUnits } from "ethers/lib/utils";
import { useStore } from "store/zustand";

export function useCallWithGasPrice() {
  const gasPrice = useGasPrice();
  /**
   * Perform a contract call with a gas price returned from useGasPrice
   * @param contract Used to perform the call
   * @param methodName The name of the method called
   * @param methodArgs An array of arguments to pass to the method
   * @param overrides An overrides object to pass to the method. gasPrice passed in here will take priority over the price returned by useGasPrice
   * @returns https://docs.ethers.io/v5/api/providers/types/#providers-TransactionReceipt
   */
  const callWithGasPrice = useCallback(
    async (
      contract: Contract,
      methodName: string,
      methodArgs: any[] = [],
      overrides: CallOverrides = null
    ): Promise<TransactionResponse> => {
      const contractMethod = get(contract, methodName);
      const hasManualGasPriceOverride = overrides?.gasPrice;
      const tx = await contractMethod(
        ...methodArgs,
        hasManualGasPriceOverride
          ? { ...overrides }
          : { ...overrides, gasPrice }
      );

      return tx;
    },
    [gasPrice]
  );

  return { callWithGasPrice };
}

export enum GAS_PRICE {
  default = "100",
  fast = "110",
  instant = "120",
  testnet = "100",
}

export const GAS_PRICE_GWEI = {
  default: parseUnits(GAS_PRICE.default, "gwei").toString(),
  fast: parseUnits(GAS_PRICE.fast, "gwei").toString(),
  instant: parseUnits(GAS_PRICE.instant, "gwei").toString(),
  testnet: parseUnits(GAS_PRICE.testnet, "gwei").toString(),
};

export function useGasPrice(): string {
  //   const { chainId, chain } = useActiveWeb3React();
  const chainId = useChainId();
  const { chain } = useNetwork();
  const userGas = useStore((state) => state.gasPrice);
  const { data } = useFeeData({
    chainId,
    enabled:
      chainId !== ChainId.EMERALD_MAINNET &&
      chainId !== ChainId.EMERALD_TESTNET,
    watch: true,
  });
  if (chainId === ChainId.EMERALD_MAINNET) {
    return userGas;
  }
  if (chainId === ChainId.EMERALD_TESTNET) {
    return GAS_PRICE_GWEI.testnet;
  }
  if (chain?.testnet) {
    return data?.formatted?.maxPriorityFeePerGas;
  }
  return data?.formatted?.gasPrice;
}
