import { emeraldMainnet, emeraldTestnet } from "connectors/chains";
import { configureChains } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

const { provider, chains } = configureChains(
  [emeraldMainnet, emeraldTestnet],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === emeraldMainnet.id || chain.id === emeraldTestnet.id) {
          return { http: chain.rpcUrls.default };
        }
        console.log("chain", chain);
        return null;
      },
    }),
  ]
);
const injectedConnector = new InjectedConnector({ chains });
const metamaskConnector = new MetaMaskConnector({ chains });
export { provider, chains, injectedConnector, metamaskConnector };
