import { ethers } from "ethers";
import {
  useLPTokenAddress,
  useRHUBTokenAddress,
  useStakeMasterAddress,
} from "hooks/contracts/useContractAddresses";
import { erc20ABI, useAccount, useContractReads } from "wagmi";

export default function useRPADAllowance() {
  const { address, isConnected } = useAccount();
  const stakingContractAddress = useStakeMasterAddress();
  const rhubAddress = useRHUBTokenAddress();
  const lpTokenAddress = useLPTokenAddress();
  const {
    data: tokenAllowances,
    isSuccess,
    isError,
    refetch: refetchAllowances,
  } = useContractReads({
    contracts: [
      {
        addressOrName: rhubAddress,
        contractInterface: erc20ABI,
        functionName: "allowance",
        args: [address, stakingContractAddress],
      },
      {
        addressOrName: lpTokenAddress,
        contractInterface: erc20ABI,
        functionName: "allowance",
        args: [address, stakingContractAddress],
      },
    ],
    enabled: !!stakingContractAddress,
  });

  if (isError) {
    return undefined;
  }

  return {
    tokens: {
      rpad:
        tokenAllowances?.length > 0 &&
        !!tokenAllowances[0] &&
        isSuccess &&
        isConnected
          ? Number(ethers.utils.formatEther(tokenAllowances[0]))
          : undefined,
      rpadLP:
        tokenAllowances?.length > 0 &&
        !!tokenAllowances[1] &&
        isSuccess &&
        isConnected
          ? Number(ethers.utils.formatEther(tokenAllowances[1]))
          : undefined,
    },
    refetchAllowances,
  };
}
