import gearIcon from "assets/icons/gear.svg";
import caretIcon from "assets/icons/caret.svg";
import GlowContainer from "components/GlowContainer";
import IconBtn from "components/IconBtn";
import { useEffect, useMemo, useState } from "react";
import {
  Header,
  Heading,
  Title,
  Sub,
  Options,
  Body,
  Inputs,
  BackIcon,
} from "./styles/AddLiquidityStyles";
import "bootstrap/dist/css/bootstrap.css";
import PairInfo from "../../components/PairInfo";
import CurrencyInput from "views/Swap/CurrencyInput";
// import useReserves from "hooks/useReserves";
import SlippageSettings from "components/SlippageSettings";
// import useSigner from "hooks/useSigner";
import useTokens from "hooks/useTokens";
import useConfirmAddLiquidity from "./hooks/useConfirmAddLiquidity";
import GradientButton from "components/GradientBtn";
import useLiquidityAllowances from "./hooks/useLiquidityAllowances";
import useApproveLiquidityAllowance from "./hooks/useApproveLiquidityAllowance";
import { useLocation, useNavigate } from "react-router";
import { Token } from "@rosehub-tech/sdk";
import { useLiquidityInputHandlers, useMintState } from "./hooks/usePairs";
import { ErrorTypes, useDerivedMintInfo } from "./hooks/useDeriveMintInfo";
import { useCurrency, useNativeCurrency } from "./hooks/useCurrencyBalances";
import { useStore } from "store/zustand";
import { Field } from "types";
import { logger } from "ethers";

export default function AddLiquidity({
  onShowLiquidity,
}: {
  onShowLiquidity: () => void;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const token0Symbol = new URLSearchParams(location.search)?.get("token0");
  const token1Symbol = new URLSearchParams(location.search)?.get("token1");
  const resetMintState = useStore((state) => state.resetMintInput);
  const tokens = useTokens();
  const token0 = tokens[token0Symbol?.toLowerCase()];
  const token1 = tokens[token1Symbol?.toLowerCase()];
  const native = useNativeCurrency();

  const [tokenA, setTokenA] = useState<Token>();
  const [tokenB, setTokenB] = useState<Token>();
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [currencyIdA, currencyIdB] = [token0Symbol, token1Symbol];

  useEffect(() => {
    setTokenA(token0 ?? native);
    setTokenB(token1 ?? native);
  }, [native, navigate, token0, token1, tokens]);

  const handleSlippageModal = () => {
    setShowSettingsModal((old) => !old);
  };

  const allowance = useLiquidityAllowances(tokenA?.address, tokenB?.address);

  const { write: approveTokenA, isLoading: isApprovingTokenA } =
    useApproveLiquidityAllowance(
      tokenA?.address,
      allowance?.refetch as () => void
    );
  const { write: approveTokenB, isLoading: isApprovingTokenB } =
    useApproveLiquidityAllowance(
      tokenB?.address,
      allowance?.refetch as () => void
    );

  const currencyA = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);

  useEffect(() => {
    if (!currencyIdA && !currencyIdB) {
      resetMintState();
    }
  }, [currencyIdA, currencyIdB, resetMintState]);

  /**
   * called when a new token from modal thru the bottom token input
   * @param token
   */
  const handleSelectTokenA = (token: Token) => {
    if (!!token) {
      let qsTokens: string = `?token0=${token.symbol}&token1=${token1Symbol}`;
      //if selected token is equal to token b,   token1 and token0
      if (token.symbol === tokenB.symbol) {
        setTokenB(tokenA);
        qsTokens = `?token0=${token1Symbol}&token1=${token0Symbol}`;
      }

      setTokenA(token);
      navigate({
        search: qsTokens,
      });
    }
  };

  /**
   * called when a new token from modal thru the top token input
   * @param token
   */
  const handleSelectTokenB = (token: Token) => {
    if (!!token) {
      let qsTokens: string = `?token0=${token0Symbol}&token1=${token.symbol}`;

      //if selected token is equal to token a, switch token0 and token1
      if (token.symbol === tokenA.symbol) {
        setTokenA(tokenB);
        qsTokens = `?token0=${token1Symbol}&token1=${token.symbol}`;
      }
      setTokenB(token);
      navigate({
        search: qsTokens,
      });
    }
  };

  const [independentField, typedValue, otherTypedValue] = useMintState();
  const {
    dependentField,
    currencies,
    parsedAmounts,
    price,
    noLiquidity,
    poolTokenPercentage,
    error,
    errorType,
    addError,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);
  const { onFieldAInput, onFieldBInput } =
    useLiquidityInputHandlers(noLiquidity);

  // get formatted amounts
  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: noLiquidity
        ? otherTypedValue
        : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
    }),
    [
      dependentField,
      independentField,
      noLiquidity,
      otherTypedValue,
      parsedAmounts,
      typedValue,
    ]
  );

  const { onConfirmAddLiquidity, isLoading } = useConfirmAddLiquidity(
    currencies[Field.CURRENCY_A],
    currencies[Field.CURRENCY_B],
    formattedAmounts[Field.CURRENCY_A],
    formattedAmounts[Field.CURRENCY_B]
  );
  logger.info("add liquidity errorType:", errorType, error);
  logger.info("add liquidity addError:", addError);
  logger.info("add liquidity allowance:", allowance);
  const handleSubmit = () => {
    if (errorType === ErrorTypes.INSUFFICIENT_ALLOWANCE) {
      if (!allowance?.token0 && !currencies[Field.CURRENCY_A]?.isNative) {
        approveTokenA();
        return;
      }
      if (!allowance?.token1 && !currencies[Field.CURRENCY_A]?.isNative) {
        approveTokenB();
        return;
      }
    }

    onConfirmAddLiquidity(() => {
      resetMintState();
      navigate({
        pathname: "/liquidity",
        search: "",
      });
      onShowLiquidity();
    });
  };

  const isValid = !error && !addError;
  const errorText = error ?? addError;

  return (
    <>
      <GlowContainer>
        <Header>
          <Heading>
            <Title>
              <BackIcon
                src={caretIcon}
                alt="back"
                onClick={() => {
                  if (token0 || token1) {
                    navigate({
                      pathname: `/liquidity`,
                      search: "",
                    });
                    resetMintState();
                  }
                  onShowLiquidity();
                }}
              />{" "}
              Add Liquidity
            </Title>
            <Sub>Add liquidity to receive LP tokens</Sub>
          </Heading>
          <Options>
            <IconBtn icon={gearIcon} onClick={handleSlippageModal} />
          </Options>
        </Header>
        <Body>
          <Inputs>
            <CurrencyInput
              label="Token 1"
              token={tokenA}
              onSelectToken={handleSelectTokenA}
              onCurrencyInputChange={onFieldAInput}
              currencyValue={formattedAmounts[Field.CURRENCY_A]}
              currency={currencyA}
              showNative
            />
            <CurrencyInput
              label="Token 2"
              token={tokenB}
              onSelectToken={handleSelectTokenB}
              onCurrencyInputChange={onFieldBInput}
              currencyValue={formattedAmounts[Field.CURRENCY_B]}
              currency={currencyB}
              showNative
            />
          </Inputs>
          <PairInfo
            price={price}
            poolTokenPercentage={poolTokenPercentage}
            currencies={currencies}
          />
          <GradientButton
            disabled={
              !isValid && errorType !== ErrorTypes.INSUFFICIENT_ALLOWANCE
            }
            width={180}
            onClick={handleSubmit}
            label={errorText || "Supply"}
            loading={isLoading || isApprovingTokenA || isApprovingTokenB}
            disabledText={errorText}
          />
        </Body>
      </GlowContainer>
      <SlippageSettings
        show={showSettingsModal}
        handleClose={handleSlippageModal}
      />
    </>
  );
}
