import { ethers } from "ethers";
import { useMasterChefInterface } from "hooks/contracts/useInterfaces";
import useSigner from "hooks/useSigner";
import { useStore } from "store/zustand";
import { blockExplorers } from "connectors/chains";
import useChainId from "hooks/useChainId";
import {
  useContractWrite,
  usePrepareContractWrite,
  useProvider,
  useWaitForTransaction,
} from "wagmi";
import { useMasterChefAddress } from "hooks/contracts/useContractAddresses";

export default function useConfirmPoolDeposit(
  pid: number,
  amountIn: number,
  enabled: boolean,
  isHarvesting?: boolean,
  onClear?: () => void
) {
  const chainId = useChainId();
  const masterchefInterface = useMasterChefInterface();
  const updateToast = useStore((state) => state.updateToast);
  const signer = useSigner();
  const provider = useProvider();
  const masterchefAddress = useMasterChefAddress();
  const amountInWei = ethers.utils.parseEther(amountIn.toString());
  const { config } = usePrepareContractWrite({
    addressOrName: masterchefAddress,
    contractInterface: masterchefInterface,
    functionName: "deposit",
    args: [pid, amountInWei],
    enabled: !!masterchefAddress && !!signer && !!provider && enabled,
  });

  const {
    write,
    data: depositData,
    isLoading: isDepositing,
  } = useContractWrite({
    ...config,
    onError: (error: any) => {
      updateToast({
        message: `${error?.code}`,
        url: "",
        type: "error",
      });
    },
  });

  const { isError, isLoading: isWaitingForTransaction } = useWaitForTransaction(
    {
      confirmations: 2,
      hash: depositData?.hash,
      enabled: !!depositData?.hash,
      onSuccess: () => {
        const successMessage = isHarvesting
          ? `Successfully harvested earned RHUB`
          : `Successfully staked ${amountIn} RHUB-LP`;
        const txUrl = `${blockExplorers.get(chainId)}/tx/${depositData.hash}`;
        updateToast({
          message: successMessage,
          url: txUrl,
          type: "success",
        });
        !!onClear && onClear();
      },
      onError: (error: any) => {
        console.log("error", error);
        updateToast({
          message: `${error?.code}`,
          url: "",
          type: "error",
        });
      },
    }
  );

  return {
    onConfirmDeposit: write,
    isLoading: isDepositing || isWaitingForTransaction,
    isError,
  };
}

export function useConfirmFarmUnstake(
  pid: number,
  amountOut: number,
  enabled: boolean,
  onClear: () => void
) {
  const chainId = useChainId();
  const masterchefInterface = useMasterChefInterface();
  const updateToast = useStore((state) => state.updateToast);
  const signer = useSigner();
  const provider = useProvider();
  const masterchefAddress = useMasterChefAddress();
  const amountInWei = ethers.utils.parseEther(amountOut.toString());
  const { config } = usePrepareContractWrite({
    addressOrName: masterchefAddress,
    contractInterface: masterchefInterface,
    functionName: "withdraw",
    args: [pid, amountInWei],
  });

  const {
    write,
    data: depositData,
    isLoading: isDepositing,
  } = useContractWrite({
    ...config,
    onError: (error: any) => {
      updateToast({
        message: `${error?.code}`,
        url: "",
        type: "error",
      });
    },
    enabled:
      !!masterchefAddress && !!signer && !!provider && amountOut > 0 && enabled,
  });

  const { isError, isLoading: isWaitingForTransaction } = useWaitForTransaction(
    {
      confirmations: 2,
      hash: depositData?.hash,
      enabled: !!depositData?.hash,
      onSuccess: () => {
        const successMessage = `Successfully unstaked ${amountOut} RHUB`;
        const txUrl = `${blockExplorers.get(chainId)}/tx/${depositData.hash}`;
        updateToast({
          message: successMessage,
          url: txUrl,
          type: "success",
        });
        onClear();
      },
      onError: (error: any) => {
        console.log("error", error);
        updateToast({
          message: `${error?.code}`,
          url: "",
          type: "error",
        });
      },
    }
  );

  return {
    onConfirmUnstake: write,
    isLoading: isDepositing || isWaitingForTransaction,
    isError,
  };
}
