import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {cryptoApi, jotFormsApi} from "services/api";
import ui from "./ui";
import global from "./global";
import notification from "./notification";

const reducer = combineReducers({
  notification,
  global,
});

export default configureStore({
  reducer: {
    reducer,
    ui,
    [cryptoApi.reducerPath]: cryptoApi.reducer,
    [jotFormsApi.reducerPath]: jotFormsApi.reducer,
  },
});
