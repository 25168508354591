import {
  Currency,
  CurrencyAmount,
  JSBI,
  Pair,
  Percent,
  Token,
  Trade,
  TradeType,
} from "@rosehub-tech/sdk";
import { useCallback, useMemo } from "react";
import { flatMap } from "lodash";
import {
  ADDITIONAL_BASES,
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  BASES_TO_CHECK_TRADES_AGAINST,
  BETTER_TRADE_LESS_HOPS_THRESHOLD,
  BIG_ZERO,
  BIPS_BASE,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  CUSTOM_BASES,
} from "config/exchange";
import useChainId from "hooks/useChainId";
import { useStore } from "store/zustand";
import { SwapField as Field, SwapField } from "types";
import { wrappedCurrency } from "types/WrappedCurrency";
import isTradeBetter from "utils/isTradeBetter";
import tryParseAmount from "utils/tryParseAmount";
import useCurrencyBalances from "views/Liquidity/hooks/useCurrencyBalances";
import { PairState, usePairs } from "views/Liquidity/hooks/usePairs";
import { useAccount } from "wagmi";
import useSwapAllowance from "./useSwapAllowance";

const MAX_HOPS = 3;
export enum SwapError {
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  INSUFFICIENT_ALLOWANCE = "INSUFFICIENT_ALLOWANCE",
  INVALID_PAIR = "INVALID_PAIR",
  DISCONNECTED = "DISCONNECTED",
  NO_TOKEN_BALANCE = "NO_TOKEN_BALANCE",
  NO_INPUT = "NO_INPUT",
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(
  independentField: SwapField,
  typedValue: string,
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined
): {
  currencies: { [field in Field]?: Currency };
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
  parsedAmount: CurrencyAmount<Currency> | undefined;
  trade: Trade<Currency, Currency, TradeType> | undefined;
  inputError?: string;
  isTokenApproved?: boolean;
} {
  const { address: account } = useAccount();
  const chainId = useChainId();

  const allowance = useSwapAllowance(
    wrappedCurrency(inputCurrency, chainId)?.address
  );

  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined,
  ]);

  const isExactIn: boolean = independentField === Field.INPUT;

  const parsedAmount = tryParseAmount(
    typedValue,
    (isExactIn ? inputCurrency : outputCurrency) ?? undefined
  );

  const bestTradeExactIn = useTradeExactIn(
    isExactIn ? parsedAmount : undefined,
    outputCurrency ?? undefined
  );
  const bestTradeExactOut = useTradeExactOut(
    inputCurrency ?? undefined,
    !isExactIn ? parsedAmount : undefined
  );
  const trade = isExactIn ? bestTradeExactIn : bestTradeExactOut;

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1],
  };

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  };

  let inputError: string | undefined;
  let isTokenApproved: boolean = true;
  if (!account) {
    inputError = "Connect Wallet";
  }

  if (
    !currencies[Field.INPUT]?.isNative &&
    (!allowance || (!!allowance && allowance?.amount === BIG_ZERO))
  ) {
    inputError = `Approve`;
    isTokenApproved = false;
  }

  if (!parsedAmount) {
    inputError = inputError ?? "Enter an amount";
  }

  const [allowedSlippage] = useUserSlippageTolerance();

  const slippageAdjustedAmounts =
    trade &&
    allowedSlippage &&
    computeSlippageAdjustedAmounts(trade, allowedSlippage);

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null,
  ];

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = `Insufficient ${amountIn.currency.symbol} balance`;
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    trade: trade ?? undefined,
    inputError,
    isTokenApproved,
  };
}

export function useTradeExactIn(
  currencyAmountIn?: CurrencyAmount<Currency>,
  currencyOut?: Currency
): Trade<Currency, Currency, TradeType> | null {
  const allowedPairs = useAllCommonPairs(
    currencyAmountIn?.currency,
    currencyOut
  );

  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs?.length > 0) {
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null;
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade<Currency, Currency, TradeType> | null =
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
            maxHops: i,
            maxNumResults: 1,
          })[0] ?? null;
        // if current trade is best yet, save it
        if (
          isTradeBetter(
            bestTradeSoFar,
            currentTrade,
            BETTER_TRADE_LESS_HOPS_THRESHOLD
          )
        ) {
          bestTradeSoFar = currentTrade;
        }
      }
      return bestTradeSoFar;
    }

    return null;
  }, [allowedPairs, currencyAmountIn, currencyOut]);
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(
  currencyIn?: Currency,
  currencyAmountOut?: CurrencyAmount<Currency>
): Trade<Currency, Currency, TradeType> | null {
  const allowedPairs = useAllCommonPairs(
    currencyIn,
    currencyAmountOut?.currency
  );

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs?.length > 0) {
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null;
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
            maxHops: i,
            maxNumResults: 1,
          })[0] ?? null;
        if (
          isTradeBetter(
            bestTradeSoFar,
            currentTrade,
            BETTER_TRADE_LESS_HOPS_THRESHOLD
          )
        ) {
          bestTradeSoFar = currentTrade;
        }
      }
      return bestTradeSoFar;
    }
    return null;
  }, [currencyIn, currencyAmountOut, allowedPairs]);
}

export function useAllCommonPairs(
  currencyA?: Currency,
  currencyB?: Currency
): Pair[] {
  const chainId = useChainId();

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined];

  const bases: Token[] = useMemo(() => {
    if (!chainId) return [];

    const common = BASES_TO_CHECK_TRADES_AGAINST[chainId] ?? [];
    const additionalA = tokenA
      ? ADDITIONAL_BASES[chainId]?.[tokenA.address] ?? []
      : [];
    const additionalB = tokenB
      ? ADDITIONAL_BASES[chainId]?.[tokenB.address] ?? []
      : [];

    return [...common, ...additionalA, ...additionalB];
  }, [chainId, tokenA, tokenB]);

  const basePairs: [Token, Token][] = useMemo(
    () =>
      flatMap(bases, (base): [Token, Token][] =>
        bases.map((otherBase) => [base, otherBase])
      ),
    [bases]
  );

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] =>
              Boolean(tokens[0] && tokens[1])
            )
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA_, tokenB_]) => {
              if (!chainId) return true;
              const customBases = CUSTOM_BASES[chainId];

              const customBasesA: Token[] | undefined =
                customBases?.[tokenA_.address];
              const customBasesB: Token[] | undefined =
                customBases?.[tokenB_.address];

              if (!customBasesA && !customBasesB) return true;

              if (
                customBasesA &&
                !customBasesA.find((base) => tokenB_.equals(base))
              )
                return false;
              if (
                customBasesB &&
                !customBasesB.find((base) => tokenA_.equals(base))
              )
                return false;

              return true;
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId]
  );

  const allPairs = usePairs(allPairCombinations);

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      !!allPairs
        ? Object.values(
            allPairs
              .filter((result): result is [PairState.EXISTS, Pair] =>
                Boolean(result[0] === PairState.EXISTS && result[1])
              )
              // filter out duplicated pairs
              .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
                memo[curr.liquidityToken.address] =
                  memo[curr.liquidityToken.address] ?? curr;
                return memo;
              }, {})
          )
        : undefined,
    [allPairs]
  );
}

export function useUserSlippageTolerance(): [
  number,
  (slippage: number) => void
] {
  const [updateUserSlippageTolerance, slippageTolerance] = useStore((state) => [
    state.updateSlippage,
    state.slippage,
  ]);

  const setUserSlippageTolerance = useCallback(
    (slippage: number) => {
      updateUserSlippageTolerance(slippage);
    },
    [updateUserSlippageTolerance]
  );

  return [slippageTolerance, setUserSlippageTolerance];
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), BIPS_BASE);
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: number
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage);
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct),
  };
}

export function warningSeverity(
  priceImpact: Percent | undefined
): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1;
  return 0;
}
