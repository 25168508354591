import { z } from "zod";

const SelectedOption = z.object({
  value: z.string(),
  label: z.string(),
});

export type SelectOption = z.infer<typeof SelectedOption>;

export const projectStatus: SelectOption[] = [
  {
    value: "idea",
    label: "Idea",
  },
  {
    value: "early-dev",
    label: "Early Development",
  },
  {
    value: "late-dev",
    label: "Late Development",
  },
  {
    value: "ready-token-launch",
    label: "Ready for Token Launch",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const fundraisingStages: SelectOption[] = [
  {
    value: "seed",
    label: "Seed",
  },
  {
    value: "private",
    label: "Private",
  },
  {
    value: "public",
    label: "Public",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const kycStatus: SelectOption[] = [
  {
    value: "kyc-ready",
    label: "Ready for KYC",
  },
  {
    value: "inc-company",
    label: "Incorporated Company",
  },
  {
    value: "consider-inc",
    label: "Considering Incorporation",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const extraServices: SelectOption[] = [
  {
    value: "advisory",
    label: "Advisory",
  },
  {
    value: "marketing",
    label: "Marketing",
  },
  {
    value: "connections",
    label: "Industry connections",
  },
  {
    value: "seed-vc-funding",
    label: "Seed/strategic VC funding",
  },
  {
    value: "security-audit",
    label: "Security Audit",
  },
  {
    value: "dex",
    label: "Dex Liquidity",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const FormSchema = z.object({
  email: z
    .string({
      invalid_type_error: "Please enter your email address",
    })
    .email({
      message: "Please enter a valid email address",
    }),
  name: z.string(),
  projectTitle: z.string(),
  projectName: z.string().optional(),
  projectStatus: SelectedOption.refine((val) =>
    projectStatus.find((state) => state.value === val.value)
  ),
  dateOfIDO: z.string(),
  dateOfTGE: z.string().optional(),
  fundraisingStage: SelectedOption.refine((val) =>
    fundraisingStages.find((state) => state.value === val.value)
  ),
  fundraisedUSD: z.string().optional(),
  planToRaiseUSD: z.string(),
  fundAllocation: z.string().optional(),
  tokenBlockchain: z.string(),
  tokenUseCase: z.string(),
  kycStatus: SelectedOption.refine((val) =>
    kycStatus.find((status) => status.value === val.value)
  ),
  mvpUrl: z.string().optional(),
  listingName: z.string().optional(),
  teamProfileUrl: z.string().optional(),
  extraServices: SelectedOption.refine((val) =>
    extraServices.find((service) => service.value === val.value)
  ),
  websiteUrl: z.string().optional(),
  telegram: z.string().optional(),
  twitter: z.string().optional(),
  discord: z.string().optional(),
  mediumOrBlog: z.string().optional(),
  otherChannels: z.string().optional(),
  contactNumber: z.string(),
});

export const defaultValues = {
  projectName: "",
  dateOfTGE: "",
  fundraisedUSD: "",
  fundAllocation: "",
  mvpUrl: "",
  listingName: "",
  teamProfileUrl: "",
  websiteUrl: "",
  telegram: "",
  twitter: "",
  discord: "",
  mediumOrBlog: "",
  otherChannels: "",
};

export type FormSchemaType = z.infer<typeof FormSchema>;
