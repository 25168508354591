import { blockExplorers } from "connectors/chains";
import { ethers } from "ethers";
import { useMasterChefAddress } from "hooks/contracts/useContractAddresses";
import { useMasterChefInterface } from "hooks/contracts/useInterfaces";
import useChainId from "hooks/useChainId";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStore } from "store/zustand";
import { erc20ABI, useAccount, useSigner, useWaitForTransaction } from "wagmi";

export default function useGetFarmAllowance(pid: number | undefined) {
  const { data: signer } = useSigner();
  const masterChefAddress = useMasterChefAddress();
  const { address } = useAccount();
  const farmInfo = useFarmInfo(pid);

  const { data: lpTokenAllowance, refetch } = useQuery(
    ["lpTokenAllowance", farmInfo?.lpToken],
    async () => {
      const contract = new ethers.Contract(farmInfo.lpToken, erc20ABI, signer);
      const allowance = await contract.allowance(address, masterChefAddress);
      return allowance;
    },
    {
      enabled: !!signer && !!farmInfo?.lpToken,
    }
  );

  return {
    amount: !!lpTokenAllowance
      ? ethers.utils.formatEther(lpTokenAllowance)
      : undefined,
    lpToken: farmInfo?.lpToken,
    refetch,
  };
}

export function useApproveLPAllowance(pid: number, refetch: () => void) {
  const updateToast = useStore((state) => state.updateToast);
  const chainId = useChainId();
  const masterchefAddress = useMasterChefAddress();
  const farmInfo = useFarmInfo(pid);
  const { data: signer } = useSigner();
  // console.log("farmInfo.lpToken", farmInfo.lpToken);
  const {
    mutate: write,
    data: approveData,
    isLoading: writeLoading,
  } = useMutation(
    async () => {
      const masterchefContract = new ethers.Contract(
        farmInfo.lpToken,
        erc20ABI,
        signer
      );
      const response = await masterchefContract.approve(
        masterchefAddress,
        ethers.constants.MaxUint256
      );
      return response;
    },
    {
      onError: (error: any) => {
        updateToast({
          type: "error",
          message: `Transaction Failed ${error?.message}`,
          url: "",
        });
      },
    }
  );
  const {
    isError,
    isLoading: transactionLoading,
    error: transactionError,
    isSuccess,
  } = useWaitForTransaction({
    confirmations: 2,
    hash: approveData?.hash,
    onSettled: () => {
      console.log("useApproveLPAllowance settled");
      refetch();
    },
    onSuccess: () => {
      updateToast({
        type: "success",
        message: "Farm RHUB LP contract approved",
        url: `${blockExplorers.get(chainId)}/tx/${approveData?.hash}`,
      });
      !!refetch && refetch();
    },
    onError: (error) => {
      console.log("error", error);
      updateToast({
        type: "error",
        message: `Transaction Failed ${error?.message}`,
        url: "",
      });
    },
  });
  return {
    write,
    isLoading: writeLoading || transactionLoading,
    error: isError ? transactionError : undefined,
    isSuccess,
    refetch,
  };
}

export function useFarmInfo(pid: number | undefined) {
  const { data: signer } = useSigner();
  const masterchefAddress = useMasterChefAddress();
  const masterchefInterface = useMasterChefInterface();
  const { data: farmInfo } = useQuery(
    ["useFarmInfo", pid],
    async () => {
      const farmContract = new ethers.Contract(
        masterchefAddress,
        masterchefInterface,
        signer
      );
      const poolInfo = farmContract.poolInfo(pid);
      return poolInfo;
    },
    {
      enabled: !!signer,
    }
  );

  return farmInfo;
}
