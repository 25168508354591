import styled from "styled-components";
import HeaderPad from "./HeaderPad";

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  background: ${({ theme }) => theme.bg};
  padding-bottom: 5rem;
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: min(1920px, calc(100% - 16rem));
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 1024px) {
    max-width: calc(100% - 1rem);
  }
`;

export default function Container({ children }) {
  return (
    <StyledWrapper>
      <StyledContainer>
        <HeaderPad />
        {children}
      </StyledContainer>
    </StyledWrapper>
  );
}
