import styled, { keyframes } from "styled-components";
import { BsArrowDown, BsQuestionCircle } from "react-icons/bs";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const StyledQuestionCircle = styled(BsQuestionCircle)`
  margin-left: 4px;
  margin-right: 4px;
`;

export const StyledArrowDown = styled(BsArrowDown)`
  margin: 0.825rem 0;
  color: ; //gradient color
`;

export const ToastMessage = styled.p`
  display: flex;
  flex-direction: column;

  a {
    color: rgba(243, 8, 137, 1);
    text-decoration: none;
    font-size: 1rem;
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: min(1920px, calc(100% - 16rem));
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 0 1rem;
    margin: 0;
    max-width: 100%;
  }
`;

export const SwapCardContainer = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

export const Header = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 1rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1.7rem;
  border-bottom: 1px solid rgba(243, 8, 137, 0.224);
`;

export const Body = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1.7rem;
`;

export const CoinFieldContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h3`
  font-family: "Nasalization";
  margin-bottom: 0.5rem;
  font-size: 1.17rem;
`;

export const Sub = styled.p`
  font-family: "Nasalization";
  opacity: 0.64;
  margin-bottom: 0;
  font-size: 0.8125rem;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

export const SwapButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 1.7rem;
`;

export const SwapIconContainer = styled.div`
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  svg:hover {
    cursor: pointer;
  }
`;

export const TokenBalanceContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-left: 24px;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #f7f8fa;
  }
`;

export const SwapperContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SwapTransactionInfo = styled.div`
  width: 575px;
  margin: 1.5rem auto;
  padding: 1.7rem;
  box-shadow: inset 0 0 2rem #ff008780;
  background: radial-gradient(
      circle at center center,
      rgba(225, 0, 255, 0.067),
      transparent 40%
    ),
    radial-gradient(
      circle at left top,
      rgba(255, 153, 0, 0.204),
      transparent 12%
    ),
    radial-gradient(
      circle at right top,
      rgba(255, 0, 115, 0.376),
      transparent 15%
    ),
    radial-gradient(
      circle at right bottom,
      rgba(255, 187, 0, 0.408),
      transparent 30%
    ),
    radial-gradient(
      circle at left bottom,
      rgba(255, 0, 115, 0.286),
      transparent 25%
    ),
    linear-gradient(
      96deg,
      rgba(231, 23, 92, 0.243) 0%,
      rgba(248, 31, 78, 0.216) 100%
    );
  border-radius: 1rem;

  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
`;

export const SwapTransactionInfoText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #fff;
  font-famiy: 'Jakarta Sans'

  &:last-child {
    margin-bottom: 0;
  }

  p,
  span {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: 600;
  }
`;
