import {
  useTransition,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { usePalette } from "@lauriys/react-palette";
import styled from "styled-components";
import GradientButton from "components/GradientBtn";
import useConfirmPoolDeposit, {
  useConfirmFarmUnstake,
} from "./hooks/useConfirmFarmDeposit";
import useGetPoolInfo, { getFarmApr } from "./hooks/useGetFarms";
import useGetPoolAllowance, {
  useApproveLPAllowance,
} from "./hooks/useGetFarmsAllowance";
import { useAccount } from "wagmi";
import AddFarmModal from "./AddFarmModal";
import useFarmLiquidityValue, {
  useFarmLPAmount,
} from "views/Liquidity/hooks/useFarmLiquidityValue";
import {
  usePriceByPairs,
  useUSDTRhubAmount,
  useUSDTRoseAmount,
} from "views/Liquidity/hooks/useUSDTPrice";
import { SerializedFarmConfig } from "types";
import { deserializeToken } from "utils/serialized";
import { USDT } from "utils/tokens";
import { multiplyPriceByAmount } from "views/Liquidity/hooks/useLPValues";
import useChainId from "hooks/useChainId";
import BigNumber from "bignumber.js";
import GradientBtnAlt from "components/GradientBtnAlt";
import WithdrawalsModal from "./WithdrawalsModal";
import { useGetLockInfoLength } from "./hooks/useGetLockInfo";
import { RHUB } from "constants/tokens";
import { ChainId, WNATIVE } from "@rosehub-tech/sdk";
import UnstakeModal from "./UnstakeModal";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "services/firebase";
import { FARMS_TVL_KEY } from "config/exchange";
import { ethers } from "ethers";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  border-bottom: 1px solid #222325;
  -webkit-box-shadow: inset 0 0 2rem ${({ theme }) => theme.primary}80;
  -moz-box-shadow: inset 0 0 2rem ${({ theme }) => theme.primary}80;
  box-shadow: inset 0 0 2rem ${({ theme }) => theme.primary}80;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  max-width: 24rem;
  min-width: 22rem;
  flex-wrap: wrap;
  min-height: 425px;

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`;

const Logo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Token = styled.div`
  width: 2.5rem;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 3px solid #2c2936;
  z-index: 1;
  overflow: hidden;
`;

const Token1 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 100%;
  padding: 0.25rem;
`;

const Token2 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
  padding: 0.25rem;
`;

const Title = styled.h3`
  font-family: "Nasalization";
  font-size: 1.15rem;
`;

const Pair = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.p`
  opacity: 0.56;
  font-weight: 500;
  margin-bottom: 0;
`;

const Value = styled.h3`
  font-size: 1.17rem;
`;

const Stretch = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.primary}29;
  width: 100%;
  padding: 1rem 1.5rem 1rem 1.5rem;
  height: fit-content;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0 2rem 0;
`;

const DefaultActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`;

const Symbol = styled.span`
  opacity: 0.56;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 0.8125rem;
`;

const HarvestWarning = styled.span`
  font-size: 0.8125rem;
  opacity: 0.56;
  text-align: center;
  margin-top: 0.5rem;
  padding: 1rem;
`;

const TopActions = styled.div`
  display: flex;
  align-items: center;
`;

interface GridItemProps {
  farm: SerializedFarmConfig;
}
export default function GridItem({ farm }: GridItemProps) {
  const pid = farm?.pid;
  const chainId = useChainId();
  const [isPending, startTransition] = useTransition();
  const { data: bg1 } = usePalette(`/tokens/${farm?.quoteToken?.address}.png`);
  const { data: bg2 } = usePalette(`/tokens/${farm?.token?.address}.png`);
  const [showAddPool, setShowAddPool] = useState(false);
  const [showWithdrawals, setShowWithdrawals] = useState(false);
  const { isConnected } = useAccount();
  const { data: lockInfoLength } = useGetLockInfoLength();
  const farmInfo = useGetPoolInfo(pid);
  const { amount: allowance, refetch, lpToken } = useGetPoolAllowance(pid);
  const { write, isLoading: isApprovingLPAllowance } = useApproveLPAllowance(
    pid,
    refetch
  );
  const [isStaking, setIsStaking] = useState(false);
  const [amountIn, setAmountIn] = useState(0);
  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const enabledStaking = useRef(false);
  const enabledUnstaking = useRef(false);
  const isHarvestingRef = useRef(false);

  const liquidity = useFarmLiquidityValue(
    lpToken || farm?.lpAddresses[chainId]
  );
  const { data: lpLiquidity } = useFarmLPAmount(
    lpToken || farm?.lpAddresses[chainId]
  );

  const lpBalance = useMemo(() => {
    if (!!lpLiquidity) {
      return lpLiquidity?.balanceOf
        ? ethers.utils.formatEther(lpLiquidity.balanceOf)
        : undefined;
    }
    return undefined;
  }, [lpLiquidity]);

  const liquidity0Value = liquidity?.reserve0; //token0
  const liquidity1Value = liquidity?.reserve1; //token1

  const rhubUsdtPrice = useUSDTRhubAmount(1);
  const roseUsdtPrice = useUSDTRoseAmount(1);
  const token0 = deserializeToken(farm?.quoteToken);
  const token1 = deserializeToken(farm?.token);
  const price0 = usePriceByPairs(
    isConnected ? USDT[chainId] : undefined,
    isConnected ? token0 : undefined,
    farm.lpAddresses[chainId]
  );
  const price1 = usePriceByPairs(
    isConnected ? USDT[chainId] : undefined,
    isConnected ? token1 : undefined,
    farm.lpAddresses[chainId]
  );

  const price0Amount = useMemo(() => {
    if (!price0) return 0;
    if (token0.equals(USDT[chainId])) {
      return Number(liquidity0Value);
    }
    if (token0.equals(RHUB[chainId])) {
      return Number(liquidity0Value) * rhubUsdtPrice;
    }
    if (token0.equals(WNATIVE[chainId])) {
      return Number(liquidity0Value) * roseUsdtPrice;
    }
    return price0 ? multiplyPriceByAmount(price0, Number(liquidity0Value)) : 0;
  }, [chainId, liquidity0Value, price0, token0, rhubUsdtPrice, roseUsdtPrice]);

  const reset = () => {
    setShowAddPool(false);
    farmInfo?.refetch();
    setIsStaking(false);
    setAmountIn(0);
    if (!!isHarvestingRef.current) {
      isHarvestingRef.current = false;
    }
    if (!!enabledStaking.current) {
      enabledStaking.current = false;
    }
    if (!!enabledUnstaking.current) {
      enabledUnstaking.current = false;
    }
  };

  const price1Amount = useMemo(() => {
    if (!price1) return 0;
    if (token1.equals(USDT[chainId])) {
      return Number(liquidity1Value);
    }
    if (token1.equals(RHUB[chainId])) {
      return Number(liquidity1Value) * rhubUsdtPrice;
    }
    if (token1.equals(WNATIVE[chainId])) {
      return Number(liquidity1Value) * roseUsdtPrice;
    }
    return !!price1
      ? multiplyPriceByAmount(price1, Number(liquidity1Value))
      : 0;
  }, [price1, token1, chainId, liquidity1Value, roseUsdtPrice, rhubUsdtPrice]);

  const totalLiquidityUSD =
    price0Amount || price1Amount ? price0Amount + price1Amount : 0;

  const totalRHUBLPInUSD = useMemo(() => {
    if (pid === 0 || pid === 4) {
      const roseReserve = pid === 0 ? liquidity.reserve1 : liquidity.reserve0;
      const usdValue =
        Number(lpBalance) *
        (Number(roseReserve) / Number(lpBalance)) *
        roseUsdtPrice *
        2;

      return usdValue;
    }
    const rhubReserve = liquidity.reserve0;
    const usdValue =
      Number(lpBalance) *
      (Number(rhubReserve) / Number(lpBalance)) *
      rhubUsdtPrice *
      2;
    return usdValue;
  }, [liquidity, lpBalance, pid, rhubUsdtPrice, roseUsdtPrice]);

  const { onConfirmDeposit, isLoading: isDepositing } = useConfirmPoolDeposit(
    pid,
    amountIn,
    enabledStaking?.current,
    isHarvestingRef?.current,
    reset
  );

  const handleConfirmDeposit = () => {
    setIsStaking(true);
    isHarvestingRef.current = false;
    enabledStaking.current = true;
    startTransition(() => {
      onConfirmDeposit();
    });
  };

  const { onConfirmUnstake, isLoading: isUnstaking } = useConfirmFarmUnstake(
    pid,
    amountIn,
    enabledUnstaking?.current,
    reset
  );
  const handleConfirmUnstake = () => {
    enabledUnstaking.current = true;
    startTransition(() => {
      onConfirmUnstake();
    });
  };
  const stakedAmount =
    !!farmInfo && Number(farmInfo?.stakedAmount) > 0
      ? Number(farmInfo?.stakedAmount)
      : undefined;
  const earned =
    !!farmInfo && Number(farmInfo?.pendingRewards) > 0
      ? Number(farmInfo?.pendingRewards)
      : undefined;
  const pairSymbol = !!farm?.token
    ? `${farm.quoteToken.symbol}-${farm.token.symbol}`
    : farm.quoteToken.symbol;

  const farmAPR = getFarmApr(
    chainId,
    BigNumber(farmInfo?.farmWeight),
    BigNumber(rhubUsdtPrice),
    BigNumber(totalLiquidityUSD),
    farm?.lpAddresses[chainId],
    Number(farmInfo?.apr)
  );

  const handleHarvestFarm = () => {
    isHarvestingRef.current = true;
    enabledStaking.current = true;
    startTransition(() => {
      onConfirmDeposit();
    });
  };

  const handleInputChange = useCallback(
    (amount: number) => setAmountIn(amount),
    []
  );

  const handleUpdateFarmsTVL = useCallback(
    async (data) => {
      await updateDoc(
        doc(db, FARMS_TVL_KEY, farm.lpAddresses[ChainId.EMERALD_MAINNET]),
        data
      );
    },
    [farm]
  );

  useEffect(() => {
    if (!!totalLiquidityUSD && !!totalRHUBLPInUSD) {
      handleUpdateFarmsTVL({
        rhub: totalLiquidityUSD,
        rhubLP: totalRHUBLPInUSD,
      });
    }
  }, [totalLiquidityUSD, handleUpdateFarmsTVL, totalRHUBLPInUSD]);

  const handleUnstakeModal = () => setShowUnstakeModal((old) => !old);
  return (
    <Container>
      <Stretch>
        <Pair>
          <Logo>
            <Token style={{ width: "3.6rem", backgroundColor: bg1.vibrant }}>
              <Token1 src={`/tokens/${farm.quoteToken?.symbol}.png`} />
            </Token>
            {!!farm?.token ? (
              <Token
                style={{
                  transform: "translate(-1.72rem, 0.5rem)",
                  backgroundColor: bg2.vibrant,
                }}
              >
                <Token2 src={`/tokens/${farm.token?.symbol}.png`} />
              </Token>
            ) : null}
          </Logo>
          <Title>{pairSymbol}</Title>
        </Pair>
        <Item>
          <Label>APR</Label>
          <Value>
            {farmAPR?.rhubRewardsApr
              ? farmAPR.rhubRewardsApr?.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })
              : "0"}
            %
          </Value>
        </Item>
      </Stretch>
      <Stretch>
        <Item>
          <Label>Reward per block</Label>
          <Value>
            {farmInfo?.apr} <Symbol>RHUB</Symbol>
          </Value>
        </Item>
        <Item>
          <Label style={{ textAlign: "right" }}>Liquidity</Label>
          <Value>
            {totalLiquidityUSD > 0
              ? Number(totalLiquidityUSD).toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : 0}{" "}
            <Symbol>{totalLiquidityUSD > 0 ? " USD" : ""}</Symbol>
          </Value>
        </Item>
      </Stretch>
      <Stretch>
        <Item>
          <Label>Earned</Label>
          <Value>
            {earned ? Number(earned).toFixed(6) : "0.0"}
            <Symbol>{earned ? " RHUB" : ""}</Symbol>
          </Value>
        </Item>
        <Item>
          <Label style={{ textAlign: "right" }}>Staked</Label>
          <Value>
            {stakedAmount ? Number(stakedAmount).toFixed(6) : "0.0"}
            <Symbol>{stakedAmount ? ` ${pairSymbol}` : ""}</Symbol>
          </Value>
        </Item>
      </Stretch>
      <Actions>
        <TopActions>
          {!!allowance && Number(allowance) > 0 ? (
            <GradientButton
              width={!!stakedAmount ? 150 : 240}
              label="Stake LP"
              onClick={() => {
                setShowAddPool(true);
              }}
              disabled={!isConnected}
              disabledText="Stake LP"
              loading={isPending || (isDepositing && isStaking && !showAddPool)}
            />
          ) : null}
          {!allowance || (!!allowance && Number(allowance) <= 0) ? (
            <GradientButton
              width={150}
              label="Approve"
              onClick={write}
              disabled={!isConnected || farmInfo?.isLoading}
              disabledText="Approve"
              disabledWidth={150}
              loading={isApprovingLPAllowance}
            />
          ) : null}
          {!!stakedAmount ? (
            <GradientBtnAlt
              width={150}
              label="Unstake"
              onClick={handleUnstakeModal}
              loading={isUnstaking}
            />
          ) : null}
        </TopActions>
        <DefaultActions>
          {Number(earned) > 0 ? (
            <GradientBtnAlt
              width={150}
              label="Harvest"
              onClick={handleHarvestFarm}
              loading={isDepositing && isHarvestingRef.current}
            />
          ) : null}
          {lockInfoLength > 0 ? (
            <GradientBtnAlt
              width={150}
              label="Withdraw"
              onClick={() => setShowWithdrawals((old) => !old)}
            />
          ) : null}
        </DefaultActions>
        {Number(earned) > 0 ? (
          <HarvestWarning>
            * After Harvesting, you must wait {farmInfo.lockDuration} days
            before your RHUB can be unlock and available for withdrawal.
          </HarvestWarning>
        ) : null}
      </Actions>
      <WithdrawalsModal
        show={showWithdrawals}
        handleClose={() => {
          setShowWithdrawals((old) => !old);
          farmInfo?.refetch();
        }}
      />
      <AddFarmModal
        show={showAddPool}
        handleClose={() => {
          setShowAddPool(false);
        }}
        onChangeAmount={handleInputChange}
        onConfirmDeposit={handleConfirmDeposit}
        loading={isDepositing}
        tokenAddress={lpToken}
        pairSymbol={!!farm?.token && !!farm.quoteToken ? pairSymbol : ""}
      />
      <UnstakeModal
        show={showUnstakeModal}
        handleClose={handleUnstakeModal}
        onChangeAmount={handleInputChange}
        onConfirmUnstake={handleConfirmUnstake}
        loading={isUnstaking}
        stakedAmount={stakedAmount?.toString()}
      />
    </Container>
  );
}
