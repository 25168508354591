import styled, { keyframes } from "styled-components";

const Pulse = keyframes`
    0%   {background-size: 100% 100%;}
    25%  {background-size: 300% 300%;}
    50%  {background-size: 200% 200%;}
    75%  {background-size: 400% 350%;}
    100% {background-size: 100% 100%;}
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  margin-right: 4px;

  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 4px solid #e100ff8d;
  background: transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const Inset = styled.div`
  background-color: black;
  width: calc(100% - 0.2rem);
  height: calc(100% - 0.2rem);
  position: absolute;
  border-radius: 5rem;
  top: 0.1rem;
  left: 0.1rem;
`;

const Container: any = styled.button`
  color: black;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0.75rem 1.75rem;
  border-radius: 5rem;
  height: 40px;
  width: ${({ width }: { width: number }) =>
    !!width ? `${width}px` : "fit-content"};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  position: relative;
  background: ${({ bg }: any) => bg};
  overflow: hidden;
  transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &::before {
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
          circle at center center,
          rgba(225, 0, 255, 0.553),
          transparent 40%
        )
        no-repeat,
      radial-gradient(circle at left top, rgb(255, 153, 0), transparent 12%),
      radial-gradient(circle at right top, rgb(255, 0, 115), transparent 15%),
      radial-gradient(circle at right bottom, rgb(255, 187, 0), transparent 30%),
      radial-gradient(circle at left bottom, rgb(255, 0, 115), transparent 25%),
      linear-gradient(96deg, rgb(231, 23, 93) 0%, rgb(248, 31, 78) 100%);
    transition: all 560ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    animation: 1400ms ease 0s infinite normal none running
      ${({ animate }: any) => animate && Pulse};
    background-repeat: no-repeat;
  }

  &::after {
    opacity: 0;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;

    animation: 820ms ease 0s infinite normal none running
      ${({ animate, animateOnHover }: any) =>
        (animate || animateOnHover) && Pulse};
    background-repeat: no-repeat;
  }

  &:hover {
    ${Inset} {
      background-color: #25051bfa;
    }

    &::after {
      opacity: ${({ stroked }: any) => (!stroked ? 1 : 0)};
    }
  }
`;

const Label = styled.label`
  font-family: "Nasalization";
  cursor: pointer;
  z-index: 2;
  color: rgb(255, 0, 115);
`;

interface GradientBtnWhiteProps {
  label: string;
  onClick: () => void;
  width?: number;
  loading?: boolean;
}

export default function GradientBtnAlt({
  label,
  onClick,
  width,
  loading,
}: GradientBtnWhiteProps) {
  return (
    <Container stroked animate animateOnHover onClick={onClick} width={width}>
      <Inset />
      {loading ? (
        <>
          <Spinner /> <Label>Pending</Label>
        </>
      ) : (
        <Label> {label}</Label>
      )}
    </Container>
  );
}
