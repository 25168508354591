import styled from "styled-components";
import { useState } from "react";
import { useAccount } from "wagmi";
import { usePalette } from "@lauriys/react-palette";
import useLPValues from "./hooks/useLPValues";
import { useNavigate } from "react-router";
import GradientBtnAlt from "components/GradientBtnAlt";
import GradientButton from "components/GradientBtn";
import RemoveLiquidityModal from "./RemoveLiquidityModal";
import caretIcon from "assets/icons/caret.svg";
import {
  CurrencyAmount,
  Pair as LiquidityPair,
  Token as RosehubToken,
} from "@rosehub-tech/sdk";
import { useSortTokenPair } from "./hooks/useSortTokens";

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primary}29;
  padding: 1rem 1.5rem 1rem 1.5rem;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Logo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Token = styled.div`
  width: 2.5rem;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 3px solid #2c2936;
  z-index: 1;
  overflow: hidden;

  &:last-child {
    transform: translate(-1rem);
  }
`;

const Token1 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  padding: 0.25rem;
  border-radius: 100%;
`;

const Token2 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0.25rem;
  border-radius: 100%;
`;

const Title = styled.h3`
  font-family: "Nasalization";
  font-size: 1.17rem;
`;

const Pair = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Label = styled.p`
  opacity: 0.56;
  font-weight: 500;
  margin-bottom: 0;
`;

const Value = styled.h3`
  white-space: nowrap;
  font-size: 1.17rem;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Caret = styled.button`
  display: grid;
  place-content: center;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.75rem 0.64rem;
  border-radius: 0.5rem;
  width: fit-content;
  height: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.inputBG};
  }
`;

const CaretIcon = styled.img.attrs({ src: caretIcon })`
  width: 0.7rem;
  filter: invert(1);
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1fr);
    gap: 2rem;
  }
`;

const ExpandedContainer: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  align-self: flex-end;
  height: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0 1rem;

  @media (max-width: 1024px) {
    align-self: center;
  }

  &.show-more {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
`;

const PoolDetailsContainer = styled.div`
  width: 100%;
`;

const PoolDetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PoolDetailsActions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  justify-content: center;

  button {
    width: 220px !important;
  }
`;

export function formatTokenAmount(
  amount: CurrencyAmount<RosehubToken>,
  digits = 3
) {
  if (!amount) return "-";
  const amountStr = amount.toExact();
  if (Math.abs(Number(amountStr)) > 1) {
    return Number(amountStr).toLocaleString();
  }
  return amount.toSignificant(digits);
}

interface ListItemProps {
  pair: LiquidityPair;
  onShowLiquidity: Function;
}

export function ListItem({ pair, onShowLiquidity }: ListItemProps) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const { address } = useAccount();

  const handleClose = () => setShowRemove((old) => !old);

  const [tokenA, tokenB] = useSortTokenPair(pair);
  const token0Image = `/tokens/${tokenA?.symbol}.png`;
  const token1Image = `/tokens/${tokenB?.symbol}.png`;

  const {
    poolTokenPercentage,
    token0Deposited,
    token1Deposited,
    userPoolBalance,
    totalUSDValue,
  } = useLPValues(address, pair);
  const { data: bg1 } = usePalette(token0Image);
  const { data: bg2 } = usePalette(token1Image);

  return (
    <Container>
      <Main>
        <Pair>
          <Logo>
            <Token style={{ backgroundColor: bg1.vibrant }}>
              <Token1 src={token0Image} />
            </Token>

            <Token style={{ backgroundColor: bg2.vibrant }}>
              <Token2 src={token1Image} />
            </Token>
          </Logo>
          <Title>
            {tokenA?.symbol}-{tokenB.symbol}
          </Title>
        </Pair>

        <Caret onClick={() => setIsOpen(!isOpen)}>
          <CaretIcon />
        </Caret>
      </Main>

      <ExpandedContainer className={isOpen && "show-more"}>
        <PoolDetailsContainer>
          <PoolDetailsItem>
            <Label>Pool Balance</Label>
            <Value>
              <span>{`${formatTokenAmount(
                userPoolBalance as CurrencyAmount<RosehubToken>
              )} `}</span>
            </Value>
          </PoolDetailsItem>

          {Number.isFinite(totalUSDValue) ? (
            <PoolDetailsItem>
              <Label>Total USD Value</Label>
              {Number.isFinite(totalUSDValue) && (
                <Value>
                  {totalUSDValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  USD
                </Value>
              )}
            </PoolDetailsItem>
          ) : null}

          <PoolDetailsItem>
            <Label>{`Pooled ${tokenA.symbol}`}</Label>
            <Value>
              {`${formatTokenAmount(
                token0Deposited as CurrencyAmount<RosehubToken>
              )}
               ${tokenA?.symbol}`}
            </Value>
          </PoolDetailsItem>
          <PoolDetailsItem>
            <Label>{`Pooled ${tokenB.symbol}`}</Label>
            <Value>{`${formatTokenAmount(
              token1Deposited as CurrencyAmount<RosehubToken>
            )} ${tokenB.symbol}`}</Value>
          </PoolDetailsItem>
          <PoolDetailsItem>
            <Label>Your pool share</Label>
            <Value>
              {poolTokenPercentage
                ? `${
                    poolTokenPercentage.toFixed(6) === "0.00"
                      ? "<0.01"
                      : poolTokenPercentage.toFixed(6)
                  }%`
                : "-"}
            </Value>
          </PoolDetailsItem>
        </PoolDetailsContainer>
        <PoolDetailsActions>
          <GradientBtnAlt label="Remove" onClick={handleClose} />
          <GradientButton
            onClick={() => {
              onShowLiquidity();
              navigate(
                `/liquidity?token0=${tokenA.symbol}&token1=${tokenB.symbol}`
              );
            }}
            label="Add"
          />
        </PoolDetailsActions>
      </ExpandedContainer>
      <RemoveLiquidityModal
        show={showRemove}
        handleClose={handleClose}
        currencies={{
          token0: tokenA,
          token1: tokenB,
        }}
        images={{
          token0: token0Image,
          token1: token1Image,
        }}
        poolTokenPercentage={poolTokenPercentage}
        token0Deposited={token0Deposited}
        token1Deposited={token1Deposited}
        userPoolBalance={userPoolBalance}
        pair={pair}
      />
    </Container>
  );
}
