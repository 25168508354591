import { Currency, Trade, TradeType } from "@rosehub-tech/sdk";
import { ONE_BIPS } from "config/exchange";
// import useReserves from "hooks/useReserves";
import { useMemo } from "react";
// import { useStore } from "store/zustand";
import { SwapField } from "types";
import {
  computeSlippageAdjustedAmounts,
  // warningSeverity,
} from "./hooks/useDerivedSwapInfo";
import { computeTradePriceBreakdown } from "./hooks/useSwapActionHandlers";
import {
  StyledQuestionCircle,
  SwapTransactionInfo,
  SwapTransactionInfoText,
} from "./styles/style";

interface TradeSummaryProps {
  trade?: Trade<Currency, Currency, TradeType>;
  allowedSlippage: number;
}

export const TOTAL_FEE = 0.0025;
export const LP_HOLDERS_FEE = 0.0017;
export const TREASURY_FEE = 0.000225;
export const BUYBACK_FEE = 0.000575;

export default function TradeSummary({
  trade,
  allowedSlippage,
}: TradeSummaryProps) {
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade]
  );
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade]
  );

  return (
    <SwapTransactionInfo>
      <SwapTransactionInfoText>
        <p>
          <span>
            {trade?.tradeType === TradeType.EXACT_INPUT
              ? "Minimum received"
              : "Maximum sold"}
            <StyledQuestionCircle />
          </span>
        </p>{" "}
        <span>
          {" "}
          {trade?.tradeType === TradeType.EXACT_INPUT
            ? slippageAdjustedAmounts[SwapField.OUTPUT]?.toSignificant(4) ?? "-"
            : slippageAdjustedAmounts[SwapField.INPUT]?.toSignificant(4) ?? "-"}
        </span>
      </SwapTransactionInfoText>
      <SwapTransactionInfoText>
        <p>
          Price Impact <StyledQuestionCircle />{" "}
        </p>
        <span>
          {priceImpactWithoutFee
            ? priceImpactWithoutFee.lessThan(ONE_BIPS)
              ? "<0.01%"
              : `${priceImpactWithoutFee.toFixed(2)}%`
            : "-"}
        </span>
      </SwapTransactionInfoText>
      <SwapTransactionInfoText>
        <p>
          Liquidity Provider Fee <StyledQuestionCircle />
        </p>
        <span>
          {realizedLPFee
            ? `${realizedLPFee?.toSignificant(6)} ${
                trade.inputAmount.currency.symbol
              }`
            : "-"}
        </span>
      </SwapTransactionInfoText>
    </SwapTransactionInfo>
  );
}
